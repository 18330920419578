import React from "react";
import "./Home.scss";
import BottomHeader from "../../componentsNew/Header/BottomHeader";

function customHome(props) {
  return (
    <div className="home-page">
      <div className="bg-50">
        <BottomHeader noHeaderColour={true} />
        <div className="px-sm-5 px-2  row m-0 d-flex justify-content-center mt-5 py-5">
          {/* <div className="col-lg-6 col-sm-10 col-12 mb-lg-0 mb-5 ">
            <div className="h2 text-white">Training Centre</div>
            <div className="divide bg-white mt-5" />
            <div className="h4-web text-white mt-3">
             The Lion Group Training Centre portal is the gateway to a range of resources to efficiently manage staff and track their training. Quick links and to plan, manage, schedule trainings. 
            </div>
            <div className="mt-3 d-flex flex-sm-row flex-column">
              <Button
                className="rounded text-nowrap w-200 "
                size="lg"
                title="Learn More"
                variant="primary"
              />
              <Button
                className="rounded ms-sm-4 mt-sm-0 mt-3 text-nowrap w-200"
                size="lg"
                title="Access Portal"
                variant="primary"
              />
            </div>
          </div> */}
          <div className="col-lg-6 col-sm-10 col-12 pe-0">{props.children}</div>
        </div>
      </div>
    </div>
  );
}

export default customHome;
