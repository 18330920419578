import React from "react";
import Arrow from "../../../../assets/svg/arrowRight.svg";
import { useHistory } from "react-router-dom";
import "./BreadCrumb.scss";

export const BreadCrumb = ({ breadCrumbItems = [] }) => {
  // const history = createBrowserHistory();
  const history = useHistory();

  return (
    <div className=" h7 text-grey1 d-flex align-items-center py-1">
      {breadCrumbItems.map((item, index) => (
        <React.Fragment key={`breadcrumb_${index}`}>
          <span
            className="sp-breadcrumb"
            onClick={() => {
              item.page && item.page(0);
              history.push(item?.link);
            }}
          >
            {item?.title}
          </span>
          {index + 1 !== breadCrumbItems?.length && (
            <img className="p-3" src={Arrow} alt="" />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};
