const Auth = {
  LOGOUT: "api/user/logout",
  LOGIN: "api/user/login",
  SEND_RESET_OTP: "login/sendresetotp",
  VERIFY_RESET_OTP: "login/verifyresetotp",
  RESET_PASSWORD: "login/resetpassword",
};

const Pilot = {
  MY_PROFILE: "api/pilot/profile",
  MEDICAL_CERTIFICATE: "api/pilot/medicalcertificates",
  DOCUMENTS: "api/pilot/documents",
  QUALIFICATION: "api/pilot/qualifications",
  TRAININGS: "common/getTrainings",
  GETTRAININGFORM: "api/TrainingForm",
  GETFILTEREDTRAININGFORMS: "api/TrainingForm/search",
  TRAINING_FORM: "common/getFormData?id",
  SCHEDULE_TRAINING: "api/pilot/schedule-trainings",
  REVIEW_QUALIFICATION: "/reviewed",
};

const Trax = {
  GET_PART: "api/Material/",
  GET_ALLPARTS: "api/Material/parts/",
  REQUISITION_REQUEST: "api/Requisition/request",
  REQUISITION_REQUEST_LIST: "api/requisition/request/list/",
  REQUISITION_ACTION: "api/requisition/request/action",
  REQUISITION_CLOSE: "api/requisition/request/close",
  GET_LOCATIONS: "api/Master/locations",
  GET_SITES: "api/Master/sites",
  GET_ACS: "api/Master/acs",
  GET_WOS: (selectedAC) => `api/Master/wos/${selectedAC}`,
  GET_TASKCARD: (selectedAC, selectedWO) =>
    `api/Master/task-cards/${selectedAC}/${selectedWO}`,
  GET_DEFECTS: (selectedAC, selectedWO, selectedTaskCard) =>
    `api/Master/defects/${selectedAC}/${selectedWO}/${selectedTaskCard}`,
};

const Dashboard = {
  GET_DASHBOARD_DATA: "api/Dashboard/1",
};

const Director = {
  DASHBOARD: "api/do/profile",
  TRAINING_SCHEDULE: "api/Training/active-schedule",
  LICENSE_DOCUMENT: "api/do/pilots/document-dues",
  QUALIFICATION_DATA: "api/master/data-qualfications",
  TRAINING_REVIEW: "api/Training/request/schedules/to-reviews",
  PILOT_QUALIFICATION: "api/do/pilotqualifications",
  REQUEST_SCHEDULE: "api/Training/request/schedule",
  ASSIGN_TRAINING_PILOT: "api/do/assigntrainingtopilot",
};

const Admin = {
  GET_STAFF: "api/user",
  GET_USER: "api/user",
  GET_USER_LIST_BY_ROLE: (roleCode) => `api/user/byrole/${roleCode}`,
  ADD_USER: "api/user",
  UPDATE_USER: "api/user",
  DELETE_USER: "api/user",
  GET_MASTER_FETCH: "api/master/data-fetch",
};

const Master = {
  GET_ROLES: "api/master/roles",
  GET_COMPANY: "api/master/company",
};
const MechDashboard = {
  GET_MECH_DASHBOARD_DATA: "api/requisition/track",
  MECH_REQUISITION_REQUEST: "api/Mechanic/requisition-request",
  GET_DASHBOARD_SHIPPING_DATA: "api/Dashboard/shipped",
  GET_DASHBOARD_RECEIVING_DATA: "api/Dashboard/receiving",
};

export const ENDPOINTS = {
  ...Auth,
  ...Pilot,
  ...Director,
  ...Admin,
  ...Trax,
  ...Dashboard,
  ...Master,
  ...MechDashboard,
};
