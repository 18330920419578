import React, { useState, useContext, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
// import CreateShedule from "../../TrainingSchedule/CreateSheduleV2";

import "./DirectorTrainingSchedule.scss";
import {
  BreadCrumb,
  Button,
  Dropdown,
  Checkbox,
  Badge,
  InputSearch,
} from "../../componentsNew/core";
import moment from "moment";
// import Clock from "../../../assets/svg/clock.svg";
// import ClockIcon from "../../../assets/svg/Clock-icn.svg";
import ArrowLeft from "../../../assets/svg/NewIcons/arrow_left_white.svg";
import ArrowRight from "../../../assets/svg/NewIcons/arrow_right_white.svg";
import { errorToast, successToast } from "../../componentsNew/Toast/Toast";
import { ENDPOINTS, getApi, postApi } from "../../services/http";
import { getFromLocal } from "../../services/storage/LocalStorage";
import ConstructObject from "../../hooks/helper";
import { ViewState, EditingState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  Toolbar,
  MonthView,
  DayView,
  DateNavigator,
  WeekView,
  ViewSwitcher,
  Appointments,
  AppointmentTooltip,
  AppointmentForm,
  AllDayPanel,
  // navigationButtonComponent,
} from "@devexpress/dx-react-scheduler-material-ui";
import RequestTraining from "./ReuestTrainingsSchedule";
import { useModal } from "../../hooks/useModal";
import { ModalContext } from "../../services/context/modal-context/State";
import { PageDetails } from "../../componentsNew/page-details/PageDetails";
import { usePagination } from "../../hooks/usePagination";

const breadCrumbItems = [
  {
    title: "Lion Group",
    link: "/dashboard/new-design/director/dashboard",
  },
  {
    title: "Training Schedule",
    link: 1,
  },
];

function DirectorTrainingSchedule(props) {
  //initial form appointments
  let initial = {
    title: "",
    //startDate: '',
    endDate: "",
    maxAttendees: 0,
    description: "",
    location: "",
    qualificationId: 0,
    scheduleType: 1,
    pilotIds: [],
  };

  const [schedulerData, setSchedulerData] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [startDayHour] = useState(0);
  const [endDayHour] = useState(24);
  const [reload, setReLoad] = useState(false);
  const [records, getRecords] = useState([]);
  const [, setAddedAppointment] = useState({});
  const [, setDeletedAppointmentId] = useState(undefined);
  const [finalRow, setFinalRow] = useState(records);
  const [searchText, setSearchText] = useState("");
  const [trainingSchedules, setTrainingSchedules] = useState([]);
  const [, setTrainings] = useState([]);
  const [pilotSelection, setPilotSelection] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [selectedTrainingId, setSelectedTrainingId] = useState(0);
  const [previousAppointment, setPreviousAppointment] = useState(undefined);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [isNewAppointment, setIsNewAppointment] = useState(false);
  const [editingFormVisible, setEditingFormVisible] = useState(false);
  const [editingAppointment, setEditingAppointment] = useState(undefined);
  const [usePayLoad, setUsePayLoad] = useState(initial);
  const [expiryDays, setExpiryDays] = useState(null);
  const [loadPilots, setLoadPilots] = useState(false);
  const [formMasterData] = useState([]);
  const [trainingsDrop, setTrainingsDrop] = useState([]);
  const { handleExpiredToken } = useModal();
  const { setIsLoading } = useContext(ModalContext);
  const { pagination, rowItems, handlePagination } = usePagination(finalRow);

  useEffect(() => {
    handlePagination();
  }, [finalRow]);

  const handleChangeWithLib = (value) => {
    setSearchText(value);
    let newRows = [];
    if (value) {
      let searchTxt = value.toString().trim().toLowerCase();
      newRows = records?.filter((value) => {
        return (
          value.pilotName && value.pilotName.toLowerCase().includes(searchTxt)
        );
      });
    } else {
      newRows = [...records];
    }
    setFinalRow(newRows);
  };

  const getSchedules = async () => {
    setIsLoading(true);
    var user = getFromLocal("user");
    var userId = parseInt(user?.userId);
    var headers = {
      UserId: userId,
    };
    const response = await getApi(`${ENDPOINTS.TRAINING_SCHEDULE}`, {
      headers,
    });
    if (response) {
      if (response.status == 200) {
        setTrainingSchedules(response?.data);
        if (response?.data.length > 0) {
          var scheduleData = response?.data.map((el) => ({
            ...el,
            rRule: el.repeatTrainingRule,
            exDate: el.repeatTrainingExcludeDates,
          }));
          setSchedulerData(scheduleData);
        }

        setIsLoading(false);
      } else if (response.status == 401) {
        setIsLoading(false);
        handleExpiredToken();
      } else {
        setIsLoading(false);
      }
    }
  };

  const handleClick = async (status) => {
    if (status === "Update") {
      if (parseInt(selectedTrainingId) === 0) {
        errorToast("Please select training");
      } else {
        setIsLoading(true);
        var user = getFromLocal("user");
        var userId = parseInt(user?.userId);

        var idsToUpdate = pilotSelection
          ?.filter((x) => x.isAssigned === true)
          .map((a) => a.pilotId);
        var request = {
          PilotIds: idsToUpdate,
          UserId: userId,
          TrainingScheduleId: parseInt(selectedTrainingId),
        };

        const response = await postApi(
          `${ENDPOINTS.ASSIGN_TRAINING_PILOT}`,
          request
        );
        if (response) {
          if (response.status == 200) {
            setIsLoading(false);
            getPilots(parseInt(selectedTrainingId));
            successToast("Successfully Updated");
            getSchedules();
          } else if (response.status == 401) {
            setIsLoading(false);
            handleExpiredToken();
          } else {
            setIsLoading(false);
          }
        }
      }
    }
    if (status === "Cancel") {
      var tempArray = JSON.parse(JSON.stringify(responseData));

      getRecords(tempArray);
      let temp = [...tempArray];
      setPilotSelection(temp);
    }
  };

  const changeDate = (e) => {
    setCurrentDate(new Date(e));
    setReLoad(!reload);
  };

  function setChecked(e) {
    var selectedCount = pilotSelection?.filter(
      (x) => x.isAssigned === true
    ).length;
    if (selectedCount >= 10 && e.target.checked) {
      errorToast("10 Attendees maximum");
      e.target.checked = false;
    } else {
      let temp = [...pilotSelection];
      temp.filter((x) => x.pilotId == parseInt(e.target.value))[0].isAssigned =
        e.target.checked;
      setPilotSelection(temp);
    }
  }

  const handleGetPilots = (event) => {
    const selectedTrainingId = event;
    if (selectedTrainingId === "0") {
      getRecords([]);
      setPilotSelection([]);
      setResponseData([]);
    } else {
      setSelectedTrainingId(selectedTrainingId);
      getPilots(selectedTrainingId);
    }
  };

  const getPilots = async (trainingId, expiryDays = 90) => {
    setIsLoading(true);
    var user = getFromLocal("user");

    var userId = parseInt(user?.userId);

    var request = {
      companyCode: user?.companyCode,
      scheduleTrainingId: parseInt(trainingId),
      userId: userId,
      noofDaysInExpiry: expiryDays,
    };

    const response = await postApi(`${ENDPOINTS.PILOT_QUALIFICATION}`, request);
    if (response) {
      if (response.status == 200) {
        setIsLoading(false);
        let pilotSelection = response?.data;
        setPilotSelection(pilotSelection);
        getRecords(pilotSelection);
        var tempArray = JSON.parse(JSON.stringify(pilotSelection));
        setResponseData(tempArray);
      } else if (response.status == 401) {
        setIsLoading(false);
        handleExpiredToken();
      } else {
        setIsLoading(false);
        errorToast(response?.data?.title ?? response?.statusText);
        getRecords([]);
        setPilotSelection([]);
        setResponseData([]);
      }
    }
  };

  useEffect(() => {
    if (trainingSchedules?.length > 0) {
      var trainings = trainingSchedules?.map((el) => ({
        trainingType: el.trainingType,
        qualification: el.qualification,
        title: el.title,
        id: el.id,
        startDate: el.startDate,
      }));
      setTrainings(trainings);

      let trainingDrop = trainings.map((el) => ({
        value: el.id,
        label: `${el.qualification} - ${el.title} -${" "}
        ${moment(el.startDate).format("DD/MM/YYYY")}`,
      }));

      setTrainingsDrop(trainingDrop);
    }
  }, [trainingSchedules]);

  useEffect(() => {
    getSchedules();

    var expiry =
      props.location.state !== undefined
        ? props.location.state["expiryDays"]
        : null;

    if (expiry || expiry === 0) {
      setExpiryDays(parseInt(expiry));
      setLoadPilots(true);
    }
  }, []);

  useEffect(() => {
    if (loadPilots) {
      getPilots(0, expiryDays);
    }
  }, [expiryDays]);

  useEffect(() => {
    if (records) {
      setFinalRow(records);
    }
  }, [records]);

  const toggleConfirmationVisible = () => {
    setConfirmationVisible(!confirmationVisible);
  };

  const toggleEditingFormVisibility = () => {
    setEditingFormVisible(!editingFormVisible);
  };

  const onAddedAppointmentChange = (addedAppointment) => {
    setAddedAppointment(addedAppointment);

    usePayLoad["endDate"] = addedAppointment?.endDate;
    usePayLoad["allDay"] = addedAppointment?.allDay;

    if (editingAppointment !== undefined) {
      setPreviousAppointment(editingAppointment);
    }
    setUsePayLoad({ ...usePayLoad });
    setEditingAppointment(undefined);
    setIsNewAppointment(true);
  };

  const commitChanges = ({ added, changed, deleted }) => {
    if (added) {
      AddAppointment(added);
    }
    if (changed) {
      ModifyAppointment(changed);
    }
    if (deleted !== undefined) {
      setDeletedAppointmentId(deleted);

      toggleConfirmationVisible();
    }

    setAddedAppointment({});
    setUsePayLoad(initial);
  };

  async function AddAppointment(appointment) {
    setIsLoading(true);
    var user = getFromLocal("user");
    var userId = parseInt(user?.userId);
    var request = {
      userId: userId,
      companyCode: user?.companyCode,
      cutOffDate: appointment?.endDate,
      ...appointment,
    };

    const response = await postApi(`${ENDPOINTS.REQUEST_SCHEDULE}`, request);
    if (response) {
      if (response.status == 200) {
        setIsLoading(false);
        successToast("Request created");
      } else if (response.status == 401) {
        setIsLoading(false);
        handleExpiredToken();
      } else {
        setIsLoading(false);
        errorToast("Request Creation failed");
      }
    }
  }

  async function ModifyAppointment(changed) {}
  // async function DeleteAppointment(id) {}

  // const commitDeletedAppointment = (deletedAppointmentId) => {
  //   DeleteAppointment(deletedAppointmentId);
  // };
  const onEditingAppointmentChange = (editingAppointment) => {
    setEditingAppointment(editingAppointment);
  };

  const openModals = () => {
    const currentAppointment =
      (schedulerData &&
        schedulerData.filter(
          (appointment) =>
            editingAppointment && appointment.id === editingAppointment.id
        )[0]) ||
      usePayLoad;

    const cancelAppointment = () => {
      if (isNewAppointment) {
        setEditingAppointment(previousAppointment);
        setIsNewAppointment(false);
        setUsePayLoad(initial);
      }
    };
    return (
      <>
        {editingFormVisible && (
          <RequestTraining
            visible={editingFormVisible}
            appointmentData={currentAppointment}
            commitChanges={commitChanges}
            visibleChange={toggleEditingFormVisibility}
            onEditingAppointmentChange={onEditingAppointmentChange}
            cancelAppointment={cancelAppointment}
            formMasterData={formMasterData}
          />
        )}
      </>
    );
  };

  const Content = ({ children, appointmentData, ...restProps }) => (
    <AppointmentTooltip.Content
      {...restProps}
      appointmentData={appointmentData}
    >
      {appointmentData?.assignedPilots?.length > 0 && (
        <>
          <hr />
          <div className="pilots-assigned">
            <h6>Pilots Assigned</h6>
            <div>
              <ul>
                {appointmentData?.assignedPilots?.map((pilot) => (
                  <li>{pilot?.pilotName}</li>
                ))}
              </ul>
            </div>
          </div>
        </>
      )}
    </AppointmentTooltip.Content>
  );

  const Appointment = ({ children, style, ...restProps }) => (
    <Appointments.Appointment
      {...restProps}
      style={{
        ...style,
        backgroundColor: "#BCBCBC",
        borderRadius: "8px",
      }}
    >
      {children}
    </Appointments.Appointment>
  );

  const CustomNavigationButton = (props) => {
    return (
      <DateNavigator.NavigationButton
        {...props}
        className="bg-highlight3 me-2"
        style={{ width: 32, height: 32 }}
      />
    );
  };

  const schedule = (
    <>
      <Paper>
        <Scheduler data={schedulerData} height={660}>
          <ViewState
            currentDate={currentDate}
            onCurrentDateChange={(e) => changeDate(e)}
          />
          <EditingState
            onCommitChanges={commitChanges}
            onEditingAppointmentChange={onEditingAppointmentChange}
            onAddedAppointmentChange={onAddedAppointmentChange}
          />
          <MonthView />

          <DayView startDayHour={startDayHour} endDayHour={endDayHour} />
          <WeekView startDayHour={startDayHour} endDayHour={endDayHour} />
          <AllDayPanel />
          <Appointments appointmentComponent={Appointment} />
          <AppointmentTooltip showCloseButton contentComponent={Content} />
          <Toolbar />
          <DateNavigator navigationButtonComponent={CustomNavigationButton} />
          <ViewSwitcher />
          <AppointmentForm
            overlayComponent={openModals}
            visible={editingFormVisible}
            onVisibilityChange={toggleEditingFormVisibility}
          />
        </Scheduler>
      </Paper>
    </>
  );

  const TableControls = (
    <div className="col-12 d-flex align-items-center p-0">
      <div className="col-md-3 p-0 align-items-center">
        <PageDetails pagination={pagination} />
      </div>
      <div className="d-flex col-md-9 justify-content-end pr-0">
        <div className="flex-wrap d-flex align-items-center justify-content-center">
          <div className="">
            <div className="col-md-12 col-sm-4 col-8">
              <InputSearch
                placeholder="search here..."
                className="w-100"
                onChange={handleChangeWithLib}
                //   value={searchText}
              />
            </div>
          </div>
        </div>

        <div className="col-xs-3 d-flex align-items-center justify-content-center pe-0">
          <Badge
            className="rounded-50"
            variant="primary"
            size="sm"
            icon={ArrowLeft}
            title="Previous Page"
            disabled={!pagination?.prevPageUrl}
            onClick={() => handlePagination("prev")}
          />
          <Badge
            className="rounded-50 ms-2"
            variant="primary"
            size="sm"
            icon={ArrowRight}
            title="Next Page"
            disabled={!pagination?.nextPageUrl}
            onClick={() => handlePagination("next")}
          />
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="px-5">
        <BreadCrumb breadCrumbItems={breadCrumbItems} />
        <div className="row my-4">
          <div className="col-6">
            <div className="box-shadow p-3 h-400 rounded d-flex flex-column justify-content-between ">
              <div>
                <div className="h4 ps-0 text-grey2 p-2">Assign Pilots</div>
                <div className="h7 mb-3">Select Trainings by LGTC</div>
                {/* <Dropdown onChange={handleGetPilots} value={selectedTrainingId}>
                  <option value={""}>Select</option>
                  {trainings?.map((training) => (
                    <option value={training.id}>
                      {training.trainingType} - {training.title} -{" "}
                      {moment(training.startDate).format("DD/MM/YYYY")}
                    </option>
                  ))}
                </Dropdown> */}

                <Dropdown
                  Shadow
                  selectOptions={trainingsDrop}
                  value={ConstructObject(trainingsDrop, selectedTrainingId)}
                  onChange={handleGetPilots}
                  containerClassName={"w-100 p-0"}
                />
                <div className="d-flex my-3 justify-content-between">
                  <div className="h7">Choose Pilots</div>
                  <u className="text-danger h8 ">10 Attendees Maximum</u>
                </div>

                {searchText == ""
                  ? rowItems.length > 0 && TableControls
                  : TableControls}
                <div className="mt-2">
                  <table className="w-100 custom-table">
                    <tr className="h7 text-grey1">
                      <th>Select</th>
                      <th>Name</th>
                      <th>Qualification</th>
                      <th>Expiry Date</th>
                    </tr>
                    <tbody className="h7-web ">
                      {rowItems && rowItems.length > 0 ? (
                        rowItems.map((row, rowIndex) => (
                          <tr className=" m-2">
                            <td>
                              <Checkbox
                                className="rounded-3"
                                size="xs"
                                value={row.pilotId}
                                checked={row.isAssigned}
                                onChange={setChecked}
                              />
                            </td>
                            <td>{row.pilotName}</td>
                            <td>{row.qualficationName}</td>
                            <td className="text-danger">
                              {row.qualificationExpiryDate
                                ? moment(row.qualificationExpiryDate).format(
                                    "DD/MM/YYYY"
                                  )
                                : ""}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr className="text-center h6-web">No records found</tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="mt-2 text-grey1 h5">
                <u>Request a Training</u>
              </div>
            </div>
            <div className="d-flex mt-4">
              <Button
                className="rounded me-2"
                size="lg"
                title="Cancel"
                variant="primary"
                onClick={() => handleClick("Cancel")}
              />
              <Button
                className="rounded "
                size="lg"
                title="Update"
                variant="success"
                onClick={() => handleClick("Update")}
              />
            </div>
          </div>

          <div className="col-6 customize-scheduler">
            <div className="box-shadow p-3 h-400 rounded d-flex flex-column justify-content-between ">
              {schedule}
            </div>

            {/* <div className="mt-4">
              <div className="h4 ">Reminder</div>
              <div className="h8 text-grey1">
                Dont forget schedule for tomorrow
              </div>
              <div className="bg-grey d-flex align-items-center  border-radius-10 mt-3 p-2">
                <div className="box-remainder d-flex justify-content-center align-items-center bg-white rounded p-3">
                  <img src={Clock} />
                </div>
                <div className="ms-3">
                  <div className="text-grey2 h8">
                    Urus SIM di samsat Klayatan{" "}
                  </div>
                  <div className="text-grey2 h8-400 ">
                    {" "}
                    <img className="me-2" src={ClockIcon} />
                    12.00 - 16.00
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default DirectorTrainingSchedule;
