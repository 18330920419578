import React, { useState, useEffect } from "react";
import { errorToast, successToast } from "../../componentsNew/Toast/Toast";
import { ENDPOINTS, getApi, postApi, putApi } from "./../../services/http";
import { Dropdown, Input, Button } from "../../componentsNew/core";
import "./../../scss/trax.scss";
import { ConstructObject } from "../../helper";

const initialState = {
  firstName: "",
  middleName: "",
  lastName: "",
  emailId: "",
  roleId: "",
  companyCode: "",
  staffId: "",
};

function TraxUserModal(props) {
  const { setShowModal, setIsLoading, title, data, getUserData } = props;
  const [modalTitle, setModalTitle] = useState("Add User");
  const [userRoles, setUserRoles] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [staffResponse, setStaffResponse] = useState([]);
  const [payload, setPayload] = useState(initialState);
  const [staffId, setStaffId] = useState("");

  useEffect(() => {
    if (data) {
      setPayload({
        ...payload,
        firstName: data?.firstName,
        middleName: data?.middleName,
        lastName: data?.lastName,
        companyCode: data?.companyCode,
        emailId: data?.email,
        roleId: data?.roles[0]?.roleId,
        staffId: data?.staffId,
      });
      setStaffId(data?.staffId);
    }

    setModalTitle(title);
    getRoles();
    getCompanies();
  }, []);

  const getStaffDetail = async () => {
    setIsLoading(true);
    const response = await getApi(`${ENDPOINTS.GET_STAFF}/${staffId}`);
    if (response) {
      if (response.status === 200) {
        setIsLoading(false);

        if (response?.data?.length > 1) {
          setStaffResponse(response?.data);
          let staff = response?.data.map((item) => ({
            value: item.staffId,
            label: item.firstName + " " + item.lastName,
            ...item,
          }));
          setStaffResponse(staff);
          setPayload({
            ...payload,
            firstName: "",
            lastName: "",
            companyCode: "",
            emailId: "",
          });
        } else {
          setPayload({
            ...payload,
            firstName: response?.data[0]?.firstName,
            lastName: response?.data[0]?.lastName,
            companyCode: response?.data[0]?.companyCode,
            emailId: response?.data[0]?.emailId,
            staffId: response?.data[0]?.staffId,
          });
          if (response?.data[0]?.staffId) {
            setStaffId(response?.data[0]?.staffId);
          }
          setStaffResponse([]);
        }
      } else {
        setIsLoading(false);

        errorToast("something went wrong, Please try again");
      }
    }
  };

  const getRoles = async () => {
    const response = await getApi(`${ENDPOINTS.GET_ROLES}`);
    if (response) {
      if (response.status === 200) {
        let user = response?.data.map((item) => ({
          value: item.roleId,
          label: item.roleName,
        }));
        setUserRoles(user);
      } else {
        errorToast("something went wrong, Please try again");
      }
    }
  };

  const getCompanies = async () => {
    const response = await getApi(`${ENDPOINTS.GET_COMPANY}`);
    if (response) {
      if (response.status === 200) {
        let companies = response?.data.map((item) => ({
          value: item.code,
          label: `${item.code} - ${item.name}`,
        }));

        setCompanies(companies);
      } else {
        errorToast("something went wrong, Please try again");
      }
    }
  };

  const selectStaff = (key, value) => {
    var selectedStaff = staffResponse?.find((x) => x.staffId === value);

    setPayload({
      firstName: selectedStaff?.firstName,
      lastName: selectedStaff?.lastName,
      companyCode: selectedStaff?.companyCode,
      emailId: selectedStaff?.emailId,
      staffId: selectedStaff?.staffId,
    });
  };

  const updatePayload = (key, value) => {
    if (key === "roleId") {
      setPayload({
        ...payload,
        [key]: value,
      });
      return;
    }

    setPayload({ ...payload, [key]: value });
  };

  const submitForm = async (url, updatedPayload, type) => {
    setIsLoading(true);

    let response = null;
    if (type === "Add User") {
      response = await postApi(url, updatedPayload);
    } else {
      response = await putApi(url, updatedPayload);
    }

    if (response) {
      if (response.status === 200) {
        successToast(
          type === "Add User"
            ? "Successfully added a new user"
            : "Successfully updated the user"
        );
        getUserData();
      } else {
        errorToast(response?.data?.title);
      }
    }
    setIsLoading(false);
  };

  const handleModalSubmit = async () => {
    let requestData = {
      Email: payload.emailId,
      FirstName: payload.firstName,
      MiddleName: payload.middleName,
      LastName: payload.lastName,
      CompanyCode: payload.companyCode,
      RoleId: payload.roleId,
      StaffId: payload.staffId,
    };

    // Add User
    if (modalTitle === "Add User") {
      requestData = {
        ...requestData,
      };
      submitForm(ENDPOINTS.ADD_USER, requestData, "Add User");
    }
    // Update User
    else {
      var url = `${ENDPOINTS.UPDATE_USER}/${data?.userId}`;
      requestData = {
        ...requestData,
      };
      submitForm(url, requestData, "Update User");
    }
    setShowModal(false);
  };

  const controlButtons = (
    <div className="d-flex justify-content-end">
      <Button
        className="rounded h6-web w-80"
        size="md"
        title={"Cancel"}
        variant="grey2"
        onClick={() => setShowModal(false)}
      />
      <Button
        className="ml-2 rounded h6-web w-80"
        size="md"
        title={modalTitle === "Add User" ? "Add" : "Update"}
        variant="primary"
        onClick={handleModalSubmit}
      />
    </div>
  );

  const renderEditRequestModal = (
    <>
      <h4>{modalTitle}</h4>

      <div className="row align-items-center mx-2 mt-4">
        <span className="col-sm-3 h6 p-0">Staff ID</span>
        <Input
          Shadow
          className={`h6 rounded p-0 ${
            modalTitle === "Edit User" ? "col-sm-9" : "col-sm-6"
          }`}
          value={staffId}
          onChange={(value) => setStaffId(value)}
          type="text"
          readOnly={modalTitle === "Edit User"}
          placeholder="Staff ID"
        />
        {modalTitle !== "Edit User" && (
          <Button
            className="rounded col-sm-3 p-1 mb-2"
            size="md"
            title={"Retrieve"}
            variant="primary"
            onClick={() => getStaffDetail()}
          />
        )}
      </div>

      {staffResponse?.length > 0 && (
        <div className="row align-items-center m-2  my-3">
          <span className="col-12 col-sm-3 h6 p-0">Select Staff</span>
          <Dropdown
            Shadow
            selectOptions={staffResponse}
            placeholder="Select Staff Name"
            value={ConstructObject(staffResponse, payload.staffId)}
            onChange={(value) => selectStaff("staffId", value)}
            containerClassName={" p-0 col-sm-9"}
          />
        </div>
      )}
      <div className="row align-items-center m-2  my-3">
        <span className="col-12 col-sm-3 h6 p-0">User Type</span>
        <Dropdown
          Shadow
          selectOptions={userRoles}
          placeholder="Select User Type"
          value={ConstructObject(userRoles, payload.roleId)}
          onChange={(value) => updatePayload("roleId", value)}
          containerClassName={" p-0 col-12 col-sm-9"}
        />
      </div>

      <div className="row align-items-center mx-2 my-3">
        <span className="col-sm-3 h6 p-0">First name</span>
        <Input
          Shadow
          className="h6 rounded p-0 col-sm-9"
          value={payload.firstName}
          onChange={(value) => updatePayload("firstName", value)}
          type="text"
          readOnly={true}
          placeholder="First name"
        />
      </div>
      <div className="row align-items-center mx-2 my-3">
        <span className="col-sm-3 h6 p-0">Middle name</span>
        <Input
          Shadow
          className="h6 rounded p-0 col-sm-9"
          value={payload.middleName}
          onChange={(value) => updatePayload("middleName", value)}
          type="text"
          readOnly={true}
          placeholder="Middle name"
        />
      </div>
      <div className="row align-items-center mx-2 my-3">
        <span className="col-sm-3 h6 p-0">Last name</span>
        <Input
          Shadow
          className="h6 rounded p-0 col-sm-9"
          value={payload.lastName}
          onChange={(value) => updatePayload("lastName", value)}
          type="text"
          readOnly={true}
          placeholder="Last name"
        />
      </div>

      <div className="row align-items-center mx-2 my-3">
        <span className="col-sm-3 h6 p-0">Email ID</span>
        <Input
          Shadow
          className="h6 rounded p-0 col-sm-9"
          value={payload.emailId}
          onChange={(value) => updatePayload("emailId", value)}
          type="text"
          disabled={true}
          placeholder="Email ID"
        />
      </div>

      <div className="row align-items-center mx-2 my-3">
        <span className="col-sm-3 h6 p-0">Company</span>
        <Dropdown
          Shadow
          selectOptions={companies}
          placeholder="Select Company"
          value={ConstructObject(companies, payload.companyCode)}
          onChange={(value) => updatePayload("companyCode", value)}
          containerClassName={" p-0 col-sm-9"}
        />
      </div>

      {controlButtons}
    </>
  );

  return <div>{renderEditRequestModal}</div>;
}
export default TraxUserModal;
