import React from "react";
import moment from "moment";

export default function AdminDashboard({
  dashboardData,
  tableSortKey,
  sort,
  SortBy,
}) {
  return (
    <table className="table table-bordered shadow-sm align-middle ">
      <thead className="bg-grey h7-table text-grey1">
        <tr className="text-center text-nowrap">
          <td className="has-pointer" onClick={() => SortBy(0)}>
            RQ number
            <i
              className={
                tableSortKey === 0
                  ? sort === 0
                    ? "fa fa-sort-asc"
                    : "fa fa-sort-desc"
                  : "fa fa-fw fa-sort"
              }
            ></i>
          </td>
          <td>RQ Line</td>
          <td onClick={() => SortBy(3)}>
            PN
            <i
              className={
                tableSortKey === 3
                  ? sort === 0
                    ? "fa fa-sort-asc"
                    : "fa fa-sort-desc"
                  : "fa fa-fw fa-sort"
              }
            ></i>
          </td>
          <td className="has-pointer">PN Quantity</td>
          <td className="has-pointer" onClick={() => SortBy(1)}>
            Type
            <i
              className={
                tableSortKey === 1
                  ? sort === 0
                    ? "fa fa-sort-asc"
                    : "fa fa-sort-desc"
                  : "fa fa-fw fa-sort"
              }
            ></i>
          </td>
          <td className="has-pointer" onClick={() => SortBy(4)}>
            Order Type
            <i
              className={
                tableSortKey === 4
                  ? sort === 0
                    ? "fa fa-sort-asc"
                    : "fa fa-sort-desc"
                  : "fa fa-fw fa-sort"
              }
            ></i>
          </td>
          <td className="has-pointer" onClick={() => SortBy(2)}>
            Order number
            <i
              className={
                tableSortKey === 2
                  ? sort === 0
                    ? "fa fa-sort-asc"
                    : "fa fa-sort-desc"
                  : "fa fa-fw fa-sort"
              }
            ></i>
          </td>
          <td>Order line</td>
          <td>Order PN</td>
          <td>Quantity</td>
          <td onClick={() => SortBy(5)}>
            EDD
            <i
              className={
                tableSortKey === 5
                  ? sort === 0
                    ? "fa fa-sort-asc"
                    : "fa fa-sort-desc"
                  : "fa fa-fw fa-sort"
              }
            ></i>
          </td>
          <td onClick={() => SortBy(6)}>
            Status
            <i
              className={
                tableSortKey === 6
                  ? sort === 0
                    ? "fa fa-sort-asc"
                    : "fa fa-sort-desc"
                  : "fa fa-fw fa-sort"
              }
            ></i>
          </td>
          <td>Order Status</td>
        </tr>
      </thead>
      <tbody className="h8 text-table-body">
        {dashboardData?.map((row, rowIndex) => (
          <tr className="text-center text-nowrap" key={"row_" + rowIndex}>
            <td>{row.requisition}</td>
            <td>{row.requisitionLine}</td>
            <td>{row.pn}</td>
            <td>{row.pnQty}</td>
            <td>{row.priorityType}</td>
            <td>{row.orderType}</td>
            <td>{row.orderNumber}</td>
            <td>{row.orderLine}</td>
            <td>{row.orderPN}</td>
            <td>{row.orderQty}</td>
            <td>{row.edd ? moment(row.edd).format("DD/MM/YYYY") : ""}</td>
            <td>{row.status}</td>
            <td>{row.orderStatus}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
