import React, { useState, useEffect, useContext } from "react";
import "./../../scss/trax.scss";
import { ModalContext } from "../../services/context/modal-context/State";
import TRAXAccount from "../TraxAccount/TRAXAccount";
import { useModal } from "../../hooks/useModal";
import moment from "moment";

import {
  Badge,
  InputSearch,
  Button,
  DatePickers,
  Input,
  Dropdown,
} from "../../componentsNew/core";
import downloadsvg from "../../../assets/svg/NewIcons/xls-icon.svg";
import ArrowLeft from "../../../assets/svg/NewIcons/arrow_left_white.svg";
import ArrowRight from "../../../assets/svg/NewIcons/arrow_right_white.svg";
import AdminDashboard from "./adminDashboard";
import MechDashboard from "./MechDashboard";
// import { getFromLocal } from "../../services/storage/LocalStorage";
import {
  downloadHandler,
  getAdminDashData,
  getMechDashData,
  getUserListData,
} from "./function";
import { UserContext } from "../../services/context/userContext/State";
import { errorToast } from "../../componentsNew/Toast/Toast";

function TraxDashboard() {
  const { setIsLoading } = useContext(ModalContext);
  const { role } = useContext(UserContext);
  const { handleExpiredToken } = useModal();
  const [pagingData, setPagingData] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchTextPart, setSearchTextPart] = useState("");
  const [searchTextUser, setSearchTextUser] = useState("");
  const [isMyRequisitionRequest, setIsMyRequisitionRequest] = useState(false);
  const [SearchByStatus, setSearchByStatus] = useState(0);
  const [SearchByAuthorization, setSearchByAuthorization] = useState("");
  const [AssignedTo, setAssignedTo] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [goto, setGoto] = useState(currentPage);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [userList, setUserList] = useState([]);
  //Sort Order --> 0:Asc 1:Desc
  const [sort, setSort] = useState(0);
  //Sort tableSortKey --> 0:Requisition 1:RequisitionType 2:OrderNumber 3:OrderType 4:EDD
  const [tableSortKey, setTableSortKey] = useState(0);

  useEffect(() => {
    if (
      role === "Admin" ||
      role === "MP" ||
      role === "Prod" ||
      role === "Gen"
    ) {
      var today = new Date();
      today.setHours(0, 0, 0, 0);
      var yesterday = new Date();
      yesterday.setHours(0, 0, 0, 0);
      yesterday.setDate(yesterday.getDate() - 1);

      setStartDate(yesterday);
      setEndDate(today);
      var req = {
        startDate: moment(yesterday).format("YYYY-MM-DD"),
        endDate: moment(today).format("YYYY-MM-DD"),
        pageNo: 1,
        pageSize: 25,
        sorting: 0,
        sortingOrder: 0,
      };
      getDashData(req);
    }
    if (role === "Admin" || role === "Gen") {
      getUserListData(setUserList);
    }
  }, [role]);

  const handlePaging = (value, pageNo) => {
    var pageNum = currentPage;
    if (value === "go") {
      pageNum = Number(pageNo) || 1;
      Number(pageNo) || setGoto(1);
    }
    if (value === "prev") {
      pageNum = currentPage - 1;
    }
    if (value === "next") {
      pageNum = currentPage + 1;
    }
    var req = {
      startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
      endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
      pageNo: pageNum,
      pageSize: 25,
      sorting: tableSortKey,
      sortingOrder: sort,
    };
    if (SearchByStatus) {
      req.SearchByStatus = SearchByStatus;
    }
    if (searchTextUser) {
      req.AssignedTo = AssignedTo;
    }
    if (searchText) {
      req.SearchByRequisition = Number(searchText);
    }
    if (searchTextPart) {
      req.SearchByPartNumber = searchTextPart;
    }
    if (isMyRequisitionRequest) {
      req.isMyRequisitionRequest = isMyRequisitionRequest;
    }
    if (SearchByAuthorization) {
      req.Authorized = SearchByAuthorization;
    }
    getDashData(req);
  };
  const getData = async () => {
    if (
      role === "Admin" ||
      role === "MP" ||
      role === "Prod" ||
      role === "Gen"
    ) {
      const startdate = startDate
        ? moment(startDate).format("YYYY-MM-DD")
        : null;
      const enddate = endDate ? moment(endDate).format("YYYY-MM-DD") : null;
      if (
        !(
          (startdate && enddate) ||
          SearchByStatus ||
          searchText ||
          searchTextPart
        )
      ) {
        errorToast(
          enddate ? `Required min one filter to search` : "Enter end date."
        );
        return;
      }
      let req = {
        startDate: startdate,
        endDate: enddate,
        pageNo: 1,
        pageSize: 25,
        sorting: 0,
        sortingOrder: 0,
      };
      if (SearchByStatus) {
        req.SearchByStatus = SearchByStatus;
      }
      if (searchTextUser) {
        req.AssignedTo = AssignedTo;
      }
      if (searchText) {
        req.SearchByRequisition = Number(searchText);
      }
      if (searchTextPart) {
        req.SearchByPartNumber = searchTextPart;
      }
      if (isMyRequisitionRequest) {
        req.isMyRequisitionRequest = isMyRequisitionRequest;
      }
      if (SearchByAuthorization) {
        req.Authorized = SearchByAuthorization;
      }
      getDashData(req);
    }
    if (role === "Unknown") {
      getMechDashData(
        searchText,
        setIsLoading,
        setDashboardData,
        handleExpiredToken
      );
    }
  };

  const getDashData = (request) =>
    getAdminDashData(
      request,
      setIsLoading,
      setPagingData,
      setDashboardData,
      setCurrentPage,
      setLastPage,
      handleExpiredToken
    );

  const SortBy = (key) => {
    var sortBy = 0;
    if (tableSortKey === key) {
      if (sort === 0) {
        setSort(1);
        sortBy = 1;
      } else if (sort === 1) {
        setSort(0);
      }
    } else {
      setTableSortKey(key);
      setSort(0);
    }
    var req = {
      startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
      endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
      pageNo: 1,
      pageSize: 25,
      sorting: key,
      sortingOrder: sortBy,
    };
    if (SearchByStatus) {
      req.SearchByStatus = SearchByStatus;
    }
    if (searchTextUser) {
      req.AssignedTo = AssignedTo;
    }
    if (searchText) {
      req.SearchByRequisition = Number(searchText);
    }
    if (searchTextPart) {
      req.SearchByPartNumber = searchTextPart;
    }
    if (isMyRequisitionRequest) {
      req.isMyRequisitionRequest = isMyRequisitionRequest;
    }
    if (SearchByAuthorization) {
      req.Authorized = SearchByAuthorization;
    }
    getDashData(req);
  };
  const resetFn = () => {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let yesterday = new Date();
    yesterday.setHours(0, 0, 0, 0);
    yesterday.setDate(yesterday.getDate() - 1);

    setSearchText("");
    setSearchByStatus(0);
    setSearchTextPart("");
    setAssignedTo("");
    setSearchTextUser("");
    setStartDate(yesterday);
    setEndDate(today);
    setIsMyRequisitionRequest(false);
  };
  const filteredUserList = (searchTextUser) =>
    userList?.filter(
      (user) =>
        `${user.firstName} ${user.lastName}`
          .toLowerCase()
          .includes(searchTextUser.toLowerCase()) ||
        user.userId
          .toString()
          .toLowerCase()
          .includes(searchTextUser.toLowerCase())
    ) || [];

  const TableControls =
    role === "Unknown" ? (
      <></>
    ) : (
      <>
        <div className="d-flex justify-content-end align-items-center">
          {!!(pagingData?.currentPage && pagingData?.maxPages) && (
            <p className="h8 text-grey1 mb-0 mr-2">
              Page {pagingData?.currentPage} of {pagingData?.maxPages} (Total
              Records: {pagingData?.totalItems})
            </p>
          )}
          <Input
            style={{ width: "50px" }}
            type="number"
            value={goto}
            onChange={(value) => {
              value >= 0 && value < pagingData?.maxPages + 1 && setGoto(value);
            }}
          />
          <Badge
            className="rounded-50 ms-2 text-white"
            variant="primary"
            size="xl"
            title="Go"
            disabled={currentPage === lastPage}
            onClick={() => handlePaging("go", goto)}
          />
          <Badge
            className="rounded-50 ms-2"
            variant="primary"
            size="xl"
            icon={ArrowLeft}
            title="Previous Page"
            disabled={currentPage === 1}
            onClick={() => handlePaging("prev")}
          />
          <Badge
            className="rounded-50 ms-2"
            variant="primary"
            size="xl"
            icon={ArrowRight}
            title="Next Page"
            disabled={currentPage === lastPage}
            onClick={() => handlePaging("next")}
          />
          <Badge
            className="rounded-50 ms-2"
            variant="primary"
            size="xl"
            icon={downloadsvg}
            title="Export"
            onClick={() =>
              downloadHandler(
                setIsLoading,
                {
                  startDate: startDate
                    ? moment(startDate).format("YYYY-MM-DD")
                    : null,
                  endDate: endDate
                    ? moment(endDate).format("YYYY-MM-DD")
                    : null,
                },
                handleExpiredToken
              )
            }
          />
        </div>
      </>
    );

  const contentToRender = (
    <>
      <div className="h4 text-grey1 mt-4 pb-2">
        {role === "Unknown" ? "Track Requisition" : "Main Dashboard"}
      </div>
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center p-0 flex-wrap">
          {role === "Admin" ||
          role === "MP" ||
          role === "Prod" ||
          role === "Gen" ? (
            <>
              <div className="d-flex align-items-center p-0 w-45">
                <p className="h7 text-black mb-0 mr-2">
                  Requisition Created From
                </p>
                <DatePickers
                  maxDate={new Date()}
                  minDate={new Date("September 8, 2022")}
                  selected={startDate}
                  dateFormat="MMMM d, yyyy"
                  className="col-12 rounded border border-1 picker-height h7-web bg-grey"
                  onChange={(e) => {
                    setStartDate(e);
                  }}
                />
                <p className="h7 text-black mb-0 mx-2">To</p>
                <DatePickers
                  maxDate={new Date()}
                  selected={endDate}
                  dateFormat="MMMM d, yyyy"
                  className="rounded border border-1 col-12 picker-height h7-web bg-grey"
                  onChange={(e) => {
                    setEndDate(e);
                  }}
                />
              </div>
              <div className="d-flex align-items-center w-50">
                <p className="h7 text-black mb-0 mx-2 ">
                  Search By Requisition
                </p>
                <InputSearch
                  placeholder={"Enter Requisition No"}
                  onChange={setSearchText}
                  value={searchText}
                  type={"number"}
                />
                {(role === "Admin" || role === "Gen") && (
                  <div className="d-flex align-items-center w-30 ml-4">
                    <p className="h7 text-black mb-0 mr-2">Assigned To</p>
                    <InputSearch
                      placeholder={"Enter user name"}
                      className=""
                      style={{ width: "185px" }}
                      onChange={(v) => {
                        setSearchTextUser(v);
                        setAssignedTo("");
                      }}
                      value={searchTextUser}
                      type={"text"}
                    >
                      {searchTextUser && !AssignedTo && (
                        <div
                          className="position-absolute bg-white border"
                          style={{
                            width: "185px",
                            overflow: "auto",
                            maxHeight: "200px",
                          }}
                        >
                          {filteredUserList(searchTextUser)?.length ? (
                            filteredUserList(searchTextUser)?.map((user) => (
                              <p
                                className="m-0 p-1 fs-14 cursor-pointer"
                                onClick={() => {
                                  setSearchTextUser(
                                    `${user.firstName || ""} ${
                                      user.lastName || ""
                                    }`
                                  );
                                  setAssignedTo(user.userId || "");
                                }}
                              >{`${user.firstName || ""} ${
                                user.lastName || ""
                              }`}</p>
                            ))
                          ) : (
                            <p className="m-0 p-1 fs-14 cursor-default">
                              No user found
                            </p>
                          )}
                        </div>
                      )}
                    </InputSearch>
                  </div>
                )}
                {role === "Prod" && (
                  <div className="d-flex align-items-center w-30">
                    <p className="h7 text-black mb-0 w-30 ms-5">
                      My Requisition
                    </p>
                    <div class="form-check form-switch ml-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={isMyRequisitionRequest}
                        onChange={() =>
                          setIsMyRequisitionRequest(!isMyRequisitionRequest)
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="d-flex align-items-center mt-4 w-45">
                <p className="h7 text-black mb-0 w-30">Search By Part No</p>
                <InputSearch
                  placeholder={"Enter Part No"}
                  className="ml-5 pl-2"
                  style={{ width: "185px" }}
                  onChange={setSearchTextPart}
                  value={searchTextPart}
                  type={"text"}
                />
                <div className="d-flex align-items-center ">
                  <p className="h7 text-black mb-0 mx-2">Authorization</p>
                  <Dropdown
                    className="ml-4 pl-3"
                    value={
                      [
                        { label: "All", value: "" },
                        { label: "Yes", value: "Y" },
                        { label: "No", value: "N" },
                      ][
                        SearchByAuthorization
                          ? SearchByAuthorization === "Y"
                            ? 1
                            : 0
                          : 0
                      ]
                    }
                    selectOptions={[
                      { label: "All", value: "" },
                      { label: "Yes", value: "Y" },
                      { label: "No", value: "N" },
                    ]}
                    onChange={setSearchByAuthorization}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center mt-4 ">
                <p className="h7 text-black mb-0 mx-2">Search By Status</p>
                <Dropdown
                  className="ml-4 pl-2"
                  value={
                    [
                      { label: "All", value: 0 },
                      { label: "Open", value: 1 },
                      { label: "Order", value: 2 },
                      { label: "Cancel", value: 3 },
                      { label: "Closed", value: 4 },
                    ][SearchByStatus]
                  }
                  selectOptions={[
                    { label: "All", value: 0 },
                    { label: "Open", value: 1 },
                    { label: "Order", value: 2 },
                    { label: "Cancel", value: 3 },
                    { label: "Closed", value: 4 },
                  ]}
                  onChange={setSearchByStatus}
                />
              </div>
            </>
          ) : (
            <></>
          )}
          <Button
            className="rounded h6-web w-80 ml-2 mt-4"
            size="md"
            title={"Submit"}
            variant="primary"
            onClick={getData}
          />
          <Button
            className="rounded h6-web w-80 ml-2 mt-4"
            size="md"
            title={"Reset"}
            variant="primary"
            onClick={resetFn}
          />
        </div>
      </div>
      <div className=" mt-3 mb-2 table-responsive ">
        {role === "Admin" && (
          <AdminDashboard
            dashboardData={dashboardData}
            tableSortKey={tableSortKey}
            sort={sort}
            SortBy={SortBy}
          />
        )}
        {role === "MP" && (
          <AdminDashboard
            dashboardData={dashboardData}
            tableSortKey={tableSortKey}
            sort={sort}
            SortBy={SortBy}
          />
        )}
        {role === "Prod" && (
          <AdminDashboard
            dashboardData={dashboardData}
            tableSortKey={tableSortKey}
            sort={sort}
            SortBy={SortBy}
          />
        )}
        {role === "Gen" && (
          <AdminDashboard
            dashboardData={dashboardData}
            tableSortKey={tableSortKey}
            sort={sort}
            SortBy={SortBy}
          />
        )}
        {role === "Unknown" && (
          <MechDashboard
            dashboardData={dashboardData}
            tableSortKey={tableSortKey}
            sort={sort}
            SortBy={SortBy}
          />
        )}
        {!dashboardData.length && <p className="text-center">No Data Found</p>}
      </div>
      {TableControls}
    </>
  );

  return (
    <TRAXAccount breadCrumbTitle="Dashboard">{contentToRender}</TRAXAccount>
  );
}

export default TraxDashboard;
