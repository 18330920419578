/* eslint-disable no-unused-vars */
import React from "react";
// import { useHistory } from "react-router-dom";
import "./Tabs.scss";

export const Tabs = React.forwardRef((props) => {
  // let history = useHistory();
  let {
    label,
    labelColor,
    selectedTab,
    setSelectedTab,
    tabArray,
    hideBar,
    onClick = () => {},
    ...rest
  } = props;
  return (
    <>
      {tabArray.map((item, index) => (
        <div
          key={index}
          className="d-inline-block p-3"
          onClick={() => setSelectedTab(index)}
        >
          <p
            className={
              selectedTab == index
                ? "selected-tab_text h5 web cursor-pointer"
                : " cursor-pointer unselected-tab_text h5 web"
            }
          >
            {item}
            <div
              className={
                selectedTab == index
                  ? "selected-tab mt-2"
                  : "unselected-tab mt-2"
              }
            ></div>
          </p>
        </div>
      ))}
      {props.children}
      {!hideBar && (
        <div
          style={{
            background: "#EBEAEA",
            width: "100%",
            height: "3px",
            marginTop: "-26px",
          }}
        ></div>
      )}
      <br></br>
    </>
  );
});
