import React, { useState, useEffect } from 'react';
import API from '../../../utils/Api';
import HeaderTitle from "../../../components/HeaderTitle/HeaderTitle";
import Loader from "../../../components/Loader/Loader";
import { errorToast } from '../../../components/Generic/Generic';
import { getFromLocal } from '../../../utils/generic';
import endpoint from '../../../utils/endpoints';
import "./DirectorDashboard.scss";
import LGTCScheduledTrainings from '../LGTCScheduledTrainings/LGTCScheduledTrainings';
import TrainingRequestView from '../TrainingRequestView/TrainingRequestView';

const DirectorDashboard = (props) => {
    const [isBusy, setBusy] = useState(true);
    const [dashboardData, setDashboardData] = useState(null);
    const [records, getRecords] = useState([]);

    const getMyProfile = async () => {
        var user = getFromLocal("user");
        var userId = parseInt(user?.userId);

        var url = `${endpoint.GetDirectorDashboard}/${user?.companyCode}`;

        var headers = {
            'UserId': userId
        }
        API.getWithHeader(url, headers, (isSuccess, response) => {
            if (isSuccess) {
                setBusy(false);
                setDashboardData(response);
                getRecords(response?.scheduledTrainings);
            } else {
                errorToast("Error loading profile!");
                setBusy(false);
            }
        })
    }

    useEffect(() => {
        setBusy(true);
        getMyProfile();
    }, [])

    const redirectToTraining = (expiry, pilotCount) => {
        if (pilotCount !== 0) {
            props.history.push({
                pathname: '/dashboard/directortrainingchedule',
                state: { expiryDays: expiry }
            });
        }
    };

    const redirectToLicenses = (expiry, pilotCount, docType) => {
        if (pilotCount !== 0) {
            props.history.push({
                pathname: '/dashboard/licensesexpiring',
                state: { expiryDays: expiry, documentType: docType }
            });
        }
    };

    const redirectToLGTCScheduledTrainings = () => {
        props.history.push({
            pathname: '/dashboard/lgtcscheduledtrainings',
            state: { records: records, showAll: true }
        });

    };

    const redirectToTrainingRequestView = () => {
        props.history.push({
            pathname: '/dashboard/scheduledtrainingRequests'
            ,
            state: {
                records: records,
                showAll: true
            }
        });

    };

    const getCardClassName = (num, value) => {
        return `display-card-content card-${num} ${value && value > 0 ? "has-click" : ""}`;
    }
    return (
        <div className="main-content">
            {isBusy && <Loader />}
            <div className="row">
                <div className="col-md-8"><HeaderTitle title="Pilots due Training" /></div>
            </div>
            <div className="row">
                <div className="display-card">
                    <div className={getCardClassName(1, dashboardData?.countOfQualificationsExpired)} onClick={() => redirectToTraining(0, dashboardData?.countOfQualificationsExpired)}>
                        <span className="display-card-content-header">Qualifications Expired</span>
                        <span className="display-card-content-value">{dashboardData?.countOfQualificationsExpired}</span>
                    </div>
                </div>
                <div className="display-card">
                    <div className={getCardClassName(2, dashboardData?.countOfQualificationsExpiryIn15Days)} onClick={() => redirectToTraining(15, dashboardData?.countOfQualificationsExpiryIn15Days)}>
                        <span className="display-card-content-header">Expiring in 15 days</span>
                        <span className="display-card-content-value">{dashboardData?.countOfQualificationsExpiryIn15Days}</span>
                    </div>
                </div>
                <div className="display-card">
                    <div className={getCardClassName(3, dashboardData?.countOfQualificationsExpiryIn30Days)} onClick={() => redirectToTraining(30, dashboardData?.countOfQualificationsExpiryIn30Days)}>
                        <span className="display-card-content-header">Expiring in 30 days</span>
                        <span className="display-card-content-value">{dashboardData?.countOfQualificationsExpiryIn30Days}</span>
                    </div>
                </div>
                <div className="display-card">
                    <div className={getCardClassName(4, dashboardData?.countOfQualificationsExpiryIn60Days)} onClick={() => redirectToTraining(60, dashboardData?.countOfQualificationsExpiryIn60Days)}>
                        <span className="display-card-content-header">Expiring in 60 days</span>
                        <span className="display-card-content-value">{dashboardData?.countOfQualificationsExpiryIn60Days}</span>
                    </div>
                </div>
                <div className="display-card">
                    <div className={getCardClassName(5, dashboardData?.countOfQualificationsExpiryIn90Days)} onClick={() => redirectToTraining(90, dashboardData?.countOfQualificationsExpiryIn90Days)}>
                        <span className="display-card-content-header">Expiring in 90 days</span>
                        <span className="display-card-content-value">{dashboardData?.countOfQualificationsExpiryIn90Days}</span>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-8"><HeaderTitle title="Pilots due for Medical" /></div>
            </div>
            <div className="row">
                <div className="display-card">
                    <div className={getCardClassName(1, dashboardData?.countOfMedicaldocsExpired)} onClick={() => redirectToLicenses(0, dashboardData?.countOfMedicaldocsExpired, "Medical License")}>
                        <span className="display-card-content-header">Medical Expired</span>
                        <span className="display-card-content-value">{dashboardData?.countOfMedicaldocsExpired}</span>
                    </div>
                </div>
                <div className="display-card">
                    <div className={getCardClassName(2, dashboardData?.countOfMedicaldocsExpiryIn15Days)} onClick={() => redirectToLicenses(15, dashboardData?.countOfMedicaldocsExpiryIn15Days, "Medical License")}>
                        <span className="display-card-content-header">Expiring in 15 days</span>
                        <span className="display-card-content-value">{dashboardData?.countOfMedicaldocsExpiryIn15Days}</span>
                    </div>
                </div>
                <div className="display-card">
                    <div className={getCardClassName(3, dashboardData?.countOfMedicaldocsExpiryIn30Days)} onClick={() => redirectToLicenses(30, dashboardData?.countOfMedicaldocsExpiryIn30Days, "Medical License")}>
                        <span className="display-card-content-header">Expiring in 30 days</span>
                        <span className="display-card-content-value">{dashboardData?.countOfMedicaldocsExpiryIn30Days}</span>
                    </div>
                </div>
                <div className="display-card">
                    <div className={getCardClassName(4, dashboardData?.countOfMedicaldocsExpiryIn60Days)} onClick={() => redirectToLicenses(60, dashboardData?.countOfMedicaldocsExpiryIn60Days, "Medical License")}>
                        <span className="display-card-content-header">Expiring in 60 days</span>
                        <span className="display-card-content-value">{dashboardData?.countOfMedicaldocsExpiryIn60Days}</span>
                    </div>
                </div>
                <div className="display-card">
                    <div className={getCardClassName(5, dashboardData?.countOfMedicaldocsExpiryIn90Days)} onClick={() => redirectToLicenses(90, dashboardData?.countOfMedicaldocsExpiryIn90Days, "Medical License")}>
                        <span className="display-card-content-header">Expiring in 90 days</span>
                        <span className="display-card-content-value">{dashboardData?.countOfMedicaldocsExpiryIn90Days}</span>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-8"><HeaderTitle title="License Expiry" /></div>
            </div>
            <div className="row">
                <div className="display-card">
                    <div className={getCardClassName(1, dashboardData?.countOfLicensesExpired)} onClick={() => redirectToLicenses(0, dashboardData?.countOfLicensesExpired, "Pilot License")}>
                        <span className="display-card-content-header">Licenses Expired</span>
                        <span className="display-card-content-value">{dashboardData?.countOfLicensesExpired}</span>
                    </div>
                </div>
                <div className="display-card">
                    <div className={getCardClassName(2, dashboardData?.countOfLicensesExpiryIn15Days)} onClick={() => redirectToLicenses(15, dashboardData?.countOfLicensesExpiryIn15Days, "Pilot License")}>
                        <span className="display-card-content-header">Expiring in 15 days</span>
                        <span className="display-card-content-value">{dashboardData?.countOfLicensesExpiryIn15Days}</span>
                    </div>
                </div>
                <div className="display-card">
                    <div className={getCardClassName(3, dashboardData?.countOfLicensesExpiryIn30Days)} onClick={() => redirectToLicenses(30, dashboardData?.countOfLicensesExpiryIn30Days, "Pilot License")}>
                        <span className="display-card-content-header">Expiring in 30 days</span>
                        <span className="display-card-content-value">{dashboardData?.countOfLicensesExpiryIn30Days}</span>
                    </div>
                </div>
                <div className="display-card">
                    <div className={getCardClassName(4, dashboardData?.countOfLicensesExpiryIn60Days)} onClick={() => redirectToLicenses(60, dashboardData?.countOfLicensesExpiryIn60Days, "Pilot License")}>
                        <span className="display-card-content-header">Expiring in 60 days</span>
                        <span className="display-card-content-value">{dashboardData?.countOfLicensesExpiryIn60Days}</span>
                    </div>
                </div>
                <div className="display-card">
                    <div className={getCardClassName(5, dashboardData?.countOfLicensesExpiryIn90Days)} onClick={() => redirectToLicenses(90, dashboardData?.countOfLicensesExpiryIn90Days, "Pilot License")}>
                        <span className="display-card-content-header">Expiring in 90 days</span>
                        <span className="display-card-content-value">{dashboardData?.countOfLicensesExpiryIn90Days}</span>
                    </div>
                </div>
            </div>

            <br />
            <div className="row">
                <div className="col-md-8"><HeaderTitle title="Trainings scheduled by LGTC" /></div>
                <div className="col-md-4 text-right view-all has-click" onClick={() => redirectToLGTCScheduledTrainings()}>View All</div>
            </div>
            <LGTCScheduledTrainings records={records} showAll={false} />

            <br /><br />
            <div className="row">
                <div className="col-md-8"><HeaderTitle title="Training Requests" /></div>
                <div className="col-md-4 text-right view-all has-click" onClick={() => redirectToTrainingRequestView()}>View All</div>
            </div>
            <TrainingRequestView records={records} showAll={false} />
        </div>
    )
}

export default DirectorDashboard;