import React from 'react';
import "./HeaderTitle.scss";

const HeaderTitle = (props) => {
    const {title} = props;

    return(
        <div className="title-div">{title}</div>
    )
}

export default HeaderTitle;