import HeaderTitle from "../../componentsNew/core/sp-title/HeaderTitle";
import React, { useState, useEffect } from "react";
import moment from "moment";
import "./LGTCSchedule.scss";
import { PageDetails } from "../../componentsNew/page-details/PageDetails";
import { usePagination } from "../../hooks/usePagination";
import ArrowLeft from "../../../assets/svg/NewIcons/arrow_left_white.svg";
import ArrowRight from "../../../assets/svg/NewIcons/arrow_right_white.svg";
import { Badge, InputSearch } from "../../componentsNew/core";
import SortValue from "../../helper/sort";

function LGTCSchedule(props) {
  const [sort, setSort] = useState("");
  const [tableSortKey, setTableSortKey] = useState("");
  var [finalRow, setFinalRow] = useState([]);
  const { pagination, rowItems, handlePagination } = usePagination(finalRow);
  var [data, setData] = useState(null);

  useEffect(() => {
    handlePagination();
  }, [finalRow]);

  useEffect(() => {
    if (props?.records?.length > 0) {
      setData(props?.records);
    }
  }, [props?.records]);

  useEffect(() => {
    if (data?.length > 0) {
      if (props?.showAll === false) {
        let row = data.splice(0, 3);
        setFinalRow(row);
      } else {
        setFinalRow(data);
      }
    }
  }, [data]);

  const SortBy = (key) => {
    if (tableSortKey == key) {
      if (sort == "") {
        setSort("asc");
        let val = finalRow.sort(SortValue(key));
        setFinalRow(val);
        handlePagination();
      } else if (sort == "asc") {
        setSort("desc");
        let val = finalRow.sort(SortValue(`-${key}`));
        setFinalRow(val);
        handlePagination();
      } else if (sort == "desc") {
        setSort("asc");
        let val = finalRow.sort(SortValue(key));
        setFinalRow(val);
        handlePagination();
      } else {
        setSort("");
      }
    } else {
      setTableSortKey(key);
      setSort("asc");
      let val = finalRow.sort(SortValue(key));
      setFinalRow(val);
      handlePagination();
    }
  };

  const handleChangeWithLib = (value) => {
    let newRows = [];
    if (value) {
      let searchTxt = value.toString().trim().toLowerCase();
      newRows = data?.filter((value) => {
        return (
          (value.title &&
            value.title.toString().toLowerCase().includes(searchTxt)) ||
          (value.trainingType &&
            value.trainingType.toLowerCase().includes(searchTxt))
        );
      });
    } else {
      newRows = [...data];
    }
    setFinalRow(newRows);
  };

  const TableControls = (
    <div className="d-flex col-6 justify-content-end">
      <div className="flex-wrap d-flex align-items-center justify-content-center">
        <div className="">
          <div className="col-md-12 col-sm-4 col-8">
            <InputSearch
              placeholder="search here..."
              className="w-100"
              onChange={handleChangeWithLib}
              //   value={searchText}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-3 d-flex align-items-center justify-content-center pe-0">
        <Badge
          className="rounded-50"
          variant="primary"
          size="xl"
          icon={ArrowLeft}
          title="Previous Page"
          disabled={!pagination?.prevPageUrl}
          onClick={() => handlePagination("prev")}
        />
        <Badge
          className="rounded-50 ms-2"
          variant="primary"
          size="xl"
          icon={ArrowRight}
          title="Next Page"
          disabled={!pagination?.nextPageUrl}
          onClick={() => handlePagination("next")}
        />
      </div>
    </div>
  );

  return (
    <>
      <div className={props?.showAll === false ? "" : "main-content"}>
        {props?.showAll !== false && (
          <div className="row">
            <div className="col-md-6">
              <HeaderTitle title="Trainings scheduled by LGTC" />
            </div>
            {TableControls}
          </div>
        )}
        <div className="row">
          <div className="col-md-12 ">
            <div className=" mt-2 mb-2 table-responsive ">
              <table className="table table-bordered shadow-sm align-middle ">
                <thead className="bg-grey h7-table text-grey1">
                  <tr className="text-center text-nowrap">
                    <td onClick={() => SortBy("title")}>
                      Training Name
                      <i
                        className={
                          tableSortKey == "title"
                            ? sort == "asc"
                              ? "fa fa-sort-asc"
                              : "fa fa-sort-desc"
                            : "fa fa-fw fa-sort"
                        }
                      ></i>
                    </td>
                    <td onClick={() => SortBy("trainingType")}>
                      Training Type
                      <i
                        className={
                          tableSortKey == "trainingType"
                            ? sort == "asc"
                              ? "fa fa-sort-asc"
                              : "fa fa-sort-desc"
                            : "fa fa-fw fa-sort"
                        }
                      ></i>
                    </td>
                    <td onClick={() => SortBy("startDate")}>
                      Start Date
                      <i
                        className={
                          tableSortKey == "startDate"
                            ? sort == "asc"
                              ? "fa fa-sort-asc"
                              : "fa fa-sort-desc"
                            : "fa fa-fw fa-sort"
                        }
                      ></i>
                    </td>
                    <td onClick={() => SortBy("endDate")}>
                      End Date
                      <i
                        className={
                          tableSortKey == "endDate"
                            ? sort == "asc"
                              ? "fa fa-sort-asc"
                              : "fa fa-sort-desc"
                            : "fa fa-fw fa-sort"
                        }
                      ></i>
                    </td>
                    <td onClick={() => SortBy("isRepeatTrainings")}>
                      Is Repeat Trainings
                      <i
                        className={
                          tableSortKey == "isRepeatTrainings"
                            ? sort == "asc"
                              ? "fa fa-sort-asc"
                              : "fa fa-sort-desc"
                            : "fa fa-fw fa-sort"
                        }
                      ></i>
                    </td>
                    <td onClick={() => SortBy("instructorName")}>
                      Instructor Name
                      <i
                        className={
                          tableSortKey == "instructorName"
                            ? sort == "asc"
                              ? "fa fa-sort-asc"
                              : "fa fa-sort-desc"
                            : "fa fa-fw fa-sort"
                        }
                      ></i>
                    </td>
                    <td onClick={() => SortBy("maxAttendees")}>
                      Max Attendees
                      <i
                        className={
                          tableSortKey == "maxAttendees"
                            ? sort == "asc"
                              ? "fa fa-sort-asc"
                              : "fa fa-sort-desc"
                            : "fa fa-fw fa-sort"
                        }
                      ></i>
                    </td>
                    <td onClick={() => SortBy("trainingDevice")}>
                      Training Device
                      <i
                        className={
                          tableSortKey == "trainingDevice"
                            ? sort == "asc"
                              ? "fa fa-sort-asc"
                              : "fa fa-sort-desc"
                            : "fa fa-fw fa-sort"
                        }
                      ></i>
                    </td>
                    <td onClick={() => SortBy("aircraftType")}>
                      Aircraft Type
                      <i
                        className={
                          tableSortKey == "aircraftType"
                            ? sort == "asc"
                              ? "fa fa-sort-asc"
                              : "fa fa-sort-desc"
                            : "fa fa-fw fa-sort"
                        }
                      ></i>
                    </td>
                    <td onClick={() => SortBy("description")}>
                      Description
                      <i
                        className={
                          tableSortKey == "description"
                            ? sort == "asc"
                              ? "fa fa-sort-asc"
                              : "fa fa-sort-desc"
                            : "fa fa-fw fa-sort"
                        }
                      ></i>
                    </td>
                    <td onClick={() => SortBy("location")}>
                      Location
                      <i
                        className={
                          tableSortKey == "location"
                            ? sort == "asc"
                              ? "fa fa-sort-asc"
                              : "fa fa-sort-desc"
                            : "fa fa-fw fa-sort"
                        }
                      ></i>
                    </td>
                    <td onClick={() => SortBy("durationInHr")}>
                      Duration In Hr
                      <i
                        className={
                          tableSortKey == "durationInHr"
                            ? sort == "asc"
                              ? "fa fa-sort-asc"
                              : "fa fa-sort-desc"
                            : "fa fa-fw fa-sort"
                        }
                      ></i>
                    </td>
                    <td onClick={() => SortBy("durationInMin")}>
                      Duration In Min
                      <i
                        className={
                          tableSortKey == "durationInMin"
                            ? sort == "asc"
                              ? "fa fa-sort-asc"
                              : "fa fa-sort-desc"
                            : "fa fa-fw fa-sort"
                        }
                      ></i>
                    </td>
                  </tr>
                </thead>
                <tbody className="h8 text-table-body">
                  {rowItems && rowItems.length > 0 ? (
                    rowItems.map((row, rowIndex) => (
                      <tr className="text-center" key={"row_" + rowIndex}>
                        <td>{row.title}</td>
                        <td>{row.trainingType}</td>
                        <td>
                          {row.startDate
                            ? moment(row.startDate).format("DD/MM/YYYY")
                            : ""}
                        </td>
                        <td>
                          {row.endDate
                            ? moment(row.endDate).format("DD/MM/YYYY")
                            : ""}
                        </td>
                        <td>{row.isRepeatTrainings ? "Y" : "N"}</td>
                        <td>{row.instructorName}</td>
                        <td>{row.maxAttendees}</td>
                        <td>{row.trainingDevice}</td>
                        <td>{row.aircraftType}</td>
                        <td>{row.description}</td>
                        <td>{row.location}</td>
                        <td>{row.durationInHr}</td>
                        <td>{row.durationInMin}</td>
                      </tr>
                    ))
                  ) : (
                    <tr className="text-center h6-web">No records found</tr>
                  )}
                </tbody>
              </table>
            </div>

            {props?.showAll !== false && (
              <PageDetails pagination={pagination} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default LGTCSchedule;
