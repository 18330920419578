import { errorToast } from "../../componentsNew/Toast/Toast";
import { ENDPOINTS, getApi } from "../../services/http";

export const getRequistionTrackData = async (
    rqno,
    setIsLoading,
    setDashboardData,
    handleExpiredToken
  ) => {
    if (!rqno) {
      errorToast("Enter Requisition No");
      return;
    }
    setIsLoading(true);
    const response = await getApi(`${ENDPOINTS.GET_MECH_DASHBOARD_DATA}/${rqno}`);
    if (response) {
      if (response.status === 200) {
        setIsLoading(false);
        setDashboardData(response?.data);
      } else if (response.status === 401) {
        setIsLoading(false);
        handleExpiredToken();
      } else {
        setIsLoading(false);
        if (response !== undefined) {
          errorToast(response.data.statusText);
        } else {
          errorToast("Some error occured.");
        }
      }
    }
    setIsLoading(false);
  };