import "./Dashboard.scss";
import endpoint from "../../utils/endpoints";
import React, { useState, useEffect } from "react";
import { errorToast } from "../../components/Generic/Generic";
import API from "../../utils/Api";
import Loader from "../../components/Loader/Loader";
import "antd/dist/antd.css";
import DashboardCard from "../../components/DashboardCard/DashboardCard";
import { Modal, Button } from "antd";
import Chart from "chart.js/auto";
import zoomPlugin from "chartjs-plugin-zoom";
import $ from "jquery";

const Dashboard = (props) => {
  Chart.register(zoomPlugin);
  const [isBusy, setBusy] = useState(true);
  const [totalInstructors, setTotalInstructors] = useState(0);
  const [totalPilots, setTotalPilots] = useState(0);
  const [totalTrainings, setTotalTrainings] = useState(0);

  //const [totalMOR, setTotalMOR] = useState(0);
  const [airLinesBlock, setAirlinesBlock] = useState([]);
  const [formOptions, setFormOptions] = useState([]);
  const [tapsOverTime, setTapsOverTime] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [today, setToday] = useState("");
  const [weekBefore, setWeekBefore] = useState("");

  const [
    drilledLessThanTwoCompetencyListHTML,
    setDrilledLessThanTwoCompetencyListHTML,
  ] = useState([]);
  const [selectedTrainingType, setTrainingType] = useState("PPC/IR");
  const [expiringCertAndLicensesListHTML, setExpiringCertAndLicensesListHTML] =
    useState([]);

  const getDashData = async () => {
    var processFields = {
      ChildEntityKey: "PPC/IR",
    };

    API.post(endpoint.DashboardData, processFields, (isSuccess, response) => {
      if (isSuccess) {
        setTotalInstructors(response.Table[0].InstructorCount);
        setTotalPilots(response.Table1[0].PilotCount);
        setTotalTrainings(response.Table2[0].TrainingCount);

        //setInstructorsByCategory(response.Table3);
        setTrainingsByType(response.Table4);

        //setTotalMOR(response.Table5[0].TotalMOR);

        if (response.Table7.length > 0) {
          setTapsOverTime(response.Table7);
        }

        if (response.Table8.length > 0) {
          setLessThanTwoCompetencyTrainingsByType(response.Table8);
        }

        var resp = response.Table6;
        if (resp.length > 0) {
          setAirlinesBlock(resp);
        }
        var options = [];
        for (var i = 0; i < resp.length; i++) {
          options.push({ name: resp[i].FormName, value: resp[i].FormName });
        }

        setFormOptions(options);
        filterTrainings();
        setBusy(false);
      } else {
        if (response != undefined) {
          errorToast(response.statusText);
        } else {
          errorToast("Some error occured.");
        }
        setBusy(false);
      }
    });
  };

  useEffect(() => {
    fnSetTapsOverTime("");
  }, [tapsOverTime]);

  useEffect(() => {
    setBusy(true);
    getDashData();
    changeExpiringDataParamType(null);
    var now = new Date();
    now.setDate(now.getDate() - 7);

    var month = now.getMonth() + 1;
    var day = now.getDate();
    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;

    setWeekBefore(now.getFullYear() + "-" + month + "-" + day);

    var d = new Date();
    var m = d.getMonth() + 1;
    var dt = d.getDate();
    if (m < 10) m = "0" + m;
    if (dt < 10) dt = "0" + dt;

    setToday(d.getFullYear() + "-" + m + "-" + dt);
  }, []);

  function fnSetTapsOverTime(input) {
    let labels = [];
    let pieData = [];

    let data = tapsOverTime;
    if (input != "") {
      data = tapsOverTime.filter((s) => s.FormName == input);
    }

    for (var i = 0; i < data.length; i++) {
      labels.push(getMonth(data[i].Month) + "-" + data[i].Year);
      pieData.push(data[i].TotalMOR);
    }

    if ($("canvas#myChart") != null) {
      $("canvas#myChart").remove();
      $("div.chartReport").append(
        '<canvas id="myChart" className="animated fadeIn" height="300"></canvas>'
      );
    }

    var canvas = document.getElementById("myChart");
    var ctx = canvas.getContext("2d");
    new Chart(ctx, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            label: "MOR Reports",
            data: pieData,
            backgroundColor: ["rgb(234 53 61)"],
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        maintainAspectRatio: false,
        plugins: {
          zoom: {
            pan: {
              enabled: true,
              mode: "xy",
            },
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: "xy",
            },
          },
        },
      },
    });
  }

  function changeAirlinesValue(event) {
    var input = event.currentTarget.value;
    fnSetTapsOverTime(input);
  }

  function getMonth(number) {
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[number - 1];
  }

  function setTrainingsByType(input) {
    let labels = input.map((a) => a.TrainingType);
    let pieData = input.map((a) => a.Trainings);

    if ($("canvas#myPie") != null) {
      $("canvas#myPie").remove();
      $("div.chrTrainingsLastWeek").append(
        '<canvas id="myPie" className="animated fadeIn" height="260"></canvas>'
      );
    }

    var canvas = document.getElementById("myPie");
    var ctx = canvas.getContext("2d");
    new Chart(ctx, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Trainings count",
            data: pieData,
            backgroundColor: ["rgb(234 53 61)"],
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        maintainAspectRatio: false,
      },
    });
  }

  function filterTrainings() {
    var from = document.getElementById("fromDate");
    var to = document.getElementById("toDate");

    if (new Date(to.value) < new Date(from.value)) {
      errorToast("To date should be greater than from date");
      return;
    }

    var formData = {
      EntityKey: from.value,
      ChildEntityKey: to.value,
    };

    API.post(
      endpoint.GetDashboardTrainingCount,
      formData,
      (isSuccess, response) => {
        if (isSuccess) {
          setTrainingsByType(response);
        } else {
          errorToast(response.statusText);
        }
      }
    );
  }

  function setLessThanTwoCompetencyTrainingsByType(input) {
    let labels = input.map((a) => a.TrainingType);
    let pieData = input.map((a) => a.Trainings);

    if ($("canvas#myTrainingsLessThanTwoCompetencyOverall") != null) {
      $("canvas#myTrainingsLessThanTwoCompetencyOverall").remove();
      $("div.chrTrainingsLessThanTwoCompetencyOverall").append(
        '<canvas id="myTrainingsLessThanTwoCompetencyOverall" className="animated fadeIn" height="500"></canvas>'
      );
    }

    var canvas = document.getElementById(
      "myTrainingsLessThanTwoCompetencyOverall"
    );
    var ctx = canvas.getContext("2d");
    var tttChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Trainings count",
            data: pieData,
            backgroundColor: ["rgb(234 53 61)"],
            borderWidth: 1,
          },
        ],
      },
      options: {
        //indexAxis: 'y',
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        maintainAspectRatio: false,
        onClick: function (evt, q, w) {
          const points = tttChart.getElementsAtEventForMode(
            evt,
            "nearest",
            { intersect: true },
            true
          );

          if (points.length) {
            const firstPoint = points[0];
            const label = tttChart.data.labels[firstPoint.index];
            setBusy(true);

            getDrilledLessThanTwoCompetencyList(label);
          }
        },
      },
    });
  }

  function changeLessThanTwoSelectList(event) {
    setBusy(true);
    var input = event.currentTarget.value;
    setTrainingType(input);
    var processFields = {
      ChildEntityKey: input,
    };

    API.post(endpoint.DashboardData, processFields, (isSuccess, response) => {
      if (isSuccess) {
        if (response.Table8.length > 0) {
          setLessThanTwoCompetencyTrainingsByType(response.Table8);
        }
        setBusy(false);
      } else {
        if (response != undefined) {
          errorToast(response.statusText);
        } else {
          errorToast("Some error occured.");
        }
        setBusy(false);
      }
    });
  }

  function changeExpiringDataParamType(event) {
    setBusy(true);
    var input = "LICNumber";

    if (event != null) input = event.currentTarget.value;

    var processFields = {
      EntityKey: input,
    };

    API.post(
      endpoint.ExpiringCertAndLicenses,
      processFields,
      (isSuccess, response) => {
        if (isSuccess) {
          let responseData = response;
          var safetyHeadersHTML = [];
          if (responseData.length > 0) {
            for (var i = 0; i < responseData.length; i++) {
              var id = "rrr-" + responseData[i].Id;
              safetyHeadersHTML.push(
                <tr className={id}>
                  <td>{responseData[i].Name}</td>
                  <td>{responseData[i].Email}</td>
                  <td>{responseData[i].License}</td>
                  <td>{responseData[i].ExpiryDate}</td>
                  <td>{responseData[i].NotificationSent}</td>
                </tr>
              );
            }
          } else {
            safetyHeadersHTML.push(
              <tr>
                <td colSpan="5">No data found</td>
              </tr>
            );
          }

          setExpiringCertAndLicensesListHTML(safetyHeadersHTML);
          setBusy(false);
        } else {
          if (response != undefined) {
            errorToast(response.statusText);
          } else {
            errorToast("Some error occured.");
          }
          setBusy(false);
        }
      }
    );
  }

  const getDrilledLessThanTwoCompetencyList = async (labelName) => {
    var processFields = {
      EntityKey: labelName,
      ChildEntityKey: selectedTrainingType,
    };

    API.post(
      endpoint.GetDrilledLessThanTwoCompetencyList,
      processFields,
      (isSuccess, response) => {
        if (isSuccess) {
          let responseData = response;
          var safetyHeadersHTML = [];
          if (responseData.length > 0) {
            for (var i = 0; i < responseData.length; i++) {
              var id = "demo-" + responseData[i].Id;
              safetyHeadersHTML.push(
                <tr data-toggle="collapse" className={id}>
                  <td className={id}>{responseData[i].Pilot}</td>
                  <td className={id}>{responseData[i].Instructor}</td>
                  <td className={id}>{responseData[i].TrainingDate}</td>
                </tr>
              );
            }
          } else {
            safetyHeadersHTML.push(
              <tr>
                <td colSpan="5">No data found</td>
              </tr>
            );
          }
          setDrilledLessThanTwoCompetencyListHTML(safetyHeadersHTML);

          setIsModalVisible(true);
          setBusy(false);
        } else {
          if (response != undefined) {
            errorToast(response.statusText);
          } else {
            errorToast("Some error occured.");
          }
          setBusy(false);
        }
      }
    );
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="main-content">
      <div className="row">
        {isBusy && <Loader />}
        <div className="col-md-12">
          <DashboardCard title="Pilot safety issues">
            <div className="row">
              <div className="col-md-12">
                {airLinesBlock.map((e, index) => {
                  return (
                    <div className="info-border" key={index}>
                      <h3>{e.TotalMOR}</h3>
                      <span>{e.FormName}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </DashboardCard>
        </div>
        <div className="col-md-12">
          <br />
          <DashboardCard title="Taps over time">
            <div className="row">
              <div className="col-md-4">
                <select onChange={changeAirlinesValue}>
                  <option value="">--All airlines--</option>
                  {formOptions.map((e, index) => {
                    return (
                      <option key={index} value={e.value}>
                        {e.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 chartReport">
                <canvas id="myChart" height="300" />
              </div>
            </div>
          </DashboardCard>
        </div>
        <div className="col-md-4">
          <br />
          <DashboardCard title="Instructors">
            <div className="info">
              <h3>{totalInstructors}</h3>
              <span>Total Instructors</span>
            </div>
          </DashboardCard>
        </div>
        <div className="col-md-4">
          <br />
          <DashboardCard title="Pilots">
            <div className="info">
              <h3>{totalPilots}</h3>
              <span>Total Pilots</span>
            </div>
          </DashboardCard>
        </div>
        <div className="col-md-4">
          <br />
          <DashboardCard title="Trainings">
            <div className="info">
              <h3>{totalTrainings}</h3>
              <span>Total Trainings</span>
            </div>
          </DashboardCard>
        </div>
        <div className="col-md-12">
          <br />
          <DashboardCard title="Trainings">
            <div className="row" style={{ marginBottom: "15px" }}>
              <div style={{ float: "left" }}>
                From:{" "}
                <input type="date" defaultValue={weekBefore} id="fromDate" />
              </div>
              <div style={{ float: "left", marginLeft: "15px" }}>
                To: <input type="date" defaultValue={today} id="toDate" />
              </div>
              <div style={{ float: "left", marginLeft: "15px" }}>
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={filterTrainings}
                >
                  Submit
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 chrTrainingsLastWeek">
                <canvas id="myPie" height="260" />
              </div>
            </div>
          </DashboardCard>
        </div>
        <div className="col-md-12">
          <br />
          <DashboardCard title="Total grade 2 by training type">
            <div className="row">
              <div className="col-md-4">
                Select training type:{" "}
                <select onChange={changeLessThanTwoSelectList}>
                  <option value="PPC/IR">PPC/IR</option>
                  <option value="RECC-LOFT">RECC-LOFT</option>
                  <option value="CORRECTIVE">CORRECTIVE</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 chrTrainingsLessThanTwoCompetencyOverall">
                <canvas
                  id="myTrainingsLessThanTwoCompetencyOverall"
                  height="500"
                />
              </div>
            </div>
          </DashboardCard>
        </div>
        <div className="col-md-12">
          <br />
          <DashboardCard title="Licenses expiring in 60 days">
            <div className="row">
              <div className="col-md-4">
                Select type:{" "}
                <select onChange={changeExpiringDataParamType}>
                  <option value="LICNumber">LICNumber</option>
                  <option value="LOANumber">LOANumber</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <br />
                <table className="table table-striped table-hover table-sm table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email ID</th>
                      <th>License</th>
                      <th>Expiry Date</th>
                      <th>User Notified</th>
                    </tr>
                  </thead>
                  <tbody>{expiringCertAndLicensesListHTML}</tbody>
                </table>
              </div>
            </div>
          </DashboardCard>
        </div>
      </div>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width="80%"
        footer={[
          <Button type="primary" onClick={handleCancel}>
            Close
          </Button>,
        ]}
      >
        {isBusy ? (
          <div></div>
        ) : (
          <table className="table table-striped table-hover table-sm table-bordered">
            <thead>
              <tr>
                <th>Pilot</th>
                <th>Instructor</th>
                <th>Training Date</th>
              </tr>
            </thead>
            <tbody>{drilledLessThanTwoCompetencyListHTML}</tbody>
          </table>
        )}
      </Modal>
    </div>
  );
};

export default Dashboard;
