import { AxiosClient } from "./AxiosClient";

var response;

export const getApi = async (url,data, headers) => {
  await AxiosClient.get(url,data, headers)
    .then((res) => (response = res))
    .catch((err) => (response = err.response));
  return response;
};

export const postApi = async (url, data, headers) => {

  await AxiosClient.post(url, data, headers)
    .then((res) => (response = res))
    .catch((err) => (response = err.response));
  return response;
};

export const putApi = async (url, data) => {
  await AxiosClient.put(url, data)
    .then((res) => (response = res))
    .catch((err) => (response = err.response));
  return response;
};

export const deleteApi = async (url, data) => {
  await AxiosClient.delete(url, data)
    .then((res) => (response = res))
    .catch((err) => (response = err.response));
  return response;
};
