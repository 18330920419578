import { errorToast } from "../../componentsNew/Toast/Toast";
import { ENDPOINTS, getApi, postApi } from "../../services/http";
import { json2xlsDownload } from "../../utils/functions";

export const getAdminDashData = async (
  request,
  setIsLoading,
  setPagingData,
  setDashboardData,
  setCurrentPage,
  setLastPage,
  handleExpiredToken
) => {
  setIsLoading(true);
  const response = await postApi(`${ENDPOINTS.GET_DASHBOARD_DATA}`, request);
  if (response) {
    if (response.status === 200) {
      setIsLoading(false);
      setPagingData(response?.data);
      setDashboardData(response?.data?.mainDashBoardData);
      setCurrentPage(response?.data?.currentPage);
      setLastPage(response?.data?.maxPages);
    } else if (response.status === 401) {
      setIsLoading(false);
      handleExpiredToken();
    } else {
      setIsLoading(false);
      if (response !== undefined) {
        errorToast(response.data.statusText);
      } else {
        errorToast("Some error occured.");
      }
    }
  }
  setIsLoading(false);
};

export const downloadHandler = async (
  setIsLoading,
  req,
  handleExpiredToken
) => {
  setIsLoading(true);
  const response = await postApi(`${ENDPOINTS.GET_DASHBOARD_DATA}/export`, req);
  if (response) {
    if (response.status === 200) {
      setIsLoading(false);
      json2xlsDownload(
        response?.data?.mainDashBoardData,
        {
          requisition: "RQ number",
          requisitionLine: "RQ Line",
          pn: "PN",
          pnQty: "PN Quantity",
          priorityType: "Priority Type",
          orderType: "Order Type",
          orderNumber: "Order number",
          orderLine: "Order line",
          orderQty: "Quantity",
          edd: "EDD",
          status: "Status",
          orderStatus: "Order Status",
          requisitionRequestCreatedBy:"Requistion Requested By",
          requisitionRequestCreatedOn:"Requistion Requested On",
          createdBy:"Requistion Created By",
          createdDate: "Requistion Created On",
          assignedTo:"Requistion Assigned To",
          orderCreatedBy:"PO Created By",
          orderCreatedDate:"PO Created On",
          vendorName:"Vendor"
        },
        "Procurement_Main_Dashboard"
      );
    } else if (response.status === 401) {
      setIsLoading(false);
      handleExpiredToken();
    } else {
      setIsLoading(false);
      if (response !== undefined) {
        errorToast(response.data.statusText);
      } else {
        errorToast("Some error occured.");
      }
    }
  }
};

export const getMechDashData = async (
  rqno,
  setIsLoading,
  setDashboardData,
  handleExpiredToken
) => {
  if (!rqno) {
    errorToast("Enter Requisition No");
    return;
  }
  setIsLoading(true);
  const response = await getApi(`${ENDPOINTS.GET_MECH_DASHBOARD_DATA}/${rqno}`);
  if (response) {
    if (response.status === 200) {
      setIsLoading(false);
      setDashboardData(response?.data);
    } else if (response.status === 401) {
      setIsLoading(false);
      handleExpiredToken();
    } else {
      setIsLoading(false);
      if (response !== undefined) {
        errorToast(response.data.statusText);
      } else {
        errorToast("Some error occured.");
      }
    }
  }
  setIsLoading(false);
};
export const getUserListData = async (setUserListData) => {
  const response = await getApi(ENDPOINTS.GET_USER_LIST_BY_ROLE("gen"));
  if (response) {
    if (response.status === 200) {
      setUserListData(response?.data);
    } else if (response.status === 401) {
    } else {
      if (response !== undefined) {
        errorToast(response.data.statusText);
      } else {
        errorToast("Error loading on user list");
      }
    }
  }
};
