import React from 'react';
import { Modal } from 'antd';
import './ModalContainer.scss';

function ModalContainer(props) {

    return (
        <Modal className="my-modal" onCancel={props.handleClose} visible={true} centered={true} maskStyle={{ opacity: "0.6" }}
            footer={null} closable={true} width={"21rem"} transitionName="none" maskClosable={false}>
                {props.children}
        </Modal>
    )
}

export default ModalContainer
