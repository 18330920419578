import HeaderTitle from "../../../components/HeaderTitle/HeaderTitle";
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TableCell, TableRow } from "@material-ui/core";
import DataTable from '../../../components/DataTable/DataTable';
import moment from 'moment';
import "./LGTCScheduledTrainings.scss";
import { errorToast } from '../../../components/Generic/Generic';
import { getFromLocal } from '../../../utils/generic';
import endpoint from '../../../utils/endpoints';
import API from '../../../utils/Api';
import Loader from "../../../components/Loader/Loader";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#33485D",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "white",
            borderTop: "1px solid #E5E5E5"
        },
    },
}))(TableRow);

const columns = [
    { title: "Training Name", showSort: false, id: "title" },
    { title: "Training Type", showSort: false, id: "trainingType" },
    { title: "Start Date", showSort: false, id: "startDate" },
    { title: "End Date", showSort: false, id: "endDate" },
    { title: "Is Repeat Trainings", showSort: false, id: "isRepeatTrainings" },
    { title: "Instructor Name", showSort: false, id: "instructorName" },
    { title: "Max Attendees", showSort: false, id: "maxAttendees" },
    { title: "Training Device", showSort: false, id: "trainingDevice" },
    { title: "Aircraft Type", showSort: false, id: "aircraftType" },
    { title: "Description", showSort: false, id: "description" },
    { title: "Location", showSort: false, id: "location" },
    { title: "Duration In Hr", showSort: false, id: "durationInHr" },
    { title: "Duration In Min", showSort: false, id: "durationInMin" },
];

const LGTCScheduledTrainings = (props) => {
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("Name");
    var [finalRow, setFinalRow] = useState([]);
    var [searchText, setSearchText] = useState("");
    var [data, setData] = useState(null);
    const [isBusy, setBusy] = useState(false);

    const renderTableBody = (row, key) => {
        return (
            <StyledTableRow key={key}>
                <StyledTableCell>{row.title}</StyledTableCell>
                <StyledTableCell>{row.trainingType}</StyledTableCell>
                <StyledTableCell>{row.startDate ? moment(row.startDate).format("DD/MM/YYYY") : ""}</StyledTableCell>
                <StyledTableCell>{row.endDate ? moment(row.endDate).format("DD/MM/YYYY") : ""}</StyledTableCell>
                <StyledTableCell>{row.isRepeatTrainings ? "Y" : "N"}</StyledTableCell>
                <StyledTableCell>{row.instructorName}</StyledTableCell>
                <StyledTableCell>{row.maxAttendees}</StyledTableCell>
                <StyledTableCell>{row.trainingDevice}</StyledTableCell>
                <StyledTableCell>{row.aircraftType}</StyledTableCell>
                <StyledTableCell>{row.description}</StyledTableCell>
                <StyledTableCell>{row.location}</StyledTableCell>
                <StyledTableCell>{row.durationInHr}</StyledTableCell>
                <StyledTableCell>{row.durationInMin}</StyledTableCell>
            </StyledTableRow>
        )
    }

    useEffect(() => {
        var recordData = props.location?.state !== undefined ? props.location.state["records"] : null;
        if (recordData?.length > 0) {
            getAllSchedules();

        }
    }, [])


    const getAllSchedules = async () => {
        var url = `${endpoint.TrainingSchedule}/list`;

        var user = getFromLocal("user");
        var userId = parseInt(user?.userId);
        var headers = {
            'UserId': userId
        }
        setBusy(true);
        var request = {  }
        API.postWithHeader(url, headers, request, (isSuccess, response) => {
            if (isSuccess) {
                setBusy(false);
                if (response) {
                    setData(response);
                }
            } else {
                setBusy(false);
                errorToast("Error loading schedules!");
            }
        })
    }

    useEffect(() => {
        if (props?.records?.length > 0) {
            setData(props?.records);
        }
    }, [props?.records])

    useEffect(() => {
        if (data?.length > 0) {
            setFinalRow(data)
        }
    }, [data])

    useEffect(() => {
        if (searchText) {
            var searchTxt = searchText.toString().trim().toLowerCase();
            var newRows = data?.filter((value) => {
                return (
                    value.title?.toString().toLowerCase().includes(searchTxt) ||
                    value.instructorName?.toLowerCase().includes(searchTxt)
                );
            })
            setFinalRow(newRows);

        } else {
            setFinalRow(data)
        }
    }, [searchText, data])


    return (
        <>
            {isBusy && <Loader />}
            <div className={props?.showAll === false ? "" : "main-content"}>
                {props?.showAll !== false &&
                    <div className="row">
                        <div className="col-md-8"><HeaderTitle title="Trainings scheduled by LGTC" /></div>
                    </div>
                }
                <div className="row">
                    <div className="col-md-12 trainingsltgc">
                        <DataTable setSearchText={setSearchText} columns={columns} rows={props?.showAll === false ? finalRow?.slice(0, 3) : finalRow}
                            order={order} orderBy={orderBy} setOrder={(e) => setOrder(e)} setOrderBy={(e) => setOrderBy(e)}
                            renderTableBody={(row, key) => renderTableBody(row, key)} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default LGTCScheduledTrainings;