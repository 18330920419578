import React, { useRef, useState } from "react";
import IdleTimer from "react-idle-timer";
// import { ENDPOINTS, postApi } from "../../services/http";
import { getFromLocal, clearLocal } from "../../services/storage/LocalStorage";
import { useHistory } from "react-router-dom";

import { Modal, Button } from "../../componentsNew/core";

const SessionContainer = (props) => {
  const history = useHistory();
  const timeout = 1000 * 60 * 30; //*30 mins - Initial value only, final is from property file*/

  const [showModal, setShowModal] = useState(false);
  const idleTimerRef = useRef(null);
  const sessionTimeoutRef = useRef(null);
  const user = getFromLocal("user");

  const onIdle = () => {
    setShowModal(true);
    idleTimerRef.current.reset();
    sessionTimeoutRef.current = setTimeout(logOut, 1000 * 60);
  };

  const logOut = async () => {
    if (user != undefined) {
      // var credentials = {
      //   UserId: parseInt(user["userId"])
      // };
      // const response = await postApi(
      //   `${ENDPOINTS.LOGOUT}`, credentials
      // );
      //if (response.status == 200) {
      clearLocal();
      history.push("/");
      clearTimeout(sessionTimeoutRef.current);
      // } else {
      //   response.status != 401 &&
      //   errorToast("logout failed")
      // }
    }
  };

  const stayActive = () => {
    idleTimerRef.current.reset();
    setShowModal(false);
    clearTimeout(sessionTimeoutRef.current);
  };

  const renderModal = (
    <>
      <p className="h5 p-3 text-center">You will be logged out soon</p>

      <div className="d-flex justify-content-end">
        <div className="w-40 mr-2">
          <Button
            onClick={() => logOut()}
            className="rounded mt-2 "
            size="md"
            title="Log me out"
          />
        </div>

        <div className="float-end w-60">
          <Button
            onClick={() => stayActive()}
            className="rounded mt-2 "
            size="md"
            title="Keep me signed in"
          />
        </div>
      </div>
    </>
  );

  return (
    <div>
      {showModal && (
        <Modal
          open={showModal}
          close={setShowModal}
          children={renderModal}
        ></Modal>
      )}
      <IdleTimer
        ref={idleTimerRef}
        onIdle={onIdle}
        debounce={250}
        timeout={timeout}
      ></IdleTimer>
    </div>
  );
};

export default SessionContainer;
