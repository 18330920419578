import React, { useState } from "react";
import { Button, Tabs } from "../../componentsNew/core";
import "./lgtcAccount.scss";
import moment from "moment";

function LGTCPilotModal(props) {
  const [pilotDetail] = useState(props.pilotDetail);
  const [pilotDetails1] = useState(props.pilotDetails1);
  const [pilotDetails2] = useState(props.pilotDetails2);
  const [pilotDetails3] = useState(props.pilotDetails3);
  const [pilotDetails4] = useState(props.pilotDetails4);
  const [pilotDetails5] = useState(props.pilotDetails5);
  const [pilotDetails6] = useState(props.pilotDetails6);
  const [pilotDetails7] = useState(props.pilotDetails7);
  const [pilotDetails8] = useState(props.pilotDetails8);

  const [selectedTab, setSelectedTab] = useState(0);
  const tabArray = [
    "Details",
    "Ranks",
    "Bases",
    "Fleets",
    "Documents",
    "Qualifications",
    "Trainings",
    "Schedule",
    "Safety",
  ];

  const getLocalTimeZoneDateTime = (date) => {
    const dateUtc = new Date(date);
    var userTimezoneOffsetEndDate = dateUtc.getTimezoneOffset() * 60000;
    var newDate = new Date(dateUtc.getTime() - userTimezoneOffsetEndDate);
    return moment(newDate).format("DD/MM/YYYY HH:mm");
  };

  const tab1 = (
    <>
      <table className="table table-bordered table-hover table-sm ">
        <tbody>
          <tr>
            <td className="textColor">Sabre Id:</td>
            <td className="childColor">{pilotDetail?.Sabre_Id}</td>
            <td className="textColor">Name:</td>
            <td className="childColor">{pilotDetail?.Pilot_Name}</td>
          </tr>
          <tr>
            <td className="textColor">Email:</td>
            <td className="childColor">{pilotDetail?.Email}</td>
            <td className="textColor">DOB:</td>
            <td className="childColor">{pilotDetail?.Birthdate}</td>
          </tr>
          <tr>
            <td className="textColor">Address:</td>
            <td className="childColor">{pilotDetail?.Address1}</td>
            <td className="textColor">City:</td>
            <td className="childColor">{pilotDetail?.City}</td>
          </tr>
          <tr>
            <td className="textColor">Province:</td>
            <td className="childColor">{pilotDetail?.Province}</td>
            <td className="textColor">Country Code:</td>
            <td className="childColor">{pilotDetail?.Country}</td>
          </tr>
          <tr>
            <td className="textColor">Employment Date:</td>
            <td className="childColor">{pilotDetail?.EmploymentDate}</td>
            <td></td>
            <td></td>
          </tr>
          <tr></tr>
        </tbody>
      </table>
      <br></br>
    </>
  );

  const tab2 = (
    <>
      <table className="table table-hover table-sm table-bordered">
        <thead>
          <tr>
            <th className="textColor">Rank Code</th>
            <th className="textColor">Start Date</th>
            <th className="textColor">End Date</th>
          </tr>
        </thead>
        <tbody>
          {pilotDetails1 && pilotDetails1.length > 0 ? (
            pilotDetails1.map((row, rowIndex) => (
              <tr key={"row_" + rowIndex}>
                <td className="childColor">{row.rankCode}</td>
                <td className="childColor">
                  {row.effectiveDate
                    ? moment(row.effectiveDate).format("DD/MM/YYYY")
                    : ""}
                </td>
                <td className="childColor">
                  {row.expiryDate
                    ? moment(row.expiryDate).format("DD/MM/YYYY")
                    : ""}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3" className="childColor">
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <br></br>
    </>
  );

  const tab3 = (
    <>
      <table className="table table-hover table-sm table-bordered">
        <thead>
          <tr>
            <th className="textColor">Code</th>
            <th className="textColor">Start Date</th>
            <th className="textColor">End Date</th>
          </tr>
        </thead>
        <tbody>
          {pilotDetails2 && pilotDetails2.length > 0 ? (
            pilotDetails2.map((row, rowIndex) => (
              <tr key={"row_" + rowIndex}>
                <td className="childColor">{row.code}</td>
                <td className="childColor">
                  {row.effectiveDate
                    ? moment(row.effectiveDate).format("DD/MM/YYYY")
                    : ""}
                </td>
                <td className="childColor">
                  {row.expiryDate
                    ? moment(row.expiryDate).format("DD/MM/YYYY")
                    : ""}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3" className="childColor">
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <br></br>
    </>
  );

  const tab4 = (
    <>
      <table className="table table-hover table-sm table-bordered">
        <thead>
          <tr>
            <th className="textColor">Fleet Code</th>
            <th className="textColor">Fleet Company Code</th>
            <th className="textColor">Start Date</th>
            <th className="textColor">End Date</th>
          </tr>
        </thead>
        <tbody>
          {pilotDetails3 && pilotDetails3.length > 0 ? (
            pilotDetails3.map((row, rowIndex) => (
              <tr key={"row_" + rowIndex}>
                <td className="childColor">{row.fleetCode}</td>
                <td className="childColor">{row.fleetCompanyCode}</td>
                <td className="childColor">
                  {row.effectiveDate
                    ? moment(row.effectiveDate).format("DD/MM/YYYY")
                    : ""}
                </td>
                <td className="childColor">
                  {row.expiryDate
                    ? moment(row.expiryDate).format("DD/MM/YYYY")
                    : ""}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="childColor">
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <br></br>
    </>
  );

  const tab5 = (
    <>
      <table className="table table-hover table-sm table-bordered">
        <thead>
          <tr>
            <th className="textColor">Doc. Number</th>
            <th className="textColor">Doc. Type</th>
            <th className="textColor">Start Date</th>
            <th className="textColor">End Date</th>
            <th className="textColor">Issue Place</th>
            <th className="textColor">Issuing Authority</th>
            <th className="textColor">Issuing Country Code</th>
          </tr>
        </thead>
        <tbody>
          {pilotDetails4 && pilotDetails4.length > 0 ? (
            pilotDetails4.map((row, rowIndex) => (
              <tr key={"row_" + rowIndex}>
                <td className="childColor">{row.documentNumber}</td>
                <td className="childColor">{row.documentType}</td>
                <td className="childColor">
                  {row.effectiveDate
                    ? moment(row.effectiveDate).format("DD/MM/YYYY")
                    : ""}
                </td>
                <td className="childColor">
                  {row.expiryDate
                    ? moment(row.expiryDate).format("DD/MM/YYYY")
                    : ""}
                </td>
                <td className="childColor">{row.issuePlace}</td>
                <td className="childColor">{row.issuingAuthority}</td>
                <td className="childColor">{row.issueCountryCode}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" className="childColor">
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <br></br>
    </>
  );

  const tab6 = (
    <>
      <table className="table table-hover table-sm table-bordered">
        <thead>
          <tr>
            <th className="textColor">Name</th>
            <th className="textColor">Crew Type</th>
            <th className="textColor">Issue Date</th>
            <th className="textColor">Expiry Date</th>
            <th className="textColor">Projection Date</th>
            <th className="textColor">Renewal Date</th>
          </tr>
        </thead>
        <tbody>
          {pilotDetails5 && pilotDetails5.length > 0 ? (
            pilotDetails5.map((row, rowIndex) => (
              <tr key={"row_" + rowIndex}>
                <td className="childColor">{row.name}</td>
                <td className="childColor">{row.crewtype}</td>
                <td className="childColor">
                  {row.issueDate
                    ? moment(row.issueDate).format("DD/MM/YYYY")
                    : ""}
                </td>
                <td className="childColor">
                  {row.expiryDate
                    ? moment(row.expiryDate).format("DD/MM/YYYY")
                    : ""}
                </td>
                <td className="childColor">
                  {row.projectionDate
                    ? moment(row.projectionDate).format("DD/MM/YYYY")
                    : ""}
                </td>
                <td className="childColor">
                  {row.renewalDate
                    ? moment(row.renewalDate).format("DD/MM/YYYY")
                    : ""}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="childColor">
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <br></br>
    </>
  );

  const tab7 = (
    <>
      <table className="table table-hover table-sm table-bordered">
        <thead>
          <tr>
            <th style={{ display: "none" }}>Key</th>
            <th className="textColor">Training Type</th>
            <th className="textColor">Aircraft Type</th>
            <th className="textColor">Training Device</th>
            <th className="textColor">Airline</th>
            <th className="textColor">Trainer</th>
            <th className="textColor">Training Date</th>
            <th className="textColor">Training Category</th>
            <th className="textColor">Pilot Signed</th>
          </tr>
        </thead>
        <tbody>
          {pilotDetails6 && pilotDetails6.length > 0 ? (
            pilotDetails6.map((row, rowIndex) => (
              <tr key={"row_" + rowIndex}>
                <td style={{ display: "none" }}>{row.Key}</td>
                <td className="childColor">{row.trainingType}</td>
                <td className="childColor">{row.aircraftType}</td>
                <td className="childColor">{row.trainingDevice}</td>
                <td className="childColor">{row.airlines}</td>
                <td className="childColor">{row.trainer}</td>
                <td className="childColor">
                  {getLocalTimeZoneDateTime(row.trainingDate)}
                </td>
                <td className="childColor">{row.trainingCategory}</td>
                <td className="childColor">{row.pilotSigned}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8" className="childColor">
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <br></br>
    </>
  );

  const tab8 = (
    <>
      <table className="table table-hover table-sm table-bordered">
        <thead>
          <tr>
            <th className="textColor">Duty Date</th>
            <th className="textColor">CheckIn Time</th>
            <th className="textColor">Airport Code</th>
            <th className="textColor">Flight Schedule Time</th>
            <th className="textColor">Status</th>
          </tr>
        </thead>
        <tbody>
          {pilotDetails7 && pilotDetails7.length > 0 ? (
            pilotDetails7.map((row, rowIndex) => (
              <tr key={"row_" + rowIndex}>
                <td className="childColor">{row.dutyDate}</td>
                <td className="childColor">{row.checkInTime}</td>
                <td className="childColor">{row.airportCode}</td>
                <td className="childColor">{row.flightTime}</td>
                <td className="childColor">{row.status}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="childColor">
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <br></br>
    </>
  );

  const tab9 = (
    <>
      <table className="table table-hover table-sm table-bordered">
        <thead>
          <tr>
            <th className="textColor">Report Number</th>
            <th className="textColor">Raised Date</th>
            <th className="textColor">Report Status</th>
            <th className="textColor">Event Date</th>
            <th className="textColor">Event Title</th>
          </tr>
        </thead>
        <tbody>
          {pilotDetails8 && pilotDetails8.length > 0 ? (
            pilotDetails8.map((row, rowIndex) => (
              <tr key={"row_" + rowIndex}>
                <td className={"demo-" + row.ReportNumber}>
                  {row.reportNumber}
                </td>
                <td className={"demo-" + row.ReportNumber}>{row.raisedDate}</td>
                <td className={"demo-" + row.ReportNumber}>
                  {row.reportStatus}
                </td>
                <td className={"demo-" + row.ReportNumber}>{row.eventDate}</td>
                <td className={"demo-" + row.ReportNumber}>{row.eventTitle}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="childColor">
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <br></br>
    </>
  );

  const renderEditRequestModal = (
    <>
      <div className="bg-white rounded ">
        <Tabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabArray={tabArray}
        ></Tabs>
        <div className="table-responsive topTableAlign ">
          {
            {
              0: tab1,
              1: tab2,
              2: tab3,
              3: tab4,
              4: tab5,
              5: tab6,
              6: tab7,
              7: tab8,
              8: tab9,
            }[selectedTab]
          }
        </div>
      </div>
      <div className="col-12 p-0 d-flex justify-content-end">
        <Button
          className="rounded h6-web mt-2"
          size="md"
          title={"Close"}
          variant="primary"
          onClick={() => props.setIsModalVisible(false)}
        />
      </div>
    </>
  );

  return <div>{renderEditRequestModal}</div>;
}
export default LGTCPilotModal;
