import React, { useState, useEffect, useContext } from "react";
// import { useHistory, useLocation } from "react-router-dom";
import { ENDPOINTS, getApi } from "../../services/http";
import { getFromLocal } from "../../services/storage/LocalStorage";
import "./DirectorDashboard.scss";
import DirectorAccount from "./DirectorAccount";

import LGTCSchedule from "./LGTCSchedule";
import TrainingRequest from "./TrainingRequest";
import LicenseExp from "./LicenseExp";
import { useModal } from "../../hooks/useModal";
import { ModalContext } from "../../services/context/modal-context/State";

function DirectorDashboard() {
  const [dashboardData, setDashboardData] = useState(null);
  const [records, setRecords] = useState([]);
  // const [order, setOrder] = useState("asc");
  // const [orderBy, setOrderBy] = useState("Name");
  const [, setFinalRow] = useState(records);
  // const [searchText, setSearchText] = useState("");
  const [pageValue, setPageValue] = useState(0);
  const [expiryDays, setExpiryDays] = useState(0);
  const [documentType, setDocumentType] = useState("");
  const { handleExpiredToken } = useModal();
  const { setIsLoading } = useContext(ModalContext);

  useEffect(() => {
    const getProfile = async () => {
      setIsLoading(true);
      var user = getFromLocal("user");
      var userId = parseInt(user?.userId);
      var headers = {
        UserId: userId,
      };
      const response = await getApi(
        `${ENDPOINTS.DASHBOARD}/${user?.companyCode}`,
        { headers }
      );
      if (response) {
        if (response.status == 200) {
          setDashboardData(response?.data);
          setRecords(response?.data.scheduledTrainings);

          setIsLoading(false);
        } else if (response.status == 401) {
          setIsLoading(false);
          handleExpiredToken();
        } else {
          setIsLoading(false);
        }
      }
    };

    getProfile();
  }, []);

  useEffect(() => {
    if (records) {
      setFinalRow(records);
    }
  }, [records]);

  const redirectToLicenses = (expiry, value, docType) => {
    if (value !== 0) {
      // props.history.push({
      //     pathname: '/dashboard/licensesexpiring',
      //     state: { expiryDays: expiry, documentType: docType }
      // });

      setExpiryDays(expiry);
      setDocumentType(docType);
      setPageValue(1);
      //  window.history.replaceState(null, "", "/dashboard/new-design/director/license-expiring")
    }
  };

  const redirectToLGTCScheduledTrainings = () => {
    setPageValue(2);
    // window.history.replaceState(null, "", "/dashboard/new-design/director/lgtcscheduledtrainings")
  };

  const redirectToTrainingRequestView = () => {
    setPageValue(3);
    // window.history.replaceState(null, "", "/dashboard/new-design/director/scheduledtrainingRequests")
  };

  // const SafetyCard = (
  //   <>
  //     {" "}
  //     {[1, 1, 1, 1, 1, 1, 1, 1].map((item) => (
  //       <div className="p-2 mb-2 bg-highlight3 text-center w-93 me-3 rounded">
  //         <div className="text-white h3"> 21</div>
  //         <div className="text-white h8">WINGS AIR ASR FORM</div>
  //       </div>
  //     ))}
  //   </>
  // );

  const trainingCard = (colour, head, value, License, exp, doctype) => {
    return (
      <>
        <div
          className={`w-197 ${colour} p-2 rounded text-center box-shadow`}
          onClick={() => License && redirectToLicenses(exp, value, doctype)}
        >
          <div className="label-input  text-white">{head}</div>
          <div className="h3 text-white mt-2">{value}</div>
        </div>
      </>
    );
  };

  const contentToRender = (
    <>
      <div className="row">
        {pageValue == 0 ? (
          <div className="col-12">
            {/* <div className="h4 text-grey1">Pilot Safety Issues</div>
            <div className="d-flex flex-column d-sm-flex flex-sm-row flex-wrap">
              {SafetyCard}
            </div> */}
            <div className="h4 text-grey1 mt-1">Pilots due Training</div>

            <div className=" d-flex flex-column d-sm-flex flex-sm-row justify-content-between">
              {trainingCard(
                "bg-grey3",
                "Expiring in 15 days",
                dashboardData?.countOfQualificationsExpiryIn15Days,
                false
              )}
              {trainingCard(
                "bg-brandcolor2",
                "Expiring in 30 days",
                dashboardData?.countOfQualificationsExpiryIn30Days,
                false
              )}
              {trainingCard(
                "bg-secondary",
                "Expiring in 60 days",
                dashboardData?.countOfQualificationsExpiryIn60Days,
                false
              )}
              {trainingCard(
                "bg-brandcolor3",
                "Expiring in 90 days",
                dashboardData?.countOfQualificationsExpiryIn90Days,
                false
              )}
              {trainingCard(
                "bg-brandcolor4",
                "Qualifications Expired",
                dashboardData?.countOfQualificationsExpired,
                false
              )}
            </div>

            <div className="h4 text-grey1 mt-4">Pilots due for Medical</div>

            <div className="d-flex flex-column d-sm-flex flex-sm-row justify-content-between">
              {trainingCard(
                "bg-grey3",
                "Expiring in 15 days",
                dashboardData?.countOfMedicaldocsExpiryIn15Days,
                true,
                15,
                "Medical License"
              )}
              {trainingCard(
                "bg-brandcolor2",
                "Expiring in 30 days",
                dashboardData?.countOfMedicaldocsExpiryIn30Days,
                true,
                30,
                "Medical License"
              )}
              {trainingCard(
                "bg-secondary",
                "Expiring in 60 days",
                dashboardData?.countOfMedicaldocsExpiryIn60Days,
                true,
                60,
                "Medical License"
              )}
              {trainingCard(
                "bg-brandcolor3",
                "Expiring in 90 days",
                dashboardData?.countOfMedicaldocsExpiryIn90Days,
                true,
                90,
                "Medical License"
              )}
              {trainingCard(
                "bg-brandcolor4",
                "Medical Expired",
                dashboardData?.countOfMedicaldocsExpired,
                true,
                0,
                "Medical License"
              )}
            </div>

            <div className="h4 text-grey1 mt-4">License Expiry</div>

            <div className="d-flex flex-column d-sm-flex flex-sm-row justify-content-between">
              {trainingCard(
                "bg-grey3",
                "Expiring in 15 days",
                dashboardData?.countOfLicensesExpiryIn15Days,
                true,
                15,
                "Pilot License"
              )}
              {trainingCard(
                "bg-brandcolor2",
                "Expiring in 30 days",
                dashboardData?.countOfLicensesExpiryIn30Days,
                true,
                30,
                "Pilot License"
              )}
              {trainingCard(
                "bg-secondary",
                "Expiring in 60 days",
                dashboardData?.countOfLicensesExpiryIn60Days,
                true,
                60,
                "Pilot License"
              )}
              {trainingCard(
                "bg-brandcolor3",
                "Expiring in 90 days",
                dashboardData?.countOfLicensesExpiryIn90Days,
                true,
                90,
                "Pilot License"
              )}
              {trainingCard(
                "bg-brandcolor4",
                "Licenses Expired",
                dashboardData?.countOfLicensesExpired,
                true,
                0,
                "Pilot License"
              )}
            </div>

            <div className="d-flex">
              <div className="h4 text-grey1 mt-4 mb-3 col-md-8 pl-0">
                Trainings scheduled by LGTC
              </div>
              <div
                className="col-md-4 text-right mt-4 view-all has-click pe-0"
                onClick={() => redirectToLGTCScheduledTrainings()}
              >
                View All
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-12">
                <LGTCSchedule records={records} showAll={false}></LGTCSchedule>
              </div>
            </div>

            <div className="d-flex">
              <div className="h4 text-grey1 mt-4 mb-3 col-md-8 pl-0">
                Trainings requested
              </div>
              <div
                className="col-md-4 text-right mt-4 view-all has-click pe-0"
                onClick={() => redirectToTrainingRequestView()}
              >
                View All
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-12">
                <TrainingRequest showAll={false}></TrainingRequest>
              </div>
            </div>
          </div>
        ) : pageValue == 1 ? (
          <LicenseExp
            expiryDays={expiryDays}
            documentType={documentType}
          ></LicenseExp>
        ) : pageValue == 2 ? (
          <LGTCSchedule records={records} showAll={true}></LGTCSchedule>
        ) : pageValue == 3 ? (
          <TrainingRequest showAll={true}></TrainingRequest>
        ) : null}
      </div>
    </>
  );

  return (
    <DirectorAccount
      pageValue={pageValue}
      setPageValue={setPageValue}
      breadCrumbTitle={
        expiryDays != 0
          ? `License Expiring in ${expiryDays} days`
          : `License Expired`
      }
    >
      {contentToRender}
    </DirectorAccount>
  );
}

export default DirectorDashboard;
