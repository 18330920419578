import axios from "axios";
import { baseurl } from "./baseUrl";
import {getFromLocal} from '../storage/LocalStorage'

// create an axios instance
export const AxiosClient = axios.create({
  baseURL: baseurl,
});

AxiosClient.interceptors.request.use(function (config) {
  // Do something before request is sent
  let token = getFromLocal("token");
  config.headers["Content-Type"] = "application/json"
  config.headers["Authorization"] = "Bearer " + token;
  return config;
});
