import React from "react";
import "./FloatingHeader.scss";
import CloseIcon from "../../../../assets/svg/NewIcons/CloseIcon.svg";
import HeaderProfileIcon from "../../../../assets/svg/NewIcons/User.svg";
import OutsideClickHandler from "react-outside-click-handler";
import { clearLocal } from "../../../utils/generic";

import { useHistory } from "react-router-dom";
import { ENDPOINTS, getApi } from "../../../services/http";
import { getFromLocal } from "../../../services/storage/LocalStorage";

const FloatingHeader = (props) => {
  const history = useHistory();
  let { setBanner, banner, menuList, handleLoginValue } = props;
  const user = getFromLocal("user");
  const closeFloatingNavBar = () => {
    setBanner(false);
  };

  const handleClickOutside = () => {
    if (banner) {
      setBanner(false);
    }
  };

  const handleLogout = async () => {
    var credentials = {
      UserId: parseInt(user["userId"]),
    };
    const response = await getApi(`${ENDPOINTS.LOGOUT}`, credentials);
    if (response) {
      // if (response.status === 200) {
      clearLocal();
      history.push("/auth/login");
      // }

      // else {
      //   response.status !== 401 && errorToast("logout failed");
      // }
    } else {
      clearLocal();
      history.push("/auth/login");
    }
  };

  const handleRoute = (path) => {
    if (path === "/") {
      handleLoginValue(null);
      // removeToSession("loginData");
    }

    history.push(path);

    let newDesign = true; //path.includes("/new-design/director/dashboard");

    if (newDesign) {
      history.go(0);
    }

    setBanner(false);
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        handleClickOutside();
      }}
    >
      <div
        className="floating-header-container"
        style={{ right: banner ? "0" : "-400px", transition: "0.5s" }}
      >
        <div className="p-5">
          <div className="text-end">
            <img
              className="cursor-pointer "
              src={CloseIcon}
              alt="close"
              onClick={closeFloatingNavBar}
            />
          </div>
          <div className="cursor-not-allowed p-2 h5 mt-2 text-white d-flex align-items-center">
            <img src={HeaderProfileIcon} alt="" />
            <span className="px-4">{user && user.name}</span>
          </div>

          <div className="floating-header-contents-section mt-5">
            {user && user.roleName !== "Pilot" ? (
              <div className="mt-1 h2  bg-white divide-menu w-100" />
            ) : null}
            <div className="overflow-auto mh-vh-80">
              {
                // eslint-disable-next-line array-callback-return
                menuList.map((menu, key) => {
                  let path = menu?.path;
                  if (menu !== undefined && menu != null && menu?.isNewDesign) {
                    return (
                      <React.Fragment key={`navItem_${key}  `}>
                        <div
                          className={`${
                            menu?.disable
                              ? "cursor-not-allowed"
                              : "cursor-pointer"
                          } p-2 h5 text-white d-flex align-items-center `}
                          onClick={() =>
                            !menu?.disable && handleRoute(menu.layout + path)
                          }
                          data-bs-toggle="tooltip"
                          title={menu?.disable ? "Coming Soon" : undefined}
                          data-bs-placement="top"
                        >
                          <img
                            src={menu?.icon}
                            className="svg-menu-link"
                            alt=""
                          />
                          <span className="px-4">{menu?.name}</span>
                        </div>
                        <div className="mt-1 h2  bg-white divide-menu w-100" />
                      </React.Fragment>
                    );
                  }
                })
              }
              {user && (
                <div
                  className={
                    "cursor-pointer h5 text-white align-items-center floating-bottom-textContainer"
                  }
                >
                  <div className="mb-1 h2  bg-white divide-menu w-100" />
                  <span
                    onClick={() => handleLogout()}
                    className="px-4 font-italic text-decoration-underline"
                  >
                    {"Sign out"}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default FloatingHeader;
