import React from 'react'
function TopHeader(props) {
    let { noHeaderColour } = props;


    return (
        <div className='box-shadow'>
            <div className={`px-sm-5 px-2 ${noHeaderColour ? 'bg-highlight2' : 'bg-primary'}  py-2   d-flex justify-content-between`}>
                <div className='label-input text-white'>Please check the information of each province before traveling and arrive at the airport earlier for check-in and check documents, click</div>
                {/* <div className='d-flex label-input text-white '>
                <div>ENG</div>
                <div>English</div>
                <div>Admin Portal</div>
            </div> */}
                
            </div>
        </div>
    )
}

export default TopHeader
