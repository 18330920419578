import React from "react";
import { BreadCrumb } from "../../componentsNew/core";
import "./../../scss/trax.scss";

// let dashboardtxt = "Let’s make managing your team’s training schedule a little more easier."
// let dashboardtxt = ""

function TRAXAccount(props) {
  // const history = useHistory();

  const {
    // pageValue,
    children,
    breadCrumbTitle = "Dashboard",
    // setPageValue,
    navlink = 0,
    subMenu,
    // AddButtonShow = false,
    // getUserModal,
    // getAircraftModal,
    // getAirportModal,
    // getTrainingDeviceModal
  } = props;

  const breadCrumbItems = [
    {
      title: "Lion Group",
      link: "/dashboard",
    },
    {
      title: `${breadCrumbTitle}`,
      link: 1,
    },
  ];

  const breadCrumbItems1 = [
    {
      title: "Lion Group",
      link: "/dashboard",
    },
    {
      title: `${subMenu}`,
      link: `${
        subMenu === "Trainings"
          ? "/dashboard/new-design/lgtc/trainings"
          : "/dashboard/new-design/lgtc/qualification"
      }`,
    },
    {
      title: `${breadCrumbTitle}`,
      link: 1,
    },
  ];
  return (
    <div className="px-5 py-2 bg-white fixed-container">
      <BreadCrumb
        breadCrumbItems={navlink === 0 ? breadCrumbItems : breadCrumbItems1}
      />
      <div className="row">
        <div className="col-md-12 col-12 pt-md-0 pt-3 pb-5">{children}</div>
      </div>
    </div>
  );
}

export default TRAXAccount;
