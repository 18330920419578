import { useState } from "react";

export const usePagination = (data) => {
  var updatedItems = {};
  const [rowItems, setRowItems] = useState(data);
  const [pagination, setPagination] = useState({});
  const pageDetails = {
    pageSize: 10,
    totalPage: data?.length / 10,
    totalItems: data?.length,
  };

  const handlePagination = async (actionType) => {
    switch (actionType) {
      case "next":
        updatedItems = await updatePagination(pagination.currentPage + 1);
        setPagination({...updatedItems, ...pageDetails });
        break;

      case "prev":
        updatedItems = await updatePagination(pagination.currentPage - 1);
        setPagination({...updatedItems, ...pageDetails });
        break;

      default:
        updatedItems = await updatePagination(1);
        setPagination({...updatedItems, ...pageDetails });
        break;
    }
  };

  // code to check whether next or prev page url is available
  const updatePagination = async (currentPage) => {
    const { pageSize } = pageDetails;
    const dataLength = data?.length;
    const lastPage = dataLength < pageSize ? 1 : Math.ceil(dataLength / pageSize);

    // first page
    if (currentPage == 1) {
      setRowItems(data.slice(0, pageSize));
      return {
        prevPageUrl: false,
        nextPageUrl: currentPage == lastPage ? false : true,
        from: dataLength != 0 ? 1 : 0,
        to: dataLength < pageSize ? dataLength : pageSize,
        currentPage: currentPage,
      };
    }
    // last page
    else if (currentPage == lastPage) {
      let from = pageSize * (currentPage - 1);
      setRowItems(data.slice(from , dataLength));
      return {
        prevPageUrl: true,
        nextPageUrl: false,
        from: from + 1,
        to: dataLength,
        currentPage: currentPage,
      };
    }
    // in-between pages
    else {
      let from = pageSize * (currentPage - 1);
      let to = pageSize * currentPage;
      setRowItems(data.slice(from , to));
      return {
        prevPageUrl: true,
        nextPageUrl: true,
        from: from + 1,
        to: to,
        currentPage: currentPage,
      };
    }
  };

  return { pagination, rowItems, handlePagination };
};
