import HeaderTitle from "../../componentsNew/core/sp-title/HeaderTitle";
import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import "./TrainingRequests.scss";
import { errorToast, successToast } from "../../componentsNew/Toast/Toast";
import { ENDPOINTS, getApi, deleteApi } from "../../services/http";
import { getFromLocal } from "../../services/storage/LocalStorage";
import { PageDetails } from "../../componentsNew/page-details/PageDetails";
import { usePagination } from "../../hooks/usePagination";
import ArrowLeft from "../../../assets/svg/NewIcons/arrow_left_white.svg";
import ArrowRight from "../../../assets/svg/NewIcons/arrow_right_white.svg";
import { Badge, InputSearch, Modal } from "../../componentsNew/core";
import Edit from "../../../assets/svg/NewIcons/edit.svg";
import Delete from "../../../assets/svg/NewIcons/delete.svg";

import TrainingEditModal from "./DirectorTrainingEditModal";

import { useModal } from "../../hooks/useModal";
import { ModalContext } from "../../services/context/modal-context/State";
import SortValue from "../../helper/sort";

function TrainingRequest(props) {
  const [sort, setSort] = useState("");
  const [tableSortKey, setTableSortKey] = useState("");
  const [records, setRecords] = useState([]);
  const [finalRow, setFinalRow] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [usePayLoad, setUsePayLoad] = useState([]);
  const { pagination, rowItems, handlePagination } = usePagination(finalRow);
  const { handleExpiredToken } = useModal();
  const { setIsLoading } = useContext(ModalContext);

  useEffect(() => {
    getTrainingRequests();
  }, []);

  useEffect(() => {
    handlePagination();
  }, [finalRow]);

  useEffect(() => {
    if (records?.length > 0) {
      if (props?.showAll === false) {
        let row = records.splice(0, 3);
        setFinalRow(row);
      } else {
        setFinalRow(records);
      }
    } else {
      setFinalRow(records);
    }
  }, [records]);

  const SortBy = (key) => {
    if (tableSortKey == key) {
      if (sort == "") {
        setSort("asc");
        let val = finalRow.sort(SortValue(key));
        setFinalRow(val);
        handlePagination();
      } else if (sort == "asc") {
        setSort("desc");
        let val = finalRow.sort(SortValue(`-${key}`));
        setFinalRow(val);
        handlePagination();
      } else if (sort == "desc") {
        setSort("asc");
        let val = finalRow.sort(SortValue(key));
        setFinalRow(val);
        handlePagination();
      } else {
        setSort("");
      }
    } else {
      setTableSortKey(key);
      setSort("asc");
      let val = finalRow.sort(SortValue(key));
      setFinalRow(val);
      handlePagination();
    }
  };

  const getTrainingRequests = async () => {
    setIsLoading(true);
    var user = getFromLocal("user");
    var userId = parseInt(user?.userId);
    var headers = {
      UserId: userId,
    };

    const response = await getApi(`${ENDPOINTS.TRAINING_REVIEW}`, {
      headers,
    });
    if (response) {
      if (response.status == 200) {
        setRecords(response?.data);
        setIsLoading(false);
      } else if (response.status == 401) {
        setIsLoading(false);
        handleExpiredToken();
      } else {
        setIsLoading(false);
      }
    }
  };

  const handleChangeWithLib = (value) => {
    let newRows = [];
    if (value) {
      let searchTxt = value.toString().trim().toLowerCase();
      newRows = records?.filter((value) => {
        return (
          (value.title &&
            value.title.toString().toLowerCase().includes(searchTxt)) ||
          (value.trainingType &&
            value.trainingType.toLowerCase().includes(searchTxt))
        );
      });
    } else {
      newRows = [...records];
    }
    setFinalRow(newRows);
  };

  const ChildrenModal = (
    <>
      <TrainingEditModal
        setShowModal={setShowModal}
        usePayLoadData={usePayLoad}
        getTrainingRequests={() => getTrainingRequests()}
      ></TrainingEditModal>
    </>
  );

  const EditField = (row) => {
    return (
      <Badge
        className="rounded-3"
        size="sm"
        variant="white"
        icon={Edit}
        title="Edit"
        onClick={() => handleClick("Edit", row.id)}
      />
    );
  };

  const DeleteField = (row) => {
    return (
      <Badge
        className="rounded-3"
        size="xs"
        variant="white"
        icon={Delete}
        title="Delete"
        onClick={() => handleClick("Delete", row.id)}
      />
    );
  };

  const handleClick = async (status, id) => {
    if (status === "Delete") {
      let user = getFromLocal("user");
      let userId = parseInt(user?.userId);
      let headers = {
        UserId: userId,
      };
      setIsLoading(true);
      const response = await deleteApi(
        `${ENDPOINTS.REQUEST_SCHEDULE}/${id}/remove`,
        { headers }
      );
      if (response) {
        if (response.status == 200) {
          getTrainingRequests();
          successToast("Deleted Successfully");
          setIsLoading(false);
        } else if (response.status == 401) {
          setIsLoading(false);
          handleExpiredToken();
        } else {
          setIsLoading(false);
          errorToast("Delete failed");
        }
      }
    }

    if (status === "Edit") {
      setShowModal(true);
      var trainingReq = finalRow.filter((x) => x.id === id)[0];
      setUsePayLoad(trainingReq);

      // getPilots(trainingReq?.qualificationId, trainingReq?.endDate, trainingReq?.assignedPilots);
    }
  };

  const TableControls = (
    <div className="d-flex col-6 justify-content-end">
      <div className="flex-wrap d-flex align-items-center justify-content-center">
        <div className="">
          <div className="col-md-12 col-sm-4 col-8">
            <InputSearch
              placeholder="search here..."
              className="w-100"
              onChange={handleChangeWithLib}
              //   value={searchText}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-3 d-flex align-items-center justify-content-center pe-0">
        <Badge
          className="rounded-50"
          variant="primary"
          size="xl"
          icon={ArrowLeft}
          title="Previous Page"
          disabled={!pagination?.prevPageUrl}
          onClick={() => handlePagination("prev")}
        />
        <Badge
          className="rounded-50 ms-2"
          variant="primary"
          size="xl"
          icon={ArrowRight}
          title="Next Page"
          disabled={!pagination?.nextPageUrl}
          onClick={() => handlePagination("next")}
        />
      </div>
    </div>
  );

  return (
    <>
      <div className={props?.showAll === false ? "" : "main-content"}>
        {props?.showAll !== false && (
          <div className="row">
            <div className="col-md-6">
              <HeaderTitle title="Training Requests" />
            </div>
            {TableControls}
          </div>
        )}
        <div className="row">
          <div className="col-md-12 ">
            <div className=" mt-2 table-responsive ">
              <table className="table table-bordered shadow-sm align-middle ">
                <thead className="bg-grey h7-table text-grey1">
                  <tr className="text-center text-nowrap">
                    <td onClick={() => SortBy("qualification")}>
                      Qualification
                      <i
                        className={
                          tableSortKey == "qualification"
                            ? sort == "asc"
                              ? "fa fa-sort-asc"
                              : "fa fa-sort-desc"
                            : "fa fa-fw fa-sort"
                        }
                      ></i>
                    </td>
                    <td onClick={() => SortBy("qualificationDescription")}>
                      Qualification Description
                      <i
                        className={
                          tableSortKey == "qualificationDescription"
                            ? sort == "asc"
                              ? "fa fa-sort-asc"
                              : "fa fa-sort-desc"
                            : "fa fa-fw fa-sort"
                        }
                      ></i>
                    </td>
                    <td onClick={() => SortBy("title")}>
                      Title
                      <i
                        className={
                          tableSortKey == "title"
                            ? sort == "asc"
                              ? "fa fa-sort-asc"
                              : "fa fa-sort-desc"
                            : "fa fa-fw fa-sort"
                        }
                      ></i>
                    </td>
                    <td onClick={() => SortBy("endDate")}>
                      Cut Off Date
                      <i
                        className={
                          tableSortKey == "endDate"
                            ? sort == "asc"
                              ? "fa fa-sort-asc"
                              : "fa fa-sort-desc"
                            : "fa fa-fw fa-sort"
                        }
                      ></i>
                    </td>
                    <td onClick={() => SortBy("countOfAttendees")}>
                      Max Attendees
                      <i
                        className={
                          tableSortKey == "countOfAttendees"
                            ? sort == "asc"
                              ? "fa fa-sort-asc"
                              : "fa fa-sort-desc"
                            : "fa fa-fw fa-sort"
                        }
                      ></i>
                    </td>
                    <td onClick={() => SortBy("description")}>
                      Description
                      <i
                        className={
                          tableSortKey == "description"
                            ? sort == "asc"
                              ? "fa fa-sort-asc"
                              : "fa fa-sort-desc"
                            : "fa fa-fw fa-sort"
                        }
                      ></i>
                    </td>
                    <td onClick={() => SortBy("location")}>
                      Location
                      <i
                        className={
                          tableSortKey == "location"
                            ? sort == "asc"
                              ? "fa fa-sort-asc"
                              : "fa fa-sort-desc"
                            : "fa fa-fw fa-sort"
                        }
                      ></i>
                    </td>
                    <td>Actions</td>
                  </tr>
                </thead>

                <tbody className="h8 text-table-body">
                  {rowItems && rowItems.length > 0 ? (
                    rowItems.map((row, rowIndex) => (
                      <tr className="text-center" key={"row_" + rowIndex}>
                        <td>{row.qualification}</td>
                        <td>{row.qualificationDescription}</td>
                        <td>{row.title}</td>
                        <td>
                          {row.endDate
                            ? moment(row.endDate).format("DD/MM/YYYY")
                            : ""}
                        </td>
                        <td>{row.countOfAttendees}</td>
                        <td>{row.description}</td>
                        <td>{row.location}</td>
                        <td>
                          <div className="d-flex justify-content-center">
                            <div>{EditField(row)}</div>
                            <div className="ml-2">{DeleteField(row)}</div>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="text-center h6-web">No records found</tr>
                  )}
                </tbody>
              </table>
            </div>

            {props?.showAll !== false && (
              <PageDetails pagination={pagination} />
            )}
          </div>
        </div>
        {showModal && (
          <Modal
            open={showModal}
            onClose={() => setShowModal(false)}
            showCloseIcon={true}
            children={ChildrenModal}
          ></Modal>
        )}
      </div>
    </>
  );
}

export default TrainingRequest;
