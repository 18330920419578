import React, { useState, useEffect, useRef } from "react";
import { errorToast, successToast } from "../../componentsNew/Toast/Toast";
import { ENDPOINTS, postApi } from "../../services/http";
import { getFromLocal } from "../../services/storage/LocalStorage";
import { Dropdown, Input, Button } from "../../componentsNew/core";
import "./../../scss/trax.scss";
import { ConstructObject } from "../../helper";

export default function TRAXViewRequisition(props) {
  const validationMessageRef = useRef();
  const user = getFromLocal("user");
  const userId = parseInt(user?.userId);
  const { setShowModal, setIsLoading, title, formData, loadData } = props;
  const [modalTitle, setModalTitle] = useState();
  const [payload, setPayload] = useState({
    requisitionNo: "",
    action: "",
    reason: "",
    traxRequisitionNo: "",
  });
  const [error, setError] = useState(false);
  useEffect(() => {
    setModalTitle(title);
  }, []);

  const updatePayload = (key, value) => {
    setPayload({ ...payload, [key]: value });
  };
  const actions = [
    { label: "Approved", value: 2 },
    { label: "Rejected", value: 3 },
  ];

  const submitForm = async (url, updatedPayload, type) => {
    setIsLoading(true);
    let headers = {
      UserId: userId,
    };

    let response = await postApi(url, updatedPayload, {
      headers: headers,
    });

    if (response.status === 200) {
      successToast(
        "Parts requisition request created successfully. RequisitionNo: 1"
      );
      setShowModal(false);
      loadData();
    } else {
      errorToast("Error creating request. Please try again!");
    }
    setIsLoading(false);
  };

  const checkRequiredField = async () => {
    let isValid = true;
    if (formData?.action === "Open") {
      if (payload.action === "" || payload.reason === "") {
        isValid = false;
      }
    }

    if (formData?.action === "Approved") {
      if (payload.traxRequisitionNo === "") {
        isValid = false;
      }
    }

    return isValid;
  };

  const handleModalSubmit = async () => {
    let isValidForm = await checkRequiredField();
    if (!isValidForm) {
      setError(true);
      validationMessageRef.current?.scrollIntoView({ block: "nearest" });
      return false;
    }
    setError(false);

    if (formData?.action === "Open") {
      var request = {
        requisitionNo: formData.requisitionNo,
        action: payload.action,
        reason: payload.reason,
      };
      submitForm(ENDPOINTS.REQUISITION_ACTION, request, "");
    }
    if (formData?.action === "Approved") {
      const request = {
        requisitionNo: formData.requisitionNo,
        traxRequisitionNo: payload.traxRequisitionNo,
      };
      submitForm(ENDPOINTS.REQUISITION_CLOSE, request, "");
    }
  };

  const controlButtons = (
    <div className="d-flex justify-content-end">
      <Button
        className="rounded h6-web w-80"
        size="md"
        title={"Cancel"}
        variant="grey2"
        onClick={() => setShowModal(false)}
      />
      <Button
        className="ml-2 rounded h6-web w-80"
        size="md"
        title={"Submit"}
        variant="primary"
        onClick={handleModalSubmit}
      />
    </div>
  );

  const renderEditRequestModal = (
    <>
      <h4 ref={validationMessageRef}>{modalTitle}</h4>
      {error && (
        <span className="error">Please complete all required fields</span>
      )}

      <div className="row align-items-center mx-2 mt-4">
        <span className="col-sm-3 h6 p-0">Requisition No.</span>
        <Input
          Shadow
          className="h6 rounded p-0 col-sm-9"
          value={formData?.requisitionNo}
          readOnly
          type="text"
          placeholder=""
        />
      </div>
      <div className="row align-items-center mx-2 mt-4">
        <span className="col-sm-3 h6 p-0">Description</span>
        <Input
          Shadow
          className="h6 rounded p-0 col-sm-9"
          value={formData?.description}
          readOnly
          type="text"
          placeholder=""
        />
      </div>

      {formData?.action === "Open" && (
        <>
          <div className="row align-items-center mx-2 mt-4">
            <span className="col-sm-3 h6 p-0">
              Action<sup className="text-danger ">*</sup>
            </span>
            <Dropdown
              Shadow
              selectOptions={actions}
              placeholder=""
              value={ConstructObject(actions, payload.action)}
              onChange={(value) => updatePayload("action", value)}
              containerClassName={" p-0 col-12 col-sm-9"}
            />
          </div>

          <div className="row align-items-center mx-2 mt-4">
            <span className="col-sm-3 h6 p-0">
              Reason<sup className="text-danger ">*</sup>
            </span>
            <Input
              Shadow
              className="h6 rounded p-0 col-sm-9"
              value={payload.reason}
              onChange={(value) => updatePayload("reason", value)}
              type="text"
              placeholder=""
            />
          </div>
        </>
      )}

      {formData?.action === "Approved" && (
        <>
          <div className="row align-items-center mx-2 mt-4">
            <span className="col-sm-3 h6 p-0">
              Trax Requisition No<sup className="text-danger ">*</sup>
            </span>
            <Input
              Shadow
              className="h6 rounded p-0 col-sm-9"
              value={payload.traxRequisitionNo}
              onChange={(value) => updatePayload("traxRequisitionNo", value)}
              type="text"
              placeholder=""
            />
          </div>
        </>
      )}
      {controlButtons}
    </>
  );

  return <div>{renderEditRequestModal}</div>;
}
