import React, { useState, useEffect, useContext } from "react";
import "./../../scss/trax.scss";
import { ModalContext } from "../../services/context/modal-context/State";
import { getRequistionTrackData } from "./function";
import TRAXAccount from "../TraxAccount/TRAXAccount";
import { useModal } from "../../hooks/useModal";

import { InputSearch, Button } from "../../componentsNew/core";

function TrackRequisitionData() {
  const { setIsLoading } = useContext(ModalContext);
  const { handleExpiredToken } = useModal();
  const [searchText, setSearchText] = useState("");
  const [reqTrackData, setReqTrackData] = useState([]);

  useEffect(() => {}, []);

  const getData = async () => {
    getRequistionTrackData(
      searchText,
      setIsLoading,
      setReqTrackData,
      handleExpiredToken
    );
  };

  const contentToRender = (
    <>
      <div className="h4 text-grey1 mt-4 pb-2">Track Trax Requisition</div>
      <div className="d-flex align-items-center">
        <div className="ml-2">
          <InputSearch
            placeholder="Enter Requisition No"
            className="w-100"
            onChange={setSearchText}
            value={searchText}
            type="number"
          />
        </div>
        <Button
          className="rounded h6-web w-80 ml-2"
          size="md"
          title={"Submit"}
          variant="primary"
          onClick={getData}
        />
      </div>
      <div className=" mt-2 mb-2 table-responsive ">
        <table className="table table-bordered shadow-sm align-middle ">
          <thead className="bg-grey h7-table text-grey1">
            <tr className="text-center text-nowrap">
              <td className="has-pointer">RQ number</td>
              <td>RQ Line</td>
              <td>Order Number</td>
              <td className="has-pointer">Order Line</td>
              <td className="has-pointer">OrderType</td>
              <td className="has-pointer">PN</td>
              <td className="has-pointer">SN</td>
              <td>Batch</td>
              <td>Status</td>
              <td>Shipped From</td>
              <td>Shipped To</td>
              <td>Delivery Status</td>
            </tr>
          </thead>
          <tbody className="h8 text-table-body">
            {reqTrackData?.map((row, rowIndex) => (
              <tr className="text-center text-nowrap" key={"row_" + rowIndex}>
                <td>{row.requistion}</td>
                <td>{row.requistionLine}</td>
                <td>{row.orderNumber}</td>
                <td>{row.orderLine}</td>
                <td>{row.orderType}</td>
                <td>{row.pn}</td>
                <td>{row.sn}</td>
                <td>{row.batch}</td>
                <td>{row.status}</td>
                <td>{row.shippedFrom}</td>
                <td>{row.shippedTo}</td>
                <td>{row.deliveryStatus}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {!reqTrackData.length && <p className="text-center">No Data Found</p>}
      </div>
    </>
  );

  return (
    <TRAXAccount breadCrumbTitle="Track Requisition">
      {contentToRender}
    </TRAXAccount>
  );
}

export default TrackRequisitionData;
