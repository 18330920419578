import React from "react";
import "./Checkbox.scss";

export const Checkbox = React.forwardRef((props, ref) => {
  let {
    label,
    labelColor,
    textColour,
    className,
    checked,
    onChange = () => {},
    // isError = false,
    errorMessage,
    readOnly = false,
    ...rest
  } = props;

  return (
    <div className={`sp-checkbox d-flex flex-column  ${className} `}>
      <div className="d-flex justify-content-center">
        {label && (
          <label className={`text-${labelColor} h7-web `}>{label}</label>
        )}
      </div>
      <label className="containerCheck">
        <input
          checked={checked}
          onChange={(e) => onChange(e)}
          type={"checkbox"}
          readOnly={readOnly}
          {...rest}
          ref={ref}
          id="checkbox"
          // className={`text-${textColour} p-1 h7-web rounded border border-1 cursor-pointer core`}
        />
        <span className="checkmark"></span>
      </label>
    </div>
  );
});
