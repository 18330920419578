import React, { useState, useEffect, useRef } from "react";
import { getFromLocal } from "../../services/storage/LocalStorage";
import { Dropdown, Input, Button, DatePickers } from "../../componentsNew/core";
import "./../../scss/trax.scss";
import { ConstructObject } from "../../helper";
import moment from "moment";
import { useModal } from "../../hooks/useModal";
import {
  getDefectAPI,
  getLocationSiteAcApi,
  getPartsListApi,
  getTaskCardAPI,
  getWOAPI,
  handleModalSubmitApi,
  submitFormApi,
} from "./function";

export default function TRAXPartsRequisitionModal(props) {
  const validationMessageRef = useRef();
  const user = getFromLocal("user");
  const userId = parseInt(user?.userId);
  const {
    setShowModal,
    setIsLoading,
    title,
    setRequsitionRequestMessage,
    setPartNumberMessage,
  } = props;
  const { handleExpiredToken } = useModal();
  const [modalTitle, setModalTitle] = useState();
  const [partsNameListDrop, setPartsNameListDrop] = useState([]);
  const [locationDropList, setLocationDropList] = useState([]);
  const [siteDropList, setSiteDropList] = useState([]);
  const [acDropList, setAcDropList] = useState([]);
  const [woDropList, setWoDropList] = useState([]);
  const [taskCardDropList, setTaskCardDropList] = useState([]);
  const [defectDropList, setDefectDropList] = useState([]);

  const getDate = (date) =>
    moment()
      .set("date", moment().date() + date)
      .toDate();
  const [payload, setPayload] = useState({
    location: "",
    site: "",
    priority: 0,
    description: "",
    ac: "",
    wo: "",
    taskCard: "",
    defect: "",
    pn: "",
    qty: "",
    uom: "",
    requiredDate: getDate(7),
  });
  const [error, setError] = useState(false);

  useEffect(() => {
    setModalTitle(title);
    getLocationSiteAcApi(
      userId,
      setLocationDropList,
      setSiteDropList,
      setAcDropList,
      handleExpiredToken
    );
  }, []);

  const updatePayload = (key, value) => {
    if (key === "multi") {
      setPayload({ ...payload, ...value });
    } else {
      setPayload({ ...payload, [key]: value });
    }
  };
  const priority = [
    { label: "AOG", value: 0 },
    { label: "CRIT", value: 1 },
    { label: "EXPEDITE", value: 2 },
    { label: "NORMAL", value: 3 },
    { label: "URGENT", value: 4 },
  ];

  const submitForm = (url, updatedPayload, type) =>
    submitFormApi(
      url,
      updatedPayload,
      type,
      setIsLoading,
      setRequsitionRequestMessage,
      setPartNumberMessage,
      setShowModal,
      userId
    );

  const handleModalSubmit = () =>
    handleModalSubmitApi(payload, setError, validationMessageRef, submitForm);

  const getPartsList = (e) =>
    getPartsListApi(e, setPartsNameListDrop, setIsLoading);

  const controlButtons = (
    <div className="d-flex justify-content-end">
      <Button
        className="rounded h6-web w-80"
        size="md"
        title={"Cancel"}
        variant="grey2"
        onClick={() => setShowModal(false)}
      />
      <Button
        className="ml-2 rounded h6-web w-80"
        size="md"
        title={"Add"}
        variant="primary"
        onClick={handleModalSubmit}
      />
    </div>
  );

  const renderEditRequestModal = (
    <>
      <h4 ref={validationMessageRef}>{modalTitle}</h4>
      {error && (
        <span className="error">Please complete all required fields</span>
      )}

      <div className="row align-items-center mx-2 mt-4">
        <span className="col-sm-3 h6 p-0">
          Location<sup className="text-danger ">*</sup>
        </span>
        <Dropdown
          containerClassName={" p-0 col-12 col-sm-9"}
          selectOptions={locationDropList}
          onChange={(value) => {
            updatePayload("location", value);
          }}
          placeholder={"Select Location"}
          value={ConstructObject(locationDropList, payload?.location)}
        />
      </div>

      <div className="row align-items-center mx-2 my-3">
        <span className="col-sm-3 h6 p-0">Site</span>
        <Dropdown
          containerClassName={" p-0 col-12 col-sm-9"}
          selectOptions={siteDropList}
          onChange={(value) => {
            updatePayload("site", value);
          }}
          placeholder={"Select Site"}
          value={ConstructObject(siteDropList, payload?.site)}
        />
      </div>

      <div className="row align-items-center mx-2 my-3">
        <span className="col-sm-3 h6 p-0">
          Priority<sup className="text-danger ">*</sup>
        </span>
        <Dropdown
          Shadow
          selectOptions={priority}
          placeholder=""
          value={ConstructObject(priority, payload.priority)}
          onChange={(value) => {
            const date = getDate([7, 14, 90, 180, 30][value]);
            updatePayload("multi", { priority: value, requiredDate: date });
          }}
          containerClassName={" p-0 col-12 col-sm-9"}
        />
      </div>
      <div className="row align-items-center mx-2 my-3">
        <span className="col-sm-3 h6 p-0">
          Description<sup className="text-danger ">*</sup>
        </span>
        <Input
          Shadow
          className="h6 rounded p-0 col-sm-9"
          value={payload.description}
          onChange={(value) => updatePayload("description", value)}
          type="text"
          placeholder=""
        />
      </div>
      <div className="row align-items-center mx-2 my-3">
        <span className="col-sm-3 h6 p-0">A/C</span>
        <Dropdown
          containerClassName={" p-0 col-12 col-sm-9"}
          selectOptions={acDropList}
          onChange={(value) => {
            updatePayload("ac", value);
            getWOAPI(userId, value, setWoDropList);
          }}
          placeholder={"Select A/C"}
          value={ConstructObject(acDropList, payload?.ac)}
        />
      </div>
      <div className="row align-items-center mx-2 my-3">
        <span className="col-sm-3 h6 p-0">W/O</span>
        <Dropdown
          containerClassName={" p-0 col-12 col-sm-9"}
          selectOptions={woDropList}
          onChange={(value) => {
            updatePayload("wo", value);
            getTaskCardAPI(userId, payload.ac, value, setTaskCardDropList);
          }}
          placeholder={"Select W/O"}
          value={ConstructObject(woDropList, payload?.wo)}
        />
      </div>
      <div className="row align-items-center mx-2 my-3">
        <span className="col-sm-3 h6 p-0">Taskcard</span>
        <Dropdown
          containerClassName={" p-0 col-12 col-sm-9"}
          selectOptions={taskCardDropList}
          onChange={(value) => {
            updatePayload("taskCard", value);
            getDefectAPI(
              userId,
              payload.ac,
              payload.wo,
              value,
              setDefectDropList
            );
          }}
          placeholder={"Select Taskcard"}
          value={ConstructObject(woDropList, payload?.taskCard)}
        />
      </div>
      <div className="row align-items-center mx-2 my-3">
        <span className="col-sm-3 h6 p-0">Defect</span>
        <Dropdown
          containerClassName={" p-0 col-12 col-sm-9"}
          selectOptions={defectDropList}
          onChange={(value) => {
            updatePayload("defect", value);
          }}
          placeholder={"Select Defect"}
          value={ConstructObject(woDropList, payload?.defect)}
        />
      </div>

      <div className="row align-items-center mx-2 my-3">
        <span className="col-sm-3 h6 p-0">
          PN<sup className="text-danger ">*</sup>
        </span>
        <Dropdown
          containerClassName={" p-0 col-12 col-sm-9"}
          selectOptions={partsNameListDrop}
          onInputChange={(e) => {
            getPartsList(e.toUpperCase());
          }}
          onChange={(e) => {
            updatePayload("pn", e);
          }}
          placeholder={"Type min 3 charcters..."}
          value={ConstructObject(partsNameListDrop, payload?.pn)}
        />
      </div>
      <div className="row align-items-center mx-2 my-3">
        <span className="col-sm-3 h6 p-0">
          Qty<sup className="text-danger ">*</sup>
        </span>
        <Input
          Shadow
          className="h6 rounded p-0 col-sm-9"
          value={payload.qty}
          onChange={(value) => updatePayload("qty", value)}
          type="text"
          placeholder=""
        />
      </div>
      <div className="row align-items-center mx-2 my-3">
        <span className="col-sm-3 h6 p-0">
          UOM<sup className="text-danger ">*</sup>
        </span>
        <Input
          Shadow
          className="h6 rounded p-0 col-sm-9"
          value={payload.uom}
          onChange={(value) => updatePayload("uom", value)}
          type="text"
          placeholder=""
        />
      </div>
      <div className="row align-items-center mx-2 my-3">
        <span className="col-sm-3 h6 p-0">
          Required Date<sup className="text-danger ">*</sup>
        </span>
        <div className="col-sm-9 p-0 mb-2">
          <DatePickers
            minDate={getDate([7, 14, 90, 180, 30][payload.priority])}
            selected={payload.requiredDate}
            onChange={(value) => updatePayload("requiredDate", value)}
            dateFormat="MMMM d, yyyy"
            className="shadow-text py-1 col-12 h7-web"
            placeholderText=""
          />
        </div>
      </div>

      {controlButtons}
    </>
  );

  return <div>{renderEditRequestModal}</div>;
}
