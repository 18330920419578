import React, { useState, useEffect, useContext } from "react";

import { errorToast } from "../../componentsNew/Toast/Toast";
import { ENDPOINTS, getApi, postApi } from "../../services/http";
import { getFromLocal } from "../../services/storage/LocalStorage";
import {
  Dropdown,
  Input,
  Button,
  Checkbox,
  Badge,
  TextArea,
  InputSearch,
  DatePickers,
} from "../../componentsNew/core";
import moment from "moment";
import "./TrainingRequests.scss";

import ConstructObject from "../../hooks/helper";
// import CalendarIcon from "../../../assets/svg/NewIcons/CalendarIcon.svg";
import { useModal } from "../../hooks/useModal";
import { ModalContext } from "../../services/context/modal-context/State";
import { PageDetails } from "../../componentsNew/page-details/PageDetails";
import { usePagination } from "../../hooks/usePagination";
import ArrowLeft from "../../../assets/svg/NewIcons/arrow_left_white.svg";
import ArrowRight from "../../../assets/svg/NewIcons/arrow_right_white.svg";

function TrainingEditModal(props) {
  const [usePayLoad, setUsePayLoad] = useState(props.usePayLoadData);
  const [, setQualifications] = useState([]);
  const [qualificationsDrop, setQualificationsDrop] = useState([]);
  const [pilotSelection, setPilotSelection] = useState([]);
  let dat = usePayLoad.endDate != undefined ? usePayLoad.endDate : new Date();
  const [startDate, setStartDate] = useState(
    usePayLoad.endDate != undefined
      ? new Date(dat)
      : new Date(dat.getTime() + 1 * 60000)
  );
  const { handleExpiredToken } = useModal();
  const { setIsLoading } = useContext(ModalContext);
  const [finalRow, setFinalRow] = useState([]);
  const [searchText, setSearchText] = useState("");

  const { pagination, rowItems, handlePagination } = usePagination(finalRow);

  const filterLocationOptions = [
    {
      label: "Class A",
      value: "Class A",
    },
    {
      label: "Class B",
      value: "Class B",
    },
    {
      label: "Class C",
      value: "Class C",
    },
    {
      label: "Class D",
      value: "Class D",
    },
  ];

  useEffect(() => {
    handlePagination();
  }, [finalRow]);

  useEffect(() => {
    if (pilotSelection) {
      setFinalRow(pilotSelection);
    }
  }, [pilotSelection]);

  const handleChangeWithLib = (value) => {
    setSearchText(value);
    let newRows = [];
    if (value) {
      let searchTxt = value.toString().trim().toLowerCase();
      newRows = pilotSelection?.filter((value) => {
        return (
          value.pilotName && value.pilotName.toLowerCase().includes(searchTxt)
        );
      });
    } else {
      newRows = [...pilotSelection];
    }
    setFinalRow(newRows);
  };

  const getQualifications = async () => {
    setIsLoading(true);
    var user = getFromLocal("user");
    // var userId = parseInt(user?.userId);
    const response = await getApi(
      `${ENDPOINTS.QUALIFICATION_DATA}/${user?.companyCode}`
    );
    if (response) {
      if (response.status == 200) {
        setQualifications(response?.data);
        let qualifications = response?.data.map((el) => ({
          value: el.id,
          label: `${el.code}-${el.description}`,
        }));

        setQualificationsDrop(qualifications);

        setIsLoading(false);
      } else if (response.status == 401) {
        setIsLoading(false);
        handleExpiredToken();
      } else {
        setIsLoading(false);
      }
    }
  };

  const getPilots = async (qualificationId, cutOffDate, assignedPilots) => {
    var user = getFromLocal("user");
    var userId = parseInt(user?.userId);

    var request = {
      companyCode: user?.companyCode,
      userId: userId,
      noofDaysInExpiry: 90,
      qualificationId: qualificationId,
      cutOffDate: cutOffDate,
    };
    if (qualificationId === 0 || cutOffDate === "") {
      setPilotSelection([]);
    } else {
      setIsLoading(true);
      const response = await postApi(
        `${ENDPOINTS.PILOT_QUALIFICATION}`,
        request
      );
      if (response) {
        if (response.status == 200) {
          setIsLoading(false);
          if (assignedPilots) {
            var assignedPilotIds = assignedPilots?.map((a) => a.pilotId);
            var temp = response?.data;
            temp
              .filter((x) => assignedPilotIds.includes(x.pilotId))
              .map(function (x) {
                x.isAssigned = true;
                return x;
              });
            setPilotSelection(temp);
          } else {
            setPilotSelection(response?.data);
          }
        } else if (response.status == 401) {
          setIsLoading(false);
          handleExpiredToken();
        } else {
          setIsLoading(false);
          errorToast("Error loading pilot list!");
        }
      }
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    let user = getFromLocal("user");
    let userId = parseInt(user?.userId);
    let idsToUpdate =
      pilotSelection.length > 0
        ? pilotSelection
            ?.filter((x) => x.isAssigned === true)
            .map((a) => a.pilotId)
        : [];

    let request = {
      ...usePayLoad,
      userId: userId,
      cutOffDate:
        usePayLoad?.endDate != undefined
          ? new Date(usePayLoad?.endDate)
          : new Date(startDate.getTime() + 1 * 60000),
      pilotIds: idsToUpdate,
    };

    const response = await postApi(`${ENDPOINTS.REQUEST_SCHEDULE}`, request);
    if (response) {
      if (response.status == 200) {
        setIsLoading(false);
        props.getTrainingRequests();
        props.setShowModal(false);
      } else if (response.status == 401) {
        setIsLoading(false);
        handleExpiredToken();
      } else {
        setIsLoading(false);
        errorToast("Request Update failed");
      }
    }
  };

  // const pickerEditorProps = (field) => ({
  //   ampm: false,
  //   value: usePayLoad[field],
  //   onChange: (date) =>
  //     handleChange(field, date ? date.toDate() : new Date(usePayLoad[field])),

  //   invalidDateMessage: "",
  //   invalidLabel: "",
  //   maxDateMessage: "",
  //   openTo: "year",
  //   inputVariant: "outlined",
  //   format: "DD/MM/YYYY HH:mm",
  //   onError: () => null,
  // });

  const datePickerChange = (type, date) => {
    setStartDate(date);
    handleChange(type, date ? date : new Date(usePayLoad[type]));
  };

  const handleChange = (type, value) => {
    var tempPayload = { ...usePayLoad };

    tempPayload[type] = value;
    setUsePayLoad(tempPayload);

    if (type === "endDate" || type === "qualificationId") {
      getPilots(tempPayload["qualificationId"], tempPayload["endDate"]);
    }
  };

  function setChecked(e) {
    var temp = pilotSelection;
    temp.filter((x) => x.pilotId === parseInt(e.target.value))[0].isAssigned =
      e.target.checked;
    setPilotSelection(temp);

    var selectedPilots = pilotSelection
      ?.filter((x) => x.isAssigned === true)
      .map((a) => a.pilotId);
    handleChange("pilotIds", selectedPilots);
  }

  // const handleCancel = () => {
  //   setUsePayLoad([]);
  //   setPilotSelection([]);
  //   props.setShowModal(false);
  // };

  useEffect(() => {
    getQualifications();
  }, []);

  useEffect(() => {
    var trainingReq = usePayLoad;
    getPilots(
      trainingReq?.qualificationId,
      trainingReq?.endDate,
      trainingReq?.assignedPilots
    );
  }, []);

  // const datechange = (e) => {
  //   let dates = moment(e).format("DD-MM-YYYY hh:mm:ss");
  //   setDate(dates);
  // };

  // const renderInput = (props, openCalendar) => {
  //   function clear() {
  //     props.onChange({ target: { value: "" } });
  //   }
  //   return (
  //     <div className="d-flex">
  //       <input {...props} className="h7-web col-11 pl-0 pe-0" />
  //       <Badge
  //         className="rounded-50 m-1 cursor-pointer"
  //         variant="primary"
  //         size="sm"
  //         icon={CalendarIcon}
  //         title="Calender"
  //         onClick={openCalendar}
  //       />
  //     </div>
  //   );
  // };

  const TableControls = (
    <div className="col-12 d-flex align-items-center p-0">
      <div className="col-md-3 p-0 align-items-center">
        <PageDetails pagination={pagination} />
      </div>
      <div className="d-flex col-md-9 justify-content-end pr-0">
        <div className="flex-wrap d-flex align-items-center justify-content-center">
          <div className="">
            <div className="col-md-12 col-sm-4 col-8">
              <InputSearch
                placeholder="search here..."
                className="w-100"
                onChange={handleChangeWithLib}
                //   value={searchText}
              />
            </div>
          </div>
        </div>

        <div className="col-xs-3 d-flex align-items-center justify-content-center pe-0">
          <Badge
            className="rounded-50"
            variant="primary"
            size="sm"
            icon={ArrowLeft}
            title="Previous Page"
            disabled={!pagination?.prevPageUrl}
            onClick={() => handlePagination("prev")}
          />
          <Badge
            className="rounded-50 ms-2"
            variant="primary"
            size="sm"
            icon={ArrowRight}
            title="Next Page"
            disabled={!pagination?.nextPageUrl}
            onClick={() => handlePagination("next")}
          />
        </div>
      </div>
    </div>
  );
  const renderEditRequestModal = (
    <div className="row m-0">
      <h5>Training Request</h5>
      <div className="w-80">
        <hr></hr>
      </div>
      <div className="row p-0 m-0 mb-3 ">
        <div className="col-12 mt-2 d-flex align-items-center">
          <label className="col-3 h6 p-0">Qualification</label>
          <Dropdown
            Shadow
            selectOptions={qualificationsDrop}
            value={ConstructObject(
              qualificationsDrop,
              usePayLoad?.qualificationId
            )}
            onChange={(e) => handleChange("qualificationId", parseInt(e))}
            containerClassName={"w-100 p-0"}
          />
        </div>
        {usePayLoad?.qualificationId === 0 && (
          <span className="error h6-web">Please select Qualification</span>
        )}
      </div>

      <div className="col-12 mb-3 d-flex align-items-center">
        <label className="col-3 h6 p-0">Title</label>
        <Input
          Shadow
          className="col-9 h6 rounded p-0"
          value={usePayLoad?.title}
          onChange={(e) => handleChange("title", e)}
          type="text"
          placeholder="Enter Training Description"
        />
      </div>

      <div className="col-12 d-flex align-items-center calender-date  mb-3">
        <label className="col-3 h6 p-0">Cut Off Date</label>
        <div className="col-9 p-0">
          {/* <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDateTimePicker
                                className='w-100 h6 p-0'
                                // {...pickerEditorProps('endDate')}
                            />
                        </MuiPickersUtilsProvider> */}

          {/* <Datetime value={moment.utc(usePayLoad?.repeatTrainingExpiryDateTime).format("DD-MM-YYYY hh:mm A")} closeOnSelect closeOnClickOutside renderInput={renderInput} onChange={(e) =>datePickerChange('endDate',e)} className="shadow-text col-12 " /> */}

          <DatePickers
            selected={startDate}
            onChange={(e) => datePickerChange("endDate", e)}
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
            timeIntervals={1}
            className="shadow-text col-12 picker-height h7-web"
            minDate={moment().toDate()}
          />
        </div>
      </div>

      <div className="col-12 mb-3 d-flex align-items-center">
        {pilotSelection?.length > 0 && (
          <>
            <label className="col-3  h6 p-0">Select Pilots</label>
            <div className="col-9 p-0">
              <div className="d-flex align-items-center p-2">
                {searchText == ""
                  ? rowItems.length > 0 && TableControls
                  : TableControls}
              </div>
              <div className="mt-1">
                <table className="w-100 custom-table">
                  <tr className="h7 text-grey1">
                    <th>Select</th>
                    <th>Name</th>
                  </tr>
                  <tbody className="h7-web ">
                    {rowItems && rowItems.length > 0 ? (
                      rowItems.map((row, rowIndex) => (
                        <tr className=" m-2">
                          <td>
                            <Checkbox
                              name={`chkbox_${row.pilotId}`}
                              key={`chkbox_${row.pilotId}`}
                              id={`chkbox_${row.pilotId}`}
                              type="checkbox"
                              value={row.pilotId}
                              checked={row.isAssigned}
                              onChange={setChecked}
                            />
                          </td>
                          <td>{row.pilotName}</td>
                        </tr>
                      ))
                    ) : (
                      <tr className="text-center h6-web">No records found</tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="col-12 mb-3 d-flex">
        <label className="col-3  h6 p-0">Description</label>
        <TextArea
          Shadow
          className="col-9 p-0"
          value={usePayLoad?.description}
          onChange={(e) => handleChange("description", e)}
          type="text"
          placeholder="Enter Description"
        />
      </div>
      <div className="col-12 mb-3 d-flex align-items-center">
        <label className="col-3  h6 p-0">Location</label>

        <Dropdown
          selectOptions={filterLocationOptions}
          Shadow
          value={ConstructObject(filterLocationOptions, usePayLoad?.location)}
          onChange={(e) => handleChange("location", e)}
          containerClassName={"w-100 p-0"}
        />
      </div>
      <div className="col-12 mb-3 text-right">
        <Button
          className="rounded h6-web w-80"
          size="lg"
          title={"Save"}
          variant="primary"
          onClick={() => {
            handleSave();
          }}
        />
      </div>
    </div>
  );

  return <div>{renderEditRequestModal}</div>;
}

export default TrainingEditModal;
