import React, { useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import routes from "../../../routes";
import SideNavBar from "../../components/SideNavBar/SideNavBar";
import GlobalHeader from "../../components/Header/GlobalHeader";
import { getFromLocal } from "../../utils/generic";
import Template from "../../../Template";
import SessionIdleContainer from "../../components/SessionIdleContainer/SessionIdleContainer";
import "./DashBoardLayout.scss";

const DashBoardLayout = (props) => {
  // const location = useLocation();
  const [sideBarCollapse, setSideBarCollapse] = useState(true);
  // const pathName = location.pathname;
  let newDesign = true; //pathName.includes("/new-design");
  var user = getFromLocal("user");

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/dashboard") {
        return (
          <Route
            exact
            path={prop.layout + prop.path}
            key={key}
            render={(props) => container(prop)}
          />
        );
      } else if (prop?.subMenu) {
        return prop.menuItems?.map((item, index) => {
          return (
            <Route
              exact
              path={item.layout + item.path}
              key={item.path}
              render={(props) => container(item)}
            />
          );
        });
      } else {
        return null;
      }
    });
  };

  const container = (prop) => {
    let Component = prop.component;
    return (
      <>
        {prop?.isNewDesign ? (
          <Template isActive={prop} {...prop}>
            {" "}
            <Component {...prop} {...props} />
          </Template>
        ) : (
          <Component {...prop} {...props} />
        )}
      </>
    );
  };

  if (!getFromLocal("user")) {
    return <Redirect from="*" to="/auth/login" />;
  }

  return (
    <div>
      {newDesign ? (
        <div className="">
          <div className="container-xxxl">
            <Switch>
              {getRoutes(routes)}
              <Redirect
                from="*"
                to={
                  user && user.roleName === "Pilot"
                    ? "/dashboard/new-design/pilot/dashboard"
                    : user.roleName === "Director Operation"
                    ? "/dashboard/new-design/director/dashboard"
                    : "/dashboard"
                }
              />
            </Switch>
          </div>
        </div>
      ) : (
        <div className="main-div">
          <SessionIdleContainer {...props} />
          <SideNavBar
            sideBarCollapse={sideBarCollapse}
            setSideBarCollapse={(e) => setSideBarCollapse(e)}
            routes={routes}
            {...props}
          />
          <GlobalHeader
            sideBarCollapse={sideBarCollapse}
            setSideBarCollapse={(e) => setSideBarCollapse(e)}
            {...props}
          >
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/dashboard" />
            </Switch>
          </GlobalHeader>
        </div>
      )}
    </div>
  );
};

export default DashBoardLayout;
