import React, { useContext, useState, useEffect } from "react";
import { Input, Button, Modal, Badge } from "../../componentsNew/core";
import CustomHome from "./customHome";
import { errorToast } from "../../componentsNew/Toast/Toast";
import { ENDPOINTS, postApi } from "../../services/http";
import {
  getFromLocal,
  clearLocal,
  setToLocal,
} from "../../services/storage/LocalStorage";
import ForgotPassword from "./ForgotPassword";
import EyeClose from "../../../assets/svg/NewIcons/eyeclose.svg";
import Eye from "../../../assets/svg/NewIcons/eye.svg";
import { UserContext } from "../../services/context/userContext/State";
import { ModalContext } from "../../services/context/modal-context/State";

function LoginForm(props) {
  const [payload, setPayload] = useState({
    username: null,
    password: null,
    showPassword: false,
  });
  const [, setIsError] = useState({ username: "", password: "" });
  const [loginErr, setLoginErr] = useState(null);
  const [showForgotPass, setShowForgotPass] = useState(false);
  const [PasswordVisible, setPasswordVisible] = useState(false);
  const [validationError] = useState(false);
  const { setUser } = useContext(UserContext);
  const { setIsLoading } = useContext(ModalContext);

  useEffect(() => {
    if (getFromLocal("user")) {
      let user = JSON.parse(localStorage.getItem("user"));
      setUser(user);
      if (user["roleName"] === "Director Operation") {
        props.history.replace("/dashboard/new-design/director/dashboard");
      } else if (user["roleName"] === "Pilot") {
        props.history.replace("/dashboard/new-design");
      } else if (user["roleName"] === "Admin") {
        props.history.replace("/dashboard/new-design");
      } else {
        props.history.replace("/dashboard/new-design");
      }
    } else {
      clearLocal();
    }
  }, []);
  const loginUser = async (credentials) => {
    setIsLoading(true);
    const response = await postApi(`${ENDPOINTS.LOGIN}`, credentials);
    if (response) {
      if (response.data.statusCode === 401) {
        setLoginErr("username and password mismatched");
        return null;
      } else if (response.status == 200) {
        setToLocal("token", response.data.authToken);
        setToLocal("user", response.data);
        let user = JSON.parse(localStorage.getItem("user"));
        setUser(response.data);
        if (user["roleName"] === "Director Operation") {
          props.history.replace("/dashboard/new-design/director/dashboard");
        } else if (user["roleName"] === "Pilot") {
          props.history.replace("/dashboard/new-design");
        } else if (user["roleName"] === "Admin") {
          props.history.replace("/dashboard/new-design");
        } else {
          props.history.replace("/dashboard/new-design");
        }
      } else {
        errorToast("login failed");
      }
    }
    setIsLoading(false);
  };

  const validate = () => {
    var errorData = {};
    if (payload.username === null || payload.username.length === 0) {
      errorData = { ...errorData, username: "field must be required" };
    }
    if (payload.password === null || payload.password.length === 0) {
      errorData = { ...errorData, password: "field must be required" };
    }
    setIsError(errorData);
    if (Object.entries(errorData).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async (e) => {
    if (validate()) {
      var userpayload = {
        userName: payload.username,
        userPassword: payload.password,
      };
      await loginUser(userpayload);
    } else {
    }
  };

  const onHandleChange = (key, value) => {
    payload[key] = value;
    setPayload({ ...payload, payload });
    setLoginErr(false);
  };

  // const handleClickShowPassword = () => {
  //   setPayload({ ...payload, showPassword: !payload.showPassword });
  // };

  const ChildrenModal = (
    <>
      <ForgotPassword handleClose={() => setShowForgotPass(false)} />
    </>
  );

  const ViewField = () => {
    return (
      <Badge
        className="rounded-3 bg-transparent password-eye"
        size="xs"
        icon={PasswordVisible ? Eye : EyeClose}
        title="View"
        onClick={() => setPasswordVisible(!PasswordVisible)}
      />
    );
  };

  return (
    <CustomHome>
      <div className="col-12 box-shadow">
        <div className="w-100 bg-dark h5 mb-0 text-white p-2 rounded-top">
          Access Portal
        </div>
        <div className="w-100 login-container-new p-3 pt-4 rounded-bottom">
          <Input
            label={"Username*"}
            labelColor={"white"}
            borderBottom={true}
            className={"text-white"}
            value={payload?.username}
            type={"text"}
            isError={validationError && payload?.username == ""}
            errorMessage={"Please Enter the Staff ID"}
            onChange={(value) => onHandleChange("username", value)}
          />
          <div className="d-flex">
            <Input
              label={"Password*"}
              labelColor={"white"}
              borderBottom={true}
              className={"mt-3 p-0 col-12 text-white"}
              onChange={(value) => onHandleChange("password", value)}
              isError={validationError && payload?.password == ""}
              errorMessage={"Please Enter the Password"}
              type={PasswordVisible ? "text" : "password"}
              value={payload?.password}
            />
            <div
              style={{ marginTop: "50px", marginLeft: "-28px", zIndex: "9" }}
            >
              {ViewField()}
            </div>
          </div>
          {loginErr && (
            <span className="label-input mt-2 text-danger">{loginErr}</span>
          )}
          <div className="mt-4 d-flex justify-content-between align-items-center">
            <Button
              className="rounded"
              size="lg"
              title="Continue"
              variant="primary"
              onClick={handleSubmit}
            />

            {/* <div className="text-white label-input">
              New user?{" "}
              <span
                className="cursor-pointer underline-hover"
               // onClick={() => props.history.push("/new-registration")}
              >
               Contact us
              </span>
            </div> */}

            <div className="text-right text-white">
              <span
                onClick={() => setShowForgotPass(true)}
                className="cursor-pointer h6-web text-primary"
              >
                Forgot password?
              </span>
            </div>
          </div>
        </div>
      </div>
      {showForgotPass && (
        <Modal
          open={showForgotPass}
          onClose={() => setShowForgotPass(false)}
          showCloseIcon={true}
          children={ChildrenModal}
        ></Modal>
      )}
    </CustomHome>
  );
}

export default LoginForm;
