import React from "react";
import { BreadCrumb, Button } from "../../componentsNew/core";
import "./DirectorAccount.scss";
import PropTypes from "prop-types";
import DashBoardDirectorate from "../../../assets/images/NewImg/DOdashboard.png";
import LicenseDirectorate from "../../../assets/images/NewImg/DOlicense.png";
import TrainingDirectorate from "../../../assets/images/NewImg/DOtraining.png";
// import Right from "../../../assets/svg/NewIcons/tick_green.svg";
import { useHistory } from "react-router-dom";

function DirectorAccount(props) {
  const { pageValue, children, breadCrumbTitle, setPageValue } = props;
  const history = useHistory();
  const breadCrumbItems = [
    {
      title: "Lion Group",
      link: "/dashboard/new-design/director/dashboard",
      page: setPageValue,
    },
    {
      title:
        pageValue == 0
          ? "Dashboard"
          : pageValue == 1
          ? `${breadCrumbTitle}`
          : pageValue == 2
          ? "Schedule"
          : pageValue == 3
          ? "Trainings"
          : "",
      link: 1,
    },
  ];
  return (
    <div className="px-5 py-2 bg-white fixed-container">
      <BreadCrumb breadCrumbItems={breadCrumbItems} />
      <div className="row">
        <div className="col-md-3 col-12 p-0">
          <div className="box-shadow mb-2">
            <img
              className="w-100 object-contain"
              src={
                pageValue == 0
                  ? DashBoardDirectorate
                  : pageValue == 1
                  ? LicenseDirectorate
                  : pageValue == 2
                  ? TrainingDirectorate
                  : TrainingDirectorate
              }
              alt=""
            />
            <div className="text-grey2 p-4">
              <div className="h4">
                {pageValue == 0
                  ? "Training Schedule"
                  : pageValue == 1
                  ? "License Expiring"
                  : pageValue == 2
                  ? "Training"
                  : pageValue == 3
                  ? "Trainings Requested"
                  : "Training Schedule"}
              </div>

              <div className="h7 my-2">
                {
                  {
                    0: " Let’s make managing your team’s training schedule a little more easier.",
                    1: "Manage, Review and stay updated with the current qualifications of your workforce and assign them to the trainings.",
                    2: "Manage, Review and stay updated with the current qualifications of your workforce and assign them to trainings before their qualifications expire.",
                    3: "Manage, Review and stay updated with the current qualifications of your workforce and assign them to trainings before their qualifications expire.",
                  }[pageValue]
                }
              </div>
              {pageValue == 0 ? (
                <Button
                  className="rounded h6-web w-100"
                  size="lg"
                  title={"Continue"}
                  variant="primary"
                  onClick={() => {
                    history.push(
                      "/dashboard/new-design/director/training-schedule"
                    );
                  }}
                />
              ) : null}
            </div>
          </div>
          {/* {pageValue == 3 || pageValue == 2 ? (
            <div className="text-start w-100">
              <Button
                className="rounded h6-web w-80"
                size="lg"
                title={pageValue == 2 ? "View Schedule" : "New Training"}
                variant="primary"
              />
            </div>
          ) : null} */}

          {/* {pageValue == 0 ? (
            <>
              <div className="h4 text-grey2 my-3 ">Notifications</div>
              <div className="box-shadow mt-3 p-3 text-grey2 overflow-scroll">
                {[1, 1, 1, 1, 1, 1, 1].map(() => (
                  <div className="d-flex mb-3 ">
                    <img src={Right} />
                    <div className="px-3">
                      <div className="h7">
                        Pilots have been assigned to Training 121
                      </div>
                      <div className="h7-web">
                        Lorem ipsum dolor sit amet, consectetur adip
                      </div>
                    </div>
                  </div>
                ))}
              </div>{" "}
            </>
          ) : null} */}
        </div>
        <div className="col-md-9 col-12 pt-md-0 pt-3 pb-5">{children}</div>
      </div>
    </div>
  );
}

DirectorAccount.propTypes = {
  children: PropTypes.element,
  navNo: PropTypes.number,
};

export default DirectorAccount;
