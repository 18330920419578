import React from "react";
import { useHistory } from "react-router-dom";
import "./TextArea.scss";

export const TextArea = React.forwardRef((props, ref) => {
  let history = useHistory();
  let {
    label,
    labelColor,
    borderBottom,
    textColour,
    forgotPassword,
    placeholder,
    className,
    onChange,
    value = "",
    isError = false,
    errorMessage,
    maxLength = "",
    readOnly = false,
    Shadow = false,
    ...rest
  } = props;
  
  return (
    <div className={`remove-textarea-focus d-flex flex-column  ${className} `}>
      <div className="d-flex justify-content-between">
        {label && <label className={`text-${labelColor} h7-web `}>{label}</label>}
        {forgotPassword && (
          <span
            onClick={() => history.push("/forgot-password")}
            className="cursor-pointer text-danger label-textarea text-decoration-underline"
          >
            Forgot Password?
          </span>
        )}
      </div>
      <textarea
        maxLength={maxLength}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        readOnly={readOnly}
        {...rest}
        ref={ref}
        placeholder={placeholder}
        className={`text-${textColour} p-1 h7-web  textarea-height ${
          borderBottom
            ? "bg-transparent border-0 border-white border-bottom"
             :  Shadow ? "textarea-Shadowbox rounded p-0 h7-web" :"rounded border border-1 default-textarea"
        }`
      }
      />
      {isError && (
        <span className="text-danger label-textarea mt-1">{errorMessage}</span>
      )}
    </div>
  );
});
