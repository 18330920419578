import React, { useState, useEffect } from "react";
import HumberMenu from "../../../assets/images//Humber.png";
import Signup from "../../../assets/svg/signup-icn.svg";
import Login from "../../../assets/svg/Login-icn.svg";
import FloatingHeader from "./FloatingHeader/FloatingHeader";
import routes from "../../../routes";

import "./FloatingHeader/FloatingHeader.scss";

function BottomHeader(props) {
  let initial = [
    { name: "Sign in", icon: Login, path: "/" },
    { name: "Register", icon: Signup, path: "/new-registration" },
  ];
  const [banner, setBanner] = useState(false);
  let user = JSON.parse(localStorage.getItem("user"));
  const [menuList, setMenuList] = useState([]);

  let { noHeaderColour, handleLoginValue } = props;

  const renderLinks = () => {
    var cPath = window.location.pathname;
    let arr = routes?.map((item, key) => {
      var path = item.layout + item.path;
      if (item.layout === "/dashboard") {
        if (item?.visibleInMenu) {
          //! showing dashboard menu only for director
          if (
            user["roleName"] !== "Director Operation" &&
            path === "/dashboard/new-design/home"
          ) {
            return null;
          }
          return renderMenuItem(item, key, cPath, path);
        }
        return null;
      } else if (item?.subMenu) {
        return renderSubMenu(item, key, cPath);
      } else {
        if (item?.visibleInMenu) {
        }
        return null;
      }
    });
    return arr;
  };
  useEffect(() => {
    if (user) {
      let arr = renderLinks();
      setMenuList(arr);
    } else {
      setMenuList(initial);
    }
  }, []);

  const renderMenuItem = (item, key, cPath, path) => {
    let showMenu = true;
    let arr = [];
    const user = JSON.parse(localStorage.getItem("user")) ?? {};
    const skipPage = {
      MP: [
        "Users",
        "Parts",
        "Track Requisition",
        "Dashboard Shipping",
        "Dashboard Receiving",
      ],
      Prod: [
        "Users",
        "Track Requisition",
        "Dashboard Shipping",
        "Dashboard Receiving",
      ],
      Gen: [
        "Users",
        "Parts",
        "Track Requisition",
        "Dashboard Shipping",
        "Dashboard Receiving",
        "Requisition Request",
      ],
      Admin: [],
    };

    let roleCode;
    if (user) {
      roleCode = user.roles[0].roleCode;
    }
    if (
      item?.name !== "Dashboard" &&
      item?.name !== "Parts" &&
      item?.name !== "Requisition Request" &&
      item?.name !== "Dashboard Shipping" &&
      item?.name !== "Dashboard Receiving" &&
      item?.name !== "Users" &&
      item?.name !== "Track Requisition"
    ) {
      showMenu = false;
      arr.push(item);
    }

    if (showMenu) {
      if (skipPage[roleCode].includes(item?.name ?? "")) {
        return null;
      } else {
        return item;
      }
    } else return null;
  };

  const renderSubMenu = (item, key, cPath) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let showMenu = true;

    if (user["roleName"] === "Instructor" || user["roleName"] === "Pilot") {
      if (item.name === "Admin") {
        showMenu = false;
      }
    }

    if (user["roleName"] === "Director Operation") {
      if (item.name === "Admin" || item.name === "PERSONAL") {
        showMenu = false;
      }
    }
    if (showMenu) {
      return item.menuItems;
    }
  };

  return (
    <>
      <div
        className={`${
          noHeaderColour ? "" : "bg-primary"
        } py-4 px-sm-5 px-2 d-flex justify-content-between align-items-center`}
      >
        {/* <img className="logo lites-logo" src={Logo} /> */}
        <span className="logo lites-logo">Procurement Dashboard</span>
        {user && (
          <img
            className="cursor-pointer"
            onClick={() => setBanner(!banner)}
            src={HumberMenu}
            alt=""
          />
        )}
      </div>

      <FloatingHeader
        setBanner={setBanner}
        banner={banner}
        menuList={menuList}
        handleLoginValue={handleLoginValue}
      />
    </>
  );
}
export default BottomHeader;
