import dotenv from 'dotenv'
dotenv.config();

var endpoints = process.env.REACT_APP_SERVER_URL;
if (process.env.REACT_APP_ENV === 'uat')
{
    endpoints = process.env.REACT_APP_UAT_SERVER_URL;
}
if (process.env.REACT_APP_ENV === 'dev')
{
    endpoints = process.env.REACT_APP_DEV_SERVER_URL;
}

if (process.env.REACT_APP_ENV === 'prod') {
    endpoints = process.env.REACT_APP_PROD_SERVER_URL;
}

export const baseurl = endpoints;