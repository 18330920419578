import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { errorToast, successToast } from "../../componentsNew/Toast/Toast";
import { ENDPOINTS, getApi, postApi } from "../../services/http";
import { Button, Input } from "../../componentsNew/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(10),
  },
  textField: {
    [theme.breakpoints.up("sm")]: {
      width: "35ch",
    },
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
}));

function ForgotPassword(props) {
  const classes = useStyles();
  const [payload, setPayload] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    otp: "",
  });
  const [error, setError] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    otp: "",
  });
  const [identifier, setIdentifier] = useState("email");

  const onHandleChange = (key, value) => {
    payload[key] = value;
    setPayload({ ...payload, payload });
  };

  const handleClick = async () => {
    switch (identifier) {
      case "email":
        let email = "";
        if (payload.email?.trim().length === 0) {
          email = "field must be required";
        } else if (!/\S+@\S+\.\S+/.test(payload.email)) {
          email = "invalid email";
        } else {
          const response = await getApi(
            `${ENDPOINTS.SEND_RESET_OTP}?email=${payload.email}`
          );
          if (response) {
            if (response.status == 200) {
              if (response.data.Success) {
                successToast("OTP sent");
                setIdentifier("otp");
              } else {
                errorToast(response.data.Message);
              }
            } else {
              //errorToast("login failed");
            }
          }
        }
        setError({ ...error, email: email });
        break;
      case "otp":
        let otp = "";
        if (payload.otp?.trim().length === 0) {
          otp = "field must be required";
        } else {
          const response = await getApi(
            `${ENDPOINTS.VERIFY_RESET_OTP}?otp=${payload.otp}`
          );
          if (response) {
            if (response.status == 200) {
              if (response.data.Success) {
                successToast("OTP verified");
                setIdentifier("update");
              } else {
                errorToast(response.data.Message);
              }
            } else {
              otp = response.data.Message;
            }
          }
        }
        setError({ ...error, otp: otp });
        break;
      case "update":
        let passwordErr = "";
        let confirmPasswordErr = "";
        let password = payload.password?.trim();
        let confirmPassword = payload.confirmPassword?.trim();

        if (password.length === 0 || confirmPassword.length === 0) {
          passwordErr = password.length === 0 ? "field must be required" : "";
          confirmPasswordErr =
            confirmPassword.length === 0 ? "field must be required" : "";
        } else if (password !== confirmPassword) {
          confirmPasswordErr = "password does not match";
        } else {
          var ProcessFields = {
            EntityKey: password,
            ChildEntityKey: payload.otp,
            UserEmail: payload.email,
          };

          const response = await postApi(
            `${ENDPOINTS.RESET_PASSWORD}`,
            ProcessFields
          );
          if (response) {
            if (response.status == 200) {
              if (response.data.Success) {
                successToast("Password changed successfully.");
              } else {
                errorToast(response.data.Message);
              }
            } else {
              errorToast(response.data.Message);
            }
          }

          props.handleClose(false);
        }
        setError({
          ...error,
          password: passwordErr,
          confirmPassword: confirmPasswordErr,
        });
        break;
      default:
        break;
    }
  };

  const RenderButton = (data) => {
    return (
      <div className="text-center">
        <Button
          onClick={handleClick}
          className="btn mt-3 bg-danger border-rounded text-white"
          title={data?.label}
        ></Button>
      </div>
    );
  };

  const reSendOtp = async () => {
    const response = await getApi(
      `${ENDPOINTS.SEND_RESET_OTP}?email=${payload.email}`
    );
    if (response) {
      if (response.status == 200) {
        if (response.data.Success) {
          successToast("OTP sent again");
          setIdentifier("otp");
        } else {
          errorToast(response.data.Message);
        }
      } else {
        errorToast("Unable to send OTP. Please contact administrator");
      }
    }
  };

  const renderTextField = (key, label, type) => {
    return (
      <Input
        id="outlined-uncontrolled"
        type={type}
        className={clsx(classes.margin, classes.textField)}
        onChange={(e) => onHandleChange(key, e)}
        value={payload[key]}
        error={error?.[key] ? true : false}
        helperText={error?.[key]}
        label={label}
        variant="outlined"
      />
    );
  };

  return (
    <div className="d-flex flex-column">
      <label className="text-center fs-18 font-weight-bold mb-3">
        RESET PASSWORD
      </label>
      <div>
        {identifier === "email" && (
          <div>
            {renderTextField("email", "E-mail", "text")}
            <RenderButton label="Next" />
          </div>
        )}
        {identifier === "otp" && (
          <div className="d-flex flex-column">
            <Input
              disabled
              value={payload?.email}
              className={clsx(classes.margin, classes.textField)}
              label="email"
              variant="outlined"
            />
            {renderTextField("otp", "OTP", "text")}
            <label
              onClick={reSendOtp}
              style={{ color: "#007bd4" }}
              className="text-right cp"
            >
              Resend OTP
            </label>
            <RenderButton label="Verify" />
          </div>
        )}
        {identifier === "update" && (
          <div>
            {renderTextField("password", "New Password", "password")}
            {renderTextField(
              "confirmPassword",
              "Confirm New Password",
              "password"
            )}
            <RenderButton label="Update" />
          </div>
        )}
      </div>
    </div>
  );
}

export default ForgotPassword;
