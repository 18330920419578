import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePickers.scss";

/**
 * @references - https://github.com/Hacker0x01/react-datepicker
 */

export const DatePickers = (props) => {
  let {
    dateFormat,
    selected,
    className,
    onChange,
    placeholderText,
    showTimeSelect = false,
    timeIntervals,
    minDate,
    disabled = false,
    maxDate,
  } = props;

  return (
    <div>
      <DatePicker
        maxDate={maxDate}
        selected={selected}
        dateFormat={dateFormat}
        className={className}
        onChange={(e) => onChange(e)}
        placeholderText={placeholderText}
        showTimeSelect={showTimeSelect}
        timeIntervals={timeIntervals}
        minDate={minDate}
        disabled={disabled}
      />
    </div>
  );
};
