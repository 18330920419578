import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { successToast, infoToast, errorToast } from '../../components/Generic/Generic';
import clsx from 'clsx';
import API from '../../utils/Api';
import endpoint from '../../utils/endpoints';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(10),
    },
    textField: {
        [theme.breakpoints.up("sm")]: {
            width: '35ch'
        },
        [theme.breakpoints.down("sm")]: {
            width: '95%'
        }
    },
}))

function ForgotPassword(props) {
    const classes = useStyles();
    const [payload, setPayload] = useState({ email: "", password: "", confirmPassword: "", otp: "" });
    const [error, setError] = useState({ email: "", password: "", confirmPassword: "", otp: "" });
    const [identifier, setIdentifier] = useState("email");

    const onHandleChange = (key, value) => {
        payload[key] = value;
        setPayload({ ...payload, payload })
    }

    const handleClick = () => {
        switch (identifier) {
            case "email":
                let email = "";
                if (payload.email?.trim().length === 0) {
                    email = "field must be required";
                } else if (!/\S+@\S+\.\S+/.test(payload.email)) {
                    email = "invalid email";
                } else {
                    API.get(endpoint.SendResetOTP + '?email=' + payload.email, (isSuccess, response) => {
                        if (isSuccess) {
                            if (response.Success) {
                                infoToast("OTP sent");
                                setIdentifier("otp");
                            } else {
                                errorToast(response.Message);
                            }
                        } else {
                            //errorToast("login failed");
                        }
                    })
                }
                setError({ ...error, email: email })
                break;
            case "otp":
                let otp = "";
                if (payload.otp?.trim().length === 0) {
                    otp = "field must be required";
                } else {
                    API.get(endpoint.VerifyResetOTP + '?otp=' + payload.otp, (isSuccess, response) => {
                        if (isSuccess) {
                            if (response.Success) {
                                infoToast("OTP verified");
                                setIdentifier("update");
                            } else {
                                errorToast(response.Message);
                            }
                        } else {
                            otp = response.Message;
                        }
                    })

                }
                setError({ ...error, otp: otp })
                break;
            case "update":
                let passwordErr = "";
                let confirmPasswordErr = "";
                let password = payload.password?.trim();
                let confirmPassword = payload.confirmPassword?.trim();

                if (password.length === 0 || confirmPassword.length === 0) {
                    passwordErr = password.length === 0 ? "field must be required" : "";
                    confirmPasswordErr = confirmPassword.length === 0 ? "field must be required" : "";
                } else if (password !== confirmPassword) {
                    confirmPasswordErr = "password does not match"
                } else {
                    var ProcessFields = {
                        EntityKey: password,
                        ChildEntityKey: payload.otp,
                        UserEmail: payload.email
                    }
                    API.post(endpoint.ResetPassword, ProcessFields, (isSuccess, response) => {
                        if (isSuccess) {
                            if (response.Success) {
                                successToast("Password changed successfully.");
                            } else {
                                errorToast(response.Message);
                            }
                        } else {
                            errorToast(response.Message);
                        }
                    })

                    props.handleClose(false);
                }
                setError({ ...error, password: passwordErr, confirmPassword: confirmPasswordErr })
                break;
            default:
                break;
        }
    }

    const RenderButton = (data) => {
        return (
            <div className="text-center">
                <button onClick={handleClick} className="btn mt-3 bg-danger border-rounded text-white">
                    {data?.label}
                </button>
            </div>
        )
    }

    const reSendOtp = () => {
        API.get(endpoint.SendResetOTP + '?email=' + payload.email, (isSuccess, response) => {
            if (isSuccess) {
                if (response.Success) {
                    infoToast("OTP sent again");
                    setIdentifier("otp");
                } else {
                    errorToast(response.Message);
                }
            } else {
                errorToast("Unable to send OTP. Please contact administrator");
            }
        });
    }

    const renderTextField = (key, label, type) => {
        return (
            <TextField id="outlined-uncontrolled" type={type} className={clsx(classes.margin, classes.textField)} onChange={e => onHandleChange(key, e.target.value)}
                error={error?.[key] ? true : false} helperText={error?.[key]}
                label={label} variant="outlined" />
        )
    }

    return (
        <div className="d-flex flex-column">
            <label className="text-center fs-18 font-weight-bold mb-3">RESET PASSWORD</label>
            <div>
                {identifier === "email" && <div>
                    {renderTextField("email", "E-mail", "text")}
                    <RenderButton label="Next" />
                </div>}
                {identifier === "otp" && <div className="d-flex flex-column">
                    <TextField disabled defaultValue={payload?.email} className={clsx(classes.margin, classes.textField)} label="email" variant="outlined" />
                    {renderTextField("otp", "OTP", "text")}
                    <label onClick={reSendOtp} style={{ color: "#007bd4" }} className="text-right cp">Resend OTP</label>
                    <RenderButton label="Verify" />
                </div>}
                {identifier === "update" && <div>
                    {renderTextField("password", "New Password", "password")}
                    {renderTextField("confirmPassword", "Confirm New Password", "password")}
                    <RenderButton label="Update" />
                </div>}
            </div>
        </div>
    )
}

export default ForgotPassword
