import { errorToast, successToast } from "../componentsNew/Toast/Toast";
import * as XLSX from "xlsx";

export const json2xlsDownload = (json, header, fileName) => {
  if (json.length) {
    let data = json;
    if (header) {
      const filterData = json.map((e) => {
        const keys = Object.keys(header);
        const result = {};
        for (let i = 0; i < keys.length; i++) {
          result[keys[i]] = e[keys[i]];
        }
        return result;
      });
      data = [header, ...filterData];
    }
    const ws = XLSX.utils.json_to_sheet(data, { skipHeader: !!header });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, fileName || "sheet");
    XLSX.writeFile(wb, `${fileName || "download"}.xlsx`);
    successToast("Downloaded Successfully.");
  } else {
    errorToast("No data found to download!");
  }
};
