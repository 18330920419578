import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableCell, TableContainer, TableHead, TableRow, Grid, useMediaQuery, TableBody } from "@material-ui/core";
import { Pagination } from '@material-ui/lab';
import usePagination from "./Pagination"
import Paper from '@material-ui/core/Paper';
import DownArrow from '../../../assets/svg/DownArrow.svg';
import UpArrow from '../../../assets/svg/UpArrow.svg';
import UpDownArrow from "../../../assets/images/updownArrow.png";
import { useTheme } from '@material-ui/core/styles';
import "./DataTableV2.scss";
const { Option } = Select;

const DataTable = (props) => {
    const { columns, rows, renderTableBody, setSearchText, order, orderBy, setOrder, setOrderBy } = props;

    const [finalRows, setFinalRows] = useState(rows)
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [page, setPage] = useState(1);
    const count = Math.ceil(finalRows?.length / rowsPerPage);
    const _rowsData = usePagination(finalRows, rowsPerPage);

    useEffect(() => {
        if (order && orderBy) {
            function getComparator(order, orderBy) {
                return order === "desc"
                    ? (a, b) => descendingComparator(a, b, orderBy)
                    : (a, b) => -descendingComparator(a, b, orderBy);
            }

            setFinalRows(stableSort(rows, getComparator(order, orderBy)))
        }
    }, [order, orderBy, rows])

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const stableSort = (array, comparator) => {
        const stabilizedThis = array?.map((el, index) => [el, index]);
        stabilizedThis?.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis?.map((el) => el[0]);
    }


    const useStyles = makeStyles((theme) => ({
        tablediv: {
            borderRadius: "4px",
            
        },
        table: {
            minWidth: 700,
           
        },
        tableCell: {
            paddingRight: 4,
            paddingLeft: 5,
            whiteSpace: "nowrap",
            
       },
        paperStyle: {
            // boxShadow: "0px 5px 8px rgb(0 0 0 / 20%)",
            boxShadow:" 0px 4px 4px 0px #0000001A"

        },
        root: {
            "& .MuiPaginationItem-icon": {
                backgroundColor: "#C3C3C3",
                color: "white",
                height: "30px",
                width: "30px",
                borderRadius: "14px",
                padding: "3px",
                margin: "0px 1px",
                outline: "unset"
            },
            "& .MuiPaginationItem-textSecondary.Mui-selected": {
                backgroundColor: "#EA353D",
                outline: "unset !important"
            },
            "& .MuiPaginationItem-textSecondary.Mui-selected: focus": {
                outline: "unset !important"
            }
        }
    }));

    const classes = useStyles();

    const theme = useTheme();
    const screenExtraLarge = useMediaQuery(theme.breakpoints.only('xl'));
    const screenLarge = useMediaQuery(theme.breakpoints.only('lg'));
    const screenMedium = useMediaQuery(theme.breakpoints.only('md'));
    const screenSmall = useMediaQuery(theme.breakpoints.only('sm'));
    const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
    const screenNarrow = useMediaQuery('(max-width:340px)');

    const StyledTableCell = withStyles((theme) => ({
        head: {

            backgroundColor: '#EBEAEA',
            color: '#848484',
            fontSize: 14,
            fontWeight: 400,
            fontFamily:'DM Sans',
            borderRadius:'0px'
        },
        body: {
             fontSize: 14,

            fontFamily:'DM Sans'
        },
        
        
    }))(TableCell);

    const onTextchange = (text) => {
        setSearchText(text);
        setPage(1);
        _rowsData.jump(1);
    }

    const renderSearch = () => {
        return (
            <TableRow>
                <TableCell>
                    <div className="search-div">
                        <span className='h6 text-grey1'>Search</span>&nbsp;&nbsp;
                        <input onChange={(e) => onTextchange(e.target.value)} />
                    </div>
                </TableCell>
            </TableRow>
        )
    }

    function handleChange(value) {
        setRowsPerPage(value);
    }

    const getScreenWidth = () => {
        if (screenExtraLarge) {
            return 1;
        } else if (screenNarrow) {
            return -3;
        } else if (screenLarge) {
            return 1;
        } else if (screenMedium) {
            return 0;
        } else if (screenSmall) {
            return -1;
        } else if (screenExtraSmall) {
            return -2;
        } else {
            return -3;
        }
    }

    const renderEmptyBody = () => {
        return (
            <TableRow><TableCell align="center">No records found</TableCell></TableRow>
        )
    }

    const handlePageChange = (event, value) => {
        setPage(value);
        _rowsData.jump(value);
    };

    const renderTableFooter = (classes) => {
        var begin = _rowsData.getRange().begin;
        var end = _rowsData.getRange().end;
        return (
            <div className="table-footer">
                <div className="row-entries">
                    <strong className='h6 text-grey1' style={{ marginRight: "50px" }}>Showing results {`${begin > finalRows?.length ? finalRows?.length : begin}`} to {`${end > finalRows?.length ? finalRows?.length : end}`} of {`${finalRows?.length}`} entries</strong>
                    <div  className='h6 text-grey1'><span>Show</span>&nbsp;&nbsp;
                        <Select className="custom-select-icon h6 text-grey1" defaultValue={15} onChange={handleChange}
                            suffixIcon={<div style={{ display: "flex", alignItems: "start" }}><img alt="" style={{ height: "20px" }} src={UpDownArrow} /></div>}>
                            <Option value={15}>15</Option>
                            <Option value={25}>25</Option>
                            <Option value={35}>35</Option>
                        </Select>&nbsp;&nbsp;
                        <span  className='h6 text-grey1'>entries</span>
                    </div>
                </div>
                <Pagination siblingCount={getScreenWidth()}
                    page={page} onChange={handlePageChange}
                    size={getScreenWidth() < 0 ? "small" : "medium"} className={classes.root}
                    count={count} showFirstButton showLastButton color="secondary" />
            </div>
        )
    }

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const renderTableheader = () => {
        return (
            columns.map((e, key) => {
                return (
                    <StyledTableCell key={key}>
                        <div onClick={() => e.showSort && handleRequestSort(e.id)} style={{ cursor: e.showSort && "pointer" }}>
                            {e.title}&nbsp;&nbsp;
                            {e.showSort && <img alt="" src={(orderBy === e.id && order === "asc") ? DownArrow : UpArrow} />}
                        </div>
                    </StyledTableCell>
                )
            })
        )
    }

    return (
        <div>
            <Grid item xs={12} sm={12} lg={12} xl={12} xxl={12}>
                <Paper className={classes.paperStyle}>
                    <TableContainer className={classes.tablediv}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    {renderTableheader()}
                                </TableRow>
                            </TableHead>
                            <TableHead>{renderSearch()}</TableHead>
                            <TableBody>
                                {_rowsData?.currentData()?.length > 0 ?
                                    _rowsData?.currentData().map((row, key) => renderTableBody(row, key)) :
                                    renderEmptyBody()
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {renderTableFooter(classes)}
                </Paper>
            </Grid>
        </div>
    )
}

export default DataTable;