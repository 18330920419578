import "./Aircrafts.scss";
import endpoint from "../../utils/endpoints";
import React, { useState, useEffect } from "react";
import HeaderTitle from "../../components/HeaderTitle/HeaderTitle";
import { withStyles } from "@material-ui/core/styles";
import { TableCell, TableRow } from "@material-ui/core";
import DataTable from "../../components/DataTable/DataTable";
import { Modal } from "antd";
import { confirmAlert, errorToast } from "../../components/Generic/Generic";
import API from "../../utils/Api";
import Loader from "../../components/Loader/Loader";
import { getFromLocal } from "../../utils/generic";
import { EditTwoTone, DeleteTwoTone } from "@ant-design/icons";
import { successDialog, errorDialog } from "../../components/Generic/Generic";

const columns = [
  {
    title: "Aircraft Name",
    showSort: true,
    id: "Name",
  },
  {
    title: "Aircraft Code",
    showSort: true,
    id: "Code",
  },
  {
    title: "Manufacturer",
    showSort: true,
    id: "Manufacturer",
  },
  {
    title: "Actions",
    showSort: false,
  },
];
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#33485D",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "white",
      borderTop: "1px solid #E5E5E5",
    },
  },
}))(TableRow);

const Aircrafts = (props) => {
  const [isBusy, setBusy] = useState(true);
  const [records, getRecords] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Name");
  var [finalRow, setFinalRow] = useState(records);
  var [searchText, setSearchText] = useState("");
  const [showAirPortModal, setShowAirPortModal] = useState(false);
  const [modalTitle, setmodalTitle] = useState("Add Aircrafts");
  const [modalData, setModalData] = useState(null);
  const [fullAircraftNameField, setFullAircraftNameFieldValue] = useState("");
  const [fullAircraftCodeField, setFullAircraftCodeFieldValue] = useState("");
  const [fullManufacturerField, setFullManufacturerFieldValue] = useState("");

  const [isAdd, setAdd] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  //   const [isView, setView] = useState(false);
  const [isDelete, setDelete] = useState(false);

  useEffect(() => {
    if (records) {
      setFinalRow(records);
    }
  }, [records]);

  useEffect(() => {
    if (searchText) {
      var searchTxt = searchText.toString().trim().toLowerCase();
      var newRows = records?.filter((value) => {
        return (
          value.Name.toString().toLowerCase().includes(searchTxt) ||
          value.Code.toLowerCase().includes(searchTxt) ||
          value.ICAO.toString().toLowerCase().includes(searchTxt)
        );
      });
      setFinalRow(newRows);
    } else {
      setFinalRow(records);
    }
  }, [searchText, records]);

  useEffect(() => {
    var user = getFromLocal("user");
    var pageName = {};
    pageName = user.userRolePermissions;
    var pageActions = pageName.filter((r) => r.pageName === "Aircrafts");
    var actions = pageActions.map((a) => a.pageActions);
    if (actions.length > 0) {
      var action = actions[0].map((b) => b.actionName);
      action.forEach(actionsList);
      function actionsList(action, index, arr) {
        if (action === "Add") {
          setAdd(true);
        }
        if (action === "Update") {
          setUpdate(true);
        }
        if (action === "View") {
          //   setView(true);
        }
        if (action === "Delete") {
          setDelete(true);
        }
      }
    }
  }, []);

  async function getaircraftMaster() {
    var airport = {
      EntityKey: "tbl_lites_training_aircraft_master",
    };
    await API.getByQuery(endpoint.getMaster, airport, (isSuccess, response) => {
      if (isSuccess) {
        getRecords(response);
        setBusy(false);
      } else {
        errorToast(response.statusText);
        setBusy(false);
      }
    });
  }

  useEffect(() => {
    setBusy(true);
    getaircraftMaster();
  }, []);

  const handleDelete = async (id) => {
    var user = getFromLocal("user");

    const handleOk = async () => {
      var data = {
        Id: id,
        ModifiedBy: parseInt(user["userId"]),
        AircraftName: "Delete",
      };
      await deleteairportDetails(data);
    };

    const handleCancel = () => {};

    confirmAlert(
      "Do you want to delete these items?",
      "",
      handleOk,
      handleCancel
    );
  };

  async function deleteairportDetails(data) {
    setBusy(true);
    API.post(endpoint.AircraftDataModification, data, (isSuccess, response) => {
      if (isSuccess) {
        getaircraftMaster();
      } else {
        errorToast(response.statusText);
        setBusy(false);
      }
    });
  }

  const handleCancel = () => {
    setShowAirPortModal(false);
  };

  const getAirportModal = (title, data) => {
    if (data) {
      setFullAircraftNameFieldValue(data?.Name);
      setFullAircraftCodeFieldValue(data?.Code);
      setFullManufacturerFieldValue(data?.Manufacturer);
    } else {
      resetModalField();
    }
    setModalData(data);
    setmodalTitle(title);
    setShowAirPortModal(true);
  };

  const resetModalField = () => {
    setFullAircraftNameFieldValue("");
    setFullAircraftCodeFieldValue("");
    setFullManufacturerFieldValue("");
  };

  function changeAircraftNameFieldValue(e) {
    setFullAircraftNameFieldValue(e.target.value);
  }

  function changeAircraftCodeFieldValue(e) {
    setFullAircraftCodeFieldValue(e.target.value);
  }

  function changeManufacturerFieldValue(e) {
    setFullManufacturerFieldValue(e.target.value);
  }

  const renderTableBody = (row, key) => {
    return (
      <StyledTableRow key={key}>
        <StyledTableCell>{row.Name}</StyledTableCell>
        <StyledTableCell>{row.Code}</StyledTableCell>
        <StyledTableCell>{row.Manufacturer}</StyledTableCell>
        <StyledTableCell style={{ width: "100px" }}>
          {isUpdate ? (
            <label onClick={() => getAirportModal("Edit Aircrafts", row)}>
              <EditTwoTone
                style={{ fontSize: "17px", cursor: "pointer" }}
                title="Edit Aircrafts"
              />
            </label>
          ) : null}{" "}
          &nbsp;&nbsp;
          {isDelete ? (
            <DeleteTwoTone
              style={{ fontSize: "17px", cursor: "pointer" }}
              onClick={() => handleDelete(row.Id)}
              title="Delete"
            />
          ) : null}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const handleModalSubmit = async (e) => {
    setBusy(true);
    e.preventDefault();
    var fields = [];
    fields = e.target;
    var user = getFromLocal("user");
    var response = null;
    if (modalTitle === "Add Aircrafts") {
      var ProcessFields = {
        ModifiedBy: user["userId"],
        AircraftName: fields["AircraftFullName"].value,
        Code: fields["AircraftCode"].value,
        Manufacturer: fields["Manufacturer"].value,
      };
      response = await saveUpdateAirportDetails(ProcessFields);
    } else {
      var payload = {
        ID: modalData?.Id,
        ModifiedBy: user?.userId,
        AircraftName: fields["AircraftFullName"].value,
        Code: fields["AircraftCode"].value,
        Manufacturer: fields["Manufacturer"].value,
      };
      response = await saveUpdateAirportDetails(payload);
    }
    if (response?.Success) {
      successDialog(response?.Message, () => {
        resetModalField();
        getaircraftMaster();
      });
    } else {
      errorDialog(response?.Message);
      resetModalField();
      setBusy(false);
    }
    setShowAirPortModal(false);
  };

  async function saveUpdateAirportDetails(formData) {
    var data = null;
    await API.post(
      endpoint.AircraftDataModification,
      formData,
      (isSuccess, response) => {
        if (isSuccess) {
          data = response;
        } else {
          errorToast(response.statusText);
        }
      }
    );
    return data;
  }

  const renderAirportModal = () => {
    return (
      <Modal
        title={modalTitle}
        visible={true}
        okText={modalTitle === "Add Aircrafts" ? "Add" : "Update"}
        width={700}
        onCancel={handleCancel}
        footer={null}
      >
        <form onSubmit={handleModalSubmit}>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">Aircraft Name</label>
            <div className="col-sm-9">
              <input
                type="text"
                required
                className="form-control"
                value={fullAircraftNameField}
                onChange={changeAircraftNameFieldValue}
                name="AircraftFullName"
                placeholder="Aircraft Name"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">Code</label>
            <div className="col-sm-9">
              <input
                type="text"
                required
                className="form-control"
                value={fullAircraftCodeField}
                onChange={changeAircraftCodeFieldValue}
                name="AircraftCode"
                placeholder="Aircraft Code"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">Manufacturer</label>
            <div className="col-sm-9">
              <input
                type="text"
                required
                className="form-control"
                value={fullManufacturerField}
                onChange={changeManufacturerFieldValue}
                name="Manufacturer"
                placeholder="Manufacturer"
              />
            </div>
          </div>
          <button type="submit" className="btn btn-primary">
            {modalTitle === "Add Aircrafts" ? "Add" : "Update"}
          </button>
          &nbsp;&nbsp;&nbsp;
          <button onClick={handleCancel} className="btn btn-primary">
            Cancel
          </button>
        </form>
      </Modal>
    );
  };

  return (
    <div className="main-content">
      <div className="row">
        {isBusy && <Loader />}
        {showAirPortModal && renderAirportModal()}
        <div className="col-8">
          <HeaderTitle title="Aircrafts" />
        </div>
        {isAdd ? (
          <div className="col-4">
            <button
              type="submit"
              onClick={() => getAirportModal("Add Aircrafts", null)}
              className="btn btn-primary float-right"
            >
              Add Aircrafts
            </button>
          </div>
        ) : null}
      </div>
      <DataTable
        setSearchText={setSearchText}
        columns={columns}
        rows={finalRow}
        order={order}
        orderBy={orderBy}
        setOrder={(e) => setOrder(e)}
        setOrderBy={(e) => setOrderBy(e)}
        renderTableBody={(row, key) => renderTableBody(row, key)}
      />
    </div>
  );
};

export default Aircrafts;
