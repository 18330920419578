import React, { useState, useEffect } from "react";
import Logo from "../../../assets/images/lgtc-logo.jpeg";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import API from "../../utils/Api";
import { errorToast } from "../../components/Generic/Generic";
import endpoint from "../../utils/endpoints";
import { setToLocal, clearLocal, getFromLocal } from "../../utils/generic";
import "./Login.scss";
import ModalContainer from "../../components/ModalContainer/ModalContainer";
import ForgotPassword from "../ForgotPassword/ForgotPassword";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(10),
  },
  textField: {
    width: "38ch",
  },
}));

export default function Login(props) {
  const [payload, setPayload] = useState({
    username: null,
    password: null,
    showPassword: false,
  });
  const [error, setError] = useState({ username: "", password: "" });
  const [loginErr, setLoginErr] = useState("");
  const [showForgotPass, setShowForgotPass] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (getFromLocal("user")) {
      // eslint-disable-next-line no-unused-vars
      let user = JSON.parse(localStorage.getItem("user"));

      props.history.replace("/dashboard/trainings");
    } else {
      clearLocal();
    }
  }, []);

  const loginUser = async (credentials) => {
    API.post(endpoint.UserLogin, credentials, (isSuccess, response) => {
      if (isSuccess) {
        if (response.statusCode === 401) {
          setLoginErr("username and password mismatched");
          return null;
        }
        setToLocal("token", response.authToken);
        setToLocal("user", response);

        let user = JSON.parse(localStorage.getItem("user"));

        if (user["roleName"] === "Director Operation") {
          props.history.replace("/dashboard/new-design");
        } else if (user["roleName"] === "Pilot") {
          props.history.replace("/dashboard/new-design");
        } else if (user["roleName"] === "Admin") {
          props.history.replace("/dashboard/new-design");
        } else {
          props.history.replace("/dashboard/trainings");
        }
      } else {
        errorToast("login failed");
      }
    });
  };

  const validate = () => {
    var errorData = {};
    if (payload.username === null || payload.username.trim().length === 0) {
      errorData = { ...errorData, username: "field must be required" };
    }
    if (payload.password === null || payload.password.trim().length === 0) {
      errorData = { ...errorData, password: "field must be required" };
    }
    setError(errorData);
    if (Object.entries(errorData).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async (e) => {
    if (validate()) {
      var userpayload = {
        userName: payload.username,
        userPassword: payload.password,
      };
      await loginUser(userpayload);
    }
  };

  const onHandleChange = (key, value) => {
    payload[key] = value;
    setPayload({ ...payload, payload });
  };

  const handleClickShowPassword = () => {
    setPayload({ ...payload, showPassword: !payload.showPassword });
  };

  const renderUsername = () => {
    return (
      <TextField
        id="outlined-uncontrolled"
        className={clsx(classes.margin, classes.textField)}
        onChange={(e) => onHandleChange("username", e.target.value)}
        error={error?.username ? true : false}
        helperText={error?.username}
        label="Username"
        variant="outlined"
      />
    );
  };

  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };
  const renderPassword = () => {
    return (
      <FormControl
        className={clsx(classes.margin, classes.textField)}
        variant="outlined"
      >
        <InputLabel
          style={{ color: error?.password && "#f44336" }}
          htmlFor="outlined-adornment-password"
        >
          Password
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          error={error?.password ? true : false}
          type={payload.showPassword ? "text" : "password"}
          onChange={(e) => onHandleChange("password", e.target.value)}
          onKeyPress={handleKeypress}
          endAdornment={
            <InputAdornment position="end">
              <div
                className="visiblity"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {payload.showPassword ? (
                  <EyeOutlined style={{ fontSize: "25px" }} />
                ) : (
                  <EyeInvisibleOutlined style={{ fontSize: "25px" }} />
                )}
              </div>
            </InputAdornment>
          }
          labelWidth={75}
        />
        {error?.password && (
          <span className="error-txt">{error?.password}</span>
        )}
      </FormControl>
    );
  };
  return (
    <div className="login-wrapper">
      <div className="login-container">
        <div className="login-inner-div">
          {showForgotPass && (
            <ModalContainer handleClose={() => setShowForgotPass(false)}>
              <ForgotPassword handleClose={() => setShowForgotPass(false)} />
            </ModalContainer>
          )}
          <div>
            <img className="logo-login" src={Logo} alt="" />
          </div>
          <div className="login-txt">Login</div>
          <div className="input-wrapper">
            {renderUsername()}
            {renderPassword()}
          </div>
          <div className="login-btn" onClick={() => handleSubmit()}>
            Submit
          </div>
          {loginErr && <span className="error-style">{loginErr}</span>}
        </div>
        <div className="text-right">
          <span onClick={() => setShowForgotPass(true)} className="forgot-txt">
            Forgot <span className="blue">password?</span>
          </span>
        </div>
      </div>
    </div>
  );
}
