import React from "react";
import { BreadCrumb, Button } from "../../componentsNew/core";
import "./lgtcAccount.scss";
import TrainingDirectorate from "../../../assets/images/NewImg/DOtraining.png";
import User from "../../../assets/images/NewImg/user.png";
import Pilot from "../../../assets/images/NewImg/pilot.png";
import Aircraft from "../../../assets/images/NewImg/aircraft.png";
import Airport from "../../../assets/images/NewImg/airport.png";
import TrainingDevice from "../../../assets/images/NewImg/trainingdevice.png";
import TrainingSchedule from "../../../assets/images/NewImg/trainingschedule.png";
import Qualification from "../../../assets/images/NewImg/qualification.png";
import TAeditor from "../../../assets/images/NewImg/taeditor.png";
import Dashboard from "../../../assets/images/NewImg/dashboard.png";
import TrainingRequest from "../../../assets/images/NewImg/trainingrequest.png";
import { useHistory } from "react-router-dom";

// let dashboardtxt = "Let’s make managing your team’s training schedule a little more easier."
let dashboardtxt = "";
let trainingtxt =
  "Manage, Review and stay updated with the current qualifications of your workforce and assign them to trainings before their qualifications expire.";
let pilottxt =
  "Manage, Review and stay updated with the current qualifications of the pilots at Lion Air Group.";
let airpottxt =
  "Manage the operations of the staff assisting with our aircraft operations in different airports around the world";
let trainingdevicetxt =
  "Manage and allocate devices and equipment crucial to complete a training. ";
let usertxt =
  "Manage, Review and stay updated with your workforce’s current license and qualification status.";
let aircrafttxt =
  "Review the aircraft specifications, the crew assigned to the operations.";
let trainingscheduletxt =
  "Manage, Review and stay updated with the status of your employee’s qualifications and assign them to a training.";
let qualificationtxt =
  "Manage, Review and stay updated with the current qualifications of your workforce";
let taeditortxt = "Manage, review and stay updated with your workforce";
let trainingrequesttxt =
  "Manage your employee’s qualifications and assign them to a training to update their licenses for a smoother operation";

function LGTCAccount(props) {
  const history = useHistory();

  const {
    // pageValue,
    children,
    breadCrumbTitle = "Dashboard",
    // setPageValue,
    navlink = 0,
    subMenu,
    AddButtonShow = false,
    getUserModal,
    getAircraftModal,
    getAirportModal,
    getTrainingDeviceModal,
  } = props;

  const breadCrumbItems = [
    {
      title: "Lion Group",
      link: "/dashboard",
    },
    {
      title: `${breadCrumbTitle}`,
      link: 1,
    },
  ];

  const breadCrumbItems1 = [
    {
      title: "Lion Group",
      link: "/dashboard",
    },
    {
      title: `${subMenu}`,
      link: `${
        subMenu == "Trainings"
          ? "/dashboard/new-design/lgtc/trainings"
          : "/dashboard/new-design/lgtc/qualification"
      }`,
    },
    {
      title: `${breadCrumbTitle}`,
      link: 1,
    },
  ];
  return (
    <div className="px-5 py-2 bg-white fixed-container">
      <BreadCrumb
        breadCrumbItems={navlink == 0 ? breadCrumbItems : breadCrumbItems1}
      />
      <div className="row">
        <div className="col-md-3 col-12 p-0">
          <div className="box-shadow mb-2">
            <img
              className="w-100 object-contain"
              alt=""
              src={
                {
                  Dashboard: Dashboard,
                  Parts: Dashboard,
                  Users: User,
                  Pilots: Pilot,
                  Trainings: TrainingDirectorate,
                  "New Training": TrainingDirectorate,
                  "Edit Training": TrainingDirectorate,
                  Qualifications: Qualification,
                  "New Qualification": Qualification,
                  "Edit Qualification": Qualification,
                  Aircraft: Aircraft,
                  Airport: Airport,
                  "TA Editor": TAeditor,
                  "Training Device": TrainingDevice,
                  "Training Request": TrainingRequest,
                  "Training Schedule": TrainingSchedule,
                }[breadCrumbTitle]
              }
            />
            <div className="text-grey2 p-4">
              <div className="h4">{breadCrumbTitle}</div>

              <div className="h6 my-2">
                {
                  {
                    Dashboard: dashboardtxt,
                    Users: usertxt,
                    Pilots: pilottxt,
                    Trainings: trainingtxt,
                    "New Training": trainingtxt,
                    "Edit Training": trainingtxt,
                    Qualifications: qualificationtxt,
                    "New Qualification": qualificationtxt,
                    "Edit Qualification": qualificationtxt,
                    Aircraft: aircrafttxt,
                    Airport: airpottxt,
                    "TA Editor": taeditortxt,
                    "Training Device": trainingdevicetxt,
                    "Training Request": trainingrequesttxt,
                    "Training Schedule": trainingscheduletxt,
                  }[breadCrumbTitle]
                }
              </div>
              {AddButtonShow && (
                <Button
                  className="rounded h6-web col-12"
                  size="md"
                  title={
                    {
                      Trainings: "New Training",
                      Qualifications: "New Qualification",
                      Users: "Add User",
                      Airport: "Add Airport",
                      "Training Device": "Add Training Device",
                      Aircraft: "Add Aircraft",
                    }[breadCrumbTitle]
                  }
                  variant="primary"
                  //  onClick={() => {
                  //   return {
                  //   "Trainings": history.push("/dashboard/new-design/lgtc/trainings/new-training"),
                  //   "Qualifications": history.push("/dashboard/new-design/lgtc/qualification/new-qualification") ,
                  //   "Users": getUserModal("Add User", null) ,
                  //   "Airport": getAirportModal("Add Airport", null) ,
                  //   "Aircraft": getAircraftModal("Add Aircraft", null) ,
                  //   "Training Device": getTrainingDeviceModal("Add Training Device", null),
                  //   }[breadCrumbTitle];
                  //   }}

                  onClick={() => {
                    breadCrumbTitle == "Trainings"
                      ? history.push(
                          "/dashboard/new-design/lgtc/trainings/new-training"
                        )
                      : breadCrumbTitle == "Qualifications"
                      ? history.push(
                          "/dashboard/new-design/lgtc/qualification/new-qualification"
                        )
                      : breadCrumbTitle == "Users"
                      ? getUserModal("Add User", null)
                      : breadCrumbTitle == "Airport"
                      ? getAirportModal("Add Airport", null)
                      : breadCrumbTitle == "Aircraft"
                      ? getAircraftModal("Add Aircraft", null)
                      : breadCrumbTitle == "Training Device"
                      ? getTrainingDeviceModal("Add Training Device", null)
                      : history.push("/dashboard");
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-md-9 col-12 pt-md-0 pt-3 pb-5">{children}</div>
      </div>
    </div>
  );
}

export default LGTCAccount;
