import { baseurl } from './Constants';

const endpoint = {
    UserLogin: baseurl + "login/UserLogin",
    UserLogout: baseurl + "login/UserLogout",
    GetCrewId: baseurl + "api/Pilot/crewdetails/",
    SaveFormDetails: baseurl + "api/TrainingForm",
    GetForm: baseurl + "common/getForm",
    GetTrainings: baseurl + "common/getTrainings",
    DeleteFormDetails: baseurl + "api/TrainingForm",
    GetFormData: baseurl + "common/getFormData?id=",
    SendOTP: baseurl + "common/sendOTP",
    SubmitOTP: baseurl + "common/submitOTP",
    GetFormDetails: baseurl + "common/getFormDetails",
    getMaster: baseurl + "common/getMaster",
    AddUser: baseurl + "user",
    GetUsers: baseurl + "user",
    DeleteUser: baseurl + "user/delete",
    UpdateUser: baseurl + "user/update",
    ChangePassword: baseurl + "common/changepassword",
    DashboardData: baseurl + "common/getDashboardData",
    GetQualifications: baseurl + "common/getQualifications",
    GetPilots: baseurl + "api/pilot",
    GetPilotData: baseurl + "api/pilot/data",
    GetTAForm: baseurl + "common/getFormDatatoModify",
    UpdateFormDetails: baseurl + "common/updateFormDetails",
    AirportDataModification: baseurl + "common/airportdatamodification",
    AircraftDataModification: baseurl + "common/aircraftdatamodification",
    TrainingDevicesDataModification: baseurl + "common/trainingdevicesdatamodification",
    SendResetOTP: baseurl + "login/sendresetotp",
    VerifyResetOTP: baseurl + "login/verifyresetotp",
    ResetPassword: baseurl + "login/resetpassword",
    GetDashboardTrainingCount: baseurl + "common/fetchtrainingcount",
    GetDrilledLessThanTwoCompetencyList: baseurl + "common/getDrilledLessThanTwoCompetencyList",
    ExpiringCertAndLicenses: baseurl + "common/expiringCertAndLicenses",
    GetMasterData: baseurl + "api/training/masterdata",
    GetTrainingCategories: baseurl + "api/training/masterdata/categories",
    GetTAEditorForm: baseurl + "api/training/masterform",
    UpdateTAEditorForm: baseurl + "api/training/masterform/create",
    GetProfile: baseurl + "api/pilot/profile",
    GetQualificationTrainings: baseurl + "api/pilot/qualifications",
    ReviewQualifications: "/reviewed",
    GetDocuments: baseurl + "api/pilot/documents",
    GetDirectorDashboard: baseurl + "api/do/profile",
    PilotQualifications: baseurl + "api/do/pilotqualifications",
    GetSchedules: baseurl + "api/Training/active-schedule",
    AssignTrainingToPilot: baseurl + "api/do/assigntrainingtopilot",
    GetMedicalCertificates: baseurl + "api/pilot/medicalcertificates",
    DocumentDues: baseurl + "api/do/pilots/document-dues",
    ScheduleMasterData: baseurl + "api/master/data-fetch",
    GetQualificationsData: baseurl + "api/master/data-qualfications",
    TrainingSchedule: baseurl + "api/Training/schedule",
    GetPilotScheduleTrainings: baseurl + "api/pilot/schedule-trainings",
    RequestSchedule: baseurl + "api/Training/request/schedule",
    GetTrainingsToReview: baseurl + "api/Training/request/schedules/to-reviews",
    ReviewedScheduleRequests: baseurl + "api/Training/request/schedules/reviewed",
}

export default endpoint;