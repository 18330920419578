import React, { useState, useEffect, useContext } from "react";
import "./../../scss/trax.scss";
import { ModalContext } from "../../services/context/modal-context/State";
import { getDashboardShippingData } from "./function";
import TRAXAccount from "../TraxAccount/TRAXAccount";
import { useModal } from "../../hooks/useModal";
import moment from "moment";
import { UserContext } from "../../services/context/userContext/State";
import {
  Badge,
  InputSearch,
  Button,
  DatePickers,
  Input,
  // Dropdown,
} from "../../componentsNew/core";
import ArrowLeft from "../../../assets/svg/NewIcons/arrow_left_white.svg";
import ArrowRight from "../../../assets/svg/NewIcons/arrow_right_white.svg";

function TraxDashboardShipping() {
  const { setIsLoading } = useContext(ModalContext);
  const { role } = useContext(UserContext);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { handleExpiredToken } = useModal();
  const [dashboardData, setDashboardData] = useState([]);
  const [pagingData, setPagingData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [goto, setGoto] = useState(currentPage);
  const [sort, setSort] = useState(0);
  const [tableSortKey, setTableSortKey] = useState(0);

  useEffect(() => {
    if (role === "Admin" || role === "MP" || role === "Prod") {
      var today = new Date();
      today.setHours(0, 0, 0, 0);
      var yesterday = new Date();
      yesterday.setHours(0, 0, 0, 0);
      yesterday.setDate(yesterday.getDate() - 1);

      setStartDate(yesterday);
      setEndDate(today);
      var req = {
        startDate: moment(yesterday).format("YYYY-MM-DD"),
        endDate: moment(today).format("YYYY-MM-DD"),
        pageNo: 1,
        pageSize: 25,
        sorting: 0,
        sortingOrder: 0,
      };
      getDashData(req);
    }
  }, [role]);

  const getData = async () => {
    var req = {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      pageNo: 1,
      pageSize: 25,
      sorting: 0,
      sortingOrder: 0,
    };
    if (searchText && searchText.length > 0) {
      req.SearchByNo = searchText;
      setStartDate(null);
      setStartDate(endDate);
    }
    getDashData(req);
  };

  const getDashData = (request) =>
    getDashboardShippingData(
      request,
      setIsLoading,
      setPagingData,
      setDashboardData,
      setCurrentPage,
      setLastPage,
      handleExpiredToken
    );

  const handlePaging = (value, pageNo) => {
    var pageNum = currentPage;
    if (value === "go") {
      pageNum = Number(pageNo) || 1;
      Number(pageNo) || setGoto(1);
    }
    if (value === "prev") {
      pageNum = currentPage - 1;
    }
    if (value === "next") {
      pageNum = currentPage + 1;
    }
    var req = {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      pageNo: pageNum,
      pageSize: 25,
      sorting: tableSortKey,
      sortingOrder: sort,
    };
    getDashData(req);
  };

  const SortBy = (key) => {
    var sortBy = 0;
    if (tableSortKey === key) {
      if (sort === 0) {
        setSort(1);
        sortBy = 1;
      } else if (sort === 1) {
        setSort(0);
      }
    } else {
      setTableSortKey(key);
      setSort(0);
    }
    var req = {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      pageNo: 1,
      pageSize: 25,
      sorting: key,
      sortingOrder: sortBy,
    };
    getDashData(req);
  };

  const TableControls = (
    <>
      <div className="d-flex justify-content-end align-items-center">
        {!!(pagingData?.currentPage && pagingData?.maxPages) && (
          <p className="h8 text-grey1 mb-0 mr-2">
            Page {pagingData?.currentPage} of {pagingData?.maxPages} (Total
            Records: {pagingData?.totalItems})
          </p>
        )}
        <Input
          style={{ width: "50px" }}
          type="number"
          value={goto}
          onChange={(value) => {
            value >= 0 && value < pagingData?.maxPages + 1 && setGoto(value);
          }}
        />
        <Badge
          className="rounded-50 ms-2 text-white"
          variant="primary"
          size="xl"
          title="Go"
          disabled={currentPage === lastPage}
          onClick={() => handlePaging("go", goto)}
        />
        <Badge
          className="rounded-50 ms-2"
          variant="primary"
          size="xl"
          icon={ArrowLeft}
          title="Previous Page"
          disabled={currentPage === 1}
          onClick={() => handlePaging("prev")}
        />
        <Badge
          className="rounded-50 ms-2"
          variant="primary"
          size="xl"
          icon={ArrowRight}
          title="Next Page"
          disabled={currentPage === lastPage}
          onClick={() => handlePaging("next")}
        />
      </div>
    </>
  );

  const contentToRender = (
    <>
      <div className="h4 text-grey1 mt-4 pb-2">Shipping Dashboard</div>
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center p-0">
          <p className="h7 text-black mb-0 mr-2">From</p>
          <DatePickers
            maxDate={new Date()}
            selected={startDate}
            dateFormat="MMMM d, yyyy"
            className="col-12 rounded border border-1 picker-height h7-web bg-grey"
            onChange={(e) => {
              setStartDate(e);
              setSearchText("");
            }}
          />
          <p className="h7 text-black mb-0 mx-2">To</p>
          <DatePickers
            maxDate={new Date()}
            selected={endDate}
            dateFormat="MMMM d, yyyy"
            className="rounded border border-1 col-12 picker-height h7-web bg-grey"
            onChange={(e) => {
              setEndDate(e);
              setSearchText("");
            }}
          />
          <p className="h7 text-black mb-0 mx-2">Or Search By</p>
          <div className="ml-2">
            <InputSearch
              placeholder="Requisition/OrderNo"
              className="w-200"
              onChange={setSearchText}
              value={searchText}
              type="number"
            />
          </div>
          <Button
            className="rounded h6-web w-80 ml-2"
            size="md"
            title={"Search"}
            variant="primary"
            onClick={getData}
          />
        </div>
      </div>
      <div className=" mt-2 mb-2 table-responsive ">
        <table className="table table-bordered shadow-sm align-middle ">
          <thead className="bg-grey h7-table text-grey1">
            <tr className="text-center text-nowrap">
              <td className="has-pointer" onClick={() => SortBy(0)}>
                RQ number
                <i
                  className={
                    tableSortKey === 0
                      ? sort === 0
                        ? "fa fa-sort-asc"
                        : "fa fa-sort-desc"
                      : "fa fa-fw fa-sort"
                  }
                ></i>
              </td>
              <td>RQ Line</td>
              <td className="has-pointer" onClick={() => SortBy(2)}>
                Order number
                <i
                  className={
                    tableSortKey === 2
                      ? sort === 0
                        ? "fa fa-sort-asc"
                        : "fa fa-sort-desc"
                      : "fa fa-fw fa-sort"
                  }
                ></i>
              </td>
              <td>Order line</td>
              <td>Order Type</td>
              <td>AWB</td>
              <td>Shipping Direction</td>
              <td>Shipped To</td>
              <td>Departed At</td>
              <td>Arrived At</td>
              <td>Shipping Direction</td>
              <td>Shipped To</td>
              <td>Departed At</td>
              <td>Arrived At</td>
            </tr>
          </thead>
          <tbody className="h8 text-table-body">
            {dashboardData?.map((row, rowIndex) => (
              <tr className="text-center text-nowrap" key={"row_" + rowIndex}>
                <td>{row.requisition}</td>
                <td>{row.requisitionLine}</td>
                <td>{row.orderNumber}</td>
                <td>{row.orderLine}</td>
                <td>{row.orderType}</td>
                <td>{row.awb}</td>
                <td>{row.shippingDirectionIn}</td>
                <td>{row.shippedInTo}</td>
                <td>
                  {row.shippedFromVendorAt
                    ? moment(row.shippedFromVendorAt).format("DD/MM/YYYY")
                    : ""}
                </td>
                <td>
                  {row.shippedArrivedAt
                    ? moment(row.shippedArrivedAt).format("DD/MM/YYYY")
                    : ""}
                </td>
                <td>{row.shippingDirectionOut}</td>
                <td>{row.shippedOutTo}</td>
                <td>
                  {row.shippedDepartedAt
                    ? moment(row.shippedFromVendorAt).format("DD/MM/YYYY")
                    : ""}
                </td>
                <td>
                  {row.shippedOutArrivedAt
                    ? moment(row.shippedArrivedAt).format("DD/MM/YYYY")
                    : ""}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {!dashboardData.length && <p className="text-center">No Data Found</p>}
      </div>
      {TableControls}
    </>
  );
  return (
    <TRAXAccount breadCrumbTitle="Dashboard Shipping">
      {contentToRender}
    </TRAXAccount>
  );
}

export default TraxDashboardShipping;
