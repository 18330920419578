import React, { useState, useEffect } from 'react';
import API from '../../utils/Api';
import HeaderTitle from "../../components/HeaderTitle/HeaderTitle";
import DashboardCard from '../../components/DashboardCard/DashboardCard';
import Loader from '../../components/Loader/Loader';
import { errorToast } from '../../components/Generic/Generic';
import { getFromLocal } from '../../utils/generic';
import endpoint from '../../utils/endpoints';
import moment from 'moment';
import "./MyProfile.scss";

const MyProfile = (props) => {
    const [isBusy, setBusy] = useState(true);
    const [profile, setProfile] = useState([]);
    const [mobile, setMobile] = useState(true);
    const [address, setAddress] = useState(true);

    const getMyProfile = async () => {
        var user = getFromLocal("user");

        var userId = parseInt(user?.userId);
        var url = `${endpoint.GetProfile}/${userId}`;

        API.get(url, (isSuccess, response) => {
            if (isSuccess) {
                setProfile(response);
                setBusy(false);
                var mobile = response?.contacts.filter(x => x.phoneType === "Mobile")[0]?.number;
                setMobile(mobile);
                var address = response?.addresses[0];
                setAddress(address);
            } else {
                errorToast("Error loading profile!");
                setBusy(false);
            }
        })
    }

    useEffect(() => {
        setBusy(true);
        getMyProfile();
    }, [])

    return (
        <div className="main-content">
            {isBusy && <Loader />}
            <div className="row">
                <div className="col-md-8"><HeaderTitle title="Your Profile" /></div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <DashboardCard title="Profile Information">
                        <div className="row">

                            <div className="col-md-4">
                                <label className="form-label">Title</label>
                                <input type="text" required className="form-control mb-15" disabled value={profile?.title} name="Title" placeholder="Title" />
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">First Name</label>
                                <input type="text" required className="form-control mb-15" disabled value={profile?.firstName} name="FirstName" placeholder="First Name" />
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">Last Name</label>
                                <input type="text" required className="form-control mb-15" disabled value={profile?.lastName} name="LastName" placeholder="Last Name" />
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">Date Of Birth</label>
                                <input type="text" required className="form-control mb-15" disabled value={profile?.dateOfBirth ? moment(profile?.dateOfBirth).format("DD/MM/YYYY") :""} name="DateOfBirth" placeholder="" />
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">Sabre ID</label>
                                <input type="text" required className="form-control mb-15" disabled value={profile?.sabreId} name="StaffID" placeholder="" />
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">Company</label>
                                <input type="text" required className="form-control mb-15" disabled value={profile?.company} name="Company" placeholder="Company" />
                            </div>                            
                        </div>

                    </DashboardCard>
                </div>
                <div className="col-md-12"><br />
                    <DashboardCard title="Contact Information">
                        <div className="row">
                            <div className="col-md-6">
                                <label className="form-label">Mobile</label>
                                <input type="text" required className="form-control mb-15" disabled value={mobile ?? ""} name="Mobile" placeholder="" />
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Email Address</label>
                                <input type="text" required className="form-control mb-15" disabled value={profile?.email} name="Email" placeholder="" />
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Street</label>
                                <input type="text" required className="form-control mb-15" disabled value={address?.street1} name="Strett" placeholder="" />
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">City</label>
                                <input type="text" required className="form-control mb-15" disabled value={address?.city} name="City" placeholder="" />
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Province</label>
                                <input type="text" required className="form-control mb-15" disabled value={address?.province} name="Province" placeholder="" />
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Postal Code</label>
                                <input type="text" required className="form-control mb-15" disabled value={address?.postalCode} name="PostalCode" placeholder="" />
                            </div>
                        </div>
                    </DashboardCard>
                </div>
            </div>
        </div>
    )
}

export default MyProfile;