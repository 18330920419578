import React, { useEffect, useState } from 'react';
import "./Adduser.scss";
import HeaderTitle from "../../components/HeaderTitle/HeaderTitle"
import endpoint from '../../utils/endpoints';
import API from '../../utils/Api';
import { errorToast, errorDialog, successDialog } from '../../components/Generic/Generic';
import Loader from '../../components/Loader/Loader';
import { getFromLocal } from '../../utils/generic';

async function saveFormDetails(formData) {
    var data = null;
    var user = getFromLocal("user");
        var userId = parseInt(user?.userId);
        var headers = {
            'UserId': userId
        }
    await API.postWithHeader(endpoint.AddUser, headers, formData, (isSuccess, response) => {
        if (isSuccess) {
            data = response;
        } else {
            errorToast(response.statusText);
        }
    })
    return data;
}

const AddUser = (props) => {
    const [isBusy, setBusy] = useState(true);

    const [emailField, setEmailFieldValue] = useState("");
    const [fullNameField, setFullNameFieldValue] = useState("");

    useEffect(() => {
        setBusy(false);
    }, [])

    const handleSubmit = async e => {
        setBusy(true);
        e.preventDefault();
        var fields = [];
        fields = e.target;

        var user = JSON.parse(localStorage.getItem("user"));
        var ProcessFields = {
            UserId: user["userId"],
            EntityKey: fields["RoleId"].value,
            ChildEntityKey: fields["FullName"].value,
            UserEmail: fields["EmailId"].value
        };

        const response = await saveFormDetails(ProcessFields);
        if (response.Success) {
            successDialog(response?.Message, handleOk);
        } else {
            errorDialog(response?.Message);
            setEmailFieldValue("");
            setFullNameFieldValue("");
        }
        setBusy(false);
    }

    const handleOk = () => {
        setEmailFieldValue("");
        setFullNameFieldValue("");
    }

    function changeNameFieldValue(e) {
        setFullNameFieldValue(e.target.value);
    }

    function changeEmailFieldValue(e) {
        setEmailFieldValue(e.target.value);
    }

    return (
        <div className="main-content">
            {isBusy && <Loader/>}
            <div className="row">
                <div className="col-md-12"><HeaderTitle title="Add User" /></div>
                <div className="col-md-7">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">User Type</label>
                            <div className="col-sm-10">
                                <select className="form-control" disabled name="RoleId">
                                    <option value="1" defaultValue>Instructor</option>
                                    <option value="2">Head</option>
                                    <option value="3">Director</option>
                                    <option value="4">Pilot</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Name</label>
                            <div className="col-sm-10">
                                <input type="text" required className="form-control" value={fullNameField} onChange={changeNameFieldValue} name="FullName" placeholder="Full name" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Email ID</label>
                            <div className="col-sm-10">
                                <input type="text" required className="form-control" value={emailField} onChange={changeEmailFieldValue} name="EmailId" placeholder="Email ID" /></div>
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddUser;