import { useContext } from "react";
import { ModalContext } from "../services/context/modal-context/State";
import { ENDPOINTS, getApi } from "../services/http";
import { getFromLocal, clearLocal } from "../services/storage/LocalStorage";
import { useHistory } from "react-router-dom";

export const useModal = () => {
  const history = useHistory();
  const user = getFromLocal("user");
  const modal = useContext(ModalContext);

  const handleExpiredToken = () => {
    async function handleDeleteOk(modalId) {
      var credentials = {
        UserId: parseInt(user["userId"]),
      };
      const response = await getApi(`${ENDPOINTS.LOGOUT}`, credentials);
      if (response) {
        //if (response.status === 200) {
        clearLocal();
        history.push("/auth/login");
        //}

        // else {
        //   response.status !== 401 && errorToast("logout failed");
        // }
      } else {
        clearLocal();
        history.push("/auth/login");
      }
      let newInstance = [...modal.modalInstances];
      newInstance = newInstance.filter((instance) => instance.id != modalId);
      modal.setModalInstances(newInstance);
    }

    modal.expiredToken(
      "Your session get expired!, Please signin again",
      handleDeleteOk
    );
  };

  const resetSession = (title, content, logOut, stayActive) => {
    modal?.resetSession(title, content, logOut, stayActive);
  };

  return { handleExpiredToken, resetSession };
};
