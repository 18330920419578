// import HeaderTitle from "../../componentsNew/core/sp-title/HeaderTitle";
import React, { useState, useEffect, useContext } from "react";
import "./../../scss/trax.scss";
import { PageDetails } from "../../componentsNew/page-details/PageDetails";
import { usePagination } from "../../hooks/usePagination";
import ArrowLeft from "../../../assets/svg/NewIcons/arrow_left_white.svg";
import ArrowRight from "../../../assets/svg/NewIcons/arrow_right_white.svg";
import { Badge, InputSearch, Modal, Button } from "../../componentsNew/core";
//import LGTCAccount from "./LGTCAccount";
import downloadsvg from "../../../assets/svg/NewIcons/xls-icon.svg";
import { useModal } from "../../hooks/useModal";
import { ModalContext } from "../../services/context/modal-context/State";
import { ENDPOINTS, getApi, deleteApi } from "../../services/http";
// import { getFromLocal, clearLocal } from "../../services/storage/LocalStorage";
import { errorToast, successToast } from "../../componentsNew/Toast/Toast";
import EditIcon from "../../../assets/svg/NewIcons/edit.svg";
import DeleteIcon from "../../../assets/svg/NewIcons/delete.svg";
import UserModal from "./TraxUserModal";
import { RemoveModalInstance } from "../../helper";
import TRAXAccount from "../TraxAccount/TRAXAccount";
import { UserContext } from "../../services/context/userContext/State";
import { json2xlsDownload } from "../../utils/functions";

export default function TraxUsers(props) {
  const [, setAdd] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const [, setView] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { user, role } = useContext(UserContext);
  const userId = parseInt(user?.userId);
  const [userData, setUserData] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const [userModalData, setUserModalData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [userDataBackupData, setUserDataBackupData] = useState([]);
  const { pagination, rowItems, handlePagination } = usePagination(userData);
  const { handleExpiredToken } = useModal();
  const { setIsLoading } = useContext(ModalContext);
  const modal = useContext(ModalContext);

  useEffect(() => {
    handlePagination();
  }, [userData]);

  useEffect(() => {
    if (role) {
      if (role === "Admin") {
        getUserData();
      } else {
        window.history.back();
      }
    }
  }, [role]);

  useEffect(() => {
    setAdd(true);
    setUpdate(true);
    setView(true);
    setDelete(true);

    // let pageName = {};
    // pageName = user.userRolePermissions;
    // let pageActions = pageName.filter((r) => r.pageName === "Users");
    // let actions = pageActions.map((a) => a.pageActions);
    // if (actions.length > 0) {
    //   let action = actions[0].map((b) => b.actionName);
    //   action.forEach(actionsList);
    //   function actionsList(action, index, arr) {
    //     if (action === "Add") {
    //       setAdd(true);
    //     }
    //     if (action === "Update") {
    //       setUpdate(true);
    //     }
    //     if (action === "View") {
    //       setView(true);
    //     }
    //     if (action === "Delete") {
    //       setDelete(true);
    //     }
    //   }
    // }
  }, []);

  const getUserData = async () => {
    setIsLoading(true);
    let headers = {
      UserId: userId,
    };
    const response = await getApi(`${ENDPOINTS.GET_USER}`, {
      headers: headers,
    });
    if (response) {
      if (response.status == 200) {
        setUserData(response?.data);
        setUserDataBackupData(response?.data);
        setIsLoading(false);
      } else if (response.status == 401) {
        setIsLoading(false);
        handleExpiredToken();
      } else {
        setIsLoading(false);
        errorToast("something went wrong, Please try again");
      }
    }
  };

  const handleChangeWithLib = (value) => {
    let newRows = [];
    setSearchText(value);
    if (value) {
      let searchTxt = value.toString().trim().toLowerCase();
      newRows = userDataBackupData?.filter((value) => {
        return (
          (value.firstName &&
            value.firstName?.toString().toLowerCase().includes(searchTxt)) ||
          (value.middleName &&
            value.middleName?.toString().toLowerCase().includes(searchTxt)) ||
          (value.lastName &&
            value.lastName?.toString().toLowerCase().includes(searchTxt)) ||
          (value.roleName &&
            value.roleName?.toString().toLowerCase().includes(searchTxt))
        );
      });
    } else {
      newRows = [...userDataBackupData];
    }
    setUserData(newRows);
  };

  const handleDeleteCancel = (modalId) => {
    let newInstance = RemoveModalInstance(modal, modalId);
    modal.setModalInstances(newInstance);
  };

  const handleDeleteOk = async (rowId, modalId) => {
    setIsLoading(true);
    let response = await deleteApi(`${ENDPOINTS.DELETE_USER}/${rowId}`);
    if (response) {
      if (response.status == 200) {
        successToast("User deleted successfully");
        getUserData();
      } else if (response.status == 401) {
        setIsLoading(false);
        handleExpiredToken();
      } else {
        setIsLoading(false);
        errorToast("something went wrong, Please try again");
      }
    }
    let newInstance = RemoveModalInstance(modal, modalId);
    modal.setModalInstances(newInstance);
  };

  const handleDelete = async (rowID) => {
    modal.deleteModal(
      rowID,
      "Are you sure you want to delete this user?",
      handleDeleteOk,
      handleDeleteCancel
    );
  };

  const getUserModal = (title, data) => {
    setModalTitle(title);
    setUserModalData(data);
    setShowModal(true);
  };

  const DeleteField = (rowID) => {
    return (
      <Badge
        className="rounded-3"
        size="xs"
        variant="white"
        icon={DeleteIcon}
        title="Delete"
        onClick={() => handleDelete(rowID)}
      />
    );
  };

  const EditField = (row) => {
    return (
      <Badge
        className="rounded-3"
        size="xs"
        variant="white"
        icon={EditIcon}
        title="Edit"
        onClick={() => getUserModal("Edit User", row)}
      />
    );
  };

  const ModalChildren = (
    <UserModal
      setShowModal={setShowModal}
      title={modalTitle}
      data={userModalData}
      setIsLoading={setIsLoading}
      getUserData={getUserData}
    />
  );

  const TableControls = (
    <>
      <div className="flex-wrap d-flex align-items-center justify-content-center">
        <div className="">
          <div className="col-md-12 col-sm-4 col-8">
            <InputSearch
              placeholder="search here..."
              className="w-100"
              onChange={handleChangeWithLib}
              value={searchText}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-3 d-flex align-items-center justify-content-center pe-0">
        <Badge
          className="rounded-50"
          variant="primary"
          size="xl"
          icon={ArrowLeft}
          title="Previous Page"
          disabled={!pagination?.prevPageUrl}
          onClick={() => handlePagination("prev")}
        />
        <Badge
          className="rounded-50 ms-2"
          variant="primary"
          size="xl"
          icon={ArrowRight}
          title="Next Page"
          disabled={!pagination?.nextPageUrl}
          onClick={() => handlePagination("next")}
        />
        <Badge
          className="rounded-50 ms-2"
          variant="primary"
          size="xl"
          icon={downloadsvg}
          title="Export"
          onClick={() =>
            json2xlsDownload(
              userData?.map((e) => ({ ...e, roles: e.roles[0]?.roleName })),
              {
                staffId: "Staff Id",
                firstName: "First Name",
                middleName: "Middle Name",
                lastName: "Last Name",
                roles: "Role Name",
                email: "User Name",
                companyCode: "Company",
              },
              "Procurement_Dashboard_Users"
            )
          }
        />
      </div>
    </>
  );
  const Table = (
    <>
      <div className="d-flex align-items-center">
        <div className="col-5">
          <span className="h4 text-grey1">Users</span>
        </div>
        <div className="col-7 p-0">
          <div className="d-flex justify-content-end">
            <Button
              className="rounded h6-web w-50"
              size="md"
              title={"Add User"}
              variant="primary"
              onClick={() => getUserModal("Add User", null)}
            />
            {TableControls}
          </div>
        </div>
      </div>

      <div className=" mt-2 mb-2 table-responsive ">
        <table className="table table-bordered shadow-sm align-middle ">
          <thead className="bg-grey h7-table text-grey1">
            <tr className="text-center text-nowrap">
              <td>Staff Id</td>
              <td>First Name</td>
              <td>Middle Name</td>
              <td>Last Name</td>
              <td>Role Name</td>
              <td>User Name</td>
              <td>Company</td>
              <td>Actions</td>
            </tr>
          </thead>
          <tbody className="h8 text-table-body">
            {rowItems.map((row, rowIndex) => (
              <tr className="text-center text-nowrap" key={"row_" + rowIndex}>
                <td>{row.staffId}</td>
                <td>{row.firstName}</td>
                <td>{row.middleName}</td>
                <td>{row.lastName}</td>
                <td>{row.roles[0]?.roleName}</td>
                <td>{row.email}</td>
                <td>{row.companyCode}</td>
                <td>
                  <div className="d-flex justify-content-center">
                    {isUpdate ? EditField(row) : null}
                    {isDelete ? DeleteField(row.userId) : null}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <PageDetails pagination={pagination} />
        {showModal && (
          <Modal
            open={showModal}
            title={modalTitle}
            onClose={() => setShowModal(false)}
            showCloseIcon={true}
            classNames={{
              modal: "userEditModal",
            }}
          >
            {ModalChildren}
          </Modal>
        )}
      </div>
    </>
  );

  return (
    <TRAXAccount breadCrumbTitle="Users" AddButtonShow={false}>
      {Table}
    </TRAXAccount>
  );
}
