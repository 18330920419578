import { errorToast } from "../../componentsNew/Toast/Toast";
import { ENDPOINTS, postApi } from "../../services/http";

export const getDashboardShippingData = async (
  request,
  setIsLoading,
  setPagingData,
  setDashboardData,
  setCurrentPage,
  setLastPage,
  handleExpiredToken
) => {
  setIsLoading(true);
  const response = await postApi(
    `${ENDPOINTS.GET_DASHBOARD_RECEIVING_DATA}`,
    request
  );
  if (response) {
    if (response.status === 200) {
      setIsLoading(false);
      setPagingData(response?.data);
      setDashboardData(response?.data?.dashBoardReceiving ?? []);
      setCurrentPage(response?.data?.currentPage);
      setLastPage(response?.data?.maxPages);
    } else if (response.status === 401) {
      setIsLoading(false);
      handleExpiredToken();
    } else {
      setIsLoading(false);
      if (response !== undefined) {
        errorToast(response.data.statusText);
      } else {
        errorToast("Some error occured.");
      }
    }
  }
  setIsLoading(false);
};
