import React, { useState, useEffect, useContext } from "react";

import moment from "moment";
import "./../../scss/trax.scss";
import {
  Badge,
  Input,
  InputSearch,
  Modal,
  Tabs,
} from "../../componentsNew/core";
import { useModal } from "../../hooks/useModal";
import { ModalContext } from "../../services/context/modal-context/State";
import EditIcon from "../../../assets/svg/NewIcons/edit.svg";
import TRAXAccount from "../TraxAccount/TRAXAccount";
import TRAXViewRequisition from "./TRAXViewRequisition";
import { getMechRequestDataApi, getRequestDataApi } from "./function";
import { UserContext } from "../../services/context/userContext/State";
import ArrowLeft from "../../../assets/svg/NewIcons/arrow_left_white.svg";
import ArrowRight from "../../../assets/svg/NewIcons/arrow_right_white.svg";

export default function TRAXRequisitionRequests(props) {
  var end = new Date();
  end.setHours(0, 0, 0, 0);
  var start = new Date();
  start.setHours(0, 0, 0, 0);
  start.setDate(start.getDate() - 90);
  const limit = 10;
  const { user, role } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const modalTitle = "Part Requisition";
  const userId = parseInt(user?.userId);
  const [openRequests, setOpenRequests] = useState(null);
  const [approvedRequests, setApprovedRequests] = useState(null);
  const [closedRequests, setClosedRequests] = useState(null);
  const { handleExpiredToken } = useModal();
  const { setIsLoading } = useContext(ModalContext);
  const [goto, setGoto] = useState(1);
  const [pageNo, setPageNo] = useState(0);
  const [search, setSearch] = useState("");
  const searchFilter = (partsData, search) => {
    return partsData?.filter(
      (e) =>
        (e.pn ?? "").toLowerCase().includes(search.toLowerCase()) ||
        (e.priority ?? "").toLowerCase().includes(search.toLowerCase()) ||
        (e.createdOn ?? "").toLowerCase().includes(search.toLowerCase()) ||
        (e.location ?? "").toLowerCase().includes(search.toLowerCase()) ||
        (selectedTab === 2 &&
          String(e.requisitionNo ?? "")
            .toLowerCase()
            .includes(search.toLowerCase()))
    );
  };
  const getRequestData = (type) =>
    getRequestDataApi(
      type,
      userId,
      setIsLoading,
      setOpenRequests,
      setApprovedRequests,
      setClosedRequests,
      handleExpiredToken
    );

  const getMechRequestData = () =>
    getMechRequestDataApi(setIsLoading, setOpenRequests, handleExpiredToken);

  const EditField = (row, type) => {
    return (
      <Badge
        className="rounded-3"
        size="xs"
        variant="white"
        icon={EditIcon}
        title="View details"
        onClick={() => showEdit(row, type)}
      />
    );
  };
  const showEdit = async (formData, type) => {
    setShowModal(true);
    setModalData(formData);
  };
  useEffect(() => {
    if (role === "Gen") {
      window.history.back();
    } else {
      loadData();
    }
  }, [role]);

  const loadData = () => {
    if (role) {
      if (role === "Prod") {
        getMechRequestData();
      } else {
        getRequestData("open");
        getRequestData("approved");
        getRequestData("closedorrejected");
      }
    }
  };
  const [selectedTab, setSelectedTab] = useState(0);
  const tabArray = ["Open", "Approved", "Closed / Rejected"];
  const MPtabArray = ["Open", "Approved", "Closed / Rejected"];

  const tab1 = (
    <>
      {/* <TRAXRequestTable type="open" data={openRequests}></TRAXRequestTable> */}

      <div className=" mt-2 mb-2 table-responsive ">
        <table className="table table-bordered shadow-sm align-middle ">
          <thead className="bg-grey h7-table text-grey1">
            <tr className="text-center text-nowrap">
              <td>No.</td>
              <td>Location</td>
              <td>Priority</td>
              <td>Description</td>
              <td>A/C</td>
              <td>W/O</td>
              <td>Taskcard</td>
              <td>PN</td>
              <td>Qty</td>
              <td>UOM</td>
              <td>Required Date</td>
              {role !== "Prod" && <td>Requested By</td>}
              <td>Requested On</td>
              <td>Action</td>
              {role === "Prod" && <td>Action By</td>}
              {role === "Prod" && <td>Actioned On</td>}
              {role === "Prod" && <td>Reason</td>}
              {role === "Prod" && <td>Requisition No</td>}
              {role !== "Prod" && <td>Actions</td>}
            </tr>
          </thead>
          <tbody className="h8 text-table-body">
            {openRequests && openRequests.length > 0 ? (
              searchFilter(openRequests, search)
                ?.slice(limit * pageNo, limit * (pageNo + 1))
                ?.map((row, rowIndex) => (
                  <tr
                    className="text-center text-nowrap"
                    key={"row_" + rowIndex}
                  >
                    <td>{row.requisitionNo}</td>
                    <td>{row.location}</td>
                    <td>{row.priority}</td>
                    <td>{row.description}</td>
                    <td>{row.ac}</td>
                    <td>{row.wo}</td>
                    <td>{row.taskCard}</td>
                    <td>{row.pn}</td>
                    <td>{row.qty}</td>
                    <td>{row.uom}</td>
                    <td>{moment(row.requiredDate).format("DD/MM/YYYY")}</td>
                    {role !== "Prod" && <td>{row.requestedBy}</td>}
                    <td>{moment(row.createdOn).format("DD/MM/YYYY")}</td>
                    <td>{row.action}</td>
                    {role === "Prod" && <td>{row.actionBy}</td>}
                    {role === "Prod" && (
                      <td>
                        {row.actionedOn &&
                          moment(row.actionedOn).format("DD/MM/YYYY")}
                      </td>
                    )}
                    {role === "Prod" && <td>{row.reason}</td>}
                    {role === "Prod" && <td>{row.traxRequisitionNo}</td>}
                    {role !== "Prod" && (
                      <td>
                        <div className="d-flex justify-content-center">
                          {EditField(row)}
                        </div>
                      </td>
                    )}
                  </tr>
                ))
            ) : (
              <tr className="text-center h6-web">No records found</tr>
            )}
          </tbody>
        </table>
        {/* <PageDetails pagination={pagination} /> */}
      </div>
      <br></br>
    </>
  );

  const tab2 = (
    <>
      {/* <TRAXRequestTable type="approved" data={approvedRequests}></TRAXRequestTable> */}

      <div className=" mt-2 mb-2 table-responsive ">
        <table className="table table-bordered shadow-sm align-middle ">
          <thead className="bg-grey h7-table text-grey1">
            <tr className="text-center text-nowrap">
              <td>No.</td>
              <td>Location</td>
              <td>Priority</td>
              <td>Description</td>
              <td>A/C</td>
              <td>W/O</td>
              <td>Taskcard</td>
              <td>PN</td>
              <td>Qty</td>
              <td>UOM</td>
              <td>Required Date</td>
              <td>Requested By</td>
              <td>Requested On</td>
              <td>ApprovedBy</td>
              <td>ApprovedOn</td>
              <td>Reason</td>
              <td>Actions</td>
            </tr>
          </thead>
          <tbody className="h8 text-table-body">
            {approvedRequests && approvedRequests.length > 0 ? (
              searchFilter(approvedRequests, search)
                ?.slice(limit * pageNo, limit * (pageNo + 1))
                ?.map((row, rowIndex) => (
                  <tr
                    className="text-center text-nowrap"
                    key={"row_" + rowIndex}
                  >
                    <td>{row.requisitionNo}</td>
                    <td>{row.location}</td>
                    <td>{row.priority}</td>
                    <td>{row.description}</td>
                    <td>{row.ac}</td>
                    <td>{row.wo}</td>
                    <td>{row.taskCard}</td>
                    <td>{row.pn}</td>
                    <td>{row.qty}</td>
                    <td>{row.uom}</td>
                    <td>{moment(row.requiredDate).format("DD/MM/YYYY")}</td>
                    <td>{row.requestedBy}</td>
                    <td>{moment(row.createdOn).format("DD/MM/YYYY")}</td>
                    <td>{row.actionBy}</td>
                    <td>{moment(row.actionedOn).format("DD/MM/YYYY")}</td>
                    <td>{row.reason}</td>
                    <td>
                      <div className="d-flex justify-content-center">
                        {EditField(row)}
                      </div>
                    </td>
                  </tr>
                ))
            ) : (
              <tr className="text-center h6-web">No records found</tr>
            )}
          </tbody>
        </table>
        {/* <PageDetails pagination={pagination} /> */}
      </div>
      <br></br>
    </>
  );

  const tab3 = (
    <>
      {/* <TRAXRequestTable type="closed" data={closedRequests}></TRAXRequestTable> */}
      <div className=" mt-2 mb-2 table-responsive ">
        <table className="table table-bordered shadow-sm align-middle ">
          <thead className="bg-grey h7-table text-grey1">
            <tr className="text-center text-nowrap">
              <td>Request No.</td>
              <td>Location</td>
              <td>Priority</td>
              <td>Description</td>
              <td>A/C</td>
              <td>W/O</td>
              <td>Taskcard</td>
              <td>PN</td>
              <td>Qty</td>
              <td>UOM</td>
              <td>Required Date</td>
              <td>Requested By</td>
              <td>Requested On</td>
              <td>Action</td>
              <td>ActionBy</td>
              <td>ActionedOn</td>
              <td>Reason</td>
              <td>Trax Requisition No</td>
            </tr>
          </thead>
          <tbody className="h8 text-table-body">
            {closedRequests && closedRequests.length > 0 ? (
              searchFilter(closedRequests, search)
                ?.slice(limit * pageNo, limit * (pageNo + 1))
                ?.map((row, rowIndex) => (
                  <tr
                    className="text-center text-nowrap"
                    key={"row_" + rowIndex}
                  >
                    <td>{row.requisitionNo}</td>
                    <td>{row.location}</td>
                    <td>{row.priority}</td>
                    <td>{row.description}</td>
                    <td>{row.ac}</td>
                    <td>{row.wo}</td>
                    <td>{row.taskCard}</td>
                    <td>{row.pn}</td>
                    <td>{row.qty}</td>
                    <td>{row.uom}</td>
                    <td>{moment(row.requiredDate).format("DD/MM/YYYY")}</td>
                    <td>{row.requestedBy}</td>
                    <td>{moment(row.createdOn).format("DD/MM/YYYY")}</td>
                    <td>{row.action}</td>
                    <td>{row.actionBy}</td>
                    <td>{moment(row.actionedOn).format("DD/MM/YYYY")}</td>
                    <td>{row.reason}</td>
                    <td>{row.traxRequisitionNo}</td>
                  </tr>
                ))
            ) : (
              <tr className="text-center h6-web">No records found</tr>
            )}
          </tbody>
        </table>
        {/* <PageDetails pagination={pagination} /> */}
      </div>
      <br></br>
    </>
  );
  const ModalChildren = (
    <TRAXViewRequisition
      setShowModal={setShowModal}
      title={modalTitle}
      setIsLoading={setIsLoading}
      formData={modalData}
      loadData={loadData}
    />
  );
  useEffect(() => {
    setPageNo(0);
    setGoto(1);
    setSearch("");
  }, [selectedTab]);

  const Table = (
    <>
      <div className="bg-white rounded ">
        {role === "Prod" ? (
          <></>
        ) : (
          <>
            <div className="d-flex align-items-center justify-content-between">
              <div className="pt-4">
                <Tabs
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  tabArray={role === "MP" ? MPtabArray : tabArray}
                  hideBar
                />
              </div>
              <InputSearch
                placeholder="Search"
                onChange={setSearch}
                value={search}
              />
            </div>
            <div
              style={{
                background: "#EBEAEA",
                width: "100%",
                height: "3px",
                marginTop: "-26px",
              }}
            ></div>
          </>
        )}
        <div className="table-responsive">
          {role === "Prod"
            ? tab1
            : role === "MP"
            ? {
                0: tab1,
                1: tab2,
                2: tab3,
              }[selectedTab]
            : {
                0: tab1,
                1: tab2,
                2: tab3,
              }[selectedTab]}
        </div>
      </div>
      {showModal && (
        <Modal
          open={showModal}
          title={modalTitle}
          onClose={() => setShowModal(false)}
          showCloseIcon={true}
          classNames={{
            modal: "userEditModal",
          }}
        >
          {ModalChildren}
        </Modal>
      )}
    </>
  );

  return (
    <TRAXAccount breadCrumbTitle="Requisition Requests" AddButtonShow={false}>
      {Table}
      <div className="d-flex justify-content-end align-items-center">
        {!!{
          0: openRequests,
          1: approvedRequests,
          2: closedRequests,
        }[selectedTab]?.length && (
          <p className="h8 text-grey1 mb-0 mr-2">
            Page {Number(pageNo + 1)} of{" "}
            {Math.ceil(
              (searchFilter(
                {
                  0: openRequests,
                  1: approvedRequests,
                  2: closedRequests,
                }[selectedTab],
                search
              )?.length ?? 0) / limit
            )}{" "}
            (Total Records:{" "}
            {searchFilter(
              {
                0: openRequests,
                1: approvedRequests,
                2: closedRequests,
              }[selectedTab],
              search
            )?.length ?? 0}
            )
          </p>
        )}
        <Input
          style={{ width: "50px" }}
          type="number"
          value={goto}
          onChange={(value) => {
            value >= 0 &&
              value <
                Math.ceil(
                  (searchFilter(
                    {
                      0: openRequests,
                      1: approvedRequests,
                      2: closedRequests,
                    }[selectedTab],
                    search
                  )?.length ?? 0) / limit
                ) +
                  1 &&
              setGoto(value);
          }}
        />
        <Badge
          className="rounded-50 ms-2 text-white"
          variant="primary"
          size="xl"
          title="Go"
          onClick={() => {
            setPageNo((Number(goto) || 1) - 1);
            setGoto(Number(goto) || 1);
          }}
        />
        <Badge
          className="rounded-50 ms-2"
          variant="primary"
          size="xl"
          icon={ArrowLeft}
          title="Previous Page"
          disabled={!pageNo}
          onClick={() => {
            setPageNo(pageNo - 1);
            setGoto(Number(goto - 1));
          }}
        />
        <Badge
          className="rounded-50 ms-2"
          variant="primary"
          size="xl"
          icon={ArrowRight}
          title="Next Page"
          disabled={
            pageNo + 1 ===
            Math.ceil(
              (searchFilter(
                {
                  0: openRequests,
                  1: approvedRequests,
                  2: closedRequests,
                }[selectedTab],
                search
              )?.length ?? 0) / limit
            )
          }
          onClick={() => {
            setPageNo(pageNo + 1);
            setGoto(Number(goto + 1));
          }}
        />
      </div>
    </TRAXAccount>
  );
}
