import React, { useEffect, useState } from 'react';
import "./ChangePassword.scss";
import endpoint from '../../utils/endpoints';
import API from '../../utils/Api';
import { errorToast, errorDialog, successDialog } from '../../components/Generic/Generic';
import Loader from '../../components/Loader/Loader';
import { clearLocal } from '../../utils/generic';

async function saveFormDetails(formData) {
    var data = null;
    await API.post(endpoint.ChangePassword, formData, (isSuccess, response) => {
        if (isSuccess) {
            data = response;
        } else {
            errorToast(response.statusText);
        }
    })
    return data;
}

const ChangePassword = (props) => {
    const [isBusy, setBusy] = useState(true);

    const [oldPasswordField, setOldPasswordField] = useState("");
    const [newPasswordField, setNewPasswordField] = useState("");
    const [confirmNewPasswordField, setConfirmNewPasswordField] = useState("");

    useEffect(() => {
        setBusy(false);
    }, [])

    const handleSubmit = async e => {
        
        e.preventDefault();
        var fields = [];
        fields = e.target;

        if (!checkPassword(fields["NewPassword"].value)) {
            errorToast('Password should be minimum 8 characters with at least one numeric digit, one uppercase and one lowercase character');
            return false;
        }

        if (fields["NewPassword"].value !== fields["ConfirmNewPassword"].value) {
            errorToast('New password and confirm password doesn\'t match');
            return false;
        }

        setBusy(true);
        var user = JSON.parse(localStorage.getItem("user"));
        var ProcessFields = {
            UserId: user["userId"],
            EntityKey: fields["OldPassword"].value,
            ChildEntityKey: fields["NewPassword"].value
        };

        const response = await saveFormDetails(ProcessFields);
        if (response.Success) {
            clearLocal();
            successDialog(response?.Message + ' You will be logged out of the system, please login again with the updated password.', handleOk);
        } else {
            errorDialog(response?.Message);            
        }
        setBusy(false);
    }

    const handleOk = () => {
        props.history.push("/auth/login");
    }

    function changeOldPasswordFieldValue(e) {
        setOldPasswordField(e.target.value);
    }

    function changeNewPasswordFieldValue(e) {
        setNewPasswordField(e.target.value);
    }

    function changeConfirmNewPasswordFieldValue(e) {
        setConfirmNewPasswordField(e.target.value);
    }

    function checkPassword(inputtxt) {
        var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
        if (!inputtxt.match(passw)) {
            return false;
        } else {
            return true;
        }
    }

    return (
        <div className="main-content">
            {isBusy && <Loader />}
            <div className="row">
                <div className="col-md-7">
                    <form onSubmit={handleSubmit}>                        
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Old Password</label>
                            <div className="col-sm-9">
                                <input type="password" required className="form-control" value={oldPasswordField} onChange={changeOldPasswordFieldValue} name="OldPassword" placeholder="Old Password" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">New Password</label>
                            <div className="col-sm-9">
                                <input type="password" required className="form-control" value={newPasswordField} onChange={changeNewPasswordFieldValue} name="NewPassword" placeholder="New Password" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Confirm Password</label>
                            <div className="col-sm-9">
                                <input type="password" required className="form-control" value={confirmNewPasswordField} onChange={changeConfirmNewPasswordFieldValue} name="ConfirmNewPassword" placeholder="Confirm Password" />
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword;