import React, { useEffect, useState } from "react";
import "./SignForm.scss";
import endpoint from "../../utils/endpoints";
import API from "../../utils/Api";
import { errorToast } from "../../components/Generic/Generic";
import Loader from "../../components/Loader/Loader";

async function sendOTPToEmail(emailId, formId, refType) {
  var data = null;
  var ProcessFields = {
    EntityKey: formId,
    ChildEntityKey: refType,
    UserEmail: emailId,
  };
  await API.post(endpoint.SendOTP, ProcessFields, (isSuccess, response) => {
    if (isSuccess) {
      data = response;
    } else {
      errorToast(response.statusText);
    }
  });
  return data;
}

async function saveFormDetails(formData) {
  var data = null;
  await API.post(endpoint.SubmitOTP, formData, (isSuccess, response) => {
    if (isSuccess) {
      data = response;
    } else {
      errorToast(response.statusText);
    }
  });
  return data;
}

const SignForm = (props) => {
  const formId = props.location.state["formId"];
  const [data, setData] = useState();
  const [headings, setHeadings] = useState();
  const [isBusy, setBusy] = useState(true);

  const [, setNumberFieldValue] = useState();

  const [pilotOTPSent, setPilotOTPSent] = useState(false);
  const [, setInstructorOTPSent] = useState(false);
  const [, setDirectorOTPSent] = useState(false);

  const [otpSubmitError, setOTPSubmitError] = useState(false);
  const items = [];

  const [pilotBarcode, setPilotBarcode] = useState("");
  const [instructorBarcode, setInstructorBarcode] = useState("");

  useEffect(() => {
    setBusy(true);
    getData();
  }, []);

  const getData = () => {
    API.get(endpoint.GetFormData + formId, (isSuccess, response) => {
      if (isSuccess) {
        setData(response);
        const t = [];
        const map = new Map();
        for (const item of response["Table1"]) {
          if (!map.has(item.HeaderCategory)) {
            map.set(item.HeaderCategory, true);
            t.push({
              name: item.HeaderCategory,
            });
          }
        }
        setHeadings(t);

        if (response["Table2"]) {
          let barCode = response["Table2"][0]["QRCode"];
          let barCodeR = response["Table2"][1]["QRCode"];
          setPilotBarcode(barCode);
          setInstructorBarcode(barCodeR);
        }

        setBusy(false);
      } else {
        errorToast(response?.statusText);
        setBusy(false);
      }
    });
  };

  const handleSubmit = async (e) => {
    setBusy(true);
    e.preventDefault();
    var fields = [];
    fields = e.target;

    var ProcessFields = {
      EntityKey: fields["otpNumber"].value,
    };

    const response = await saveFormDetails(ProcessFields);
    if (response.Success) {
      window.location.reload();
    } else {
      setOTPSubmitError(true);
    }
    setBusy(false);
  };

  function otpChange(e) {
    setNumberFieldValue(e.target.value);
  }

  async function sendOTP(e, f, g) {
    setBusy(true);
    await sendOTPToEmail(e, f, g);
    if (g === "pilot") setPilotOTPSent(true);
    else if (g === "instructor") setInstructorOTPSent(true);
    else setDirectorOTPSent(true);
    setBusy(false);
  }

  if (headings) {
    for (const [index, value] of headings.entries()) {
      var c = [];
      c = data["Table1"].filter((s) => s.HeaderCategory === value.name);
      var html = (
        <div className="col-md-6" key={index}>
          <table className="formTable">
            <tbody>
              <tr>
                <th colSpan="6">{value.name}</th>
              </tr>
              {c.map((item, key) => {
                return (
                  <tr key={key}>
                    <td>{item.Questionarie}</td>
                    <td width="30" align="center">
                      {item.Score === 1 ? (
                        <div className="circle-number">1</div>
                      ) : (
                        ""
                      )}
                    </td>
                    <td width="30" align="center">
                      {item.Score === 2 ? (
                        <div className="circle-number">2</div>
                      ) : (
                        ""
                      )}
                    </td>
                    <td width="30" align="center">
                      {item.Score === 3 ? (
                        <div className="circle-number">3</div>
                      ) : (
                        ""
                      )}
                    </td>
                    <td width="30" align="center">
                      {item.Score === 4 ? (
                        <div className="circle-number">4</div>
                      ) : (
                        ""
                      )}
                    </td>
                    <td width="30" align="center">
                      {item.Score === 5 ? (
                        <div className="circle-number">5</div>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );

      items.push(html);
    }
  }

  return !data ? (
    <div />
  ) : (
    <div className="main-content">
      {isBusy && <Loader />}
      <div className="row">
        <div className="col-md-12">
          <table className="formTable">
            <tbody>
              <tr>
                <th style={{ textAlign: "center" }} colSpan="4">
                  RECURRENT TRAINING
                </th>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }} colSpan="4">
                  TYPE OF TRAINING : {data["Table"][0]["TrainingType"]}
                </td>
                {/*<td></td>*/}
              </tr>
              <tr>
                <td colSpan="4">
                  <div className="row">
                    <div className="col-md-12">
                      <table className="formTable">
                        <tbody>
                          <tr>
                            <td>Date</td>
                            <td>{data["Table"][0]["TrainingDateStr"]}</td>
                            <td>Airlines / Other</td>
                            <td>
                              {data["Table"][0]["Instructor"] === "JT"
                                ? "Malindo Air"
                                : "Lion Air"}
                            </td>
                          </tr>
                          <tr>
                            <td>Aircraft Type</td>
                            <td>{data["Table"][0]["AircraftType"]}</td>
                            <td>ID Number</td>
                            <td>{data["Table"][0]["IDNumber"]}</td>
                          </tr>
                          <tr>
                            <td>Training Device</td>
                            <td>{data["Table"][0]["TrainingDevice"]}</td>
                            <td>Pilot Name</td>
                            <td>{data["Table"][0]["PilotName"]}</td>
                          </tr>
                          <tr>
                            <td>Sector Flown / Airport From</td>
                            <td>
                              {data["Table"][0]["Sector_Flown_Airport_From"]}
                            </td>
                            <td>Ranking</td>
                            <td>{data["Table"][0]["Ranking"]}</td>
                          </tr>
                          <tr>
                            <td>Sector Flown / Airport From</td>
                            <td>
                              {data["Table"][0]["Sector_Flown_Airport_To"]}
                            </td>
                            <td>Other Pilot Id Number</td>
                            <td>{data["Table"][0]["OtherPilotIdNumber"]}</td>
                          </tr>
                          <tr>
                            <td width="30%">Instructor/Checker Name</td>
                            <td>{data["Table"][0]["Instructor"]}</td>
                            <td>Other Pilot Name</td>
                            <td>{data["Table"][0]["OtherPilotName"]}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th style={{ textAlign: "center" }} colSpan="4">
                  GRADING
                </th>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }} colSpan="4">
                  1. UnSatisfactory/Unsafe
                  Situation&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.
                  Marginal/Min.Acceptable&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.
                  Satisfactory/Adequate&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.
                  Good/Effective&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.
                  Very Good/Exemplary Manner
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">{items}</div>
      <div className="row">
        <div className="col-md-12">
          <table className="formTable">
            <tbody>
              <tr>
                <td colSpan="4">
                  {" "}
                  <div>
                    <div>
                      <p style={{ padding: "10px 0px 20px 0px" }}>
                        Comments/Debriefed Items: {data["Table"][0]["Remarks"]}
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Overall Performance</td>
                <td>{data["Table"][0]["OVERALLPERFORMANCE"]}</td>
                {data["Table"][0]["FORTRAINING"] && (
                  <>
                    <td>For Training</td>
                    <td>{data["Table"][0]["FORTRAINING"]}</td>
                  </>
                )}
                {data["Table"][0]["FORCHECKING"] && (
                  <>
                    <td>For Checking</td>
                    <td>{data["Table"][0]["FORCHECKING"]}</td>
                  </>
                )}
              </tr>
              <tr>
                <td width="30%">Instructor Recommendation</td>
                <td>{data["Table"][0]["InstructorRecommendation"]}</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <span style={{ color: "red" }}>
                    <b>Doc No.</b>
                  </span>
                  &emsp;{data["Table"][0]["DOCNo"]}
                </td>
                <td></td>
                <td>
                  <span style={{ color: "red" }}>
                    <b>License No.</b>
                  </span>
                  &emsp;{data["Table"][0]["LicenseNo"]}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span style={{ color: "red" }}>
                    <b>LOA No.</b>
                  </span>
                  &emsp;{data["Table"][0]["LOANo"]}
                  <br />
                  <span style={{ color: "red" }}>
                    <b>OTR No.</b>
                  </span>
                  &emsp;{data["Table"][0]["OTRNumber"]}
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <table className="formTable">
            <tbody>
              <tr>
                <th>Pilot Signature</th>
                <th>Instructor</th>
              </tr>
              <tr>
                <td align="center" width="33.3%">
                  {!data["Table"][0]["IsPilotSigned"] ? (
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() =>
                        sendOTP(data["Table"][0]["PilotEmail"], formId, "pilot")
                      }
                    >
                      Send OTP
                    </button>
                  ) : (
                    <img src={pilotBarcode} width="100" alt="" />
                  )}
                  {pilotOTPSent ? (
                    <div>OTP sent to {data["Table"][0]["PilotEmail"]}</div>
                  ) : (
                    ""
                  )}
                </td>
                <td align="center" width="33.3%">
                  {data["Table"][0]["IsPilotSigned"] ? (
                    <img src={instructorBarcode} width="100" alt="" />
                  ) : (
                    <span></span>
                  )}
                </td>
                {pilotOTPSent ? (
                  <div>OTP sent to {data["Table"][0]["PilotEmail"]}</div>
                ) : (
                  ""
                )}
              </tr>
              {data["Table"][0]["IsPilotSigned"] ? (
                <div />
              ) : (
                <tr>
                  <td align="center" colSpan="3">
                    <form onSubmit={handleSubmit}>
                      <div
                        style={{ textAlign: "center" }}
                        className="input-group col-md-3"
                      >
                        <input
                          placeholder="Enter OTP"
                          style={{ maxWidth: "300px" }}
                          type="text"
                          className="form-control form-control-sm"
                          name="otpNumber"
                          required
                          onChange={otpChange}
                        />
                        <div className="input-group-append">
                          &nbsp;
                          <button
                            className="btn btn-outline-primary btn-sm"
                            type="submit"
                          >
                            Submit OTP
                          </button>
                          {otpSubmitError ? (
                            <span style={{ color: "red" }}>
                              Some error occured. Please try again!
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </form>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SignForm;
