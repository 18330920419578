import React from "react";
import { useHistory } from "react-router-dom";
import "./Input.scss";

export const Input = React.forwardRef((props, ref) => {
  let history = useHistory();
  let {
    label,
    labelColor,
    borderBottom,
    textColour,
    forgotPassword,
    placeholder,
    className,
    onChange,
    onClick,
    type,
    maxDate,
    minDate,
    value = "",
    isError = false,
    errorMessage,
    maxLength = "",
    readOnly = false,
    disabled = false,
    Shadow = false,
    ...rest
  } = props;

  return (
    <div className={`remove-input-focus d-flex flex-column  ${className} `}>
      <div className="d-flex justify-content-between">
        {label && (
          <label className={`text-${labelColor} h7-web `}>{label}</label>
        )}
        {forgotPassword && (
          <span
            onClick={() => history.push("/forgot-password")}
            className="cursor-pointer text-danger label-input text-decoration-underline"
          >
            Forgot Password?
          </span>
        )}
      </div>
      <input
        maxLength={maxLength}
        value={value}
   
        type={type}
        onChange={(e) => {
          type == "checkbox" || type == "radio"
          ? onChange(e)
          : onChange(e.target.value);
          }}
        readOnly={readOnly}
        onClick={onClick}
        checked={value}
        max={maxDate}
        min={minDate}
        disabled={disabled}
        {...rest}
        ref={ref}
        placeholder={placeholder}
        className={`text-${textColour} p-1 h7-web  input-height ${
          borderBottom
            ? "bg-transparent border-0 border-white border-bottom"
            : Shadow
            ? "input-Shadowbox rounded"
            : "rounded border border-1 default-input"
        }`}
      />
      {isError && (
        <span className="text-danger label-input mt-1">{errorMessage}</span>
      )}
    </div>
  );
});
