import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { useLocation } from "react-router-dom";
import TopHeader from "./app/componentsNew/Header/TopHeader";
import BottomHeader from "./app/componentsNew/Header/BottomHeader";
import Footer from "./app/componentsNew/Footer/Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ModalContext } from "./app/services/context/modal-context/State";
import { Modal } from "./app/componentsNew/core";
import Oval from "react-loader-spinner";
import SessionContainer from "./app/componentsNew/common/Session_Container";
import "./app/scss/Global.scss";

function Template(props) {
  let { isActive, handleLoginValue } = props;
  let { footer, topHeader, bottomHeader, noHeaderColour } = isActive;
  const { modalInstances, setModalInstances, isLoading } =
    useContext(ModalContext);
  const location = useLocation();
  const pathName = location.pathname;
  let loginPage = pathName.includes("/login");

  const Loader = (
    <>
      <div className="row align-items-center bg-transparent ">
        <div className="col-4 bg-transparent ">
          <Oval
            type="Oval"
            color="#EC2227"
            secondaryColor="white"
            ariaLabel="loading"
            height={30}
            width={30}
          />
        </div>
        <div className="col-6">
          {" "}
          <p className="mb-0 text-white">Loading...</p>
        </div>
      </div>
    </>
  );

  return (
    <div>
      {topHeader && (
        <TopHeader
          noHeaderColour={noHeaderColour}
          handleLoginValue={(data) => handleLoginValue(data)}
        />
      )}

      {bottomHeader && (
        <BottomHeader
          noHeaderColour={noHeaderColour}
          handleLoginValue={(data) => handleLoginValue(data)}
        />
      )}

      {ReactDOM.createPortal(
        <ToastContainer />,
        document.getElementById("toast-container")
      )}

      {!loginPage &&
        modalInstances?.map((modalInstance, index) => (
          <Modal
            key={"modal_" + index}
            modalInstance={modalInstance}
            setModalInstances={setModalInstances}
          />
        ))}

      {isLoading && (
        <Modal
          classNames={{
            modal: "loaderModal",
          }}
          open={isLoading}
          children={Loader}
        />
      )}
      {props.children}

      {!loginPage && <SessionContainer {...props} />}
      {footer && <Footer />}
    </div>
  );
}

export default Template;
