import React, { useState, useEffect } from "react";
import HeaderTitle from "../../components/HeaderTitle/HeaderTitle";
import "./PilotProfile.scss";
import endpoint from "../../utils/endpoints";
import { getFromLocal } from "../../utils/generic";
import API from "../../utils/Api";
import { errorToast } from "../../components/Generic/Generic";
import "antd/dist/antd.css";
import Loader from "../../components/Loader/Loader";
import { Tabs } from "antd";
import moment from "moment";

const { TabPane } = Tabs;
const PilotProfile = (props) => {
  const [isBusy, setBusy] = useState(true);

  const [pilotDetails, setPilotDetails] = useState([]);
  const [pilotRanks, setPilotRanks] = useState([]);
  const [pilotBases, setPilotBases] = useState([]);
  const [pilotFleets, setPilotFleets] = useState([]);
  const [pilotDocuments, setPilotDocuments] = useState([]);
  const [pilotQualifications, setPilotQualifications] = useState([]);
  const [, setPilotTrainings] = useState([]);

  const getPilotData = (pilotId) => {
    var user = getFromLocal("user");
    var userId = parseInt(user?.userId);

    var url = `${endpoint.GetPilotData}/${userId}`;
    var headers = {
      UserId: userId,
    };
    API.getWithHeader(url, headers, (isSuccess, response) => {
      if (isSuccess) {
        setPilotDetails(response.table[0]);

        let ranks = response.table1;
        var ranksHTML = [];
        if (ranks.length > 0) {
          for (var i = 0; i < ranks.length; i++) {
            ranksHTML.push(
              <tr>
                <td>{ranks[i].rankCode}</td>
                <td>
                  {ranks[i].effectiveDate
                    ? moment(ranks[i].effectiveDate).format("DD/MM/YYYY")
                    : ""}
                </td>
                <td>
                  {ranks[i].expiryDate
                    ? moment(ranks[i].expiryDate).format("DD/MM/YYYY")
                    : ""}
                </td>
              </tr>
            );
          }
        } else {
          ranksHTML.push(
            <tr>
              <td colSpan="3">No data found</td>
            </tr>
          );
        }
        setPilotRanks(ranksHTML);

        let bases = response.table2;
        var basesHTML = [];
        if (bases.length > 0) {
          for (let i = 0; i < bases.length; i++) {
            basesHTML.push(
              <tr>
                <td>{bases[i].code}</td>
                <td>
                  {bases[i].effectiveDate
                    ? moment(bases[i].effectiveDate).format("DD/MM/YYYY")
                    : ""}
                </td>
                <td>
                  {bases[i].expiryDate
                    ? moment(bases[i].expiryDate).format("DD/MM/YYYY")
                    : ""}
                </td>
              </tr>
            );
          }
        } else {
          basesHTML.push(
            <tr>
              <td colSpan="3">No data found</td>
            </tr>
          );
        }
        setPilotBases(basesHTML);

        let fleets = response.table3;
        var fleetsHTML = [];
        if (fleets.length > 0) {
          for (let i = 0; i < fleets.length; i++) {
            fleetsHTML.push(
              <tr>
                <td>{fleets[i].fleetCode}</td>
                <td>{fleets[i].fleetCompanyCode}</td>
                <td>
                  {fleets[i].effectiveDate
                    ? moment(fleets[i].effectiveDate).format("DD/MM/YYYY")
                    : ""}
                </td>
                <td>
                  {fleets[i].expiryDate
                    ? moment(fleets[i].expiryDate).format("DD/MM/YYYY")
                    : ""}
                </td>
              </tr>
            );
          }
        } else {
          fleetsHTML.push(
            <tr>
              <td colSpan="4">No data found</td>
            </tr>
          );
        }
        setPilotFleets(fleetsHTML);

        let documents = response.table4;
        var documentsHTML = [];
        if (documents.length > 0) {
          for (let i = 0; i < documents.length; i++) {
            documentsHTML.push(
              <tr>
                <td>{documents[i].documentNumber}</td>
                <td>{documents[i].documentType}</td>
                <td>
                  {documents[i].effectiveDate
                    ? moment(documents[i].effectiveDate).format("DD/MM/YYYY")
                    : ""}
                </td>
                <td>
                  {documents[i].expiryDate
                    ? moment(documents[i].expiryDate).format("DD/MM/YYYY")
                    : ""}
                </td>
                <td>{documents[i].issuePlace}</td>
                <td>{documents[i].issuingAuthority}</td>
                <td>{documents[i].IssueCountryCode}</td>
              </tr>
            );
          }
        } else {
          documentsHTML.push(
            <tr>
              <td colSpan="7">No data found</td>
            </tr>
          );
        }
        setPilotDocuments(documentsHTML);

        let qualifications = response.Table5;
        var qualificationsHTML = [];
        if (qualifications.length > 0) {
          for (let i = 0; i < qualifications.length; i++) {
            qualificationsHTML.push(
              <tr>
                <td>{qualifications[i].name}</td>
                <td>{qualifications[i].crewtype}</td>
                <td>
                  {qualifications[i].issueDate
                    ? moment(qualifications[i].issueDate).format("DD/MM/YYYY")
                    : ""}
                </td>
                <td>
                  {qualifications[i].expiryDate
                    ? moment(qualifications[i].expiryDate).format("DD/MM/YYYY")
                    : ""}
                </td>
                <td>
                  {qualifications[i].projectionDate
                    ? moment(qualifications[i].projectionDate).format(
                        "DD/MM/YYYY"
                      )
                    : ""}
                </td>
                <td>
                  {qualifications[i].renewalDate
                    ? moment(qualifications[i].renewalDate).format("DD/MM/YYYY")
                    : ""}
                </td>
              </tr>
            );
          }
        } else {
          qualificationsHTML.push(
            <tr>
              <td colSpan="6">No data found</td>
            </tr>
          );
        }
        setPilotQualifications(qualificationsHTML);

        let trainings = response.table6;
        var trainingsHTML = [];
        if (trainings.length > 0) {
          for (let i = 0; i < trainings.length; i++) {
            trainingsHTML.push(
              <tr>
                <td style={{ display: "none" }}>{trainings[i].Key}</td>
                <td>{trainings[i].TrainingType}</td>
                <td>{trainings[i].AircraftType}</td>
                <td>{trainings[i].TrainingDevice}</td>
                <td>{trainings[i].Airlines}</td>
                <td>{trainings[i].Trainer}</td>
                <td>{trainings[i].TrainingDate}</td>
                <td>{trainings[i].TrainingCategory}</td>
                <td>{trainings[i].PilotSigned}</td>
              </tr>
            );
          }
        } else {
          trainingsHTML.push(
            <tr>
              <td colSpan="9">No data found</td>
            </tr>
          );
        }
        setPilotTrainings(trainingsHTML);

        setBusy(false);
      } else {
        if (response != null) {
          errorToast(response?.statusText);
        }
        setBusy(false);
      }
    });
  };

  useEffect(() => {
    setBusy(true);
    getPilotData();
  }, []);

  function onTabChange(key) {}

  return isBusy ? (
    <Loader />
  ) : (
    <div className="main-content">
      <HeaderTitle title="Pilot Profile" />
      <Tabs defaultActiveKey="1" onChange={onTabChange}>
        <TabPane tab="Details" key="1">
          <table className="table table-striped table-hover table-sm">
            <tbody>
              <tr>
                <td>Sabre Id:</td>
                <td>{pilotDetails?.Sabre_Id}</td>
                <td>Name:</td>
                <td>{pilotDetails?.Pilot_Name}</td>
              </tr>
              <tr>
                <td>Email:</td>
                <td>{pilotDetails?.Email}</td>
                <td>DOB:</td>
                <td>{pilotDetails?.Birthdate}</td>
              </tr>
              <tr>
                <td>Address:</td>
                <td>{pilotDetails?.Address1}</td>
                <td>City:</td>
                <td>{pilotDetails?.City}</td>
              </tr>
              <tr>
                <td>Province:</td>
                <td>{pilotDetails?.Province}</td>
                <td>Country Code:</td>
                <td>{pilotDetails?.Country}</td>
              </tr>
              <tr>
                <td>Employment Date:</td>
                <td>{pilotDetails?.EmploymentDate}</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </TabPane>
        <TabPane tab="Ranks" key="2">
          <table className="table table-striped table-hover table-sm table-bordered">
            <thead>
              <tr>
                <th>Rank Code</th>
                <th>Start Date</th>
                <th>End Date</th>
              </tr>
            </thead>
            <tbody>{pilotRanks}</tbody>
          </table>
        </TabPane>
        <TabPane tab="Bases" key="3">
          <table className="table table-striped table-hover table-sm table-bordered">
            <thead>
              <tr>
                <th>Code</th>
                <th>Start Date</th>
                <th>End Date</th>
              </tr>
            </thead>
            <tbody>{pilotBases}</tbody>
          </table>
        </TabPane>
        <TabPane tab="Fleets" key="4">
          <table className="table table-striped table-hover table-sm table-bordered">
            <thead>
              <tr>
                <th>Fleet Code</th>
                <th>Fleet Company Code</th>
                <th>Start Date</th>
                <th>End Date</th>
              </tr>
            </thead>
            <tbody>{pilotFleets}</tbody>
          </table>
        </TabPane>
        <TabPane tab="Documents" key="5">
          <table className="table table-striped table-hover table-sm table-bordered">
            <thead>
              <tr>
                <th>Doc. Number</th>
                <th>Doc. Type</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Issue Place</th>
                <th>Issuing Authority</th>
                <th>Issuing Country Code</th>
              </tr>
            </thead>
            <tbody>{pilotDocuments}</tbody>
          </table>
        </TabPane>
        <TabPane tab="Qualifications" key="6">
          <table className="table table-striped table-hover table-sm table-bordered">
            <thead>
              <tr>
                <th>Name</th>
                <th>Crew Type</th>
                <th>Issue Date</th>
                <th>Expiry Date</th>
                <th>Projection Date</th>
                <th>Renewal Date</th>
              </tr>
            </thead>
            <tbody>{pilotQualifications}</tbody>
          </table>
        </TabPane>
        <TabPane tab="Safety" key="7"></TabPane>
      </Tabs>
    </div>
  );
};

export default PilotProfile;
