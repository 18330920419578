import HeaderTitle from "../../componentsNew/core/sp-title/HeaderTitle";
import React, { useState, useEffect, useContext } from "react";

import { errorToast } from "../../componentsNew/Toast/Toast";
import { ENDPOINTS, postApi } from "../../services/http";
import { getFromLocal } from "../../services/storage/LocalStorage";
import { PageDetails } from "../../componentsNew/page-details/PageDetails";
import { usePagination } from "../../hooks/usePagination";
import ArrowLeft from "../../../assets/svg/NewIcons/arrow_left_white.svg";
import ArrowRight from "../../../assets/svg/NewIcons/arrow_right_white.svg";
import { Badge, InputSearch, Dropdown } from "../../componentsNew/core";
import ConstructObject from "../../hooks/helper";
import { useModal } from "../../hooks/useModal";
import { ModalContext } from "../../services/context/modal-context/State";

import moment from "moment";
import "./LicenseExp.scss";
import SortValue from "../../helper/sort";

function LicensesExpiring(props) {
  const [sort, setSort] = useState("");
  const [tableSortKey, setTableSortKey] = useState("");
  const [records, getRecords] = useState([]);
  const [finalRow, setFinalRow] = useState(records);
  const [docType, setDocType] = useState("");
  const [days, setDays] = useState();
  const { pagination, rowItems, handlePagination } = usePagination(finalRow);
  const { handleExpiredToken } = useModal();
  const { setIsLoading } = useContext(ModalContext);

  const filterLocationOptions = [
    {
      label: "Expired",
      value: "0",
    },
    {
      label: "15 days",
      value: "15",
    },
    {
      label: "30 days",
      value: "30",
    },
    {
      label: "60 days",
      value: "60",
    },
    {
      label: "90 days",
      value: "90",
    },
  ];

  const getLicensesExpiring = async (expiryDays, docType) => {
    setIsLoading(true);
    let user = getFromLocal("user");
    let userId = parseInt(user?.userId);
    let request = {
      companyCode: user?.companyCode,
      documentType: docType,
      userId: userId,
      noofDaysInExpiry: parseInt(expiryDays),
    };
    const response = await postApi(`${ENDPOINTS.LICENSE_DOCUMENT}`, request);
    if (response) {
      if (response.status == 200) {
        getRecords(response?.data);
        setIsLoading(false);
      } else if (response.status == 401) {
        setIsLoading(false);
        handleExpiredToken();
      } else {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    let expiry = props.expiryDays;
    let documentType = props.documentType;

    if ((expiry || expiry === 0) && documentType) {
      setDocType(documentType);
      setDays(parseInt(expiry));
      getLicensesExpiring(expiry, documentType);
    } else {
      errorToast("Error loading documents");
    }
  }, []);

  useEffect(() => {
    if (records) {
      setFinalRow(records);
    }
  }, [records]);

  useEffect(() => {
    handlePagination();
  }, [finalRow]);

  const SortBy = (key) => {
    if (tableSortKey == key) {
      if (sort == "") {
        setSort("asc");
        let val = finalRow.sort(SortValue(key));
        setFinalRow(val);
        handlePagination();
      } else if (sort == "asc") {
        setSort("desc");
        let val = finalRow.sort(SortValue(`-${key}`));
        setFinalRow(val);
        handlePagination();
      } else if (sort == "desc") {
        setSort("asc");
        let val = finalRow.sort(SortValue(key));
        setFinalRow(val);
        handlePagination();
      } else {
        setSort("");
      }
    } else {
      setTableSortKey(key);
      setSort("asc");
      let val = finalRow.sort(SortValue(key));
      setFinalRow(val);
      handlePagination();
    }
  };

  const handleChangeWithLib = (value) => {
    let newRows = [];
    if (value) {
      let searchTxt = value.toString().trim().toLowerCase();
      newRows = records?.filter((value) => {
        return (
          (value.title &&
            value.title.toString().toLowerCase().includes(searchTxt)) ||
          (value.trainingType &&
            value.trainingType.toLowerCase().includes(searchTxt))
        );
      });
    } else {
      newRows = [...records];
    }
    setFinalRow(newRows);
  };

  const TableControls = (
    <div className="d-flex col-md-4 justify-content-end">
      <div className="flex-wrap d-flex align-items-center justify-content-center">
        <div className="">
          <div className="col-md-12 col-sm-4 col-8">
            <InputSearch
              placeholder="search here..."
              className="w-100"
              onChange={handleChangeWithLib}
              //   value={searchText}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-3 d-flex align-items-center justify-content-center pe-0">
        <Badge
          className="rounded-50"
          variant="primary"
          size="xl"
          icon={ArrowLeft}
          title="Previous Page"
          disabled={!pagination?.prevPageUrl}
          onClick={() => handlePagination("prev")}
        />
        <Badge
          className="rounded-50 ms-2"
          variant="primary"
          size="xl"
          icon={ArrowRight}
          title="Next Page"
          disabled={!pagination?.nextPageUrl}
          onClick={() => handlePagination("next")}
        />
      </div>
    </div>
  );

  const handleExpiryChange = (event) => {
    var expiryDays = event;
    setDays(parseInt(expiryDays));
    getLicensesExpiring(parseInt(expiryDays), docType);
  };

  return (
    <div className="main-content">
      <div className="row justify-content-center align-items-center">
        <div className="col-md-6">
          <HeaderTitle title="Licenses Expiring" />
        </div>
        <div className="col-md-2 p-0">
          <Dropdown
            selectOptions={filterLocationOptions}
            Shadow
            value={ConstructObject(filterLocationOptions, days)}
            onChange={handleExpiryChange}
            containerClassName={"w-100 p-0"}
          />
        </div>
        {TableControls}
      </div>

      <br />
      <div className="row">
        <div className="col-md-12 license">
          <div className=" mt-2 table-responsive ">
            <table className="table table-bordered shadow-sm align-middle ">
              <thead className="bg-grey h7-table text-grey1">
                <tr className="text-center text-nowrap">
                  <td onClick={() => SortBy("documentNumber")}>
                    Document Number
                    <i
                      className={
                        tableSortKey == "documentNumber"
                          ? sort == "asc"
                            ? "fa fa-sort-asc"
                            : "fa fa-sort-desc"
                          : "fa fa-fw fa-sort"
                      }
                    ></i>
                  </td>
                  <td onClick={() => SortBy("documentType")}>
                    Document Type
                    <i
                      className={
                        tableSortKey == "documentType"
                          ? sort == "asc"
                            ? "fa fa-sort-asc"
                            : "fa fa-sort-desc"
                          : "fa fa-fw fa-sort"
                      }
                    ></i>
                  </td>
                  <td onClick={() => SortBy("firstName")}>
                    First Name
                    <i
                      className={
                        tableSortKey == "firstName"
                          ? sort == "asc"
                            ? "fa fa-sort-asc"
                            : "fa fa-sort-desc"
                          : "fa fa-fw fa-sort"
                      }
                    ></i>
                  </td>
                  <td onClick={() => SortBy("lastName")}>
                    Last Name
                    <i
                      className={
                        tableSortKey == "lastName"
                          ? sort == "asc"
                            ? "fa fa-sort-asc"
                            : "fa fa-sort-desc"
                          : "fa fa-fw fa-sort"
                      }
                    ></i>
                  </td>
                  <td onClick={() => SortBy("issueCountryCode")}>
                    Issue Country Code
                    <i
                      className={
                        tableSortKey == "issueCountryCode"
                          ? sort == "asc"
                            ? "fa fa-sort-asc"
                            : "fa fa-sort-desc"
                          : "fa fa-fw fa-sort"
                      }
                    ></i>
                  </td>
                  <td onClick={() => SortBy("effectiveDate")}>
                    Effective Date
                    <i
                      className={
                        tableSortKey == "effectiveDate"
                          ? sort == "asc"
                            ? "fa fa-sort-asc"
                            : "fa fa-sort-desc"
                          : "fa fa-fw fa-sort"
                      }
                    ></i>
                  </td>
                  <td onClick={() => SortBy("expiryDate")}>
                    Expiry Date
                    <i
                      className={
                        tableSortKey == "expiryDate"
                          ? sort == "asc"
                            ? "fa fa-sort-asc"
                            : "fa fa-sort-desc"
                          : "fa fa-fw fa-sort"
                      }
                    ></i>
                  </td>
                </tr>
              </thead>
              <tbody className="h8 text-table-body">
                {rowItems && rowItems.length > 0 ? (
                  rowItems.map((row, rowIndex) => (
                    <tr className="text-center" key={"row_" + rowIndex}>
                      <td>{row.documentNumber}</td>
                      <td>{row.documentType}</td>
                      <td>{row.firstName}</td>
                      <td>{row.lastName}</td>
                      <td>{row.issueCountryCode}</td>
                      <td>
                        {row.effectiveDate
                          ? moment(row.effectiveDate).format("DD/MM/YYYY")
                          : ""}
                      </td>
                      <td>
                        {row.expiryDate
                          ? moment(row.expiryDate).format("DD/MM/YYYY")
                          : ""}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="text-center h6-web">No records found</tr>
                )}
              </tbody>
            </table>
          </div>

          <PageDetails pagination={pagination} />
        </div>
      </div>
    </div>
  );
}

export default LicensesExpiring;
