import React from 'react';
import { Card } from 'antd';
import './DashboardCard.scss';

export default function DashboardCard(props) {

    return (
        <div>
            <Card className="card-style" title={props.title}>
                {props.children}
            </Card>
        </div>
    )
}
