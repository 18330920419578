import HeaderTitle from "../../../components/HeaderTitle/HeaderTitle";
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TableCell, TableRow } from "@material-ui/core";
import DataTable from '../../../components/DataTable/DataTable';
import moment from 'moment';
import "./TrainingRequestView.scss";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import API from '../../../utils/Api';
import { getFromLocal } from '../../../utils/generic';
import endpoint from '../../../utils/endpoints';
import { errorToast, successDialog } from '../../../components/Generic/Generic';
import Loader from "../../../components/Loader/Loader";
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import { Button } from "antd";
import Modal from "antd/lib/modal/Modal";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#33485D",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "white",
            borderTop: "1px solid #E5E5E5"
        },
    },
}))(TableRow);

const columns = [
    { title: "Qualification", showSort: false, id: "qualification" },
    { title: "Qualification Description", showSort: false, id: "qualificationDescription" },
    { title: "Title", showSort: false, id: "title" },
    { title: "Cut Off Date", showSort: false, id: "cutOffDate" },
    { title: "Max Attendees", showSort: false, id: "countOfAttendees" },
    { title: "Description", showSort: false, id: "description" },
    { title: "Location", showSort: false, id: "location" },
    { title: "Actions", showSort: false, id: "action" },
];

const pilotColumns = [
    { title: "Select", showSort: false },
    { title: "Name", showSort: false, id: "pilotName" },
];

const TrainingRequestView = (props) => {
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("Name");
    var [finalRow, setFinalRow] = useState([]);
    var [searchText, setSearchText] = useState("");
    //var [data, setData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [isBusy, setBusy] = useState(true);
    const [records, getRecords] = useState([]);
    const [usePayLoad, setUsePayLoad] = useState([]);
    const [qualifications, setQualifications] = useState([]);
    const [pilotSelection, setPilotSelection] = useState([]);
    var [pilotFinalRow, setPilotFinalRow] = useState([]);
    var [pilotSearchText, setPilotSearchText] = useState("");
    const [pilotRecords, getPilotRecords] = useState([]);

    const renderTableBody = (row, key) => {
        return (
            <StyledTableRow key={key}>
                <StyledTableCell>{row.qualification}</StyledTableCell>
                <StyledTableCell>{row.qualificationDescription}</StyledTableCell>
                <StyledTableCell>{row.title}</StyledTableCell>
                <StyledTableCell>{row.cutOffDate ? moment(row.cutOffDate).format("DD/MM/YYYY") : ""}</StyledTableCell>
                <StyledTableCell>{row.countOfAttendees}</StyledTableCell>
                <StyledTableCell>{row.description}</StyledTableCell>
                <StyledTableCell>{row.location}</StyledTableCell>
                <StyledTableCell style={{ width: "100px" }}>
                    <EditTwoTone style={{ fontSize: "17px", cursor: "pointer" }} onClick={() => handleClick("Edit", row.id)} title="Edit" />&nbsp;&nbsp;&nbsp;
                    <DeleteTwoTone style={{ fontSize: "17px", cursor: "pointer" }} onClick={() => handleClick("Delete", row.id)} title="Delete" />
                </StyledTableCell>
            </StyledTableRow>
        )
    }
    const renderPilotTableBody = (row, key) => {
        return (
            <StyledTableRow key={key}>
                <StyledTableCell>
                    <input name={`chkbox_${row.pilotId}`} key={`chkbox_${row.pilotId}`} id={`chkbox_${row.pilotId}`} type="checkbox" value={row.pilotId} defaultChecked={row.isAssigned} onChange={setChecked}
                    />
                </StyledTableCell>
                <StyledTableCell>{row.pilotName}</StyledTableCell>
            </StyledTableRow>
        )
    }

    useEffect(() => {
        if (records) {
            setFinalRow(records)
        }
    }, [records])


    const getPilots = (qualificationId, cutOffDate, assignedPilots) => {
        var user = getFromLocal("user");
        var userId = parseInt(user?.userId);

        var request = {
            companyCode: user?.companyCode,
            userId: userId,
            noofDaysInExpiry: 90,
            qualificationId: qualificationId,
            cutOffDate: cutOffDate
        }

        if (qualificationId === 0 || cutOffDate === "") {
            setPilotSelection([]);
            getPilotRecords([]);
        }
        else {
            API.post(endpoint.PilotQualifications, request, (isSuccess, response) => {
                if (isSuccess) {

                    if (assignedPilots) {
                        var assignedPilotIds = assignedPilots?.map(a => a.pilotId);
                        var temp = response;
                        temp.filter(x => assignedPilotIds.includes(x.pilotId)).map(function (x) {
                            x.isAssigned = true;
                            return x
                        });
                        setPilotSelection(temp);
                        getPilotRecords(temp);
                    }
                    else {
                        setPilotSelection(response);
                        getPilotRecords(response);
                    }
                } else {
                    errorToast("Error loading pilot list!");
                }
            })
        }

    }

    useEffect(() => {
        if (searchText) {
            var searchTxt = searchText.toString().trim().toLowerCase();
            var newRows = records?.filter((value) => {
                return (
                    value.title?.toString().toLowerCase().includes(searchTxt) ||
                    value.qualification?.toLowerCase().includes(searchTxt)
                );
            })
            setFinalRow(newRows);

        } else {
            setFinalRow(records)
        }
    }, [searchText, records])

    useEffect(() => {
        if (pilotSearchText) {
            var searchTxt = pilotSearchText.toString().trim().toLowerCase();
            var newRows = pilotRecords?.filter((value) => {
                return (
                    value.pilotName?.toString().toLowerCase().includes(searchTxt)
                );
            })
            setPilotFinalRow(newRows);

        } else {
            setPilotFinalRow(pilotRecords)
        }
    }, [pilotSearchText, pilotRecords])

    const getTrainingRequests = async () => {
        setBusy(true);

        var url = `${endpoint.GetTrainingsToReview}`;
        var user = getFromLocal("user");
        var userId = parseInt(user?.userId);
        var headers = {
            'UserId': userId
        }

        API.getWithHeader(url, headers, (isSuccess, response) => {
            if (isSuccess) {
                setBusy(false);
                getRecords(response);
            } else {
                setBusy(false);
                getRecords([]);
            }
        })
    }

    const getQualifications = () => {
        var user = getFromLocal("user");
        var url = `${endpoint.GetQualificationsData}/${user?.companyCode}`;

        API.get(url, (isSuccess, response) => {
            if (isSuccess) {
                setQualifications(response);

            } else {
                errorToast("Error loading qualifications!");
            }
        })
    }

    const handleOk = () => {

    }

    const handleClick = (status, id) => {
        if (status === "Delete") {
            setBusy(true);
            var user = getFromLocal("user");
            var userId = parseInt(user?.userId);
            var url = `${endpoint.RequestSchedule}/${id}/remove`;

            var headers = {
                'UserId': userId
            }

            API.deleteWithHeader(url, headers, {}, (isSuccess, response) => {
                if (isSuccess) {
                    getTrainingRequests();
                    successDialog(response?.message, handleOk);
                }
                else {
                    errorToast("Delete failed")
                }
            });
        }


        if (status === "Edit") {
            setShowModal(true);
            var trainingReq = records.filter(x => x.id === id)[0];
            setUsePayLoad(trainingReq);
            getPilots(trainingReq?.qualificationId, trainingReq?.endDate, trainingReq?.assignedPilots);
  
        }
    };

    const handleSave = () => {
        var user = getFromLocal("user");
        var userId = parseInt(user?.userId);
        var idsToUpdate = pilotSelection?.filter(x => x.isAssigned === true).map(a => a.pilotId);
        var request = { ...usePayLoad, userId: userId, cutOffDate: usePayLoad?.endDate, pilotIds: idsToUpdate };

        var url = `${endpoint.RequestSchedule}`;

        API.post(url, request, (isSuccess, response) => {
            if (isSuccess) {
                successDialog(response?.message, handleOk);
                setShowModal(false);
                getTrainingRequests();
            }
            else {
                errorToast("Request Update failed")
            }
        });
    };

    const pickerEditorProps = field => ({
        ampm: false,
        value: usePayLoad[field],
        onChange: date => handleChange(field, date ? date.toDate() : new Date(usePayLoad[field])),

        invalidDateMessage: "",
        invalidLabel: "",
        maxDateMessage: "",
        inputVariant: 'outlined',
        format: 'DD/MM/YYYY HH:mm',
        onError: () => null,
    });

    const handleChange = (type, value) => {
        var tempPayload = { ...usePayLoad };

        tempPayload[type] = value;
        setUsePayLoad(tempPayload);
        if (type === "endDate" || type === "qualificationId") {
            getPilots(tempPayload["qualificationId"], tempPayload["endDate"]);
        }
    }

    function setChecked(e) {
        var temp = pilotSelection;
        temp.filter(x => x.pilotId === parseInt(e.target.value))[0].isAssigned = e.target.checked;
        setPilotSelection(temp);

        var selectedPilots = pilotSelection?.filter(x => x.isAssigned === true).map(a => a.pilotId);
        handleChange("pilotIds", selectedPilots);
    }

    const handleCancel = () => {
        setUsePayLoad([]);
        setPilotSelection([]);
        setShowModal(false);
    }

    useEffect(() => {
        setBusy(true);
        getTrainingRequests();
        getQualifications();
    }, [])

    const renderEditRequestModal = () => {
        return (
            <Modal title="Training Request" visible={true} okText="Update" width={700} onCancel={handleCancel} footer={null}>

                <div className='row m-0'>
                    <div className='col-12 mb-3'>
                        <label className='col-3 pl-0 h6'>Qualification</label>
                        <select className='border col-9 pr-0 bg-white h6 rounded p-2' value={usePayLoad?.qualificationId} onChange={(e) => handleChange('qualificationId', parseInt(e.target.value))}>
                            <option value={0}>Select</option>
                            {qualifications?.map((qualification) => (
                                <option value={qualification.id}>
                                    {qualification.code} - {qualification.description}
                                </option>
                            ))}
                        </select>
                        {usePayLoad?.qualificationId === 0 &&
                            < span className='error'>Please select Qualification</span>
                        }
                    </div>

                    <div className='col-12 mb-3'>
                        <label className='col-3 pl-0 h6'>Title</label>
                        <input className='border col-9 pr-0 h6 rounded p-2' value={usePayLoad?.title} onChange={(e) => handleChange('title', e.target.value)} type='text' placeholder='Enter Training Description' />
                    </div>
                    {/* <div className='col-12 d-flex calender-date align-items-center mb-3'>
                        <label className='col-3  pl-0 h6'>Start Date</label>
                        <div className='col-9 p-0'>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDateTimePicker
                                    className='w-100 h6'
                                    {...pickerEditorProps('startDate')}
                                />

                            </MuiPickersUtilsProvider>
                        </div>
                    </div> */}
                    <div className='col-12 d-flex align-items-center calender-date  mb-3'>
                        <label className='col-3  pl-0 h6'>Cut Off Date</label>
                        <div className='col-9 p-0'>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDateTimePicker
                                    className='w-100 h6 '
                                    {...pickerEditorProps('endDate')}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    {pilotSelection?.length > 0 &&
                        <>
                            <div className='col-12 mb-0 d-flex'>
                                <label className='col-3 pl-0 h6'>Select Pilots</label>

                            </div>
                            <div className='col-12 mb-3 d-flex'>
                                <div className="col-md-12 pl-0 pr-0">
                                    <DataTable setSearchText={setPilotSearchText}
                                        columns={pilotColumns} rows={pilotFinalRow}
                                        order={order} orderBy={orderBy} setOrder={(e) => setOrder(e)} setOrderBy={(e) => setOrderBy(e)}
                                        renderTableBody={(row, key) => renderPilotTableBody(row, key)} />
                                </div>                                
                            </div>
                        </>
                    }
                    {/* <div className='col-12 mb-3 d-flex'>
                        {pilotSelection?.length > 0 &&
                            <>
                                <label className='col-3 pl-0 h6'>Select Pilots</label>
                                <div className='col-9 mb-3 high'>
                                    {pilotSelection?.map((row) => (
                                        <>
                                            <div className="pilot-row">
                                                <input name={`chkbox_${row.pilotId}`} key={`chkbox_${row.pilotId}`} id={`chkbox_${row.pilotId}`} type="checkbox" value={row.pilotId} defaultChecked={row.isAssigned} onChange={setChecked} />
                                                <label className='pl-0 h6'>{row.pilotName}</label>
                                            </div>
                                        </>

                                    ))}
                                </div>
                            </>
                        }
                    </div> */}

                    {/* <div className='col-12 mb-3'>
                        <label className='col-3 pl-0 h6'>Max Attendees</label>
                        <input className='border col-9 pr-0 rounded p-2' value={usePayLoad?.countOfAttendees} onChange={(e) => handleChange('countOfAttendees', parseInt(e.target.value))} type='number' placeholder='Enter Max Attendees' />
                    </div> */}
                    <div className='col-12 mb-3 d-flex'>
                        <label className='col-3 pl-0 h6'>Description</label>
                        <textarea className='border col-9 pr-0 rounded p-2' value={usePayLoad?.description} onChange={(e) => handleChange('description', e.target.value)} type='text' placeholder=' Enter Description' />
                    </div>
                    <div className='col-12 mb-3'>
                        <label className='col-3 pl-0 h6'>Location</label>
                        <select className='border col-9 pr-0 bg-white h6 rounded p-2' value={usePayLoad?.location}
                            onChange={(e) => handleChange('location', e.target.value)}>
                            <option value={""}>Select</option>
                            <option value={"Class A"}>Class A</option>
                            <option value={"Class B"}>Class B</option>
                            <option value={"Class C"}>Class C</option>
                            <option value={"Class D"}>Class D</option>
                        </select>
                    </div>
                    <div className='col-12 mb-3 text-right'>
                        <Button
                            variant="outlined"
                            color="primary"
                            className=""
                            onClick={() => {
                                handleSave();
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </div >
            </Modal >
        )
    }
    return (
        <>
            {isBusy && <Loader />}
            <div className={props?.showAll === false ? "" : "main-content"}>
                {props?.showAll !== false &&
                    <div className="row">
                        <div className="col-md-8"><HeaderTitle title="Training Requests" /></div>
                    </div>
                }
                <div className="row">
                    <div className="col-md-12">
                        <DataTable setSearchText={setSearchText} columns={columns} rows={props?.showAll === false ? finalRow?.slice(0, 3) : finalRow}
                            order={order} orderBy={orderBy} setOrder={(e) => setOrder(e)} setOrderBy={(e) => setOrderBy(e)}
                            renderTableBody={(row, key) => renderTableBody(row, key)} />
                    </div>
                </div>
            </div>
            {showModal && renderEditRequestModal()}
        </>
    )
}

export default TrainingRequestView;