import React from "react";
import "./InputSearch.scss";

export const InputSearch = (props) => {
  let { id, textColour, placeholder, value, className, onChange, type, style } =
    props;

  return (
    <div
      id={id}
      className={`remove-search-focus sp-input-search ${className}`}
      style={style}
    >
      <input
        type={type || "search"}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        value={value}
        className={`text-${textColour} p-2 h7-web rounded border border-1 default-search w-100 bg-white`}
      />
      {props.children}
    </div>
  );
};
