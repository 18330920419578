import React from "react";
// import moment from "moment";

export default function MechDashboard({
  dashboardData,
  tableSortKey,
  sort,
  SortBy,
}) {
  return (
    <table className="table table-bordered shadow-sm align-middle ">
      <thead className="bg-grey h7-table text-grey1">
        <tr className="text-center text-nowrap">
          <td className="has-pointer">RQ number</td>
          <td>RQ Line</td>
          <td>Order Number</td>
          <td className="has-pointer">Order Line</td>
          <td className="has-pointer">OrderType</td>
          <td className="has-pointer">PN</td>
          <td className="has-pointer">SN</td>
          <td>Batch</td>
          <td>Status</td>
          <td>Shipped From</td>
          <td>Shipped To</td>
          <td>Delivery Status</td>
        </tr>
      </thead>
      <tbody className="h8 text-table-body">
        {dashboardData?.map((row, rowIndex) => (
          <tr className="text-center text-nowrap" key={"row_" + rowIndex}>
            <td>{row.requistion}</td>
            <td>{row.requistionLine}</td>
            <td>{row.orderNumber}</td>
            <td>{row.orderLine}</td>
            <td>{row.orderType}</td>
            <td>{row.pn}</td>
            <td>{row.sn}</td>
            <td>{row.batch}</td>
            <td>{row.status}</td>
            <td>{row.shippedFrom}</td>
            <td>{row.shippedTo}</td>
            <td>{row.deliveryStatus}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
