import { notification, Modal } from "antd";
import "antd/dist/antd.css";
import './Generic.scss';
const { confirm } = Modal;

export const successToast = (msg) => {
  customNotification("success", msg);
}

export const errorToast = (msg) => {
  customNotification("error", msg);
}

export const infoToast = (msg) => {
  customNotification("info", msg);
}

export const warningToast = (msg) => {
  customNotification("warning", msg);
}

export const alertModal = (title, content, isModalVisible, handleOk, handleCancel) => {
  return (
    <Modal title={title} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
       closable={handleCancel || false} cancelButtonProps={{ style: { display: handleCancel || 'none' } }}>

      <p>{content}</p>

    </Modal>
  )
}



export const confirmAlert = (title, content, handleOk, handleCancel) => {
  confirm({
    title: title,
    content: content,
    onOk() {handleOk()},
    onCancel(){handleCancel()},
  });
}

export const infoDialog = (content, handleOk) => {
  Modal.info({
    title: content,
    onOk() {handleOk()},
  });
}

export const successDialog = (content, handleOk) => {
  Modal.success({
    title: content,
    onOk() {handleOk()},
  });
}

export const errorDialog = (content) => {
  Modal.error({
    title: content
  });
}

export const warningDialog = (content) => {
  Modal.warning({
    title: content
  });
}

var getNotificationStyle = (type) => {
  return {
    success: {
      color: "white",
      backgroundColor: "#07bc0c",
      width: "300px",
      borderRadius: "15px"
    },
    warning: {
      color: "white",
      backgroundColor: "#f1c40f",
      width: "300px",
      borderRadius: "15px"
    },
    error: {
      color: "white",
      backgroundColor: "#e74c3c",
      width: "300px",
      borderRadius: "15px"
    },
    info: {
      color: "white",
      backgroundColor: "#3498db",
      width: "300px",
      borderRadius: "15px"
    }
  }[type];
}

const customNotification = (type, msg) => {
  notification.open({
    description: msg,
    style: getNotificationStyle(type),
    duration: 3,
    className: "noti-style"
  });
}