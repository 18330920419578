import React, { useState, useEffect, useContext } from "react";
import "./lgtcAccount.scss";
import { useModal } from "../../hooks/useModal";
import { ModalContext } from "../../services/context/modal-context/State";
import { ENDPOINTS, getApi } from "../../services/http";
import { getFromLocal } from "../../services/storage/LocalStorage";
import { errorToast } from "../../componentsNew/Toast/Toast";
import { PageDetails } from "../../componentsNew/page-details/PageDetails";
import { usePagination } from "../../hooks/usePagination";
import ArrowLeft from "../../../assets/svg/NewIcons/arrow_left_white.svg";
import ArrowRight from "../../../assets/svg/NewIcons/arrow_right_white.svg";
import { Badge, InputSearch, Modal } from "../../componentsNew/core";
import LGTCAccount from "./LGTCAccount";
import ViewIcon from "../../../assets/svg/NewIcons/view.svg";
import LGTCPilotModal from "./LGTCPilotModal";
import SortValue from "../../helper/sort";

export default function LGTCPilot(props) {
  const [sort, setSort] = useState("");
  const [tableSortKey, setTableSortKey] = useState("");
  const user = getFromLocal("user");
  const userId = parseInt(user?.userId);
  const [pilotData, setPilotData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [pilotDataBackupData, setPilotDataBackupData] = useState([]);
  const { pagination, rowItems, handlePagination } = usePagination(pilotData);
  const { handleExpiredToken } = useModal();
  const { setIsLoading } = useContext(ModalContext);
  const [, setPilotDetails] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [pilotDetail, setPilotDetail] = useState([]);
  const [pilotDetails1, setPilotDetails1] = useState([]);
  const [pilotDetails2, setPilotDetails2] = useState([]);
  const [pilotDetails3, setPilotDetails3] = useState([]);
  const [pilotDetails4, setPilotDetails4] = useState([]);
  const [pilotDetails5, setPilotDetails5] = useState([]);
  const [pilotDetails6, setPilotDetails6] = useState([]);
  const [pilotDetails7, setPilotDetails7] = useState([]);
  const [pilotDetails8, setPilotDetails8] = useState([]);

  useEffect(() => {
    handlePagination();
  }, [pilotData]);

  useEffect(() => {
    const getPilotData = async () => {
      setIsLoading(true);
      var headers = {
        UserId: userId,
      };
      const response = await getApi(`${ENDPOINTS.GET_PILOT}`, {
        headers: headers,
      });
      if (response) {
        if (response.status == 200) {
          setPilotData(response?.data);
          setPilotDataBackupData(response?.data);

          setIsLoading(false);
        } else if (response.status == 401) {
          setIsLoading(false);
          handleExpiredToken();
        } else {
          setIsLoading(false);
        }
      }
    };

    getPilotData();
  }, []);

  const SortBy = (key) => {
    if (tableSortKey == key) {
      if (sort == "") {
        setSort("asc");
        let val = pilotData.sort(SortValue(key));
        setPilotData(val);
        handlePagination();
      } else if (sort == "asc") {
        setSort("desc");
        let val = pilotData.sort(SortValue(`-${key}`));
        setPilotData(val);
        handlePagination();
      } else if (sort == "desc") {
        setSort("asc");
        let val = pilotData.sort(SortValue(key));
        setPilotData(val);
        handlePagination();
      } else {
        setSort("");
      }
    } else {
      setTableSortKey(key);
      setSort("asc");
      let val = pilotData.sort(SortValue(key));
      setPilotData(val);
      handlePagination();
    }
  };

  const handleChangeWithLib = (value) => {
    let newRows = [];
    setSearchText(value);
    if (value) {
      let searchTxt = value.toString().trim().toLowerCase();
      newRows = pilotDataBackupData?.filter((value) => {
        return (
          (value.Sabre_Id &&
            value.Sabre_Id?.toString().toLowerCase().includes(searchTxt)) ||
          (value.Pilot_Name &&
            value.Pilot_Name?.toLowerCase().includes(searchTxt)) ||
          (value.Email && value.Email?.toLowerCase().includes(searchTxt)) ||
          (value.EmploymentDate &&
            value.EmploymentDate?.toString()
              .toLowerCase()
              .includes(searchTxt)) ||
          (value.CreatedOn &&
            value.CreatedOn?.toString().toLowerCase().includes(searchTxt))
        );
      });
    } else {
      newRows = [...pilotDataBackupData];
    }
    setPilotData(newRows);
  };

  const ViewField = (rowID) => {
    return (
      <Badge
        className="rounded-3"
        size="xs"
        variant="white"
        icon={ViewIcon}
        title="View details"
        onClick={() => getPilotDetails(rowID)}
      />
    );
  };

  const getPilotData = async (pilotId) => {
    setIsLoading(true);
    var user = getFromLocal("user");
    var headers = {
      UserId: parseInt(user["userId"]),
    };

    const response = await getApi(`${ENDPOINTS.GET_PILOT_DATA}/${pilotId}`, {
      headers: headers,
    });
    if (response) {
      if (response.status == 200) {
        setPilotDetails(response?.data);
        let pilotDetails = response?.data;
        setPilotDetail(pilotDetails.table[0]);
        setPilotDetails1(pilotDetails.table1);
        setPilotDetails2(pilotDetails.table2);
        setPilotDetails3(pilotDetails.table3);
        setPilotDetails4(pilotDetails.table4);
        setPilotDetails5(pilotDetails.table5);
        setPilotDetails6(pilotDetails.table6);
        setPilotDetails7(pilotDetails.table7);
        setPilotDetails8(pilotDetails.table8);
        setIsLoading(false);
        setIsModalVisible(true);
      } else if (response.status == 401) {
        setIsLoading(false);
        handleExpiredToken();
      } else {
        setIsLoading(false);
        errorToast(response?.statusText);
      }
    }
  };

  const getPilotDetails = (id) => {
    getPilotData(id);
  };

  const ChildrenModal = (
    <>
      <LGTCPilotModal
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
        pilotDetail={pilotDetail}
        pilotDetails1={pilotDetails1}
        pilotDetails2={pilotDetails2}
        pilotDetails3={pilotDetails3}
        pilotDetails4={pilotDetails4}
        pilotDetails5={pilotDetails5}
        pilotDetails6={pilotDetails6}
        pilotDetails7={pilotDetails7}
        pilotDetails8={pilotDetails8}
      ></LGTCPilotModal>
    </>
  );

  const TableControls = (
    <div className="d-flex justify-content-end">
      <div className="flex-wrap d-flex align-items-center justify-content-center">
        <div className="">
          <div className="col-md-12 col-sm-4 col-8">
            <InputSearch
              placeholder="search here..."
              className="w-100"
              onChange={handleChangeWithLib}
              value={searchText}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-3 d-flex align-items-center justify-content-center pe-0">
        <Badge
          className="rounded-50"
          variant="primary"
          size="xl"
          icon={ArrowLeft}
          title="Previous Page"
          disabled={!pagination?.prevPageUrl}
          onClick={() => handlePagination("prev")}
        />
        <Badge
          className="rounded-50 ms-2"
          variant="primary"
          size="xl"
          icon={ArrowRight}
          title="Next Page"
          disabled={!pagination?.nextPageUrl}
          onClick={() => handlePagination("next")}
        />
      </div>
    </div>
  );
  const Table = (
    <>
      <div className="d-flex align-items-center">
        <div className="col-6">
          <span className="h4 text-grey1">Pilots</span>
        </div>
        <div className="col-6 p-0">{TableControls}</div>
      </div>

      <div className=" mt-2 mb-2 table-responsive ">
        <table className="table table-bordered shadow-sm align-middle ">
          <thead className="bg-grey h7-table text-grey1">
            <tr className="text-center text-nowrap">
              <td onClick={() => SortBy("Sabre_Id")}>
                Sabre Id
                <i
                  className={
                    tableSortKey == "Sabre_Id"
                      ? sort == "asc"
                        ? "fa fa-sort-asc"
                        : "fa fa-sort-desc"
                      : "fa fa-fw fa-sort"
                  }
                ></i>
              </td>
              <td onClick={() => SortBy("Pilot_Name")}>
                Pilot Name
                <i
                  className={
                    tableSortKey == "Pilot_Name"
                      ? sort == "asc"
                        ? "fa fa-sort-asc"
                        : "fa fa-sort-desc"
                      : "fa fa-fw fa-sort"
                  }
                ></i>
              </td>
              <td onClick={() => SortBy("Email")}>
                Email
                <i
                  className={
                    tableSortKey == "Email"
                      ? sort == "asc"
                        ? "fa fa-sort-asc"
                        : "fa fa-sort-desc"
                      : "fa fa-fw fa-sort"
                  }
                ></i>
              </td>
              <td onClick={() => SortBy("EmploymentDate")}>
                Employment Date
                <i
                  className={
                    tableSortKey == "EmploymentDate"
                      ? sort == "asc"
                        ? "fa fa-sort-asc"
                        : "fa fa-sort-desc"
                      : "fa fa-fw fa-sort"
                  }
                ></i>
              </td>
              <td onClick={() => SortBy("CreatedOn")}>
                Created On
                <i
                  className={
                    tableSortKey == "CreatedOn"
                      ? sort == "asc"
                        ? "fa fa-sort-asc"
                        : "fa fa-sort-desc"
                      : "fa fa-fw fa-sort"
                  }
                ></i>
              </td>
              <td>Actions</td>
            </tr>
          </thead>
          <tbody className="h8 text-table-body">
            {rowItems && rowItems.length > 0 ? (
              rowItems.map((row, rowIndex) => (
                <tr className="text-center" key={"row_" + rowIndex}>
                  <td>{row.Sabre_Id}</td>
                  <td>{row.Pilot_Name}</td>
                  <td>{row.Email}</td>
                  <td>{row.EmploymentDate}</td>
                  <td>{row.CreatedOn}</td>
                  <td>
                    <div className="d-flex justify-content-center">
                      {ViewField(row.Id)}{" "}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="text-center h6-web">No records found</tr>
            )}
          </tbody>
        </table>
        <PageDetails pagination={pagination} />
        {isModalVisible && (
          <Modal
            open={isModalVisible}
            onClose={() => setIsModalVisible(false)}
            showCloseIcon={true}
            children={ChildrenModal}
            classNames={{
              modal: "training-modal",
              closeIcon: "training-modal-close-button",
            }}
          ></Modal>
        )}
      </div>
    </>
  );

  return <LGTCAccount breadCrumbTitle="Pilots">{Table}</LGTCAccount>;
}
