import React from "react";
import { ModalProvider } from "./modal-context/State";
import { UserProvider } from "./userContext/State";

export default function GlobalContextProvider({ children }) {
  return (
    <ModalProvider>
      <UserProvider>
        <>{children}</>
      </UserProvider>
    </ModalProvider>
  );
}
