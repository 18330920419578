import React from 'react';
import { Route, Switch, Router, Redirect } from "react-router-dom";
import DashBoardLayout from './app/layouts/DashBoardLayout/DashBoardLayout';
import AuthLayout from './app/layouts/AuthLayout/AuthLayout';
import { createBrowserHistory } from "history";
import './Typo.scss'
const history = createBrowserHistory();

function App() {
  // const pathName = history?.location?.pathname;

  return (
    <Router history={history} >
      <Switch>
        <Route path="/dashboard" render={(props) => <DashBoardLayout {...props} />} />
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Redirect from="/" to="/auth/login" />
      </Switch>
    </Router>
  );
}

export default App;
