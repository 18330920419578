import React, { useState, useEffect } from "react";
import API from "../../../utils/Api";
import { errorToast } from "../../../components/Generic/Generic";
import { getFromLocal } from "../../../utils/generic";
import endpoint from "../../../utils/endpoints";
import "./DirectorDashboard.scss";
import DataTableV2 from "../../../components/DataTable/DataTableV2";
import { withStyles } from "@material-ui/core/styles";
import { TableCell, TableRow } from "@material-ui/core";
import moment from "moment";
import Right from "../../../../assets/svg/Right-icn.svg";
import { BreadCrumb, Button } from "../../../componentsNew/core";
import DashBoardDirectorate from "../../../../assets/images/DashBoardDirectorate.png";

const breadCrumbItems = [
  {
    title: "Lion Group",
    link: -1,
  },
  {
    title: "Dashboard",
    link: 1,
  },
];

const columns = [
  { title: "Training Name", showSort: false, id: "title" },
  { title: "Training Type", showSort: false, id: "trainingType" },
  { title: "Start Date", showSort: false, id: "startDate" },
  { title: "End Date", showSort: false, id: "endDate" },
  { title: "Is Repeat Trainings", showSort: false, id: "isRepeatTrainings" },
  { title: "Instructor Name", showSort: false, id: "instructorName" },
  { title: "Max Attendees", showSort: false, id: "maxAttendees" },
  { title: "Training Device", showSort: false, id: "trainingDevice" },
  { title: "Aircraft Type", showSort: false, id: "aircraftType" },
  { title: "Description", showSort: false, id: "description" },
  { title: "Location From", showSort: false, id: "locationFrom" },
  { title: "Location To", showSort: false, id: "locationTo" },
  { title: "Duration In Hr", showSort: false, id: "durationInHr" },
  { title: "Duration In Min", showSort: false, id: "durationInMin" },
];
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#33485D",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    fontWeight: 700,
    color: "#534E4E",
    fontFamily: "DM Sans",
    padding: "10px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "white",
      borderTop: "1px solid #E5E5E5",
    },
  },
}))(TableRow);

const DirectorDashboard = (props) => {
  const [, setBusy] = useState(true);
  const [dashboardData, setDashboardData] = useState(null);
  const [records, getRecords] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Name");
  var [finalRow, setFinalRow] = useState(records);
  var [searchText, setSearchText] = useState("");

  const getMyProfile = async () => {
    var user = getFromLocal("user");

    var url = `${endpoint.GetDirectorDashboard}/${user?.companyCode}`;

    API.get(url, (isSuccess, response) => {
      if (isSuccess) {
        setBusy(false);
        setDashboardData(response);
        getRecords(response?.scheduledTrainings);
      } else {
        errorToast("Error loading profile!");
        setBusy(false);
      }
    });
  };

  useEffect(() => {
    setBusy(true);
    getMyProfile();
  }, []);

  useEffect(() => {
    if (records) {
      setFinalRow(records);
    }
  }, [records]);

  useEffect(() => {
    if (searchText) {
      var searchTxt = searchText.toString().trim().toLowerCase();
      var newRows = records?.filter((value) => {
        return (
          value.title.toString().toLowerCase().includes(searchTxt) ||
          value.instructorName.toLowerCase().includes(searchTxt)
        );
      });
      setFinalRow(newRows);
    } else {
      setFinalRow(records);
    }
  }, [searchText, records]);

  const renderTableBody = (row, key) => {
    return (
      <StyledTableRow key={key}>
        <StyledTableCell>{row.title}</StyledTableCell>
        <StyledTableCell>{row.trainingType}</StyledTableCell>
        <StyledTableCell>
          {row.startDate ? moment(row.startDate).format("DD/MM/YYYY") : ""}
        </StyledTableCell>
        <StyledTableCell>
          {row.endDate ? moment(row.endDate).format("DD/MM/YYYY") : ""}
        </StyledTableCell>
        <StyledTableCell>{row.isRepeatTrainings ? "Y" : "N"}</StyledTableCell>
        <StyledTableCell>{row.instructorName}</StyledTableCell>
        <StyledTableCell>{row.maxAttendees}</StyledTableCell>
        <StyledTableCell>{row.trainingDevice}</StyledTableCell>
        <StyledTableCell>{row.aircraftType}</StyledTableCell>
        <StyledTableCell>{row.description}</StyledTableCell>
        <StyledTableCell>{row.locationFrom}</StyledTableCell>
        <StyledTableCell>{row.locationTo}</StyledTableCell>
        <StyledTableCell>{row.durationInHr}</StyledTableCell>
        <StyledTableCell>{row.durationInMin}</StyledTableCell>
      </StyledTableRow>
    );
  };

  const saftyCard = (
    <>
      {" "}
      {[1, 1, 1, 1, 1, 1, 1, 1].map((item) => (
        <div className="p-2 mb-2 bg-highlight3 text-center w-93 me-2 rounded">
          <div className="text-white h3"> 21</div>
          <div className="text-white h8">WINGS AIR ASR FORM</div>
        </div>
      ))}
    </>
  );

  const trainingCard = (colour, head, value) => {
    return (
      <>
        <div className={`w-197 ${colour} p-2 rounded text-center box-shadow`}>
          <div className="label-input  text-white">{head}</div>
          <div className="h3 text-white mt-2">{value}</div>
        </div>
      </>
    );
  };

  return (
    <div className="px-5 py-2">
      <BreadCrumb breadCrumbItems={breadCrumbItems} />
      <div className="row">
        <div className="col-4">
          <div className="box-shadow mb-2">
            <img
              className="w-100 object-contain"
              src={DashBoardDirectorate}
              alt=""
            />
            <div className="text-grey2 p-4">
              <div className="h4">Training Schedule</div>
              <div className="h7 my-2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                ut ultrices purus, a semper sapien.{" "}
              </div>
              <Button
                className="rounded h6-web w-100"
                size="lg"
                title={"Continue"}
                variant="primary"
              />
            </div>
          </div>
          <div className="h4 text-grey2 my-3">Notifications</div>
          <div className="box-shadow mt-3 p-3 text-grey2">
            {[1, 1, 1, 1, 1, 1, 1, 1].map(() => (
              <div className="d-flex mb-3">
                <img src={Right} alt="" />
                <div className="px-3">
                  <div className="h7">
                    Pilots have been assigned to Training 121
                  </div>
                  <div className="h7-web">
                    Lorem ipsum dolor sit amet, consectetur adip
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-8">
          <div className="h4 text-grey1">Pilot Safety Issues</div>
          <div className="d-flex flex-wrap">{saftyCard}</div>
          <div className="h4 text-grey1 mt-4">Pilots due Training</div>
          <div className="d-flex justify-content-between">
            {trainingCard(
              "bg-grey3",
              "Expiring in 15 days",
              dashboardData?.countOfQualificationExpiredIn15Days
            )}
            {trainingCard(
              "bg-brandcolor2",
              "Expiring in 30 days",
              dashboardData?.countOfQualificationExpiredIn30Days
            )}
            {trainingCard(
              "bg-secondary",
              "Expiring in 60 days",
              dashboardData?.countOfQualificationExpiredIn60Days
            )}
            {trainingCard(
              "bg-brandcolor3",
              "Expiring in 90 days",
              dashboardData?.countOfQualificationExpiredIn90Days
            )}
          </div>
          <div className="h4 text-grey1 mt-4">Pilots due for Medical</div>
          <div className="d-flex justify-content-between">
            {trainingCard(
              "bg-grey3",
              "Expiring in 15 days",
              dashboardData?.countOfMedicalLicenseDueIn15Days
            )}
            {trainingCard(
              "bg-brandcolor2",
              "Expiring in 30 days",
              dashboardData?.countOfMedicalLicenseDueIn30Days
            )}
            {trainingCard(
              "bg-secondary",
              "Expiring in 50 days",
              dashboardData?.countOfMedicalLicenseDueIn60Days
            )}
            {trainingCard(
              "bg-brandcolor3",
              "Expiring in 90 days",
              dashboardData?.countOfMedicalLicenseDueIn90Days
            )}
          </div>
          <div className="h4 text-grey1 mt-4">License Expiry</div>
          <div className="d-flex justify-content-between">
            {trainingCard(
              "bg-grey3",
              "Expiring in 15 days",
              dashboardData?.countOfPilotLicenseDueIn15Days
            )}
            {trainingCard(
              "bg-brandcolor2",
              "Expiring in 30 days",
              dashboardData?.countOfPilotLicenseDueIn30Days
            )}
            {trainingCard(
              "bg-secondary",
              "Expiring in 60 days",
              dashboardData?.countOfPilotLicenseDueIn60Days
            )}
            {trainingCard(
              "bg-brandcolor3",
              "Expiring in 90 days",
              dashboardData?.countOfPilotLicenseDueIn90Days
            )}
          </div>
          <div className="h4 text-grey1 mt-4 mb-3">
            Trainings scheduled by LGTC
          </div>
          <div className="row mb-3">
            <div className="col-md-12 trainingsltgc">
              <DataTableV2
                setSearchText={setSearchText}
                columns={columns}
                rows={finalRow}
                order={order}
                orderBy={orderBy}
                setOrder={(e) => setOrder(e)}
                setOrderBy={(e) => setOrderBy(e)}
                renderTableBody={(row, key) => renderTableBody(row, key)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DirectorDashboard;
