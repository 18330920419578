import { errorToast, successToast } from "../../componentsNew/Toast/Toast";
import { ENDPOINTS, getApi, postApi } from "../../services/http";

export const getRequestDataApi = async (
  type,
  userId,
  setIsLoading,
  setOpenRequests,
  setApprovedRequests,
  setClosedRequests,
  handleExpiredToken
) => {
  setIsLoading(true);
  var headers = {
    UserId: userId,
  };
  var data = null;
  const response = await getApi(
    `${ENDPOINTS.REQUISITION_REQUEST_LIST}${type}`,
    data,
    {
      headers: headers,
    }
  );

  if (response) {
    if (response.status === 200) {
      if (type === "open") {
        setOpenRequests(response?.data);
      }

      if (type === "approved") {
        setApprovedRequests(response?.data);
      }
      if (type === "closedorrejected") {
        setClosedRequests(response?.data);
      }

      setIsLoading(false);
    } else if (response.status === 401) {
      setIsLoading(false);
      handleExpiredToken();
    } else {
      setIsLoading(false);
    }
  }
};
export const getMechRequestDataApi = async (
  setIsLoading,
  setMechReqData,
  handleExpiredToken
) => {
  setIsLoading(true);

  const response = await getApi(`${ENDPOINTS.MECH_REQUISITION_REQUEST}`);

  if (response) {
    if (response.status === 200) {
      setMechReqData(response?.data);
      console.log(response.data);
      setIsLoading(false);
    } else if (response.status === 401) {
      setIsLoading(false);
      handleExpiredToken();
    } else {
      setIsLoading(false);
    }
  }
};

export const getPartsListApi = async (
  e,
  setPartsNameListDrop,
  setIsLoading
) => {
  if (e?.length >= 3) {
    const response = await getApi(`${ENDPOINTS.GET_ALLPARTS}${e}`);
    if (response) {
      if (response.status === 200) {
        let data = response.data;
        let val = data?.map((el) => ({
          value: el,
          label: el,
        }));
        setPartsNameListDrop(val);
      }
    } else if (response.status === 401) {
      setIsLoading(false);
    }
  } else {
    setPartsNameListDrop([]);
  }
};

export const handleModalSubmitApi = async (
  payload,
  setError,
  validationMessageRef,
  submitForm
) => {
  // let requestData = {
  //   location: payload.location,
  //   site: payload.site,
  //   priority: payload.priority,
  //   description: payload.description,
  //   ac: payload.ac,
  //   wo: payload.wo,
  //   taskCard: payload.taskCard,
  //   defect: "",
  //   pn: "",
  //   qty: "",
  //   uom: "",
  //   requiredDate: "",
  // };
  const checkRequiredField = async () => {
    let isValid = true;
    if (
      payload.location === "" ||
      payload.priority === "" ||
      payload.description === "" ||
      payload.pn === "" ||
      payload.qty === "" ||
      payload.uom === "" ||
      payload.requiredDate === ""
    ) {
      isValid = false;
    }
    return isValid;
  };

  let isValidForm = await checkRequiredField();
  if (!isValidForm) {
    setError(true);
    validationMessageRef.current?.scrollIntoView({ block: "nearest" });
    return false;
  }
  setError(false);
  submitForm(ENDPOINTS.REQUISITION_REQUEST, payload, "");
};

export const submitFormApi = async (
  url,
  updatedPayload,
  type,
  setIsLoading,
  setRequsitionRequestMessage,
  setPartNumberMessage,
  setShowModal,
  userId
) => {
  setIsLoading(true);
  let headers = {
    UserId: userId,
  };

  let response = await postApi(url, updatedPayload, {
    headers: headers,
  });

  if (response.status === 201) {
    successToast(response?.data?.title);
    setRequsitionRequestMessage(response?.data?.title);
    setPartNumberMessage(response?.data?.pn);
    setShowModal(false);
  } else {
    errorToast("Error creating request. Please try again!");
    setRequsitionRequestMessage("");
  }
  setIsLoading(false);
};

export const getLocationSiteAcApi = async (
  userId,
  setLocationDropList,
  setSiteDropList,
  setAcDropList,
  handleExpiredToken
) => {
  const headers = {
    UserId: userId,
  };

  const loactionres = await getApi(`${ENDPOINTS.GET_LOCATIONS}`, {
    headers,
  });
  if (loactionres.status === 401) {
    handleExpiredToken();
  } else if (loactionres.status === 200) {
    const data = loactionres.data.result;
    const val = data?.map((el) => ({
      value: el.location,
      label: `${el.location} - ${el.locationDescription}`,
    }));
    setLocationDropList(val);
  } else {
    setLocationDropList([]);
  }
  const sitesres = await getApi(`${ENDPOINTS.GET_SITES}`, {
    headers,
  });

  if (sitesres.status === 200) {
    const data = sitesres.data;
    const val = data?.map((el) => ({
      value: el,
      label: el,
    }));
    setSiteDropList(val);
  } else {
    setSiteDropList([]);
  }

  const acsres = await getApi(`${ENDPOINTS.GET_ACS}`, {
    headers,
  });

  if (acsres.status === 200) {
    const data = acsres.data;
    const val = data?.map((el) => ({
      value: el,
      label: el,
    }));
    setAcDropList(val);
  } else {
    setAcDropList([]);
  }
};

export const getWOAPI = async (userId, selectedAc, setWoDropList) => {
  const headers = {
    UserId: userId,
  };
  const acsres = await getApi(`${ENDPOINTS.GET_WOS(selectedAc)}`, {
    headers,
  });

  if (acsres.status === 200) {
    const data = acsres.data;
    const val = data?.map((el) => ({
      value: el,
      label: el,
    }));
    setWoDropList(val);
  } else {
    setWoDropList([]);
  }
};

export const getTaskCardAPI = async (
  userId,
  selectedAc,
  selectedWO,
  setTaskCardDropList
) => {
  const headers = {
    UserId: userId,
  };
  const acsres = await getApi(
    `${ENDPOINTS.GET_TASKCARD(selectedAc, selectedWO)}`,
    {
      headers,
    }
  );

  if (acsres.status === 200) {
    const data = acsres.data;
    const val = data?.map((el) => ({
      value: el,
      label: el,
    }));
    setTaskCardDropList(val);
  } else {
    setTaskCardDropList([]);
  }
};

export const getDefectAPI = async (
  userId,
  selectedAc,
  selectedWO,
  selectedTaskCard,
  setDefectDropList
) => {
  const headers = {
    UserId: userId,
  };
  const acsres = await getApi(
    `${ENDPOINTS.GET_DEFECTS(selectedAc, selectedWO, selectedTaskCard)}`,
    {
      headers,
    }
  );

  if (acsres.status === 200) {
    const data = acsres.data;
    const val = data?.map((el) => ({
      value: el,
      label: el,
    }));
    setDefectDropList(val);
  } else {
    setDefectDropList([]);
  }
};
