import React, { memo } from "react";
import "./lgtcAccount.scss";
import { Button } from "../../componentsNew/core";
import BatikLogo from "../../../assets/svg/NewIcons/batikAir.svg";
import LionAirLogo from "../../../assets/svg/NewIcons/lionAir.svg";
import MalindoAirLogo from "../../../assets/svg/NewIcons/malindoAir.svg";
import SuperAirJetLogo from "../../../assets/svg/NewIcons/superAir.svg";
import WingsAirLogo from "../../../assets/svg/NewIcons/wingsAir.svg";
import logo from "../../../assets/images/LITESLogo.svg";

import { savePDF } from "@progress/kendo-react-pdf";
const bodyRef = React.createRef();

const createPdfFun = (html, pilotId, trainingDate) => {
  savePDF(html, {
    paperSize: "auto",
    fileName: pilotId + "-" + trainingDate + "-TA.pdf",
    margin: 40,
    imageResolution: 300,
  });
};

function LGTCViewTraining(props) {
  const createPdf = () => createPdfFun(bodyRef.current, pilotId, trainingDate);

  const {
    // setShowModal,
    formValues,
    formHeaderValues,
    pilotBarcode,
    instructorBarcode,
    pilotId,
    trainingDate,
  } = props;

  // const handleCancel = () => {
  //   setShowModal(false);
  // };

  const getLogo = (training, company) => {
    if (
      training === "PPC/IR" ||
      training === "PPC+IR" ||
      training === "Pilot Proficiency Check + Instrument Rating"
    ) {
      switch (company) {
        case "ID":
          return BatikLogo;
        case "OD":
          return MalindoAirLogo;
        case "JT":
          return LionAirLogo;
        case "IW":
          return WingsAirLogo;
        case "IU":
          return SuperAirJetLogo;
        default:
          return logo;
      }
    }
    return logo;
  };

  return (
    <>
      <div
        className="m-3 mb-0 overflow-hidden"
        style={{ "border-radius": "10px" }}
      >
        <div ref={bodyRef}>
          <table className="formTable bg-white mb-0">
            <tbody>
              <tr className="bg-primary text-white noBorder">
                <td>
                  <div className="view-logo-div">
                    <img
                      alt=""
                      style={{ height: "50px" }}
                      className="view-logo-img"
                      src={getLogo(
                        formHeaderValues["trainingType"],
                        formHeaderValues["code"]
                      )}
                    />
                  </div>
                </td>
                <td style={{ textAlign: "center" }} colSpan="2">
                  <b>TRAINING AND ASSESSMENT FORM</b>
                </td>
                <td style={{ textAlign: "center" }}>
                  <b>TA</b>
                </td>
              </tr>
              <tr>
                <th
                  className="header-color-modal"
                  style={{ textAlign: "center" }}
                  colSpan="4"
                >
                  RECURRENT TRAINING
                </th>
              </tr>
              <tr>
                <td
                  className="header-color-modal"
                  style={{ textAlign: "center" }}
                  colSpan="4"
                >
                  TYPE OF TRAINING : {formHeaderValues["trainingType"]}
                </td>
              </tr>
              <tr>
                <td className="header-color-modal">Date</td>
                <td className="content-color-modal">
                  {formHeaderValues["trainingDateStr"]}
                </td>
                <td className="header-color-modal">Airlines/Others</td>
                <td className="content-color-modal">
                  {formHeaderValues["traineeCompanyName"]}
                </td>
              </tr>
              <tr>
                <td className="header-color-modal">Aircraft Type</td>
                <td className="content-color-modal">
                  {formHeaderValues["aircraftType"]}
                </td>
                <td className="header-color-modal">ID Number</td>
                <td className="content-color-modal">
                  {formHeaderValues["idNumber"]}
                </td>
              </tr>
              <tr>
                <td className="header-color-modal">
                  Training Device Type / Training Device
                </td>
                <td className="content-color-modal">
                  {formHeaderValues["deviceType"]} /{" "}
                  {formHeaderValues["trainingDevice"]}
                </td>
                <td className="header-color-modal">Trainee Type</td>
                <td className="content-color-modal">
                  {formHeaderValues["traineeType"]}
                </td>
              </tr>
              <tr>
                <td className="header-color-modal">
                  Sector Flown / Airport From
                </td>
                <td className="content-color-modal">
                  {formHeaderValues["sector_Flown_Airport_From"]}
                </td>
                <td className="header-color-modal">Pilot Name</td>
                <td className="content-color-modal">
                  {formHeaderValues["traineeName"]}
                </td>
              </tr>
              <tr>
                <td className="header-color-modal">
                  Sector Flown / Airport To
                </td>
                <td className="content-color-modal">
                  {formHeaderValues["sector_Flown_Airport_To"]}
                </td>
                <td className="header-color-modal">Ranking</td>
                <td className="content-color-modal">
                  {formHeaderValues["traineeRanking"]}
                </td>
              </tr>
              <tr>
                <td className="header-color-modal">
                  Instructor / Checker Name
                </td>
                <td className="content-color-modal">
                  {formHeaderValues["instructor"]}
                </td>

                <td className="header-color-modal">Trainee Email</td>
                <td className="content-color-modal">
                  {formHeaderValues["traineeEmail"]}
                </td>
              </tr>
              <tr>
                <td className="header-color-modal">Function</td>
                <td className="content-color-modal">
                  {formHeaderValues["function"]}
                </td>
                <td className="header-color-modal"> Other Pilot Id Number</td>
                <td className="content-color-modal">
                  {formHeaderValues["otherPilotIdNumber"]}
                </td>
              </tr>
              <tr>
                <td colSpan={2}></td>
                <td className="header-color-modal">Other Pilot Name</td>
                <td className="content-color-modal">
                  {formHeaderValues["otherPilotName"]}
                </td>
              </tr>

              <tr>
                <td colSpan={2}></td>
                <td className="header-color-modal">Other Pilot Airlines</td>
                <td className="content-color-modal">
                  {formHeaderValues["otherTraineeCompanyName"]}
                </td>
              </tr>

              <tr>
                <td colSpan={2}></td>
                <td className="header-color-modal">Other Pilot Email</td>
                <td className="content-color-modal">
                  {formHeaderValues["otherTraineeEmail"]}
                </td>
              </tr>

              <tr>
                <th
                  className="header-color-modal"
                  style={{ textAlign: "center" }}
                  colSpan="5"
                >
                  GRADING
                </th>
              </tr>
              <tr>
                <td className="content-color-modal" colSpan="5">
                  <div className="d-flex justify-content-around">
                    <p>1. UnSatisfactory/Unsafe Situation</p>
                    <p>2. Marginal/Min.Acceptable</p>
                    <p>3. Satisfactory/Adequate</p>
                    <p>4. Good/Effective</p>
                    <p>5. Very Good/Exemplary Manner</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan="5" style={{ padding: "0px" }}>
                  {formValues}
                  <div>
                    <div
                      className="col-md-12"
                      style={{ borderTop: "1px solid #dbdbdb" }}
                    >
                      <p className="header-color-modal">
                        Flight Phases: {formHeaderValues["flightPhases"]}
                      </p>
                      <p className="header-color-modal">
                        Comments/Debriefed Items: {formHeaderValues["remarks"]}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <table
                        className="formTable"
                        style={{ marginBottom: "0px" }}
                      >
                        <tbody>
                          <tr>
                            <td className="header-color-modal" width="430">
                              OVERALL PERFORMANCE
                            </td>
                            <td style={{ padding: "0px", width: "150px" }}>
                              <table
                                className="formTable"
                                style={{ marginBottom: "0px" }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        borderLeft: "0px",
                                        borderTop: "0px",
                                        borderBottom: "0px",
                                      }}
                                      width="10"
                                      align="center"
                                    >
                                      {formHeaderValues["overallperformance"]}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            {formHeaderValues["fortraining"] && (
                              <>
                                <td colSpan="2" className="header-color-modal">
                                  FOR TRAINING
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {formHeaderValues["fortraining"]}
                                </td>
                              </>
                            )}
                            {formHeaderValues["forchecking"] && (
                              <>
                                <td colSpan="2" className="header-color-modal">
                                  FOR CHECKING
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {formHeaderValues["forchecking"]}
                                </td>
                              </>
                            )}
                          </tr>
                          <tr>
                            <td className="header-color-modal">
                              Instructor Recommendation
                            </td>
                            <td style={{ textAlign: "center", padding: "0px" }}>
                              <table
                                className="formTable"
                                style={{ marginBottom: "0px" }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        borderLeft: "0px",
                                        borderTop: "0px",
                                        borderBottom: "0px",
                                      }}
                                      align="center"
                                    >
                                      {
                                        formHeaderValues[
                                          "instructorRecommendation"
                                        ]
                                      }
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td></td>
                          </tr>

                          <tr>
                            <td style={{ textAlign: "center" }} colSpan="1">
                              {pilotBarcode === "" ? (
                                <span
                                  style={{
                                    padding: "0px 0px 5px 5px",
                                    display: "block",
                                  }}
                                  className="header-color-modal"
                                >
                                  Pilot Sign
                                </span>
                              ) : (
                                <img src={pilotBarcode} width="100" alt="" />
                              )}
                            </td>
                            <td colSpan="2">
                              <div>
                                <span className="d-flex align-items-center">
                                  <p className="text-primary m-0 h7">
                                    Doc No: &nbsp;{" "}
                                  </p>
                                  <p className=" text-grey1 m-0 ">
                                    {formHeaderValues["docNo"]}
                                  </p>
                                </span>

                                <span className="d-flex align-items-center">
                                  <p className="text-primary m-0 h7">
                                    License No: &nbsp;
                                  </p>
                                  <p className="text-grey1 m-0 ">
                                    {formHeaderValues["licenseNo"]}
                                  </p>
                                </span>

                                <span className="d-flex align-items-center">
                                  <p className="text-primary m-0 h7">
                                    LOA No: &nbsp;
                                  </p>
                                  <p className="text-grey1 m-0 ">
                                    {formHeaderValues["loaNo"]}
                                  </p>
                                </span>

                                <span className="d-flex align-items-center">
                                  <p className="text-primary m-0 h7">
                                    OTR No: &nbsp;
                                  </p>
                                  <p className="text-grey1 m-0 ">
                                    {formHeaderValues["otrNumber"]}
                                  </p>
                                </span>
                              </div>
                            </td>
                            <td style={{ textAlign: "center" }} colSpan="2">
                              {instructorBarcode === "" ? (
                                <span
                                  style={{
                                    padding: "0px 0px 5px 5px",
                                    display: "block",
                                  }}
                                  className="header-color-modal"
                                >
                                  Check Airman/CCP/ Instructor Sign
                                </span>
                              ) : (
                                <img
                                  src={instructorBarcode}
                                  width="100"
                                  alt=""
                                />
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="mr-3 float-end">
        <Button
          onClick={createPdf}
          className="rounded mt-2"
          size="sm"
          title="Download PDF"
        />
      </div>
    </>
  );
}

export default memo(LGTCViewTraining);
