import React, { useState, createContext } from "react";
import { getFromLocal } from "../../../utils/generic";

const UserContext = createContext(null);
UserContext.displayName = "UserContext";

const UserProvider = ({ children }) => {
  const [user, setUser] = useState({});

  const Fn = async () => {
    const Localuser = await getFromLocal("user");
    setUser(Localuser);
  };

  React.useEffect(() => {
    Fn();
  }, []);

  const setUserHandler = (data) => {
    setUser({ ...data });
    console.log("from user context Handler");
  };
  return (
    <UserContext.Provider
      value={{
        setUser: setUserHandler,
        user,
        role: user?.roles?.[0]?.roleCode ?? "",
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider };
export { UserContext };
