export const setToLocal = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
}

export const getFromLocal = (key) => {
    var res = localStorage.getItem(key);
    return JSON.parse(res);
}

export const clearLocal = () => {
    localStorage.clear();
}
