import React, { useState, useContext } from "react";
import "./../../scss/trax.scss";
import { errorToast } from "../../componentsNew/Toast/Toast";
import ArrowLeft from "../../../assets/svg/NewIcons/arrow_left_white.svg";
import ArrowRight from "../../../assets/svg/NewIcons/arrow_right_white.svg";
import {
  Badge,
  Button,
  Modal,
  Input,
  InputSearch,
} from "../../componentsNew/core";
import { useModal } from "../../hooks/useModal";
import { ModalContext } from "../../services/context/modal-context/State";
import { ENDPOINTS, getApi } from "../../services/http";
import TRAXPartsRequisitionModal from "./TRAXPartsRequisitionModal";
import TRAXAccount from "../TraxAccount/TRAXAccount";
import Loader from "react-loader-spinner";

export default function TRAXParts(props) {
  var end = new Date();
  end.setHours(0, 0, 0, 0);
  var start = new Date();
  start.setHours(0, 0, 0, 0);
  start.setDate(start.getDate() - 90);

  const [search, setSearch] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle] = useState("Part Requisition Request");
  const [goto, setGoto] = useState(1);
  const [partsData, setPartsData] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const { handleExpiredToken } = useModal();
  const { setIsLoading } = useContext(ModalContext);
  const [partNumber, setPartNumber] = useState("");
  const [partsNameListDrop, setPartsNameListDrop] = useState([]);
  const [searchTextPart, setSearchTextPart] = useState("");
  const [isPartAvailable, setIsPartAvailable] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [requsitionRequestMessage, setRequsitionRequestMessage] = useState("");
  const [partNumberMessage, setPartNumberMessage] = useState("");
  const [tableSort, setTableSort] = useState({ key: "", by: false });
  const handleSearch = async (pn) => {
    console.log(partsNameListDrop);
    if (!partsNameListDrop.map((d) => d.value).includes(pn || partNumber)) {
      setShowErrorModal(true);
      return;
    }
    setRequsitionRequestMessage("");
    // e.preventDefault();
    if (pn === "" && partNumber === "") {
      errorToast("Please enter part number.");
      return false;
    }
    getPart(pn);
  };
  const searchFilter = (partsData, search) => {
    return partsData?.filter(
      (e) =>
        (e.pn ?? "").toLowerCase().includes(search.toLowerCase()) ||
        (e.sn ?? "").toLowerCase().includes(search.toLowerCase()) ||
        (e.condition ?? "").toLowerCase().includes(search.toLowerCase()) ||
        (e.location ?? "").toLowerCase().includes(search.toLowerCase())
    );
  };
  const [listLoading, setListLoading] = useState(false);
  const getPartsList = async (e) => {
    setListLoading(true);
    if (e?.length >= 3) {
      const response = await getApi(`${ENDPOINTS.GET_ALLPARTS}${e}`);
      if (response) {
        if (response.status === 200) {
          let data = response.data;
          let val = data?.map((el) => ({
            value: el,
            label: el,
          }));
          setPartsNameListDrop(val);
        }
      } else if (response.status === 401) {
        setIsLoading(false);
        handleExpiredToken();
      }
    } else {
      setPartsNameListDrop([]);
    }
    setListLoading(false);
  };

  const getPart = async (pn) => {
    setIsLoading(true);
    const response = await getApi(`${ENDPOINTS.GET_PART}${pn || partNumber}`);
    if (response) {
      setIsLoading(false);
      if (response.status === 200) {
        if (response?.data && response?.data.length > 0) {
          const temp = {};
          const result = [];
          response.data
            .sort((a, b) => a.location.localeCompare(b.location))
            .forEach((e) => {
              temp[e.location[0]]
                ? temp[e.location[0]].push(e)
                : (temp[e.location[0]] = [e]);
            });
          Object.keys(temp).forEach((e) => {
            const v = temp[e].sort(
              (a, b) =>
                Number(b.quantityAvailable) - Number(a.quantityAvailable)
            );
            v.forEach((v) => result.push(v));
          });
          setPartsData(result);
          setIsPartAvailable(true);
        } else {
          setIsPartAvailable(false);
          setPartsData(null);
        }
        setShowMessage(true);
      } else if (response.status === 401) {
        setIsLoading(false);
        handleExpiredToken();
      } else if (response.status === 500) {
        setIsLoading(false);
        errorToast("Failed to fetch");
        setPartsData(null);
      } else {
        setIsLoading(false);
        setIsPartAvailable(false);
        setShowMessage(true);
        setPartsData(null);
      }
    }
    setSubmitted(true);
  };

  // useEffect(() => {
  //   var user = getFromLocal("user");
  //   var pageName = {};
  //   pageName = user.userRolePermissions;
  //   var pageActions = pageName?.filter((r) => r.pageName === "Trainings");
  //   var actions = pageActions?.map((a) => a.pageActions);
  //   if (actions?.length > 0) {
  //     var action = actions[0].map((b) => b.actionName);
  //     action?.forEach(actionsList);
  //     function actionsList(action, index, arr) {
  //       if (action === "Add") {
  //         setAdd(true);
  //       }
  //       if (action === "Update") {
  //         setUpdate(true);
  //       }
  //       if (action === "View") {
  //         setView(true);
  //       }
  //       if (action === "Delete") {
  //         setDelete(true);
  //       }
  //     }
  //   }
  // }, []);

  const ModalChildren = (
    <TRAXPartsRequisitionModal
      setShowModal={setShowModal}
      title={modalTitle}
      setIsLoading={setIsLoading}
      setRequsitionRequestMessage={setRequsitionRequestMessage}
      setPartNumberMessage={setPartNumberMessage}
    />
  );
  const reducerFn = (key) => {
    return (
      <span className="fw-bold text-grey1">
        {partsData
          ?.map((d) => d[key])
          ?.reduce(
            (previousValue, currentValue) => previousValue + currentValue,
            0
          ) || 0}
      </span>
    );
  };
  const TdSort = ({ type, field, children }) => {
    return (
      <td
        onClick={() =>
          setTableSort({
            key: field,
            by: tableSort.key === field ? !tableSort.by : true,
            type,
          })
        }
      >
        {children}
        <i
          className={
            tableSort.key === field
              ? tableSort.by
                ? "fa fa-fw fa-sort-asc"
                : "fa fa-fw fa-sort-desc"
              : "fa fa-fw fa-sort"
          }
        />
      </td>
    );
  };
  const Table = (
    <>
      <div>
        <div className="">
          <div className="col-6 mb-2">
            <span className="h4 text-grey1 "></span>
          </div>
          <div className="col-12 p-0">
            <table className="form-table-ta">
              <tbody className="border-bottom">
                <tr>
                  {/* <td className="h6-web border-0 col-1">Part Number</td> */}
                  <td className="border-0 col-6">
                    <span className="h6-web ">Part Number</span>
                    <InputSearch
                      label="s"
                      id="partSearchInput"
                      placeholder={"Type min 3 charcters..."}
                      className="h6 rounded p-2 mt-0 mb-0 w-50 d-inline-block"
                      // style={{ width: "585px" }}
                      onChange={(v) => {
                        setSearchTextPart(v.toUpperCase());
                        setPartNumber("");
                        getPartsList(v.toUpperCase());
                      }}
                      value={searchTextPart}
                      type={"text"}
                    >
                      {searchTextPart.length > 2 && !partNumber && (
                        <div
                          className="position-absolute bg-white border rounded shadow mt-1"
                          style={{
                            width:
                              document.getElementById("partSearchInput")
                                .clientWidth - 16,
                            overflow: "auto",
                            maxHeight: "200px",
                          }}
                        >
                          {listLoading ? (
                            <Loader
                              type="TailSpin"
                              height={"2rem"}
                              color="red"
                            />
                          ) : partsNameListDrop?.length ? (
                            partsNameListDrop?.map((user) => (
                              <p
                                className="m-0 p-2 fs-14 cursor-pointer partsearchinput"
                                onClick={() => {
                                  setSearchTextPart(user.label);
                                  setPartNumber(user.value || "");
                                  // handleSearch(user.value);
                                }}
                              >{`${user.label || ""}`}</p>
                            ))
                          ) : (
                            <p className="m-0 p-2 fs-14 cursor-default">
                              The PN is not yet registered in trax.
                            </p>
                          )}
                        </div>
                      )}
                    </InputSearch>
                    <Button
                      className="rounded h6-web p-1 mt-0"
                      size="lg"
                      title={"Search"}
                      variant="primary"
                      onClick={(e) => handleSearch(searchTextPart)}
                    />
                  </td>
                  <td className="p-2 border-0 text-right">
                    <Button
                      className="rounded h6-web p-1 mt-0"
                      size="lg"
                      title={"Create Requisition"}
                      variant="primary"
                      onClick={() => setShowModal(true)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div>
          {requsitionRequestMessage === "" && showMessage && isPartAvailable && (
            <p className="part-available-card p-2 text-center rounded h6-web">
              {/* Part is available at the following locations. If it’s available
                at your store location you can create pick list. But, If it’s
                not in your store location and you need it, please create RQ at
                TRAX assign to Supply Chain.*/}
              Part is available at the following locations.
            </p>
          )}

          {requsitionRequestMessage !== "" && (
            <p className="part-available-card p-2 text-center rounded h6-web">
              Your Requisition request
              <br />
              Requisition requested created successfully for part no{" "}
              <b>{partNumberMessage}</b>
              <br />
              Please note the Requisition No <b>{requsitionRequestMessage}</b>
            </p>
          )}

          {requsitionRequestMessage === "" && showMessage && !isPartAvailable && (
            <p className="part-unavailable-card p-2 text-center rounded h6-web">
              Part is unavailable. Please create a requisition request
              {/* <Button
                className="rounded h6-web p-1 mt-0 ml-4"
                size="lg"
                title={"Create Requisition"}
                variant="primary"
                onClick={(e) => setShowModal(true)}
              /> */}
            </p>
          )}
        </div>
        <div className="d-flex justify-content-between mt-4">
          {partsData && (
            <>
              <span className="h4 m-0 text-grey1">Parts</span>
              <Input
                type="text"
                placeholder="Search"
                className="h6 w-25 rounded p-0 mt-0 mb-0"
                onChange={(e) => {
                  setSearch(e);
                  setPageNo(0);
                  setGoto(1);
                }}
                value={search}
              />
            </>
          )}
        </div>
        {partsData && (
          <div className=" mt-2 mb-2 table-responsive ">
            <table className="table table-bordered shadow-sm align-middle ">
              <thead className="bg-grey h7-table text-grey1">
                <tr className="text-center text-nowrap">
                  {/* <td>Batch</td>
                  <td>Goods Received Batch</td>
                  <td>Vendor Lot</td>
                  <TdSort type="text" field={"pn"}>
                    PN
                  </TdSort>
                  <TdSort type="text" field={"sn"}>
                    SN
                  </TdSort>
                  <TdSort type="text" field={"condition"}>
                    Condition
                  </TdSort>
                  <TdSort type="text" field={"riDate"}>
                    RI Date
                  </TdSort> */}
                  <TdSort type="text" field={"location"}>
                    Location
                  </TdSort>
                  <TdSort type="number" field={"quantityAvailable"}>
                    Available
                  </TdSort>
                  <TdSort type="number" field={"qtyReserved"}>
                    Reserved
                  </TdSort>
                  <TdSort type="number" field={"qtyInTransfer"}>
                    In Transfer
                  </TdSort>
                  <TdSort type="number" field={"qtyPendingRI"}>
                    Pending R/I
                  </TdSort>
                  <TdSort type="number" field={"qtyUS"}>
                    U/S
                  </TdSort>
                  <TdSort type="number" field={"qtyInRepair"}>
                    In Repair
                  </TdSort>
                  <TdSort type="number" field={"qtyInRental"}>
                    In Rental
                  </TdSort>
                  <TdSort type="number" field={"qtyTotal"}>
                    Total
                  </TdSort>
                  {/* <td>Bin</td> */}
                </tr>
              </thead>
              <tbody className="h8 text-table-body">
                {[
                  ...searchFilter(partsData, search)
                    ?.sort((a, b) =>
                      tableSort.key
                        ? tableSort?.type === "number"
                          ? (tableSort.by
                              ? a[tableSort.key]
                              : b[tableSort.key]) -
                            (tableSort.by ? b[tableSort.key] : a[tableSort.key])
                          : (tableSort.by
                              ? a[tableSort.key]
                              : b[tableSort.key]
                            ).localeCompare(
                              tableSort.by ? b[tableSort.key] : a[tableSort.key]
                            )
                        : 0
                    )
                    ?.slice(20 * pageNo, 20 * (pageNo + 1)),
                  {
                    location: (
                      <span className="fw-bold text-grey1">Grand Total</span>
                    ),
                    quantityAvailable: reducerFn("quantityAvailable"),
                    qtyReserved: reducerFn("qtyReserved"),
                    qtyInTransfer: reducerFn("qtyInTransfer"),
                    qtyPendingRI: reducerFn("qtyPendingRI"),
                    qtyUS: reducerFn("qtyUS"),
                    qtyInRepair: reducerFn("qtyInRepair"),
                    qtyInRental: reducerFn("qtyInRental"),
                    qtyTotal: reducerFn("qtyTotal"),
                  },
                ]?.map((row, rowIndex) => (
                  //{partsData && (
                  <tr className="text-center">
                    {/* <td>{row.batch}</td>
                      <td>{row.goodReceivedBatch}</td>
                      <td>{row.vendorLot}</td> 
                      <td>{row.pn}</td>
                      <td>{row.sn}</td>
                      <td>{row.condition}</td>
                      <td>
                        {row.riDate
                          ? moment(row.riDate).format("DD/MM/YYYY")
                          : ""}
                      </td>*/}
                    <td>{row.location}</td>
                    <td>{row.quantityAvailable}</td>
                    <td>{row.qtyReserved}</td>
                    <td>{row.qtyInTransfer}</td>
                    <td>{row.qtyPendingRI}</td>
                    <td>{row.qtyUS}</td>
                    <td>{row.qtyInRepair}</td>
                    <td>{row.qtyInRental}</td>
                    <td>{row.qtyTotal}</td>
                    {/* <td>{row.bin}</td> */}
                  </tr>
                ))}
                {submitted && !partsData && (
                  <tr className="text-center h6-web">No records found</tr>
                )}
              </tbody>
            </table>
            <div className="d-flex justify-content-end align-items-center">
              {!!partsData?.length && (
                <p className="h8 text-grey1 mb-0 mr-2">
                  Page {Number(pageNo) + 1} of{" "}
                  {Math.ceil(
                    (searchFilter(partsData, search)?.length ?? 0) / 20
                  )}{" "}
                  (Total Records: {searchFilter(partsData, search)?.length ?? 0}
                  )
                </p>
              )}
              <Input
                style={{ width: "50px" }}
                type="number"
                value={goto}
                onChange={(value) => {
                  value >= 0 &&
                    value <
                      Math.ceil(
                        (searchFilter(partsData, search)?.length ?? 0) / 20
                      ) +
                        1 &&
                    setGoto(value);
                }}
              />
              <Badge
                className="rounded-50 ms-2 text-white"
                variant="primary"
                size="xl"
                title="Go"
                onClick={() => {
                  setPageNo((Number(goto) || 1) - 1);
                  setGoto(Number(goto) || 1);
                }}
              />
              <Badge
                className="rounded-50 ms-2"
                variant="primary"
                size="xl"
                icon={ArrowLeft}
                title="Previous Page"
                disabled={!pageNo}
                onClick={() => {
                  setPageNo(pageNo - 1);
                  setGoto(Number(goto - 1));
                }}
              />
              <Badge
                className="rounded-50 ms-2"
                variant="primary"
                size="xl"
                icon={ArrowRight}
                title="Next Page"
                disabled={
                  pageNo + 1 ===
                  Math.ceil((searchFilter(partsData, search)?.length ?? 0) / 20)
                }
                onClick={() => {
                  setPageNo(pageNo + 1);
                  setGoto(Number(goto + 1));
                }}
              />
            </div>
          </div>
        )}
        {showModal && (
          <Modal
            open={showModal}
            title={modalTitle}
            onClose={() => setShowModal(false)}
            showCloseIcon={true}
            classNames={{
              modal: "userEditModal",
            }}
          >
            {ModalChildren}
          </Modal>
        )}
        {showErrorModal && (
          <Modal
            open={showErrorModal}
            title={modalTitle}
            onClose={() => setShowErrorModal(false)}
            showCloseIcon={true}
            classNames={{
              modal: "userEditModal",
            }}
          >
            <p className="mb-0 h6-web">
              {searchTextPart
                ? "The Part number is not yet registered in trax."
                : "Please enter part number"}
            </p>
            <Button
              className="rounded h6-web p-1 mt-5 w-100"
              size="lg"
              title={"Ok"}
              variant="primary"
              onClick={() => setShowErrorModal(false)}
            />
          </Modal>
        )}
      </div>
    </>
  );

  return (
    <TRAXAccount breadCrumbTitle="Parts" AddButtonShow={false}>
      {Table}
    </TRAXAccount>
  );
}
