/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-unused-state */
import React, { useState, useEffect, memo } from "react";
import "./TrainingRequests.scss";
import { PageDetails } from "../../componentsNew/page-details/PageDetails";

import {
  Checkbox,
  Input,
  Dropdown,
  Modal,
  Button,
  TextArea,
  DatePickers,
  InputSearch,
  Badge,
} from "../../componentsNew/core";

import ConstructObject from "../../hooks/helper";
import { errorToast } from "../../componentsNew/Toast/Toast";
import { ENDPOINTS, getApi, postApi } from "../../services/http";
import { usePagination } from "../../hooks/usePagination";
import { getFromLocal } from "../../services/storage/LocalStorage";
import moment from "moment";
import { useModal } from "../../hooks/useModal";
import ArrowLeft from "../../../assets/svg/NewIcons/arrow_left_white.svg";
import ArrowRight from "../../../assets/svg/NewIcons/arrow_right_white.svg";
// import { ModalContext } from "../../services/context/modal-context/State";

// const StyledTableCell = withStyles((theme) => ({
//   head: {
//     backgroundColor: "#33485D",
//     color: theme.palette.common.white,
//   },
//   body: {
//     fontSize: 14,
//   },
// }))(TableCell);

// const StyledTableRow = withStyles((theme) => ({
//   root: {
//     "&:nth-of-type(odd)": {
//       backgroundColor: "white",
//       borderTop: "1px solid #E5E5E5",
//     },
//   },
// }))(TableRow);

// const columns = [
//   { title: "Select", showSort: false },
//   { title: "Name", showSort: false, id: "pilotName" },
// ];

const RequestTrainingSchedule = (props) => {
  const {
    // classes,
    visible,
    visibleChange = () => {},
    appointmentData,
    cancelAppointment,
    // target,
    // onHide,
    // PayLoad,
    formMasterData,
  } = props;
  const [usePayLoad, setUsePayLoad] = useState(appointmentData);
  const [appointmentChanges, setAppointmentChanges] = useState({});
  const [trainingTypeSelected, setTrainingTypeSelected] = useState(false);
  const [, setQualifications] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [loadData, setLoadData] = useState(true);
  const [pilotSelection, setPilotSelection] = useState([]);
  // const [order, setOrder] = useState("asc");
  // const [orderBy, setOrderBy] = useState("Name");
  var [finalRow, setFinalRow] = useState([]);
  var [searchText, setSearchText] = useState("");
  const [, getRecords] = useState([]);
  const [Aircraft, setAircraft] = useState([]);
  const [TrainingDevice, setTrainingDevice] = useState([]);
  const [qualificationsDrop, setQualificationsDrop] = useState([]);
  const [trainingType, setTrainingType] = useState([]);
  let dat = usePayLoad.endDate != undefined ? usePayLoad.endDate : new Date();
  const [startDate, setStartDate] = useState(
    usePayLoad.endDate != undefined
      ? new Date(dat)
      : new Date(dat.getTime() + 1 * 60000)
  );
  const { handleExpiredToken } = useModal();
  const { pagination, rowItems, handlePagination } = usePagination(finalRow);

  const filterLocationOptions = [
    {
      label: "Class A",
      value: "Class A",
    },
    {
      label: "Class B",
      value: "Class B",
    },
    {
      label: "Class C",
      value: "Class C",
    },
    {
      label: "Class D",
      value: "Class D",
    },
  ];

  useEffect(() => {
    handlePagination();
  }, [finalRow]);

  useEffect(() => {
    if (pilotSelection) {
      setFinalRow(pilotSelection);
    }
  }, [pilotSelection]);

  const handleChangeWithLib = (value) => {
    setSearchText(value);
    let newRows = [];
    if (value) {
      let searchTxt = value.toString().trim().toLowerCase();
      newRows = pilotSelection?.filter((value) => {
        return (
          value.pilotName && value.pilotName.toLowerCase().includes(searchTxt)
        );
      });
    } else {
      newRows = [...pilotSelection];
    }
    setFinalRow(newRows);
  };

  const getAppointmentData = () => {
    const { appointmentData } = props;
    return appointmentData;
  };

  const getAppointmentChanges = () => {
    //return appointmentChanges;
    return usePayLoad;
  };

  const getQualifications = async (selectedCompany) => {
    //setIsLoading(true)
    const response = await getApi(
      `${ENDPOINTS.QUALIFICATION_DATA}/${selectedCompany}`
    );
    if (response) {
      if (response.status == 200) {
        setQualifications(response?.data);
        let qualifications = response?.data.map((el) => ({
          value: el.id,
          label: `${el.code}-${el.description}`,
        }));

        setQualificationsDrop(qualifications);
        //setIsLoading(false)
      } else if (response.status == 401) {
        //setIsLoading(false)
        handleExpiredToken();
      } else {
        //setIsLoading(false)
        errorToast("Error loading qualifications!");
      }
    }
  };

  function setChecked(e) {
    var temp = pilotSelection;
    temp.filter((x) => x.pilotId === parseInt(e.target.value))[0].isAssigned =
      e.target.checked;
    setPilotSelection(temp);

    var selectedPilots = pilotSelection
      ?.filter((x) => x.isAssigned === true)
      .map((a) => a.pilotId);
    handleChange("pilotIds", selectedPilots);
  }

  const getPilots = async (qualificationId, cutOffDate) => {
    var user = getFromLocal("user");
    var userId = parseInt(user?.userId);

    var request = {
      companyCode: user?.companyCode,
      userId: userId,
      noofDaysInExpiry: 90,
      qualificationId: qualificationId,
      cutOffDate: cutOffDate,
    };

    if (qualificationId === 0 || cutOffDate === "") {
      setPilotSelection([]);
      getRecords([]);
      handleChange("pilotIds", []);
    } else {
      //setIsLoading(true)
      const response = await postApi(
        `${ENDPOINTS.PILOT_QUALIFICATION}`,
        request
      );
      if (response) {
        if (response.status == 200) {
          //setIsLoading(false)
          setPilotSelection(response?.data);
          getRecords(response?.data);
        } else if (response.status == 401) {
          //setIsLoading(false)
          handleExpiredToken();
        } else {
          //setIsLoading(false)
          errorToast("Error loading pilot list!");
        }
      }
    }
  };

  // const handleGetQualifications = (event) => {
  //   const selectedCompany = event.target.value;

  //   if (selectedCompany === "") {
  //     setQualifications([]);
  //   } else {
  //     getQualifications(selectedCompany);
  //   }
  // };

  const isNewAppointment = appointmentData.id === undefined;
  const applyChanges = isNewAppointment
    ? () => commitAppointment("added")
    : () => commitAppointment("changed");

  const handleClick = () => {
    setSubmitted(true);
    if (
      (trainingTypeSelected &&
        (usePayLoad.trainingTypeId === 0 ||
          usePayLoad.trainingDeviceId === 0 ||
          usePayLoad.aircraftTypeId === 0)) ||
      (!trainingTypeSelected && usePayLoad.qualificationId === 0)
    ) {
      return false;
    } else {
      visibleChange();
      applyChanges();
    }
  };
  const commitAppointment = (type) => {
    const { commitChanges } = props;
    const appointment = {
      ...getAppointmentData(),
      ...getAppointmentChanges(),
    };

    if (type === "deleted") {
      commitChanges({ [type]: appointment.id });
    } else if (type === "changed") {
      commitChanges({ [type]: { [appointment.id]: appointment } });
    } else {
      commitChanges({ [type]: usePayLoad });
    }
    setAppointmentChanges({});
  };

  const displayAppointmentData = {
    ...appointmentData,
    ...appointmentChanges,
  };

  const changeAppointment = ({ field, changes }) => {
    const nextChanges = {
      ...getAppointmentChanges(),
      [field]: changes,
    };
    handleChange(field[0], changes);
    setAppointmentChanges(nextChanges);
  };
  // const textEditorProps = (field) => ({
  //   variant: "outlined",
  //   onChange: ({ target: change }) =>
  //     changeAppointment({
  //       field: [field],
  //       changes: change.value,
  //     }),
  //   value: displayAppointmentData[field] || "",
  //   label: field[0].toUpperCase() + field.slice(1),
  //   className: classes.textField,
  // });

  // const pickerEditorProps = (field) => ({
  //   ampm: false,
  //   value: displayAppointmentData[field],
  //   onChange: (date) =>
  //     changeAppointment({
  //       field: [field],
  //       changes: date ? date.toDate() : new Date(displayAppointmentData[field]),
  //     }),
  //   invalidDateMessage: "",
  //   invalidLabel: "",
  //   maxDateMessage: "",
  //   inputVariant: "outlined",
  //   format: "DD/MM/YYYY HH:mm",
  //   onError: () => null,
  // });

  const handleChange = (type, value) => {
    var tempPayload = { ...usePayLoad };
    tempPayload[type] = value;
    setUsePayLoad(tempPayload);

    if (type === "endDate" || type === "qualificationId") {
      getPilots(tempPayload["qualificationId"], tempPayload["endDate"]);
    }
  };

  const cancelChanges = () => {
    setAppointmentChanges({});
    visibleChange();
    cancelAppointment();
  };

  const handleTrainingTypeChange = (status) => {
    // if (status == "Training Type") {
    //     setTrainingTypeSelected(true);
    //     handleChange("scheduleType", 0);
    // }
    if (status == "Qualification") {
      setTrainingTypeSelected(false);
      handleChange("scheduleType", 1);
    }
  };

  // function handleDaysChecked(e) {
  //   var days = daysChecked;
  //   if (e.target.checked) {
  //     if (!days.includes(e)) {
  //       days.push(parseInt(e));
  //       setDaysChecked(days);
  //       handleChange("daysOfWeek", days);
  //     }
  //   } else {
  //     var newArr = days.filter(function (item) {
  //       return item !== parseInt(e);
  //     });
  //     setDaysChecked(newArr);
  //     handleChange("daysOfWeek", newArr);
  //   }
  // }

  useEffect(() => {
    if (loadData) {
      if (usePayLoad?.scheduleType === 1) {
        setTrainingTypeSelected(false);
      } else {
        //setTrainingTypeSelected(true);
      }
      var user = getFromLocal("user");
      if (user?.companyCode) {
        getQualifications(user?.companyCode);
      }

      setLoadData(false);
    }

    if (formMasterData.length > 0) {
      let aircraft = formMasterData?.aircraftTypes.map((aircraftType) => ({
        value: aircraftType.id,
        label: `${aircraftType.id}-${aircraftType.name}`,
      }));

      setAircraft(aircraft);

      let device = formMasterData?.trainingDevices.map((trainingDevice) => ({
        value: trainingDevice.id,
        label: `${trainingDevice.name}`,
      }));

      setTrainingDevice(device);

      let type = formMasterData?.trainingTypes.map((types) => ({
        value: types.id,
        label: `${types.type}`,
      }));

      setTrainingType(type);
    }
  }, [usePayLoad]);

  const datePickerChange = (type, date) => {
    setStartDate(date);

    let val = date ? date : new Date(displayAppointmentData[type]);
    changeAppointment({
      field: [type],
      changes: val,
    });
  };

  const TableControls = (
    <div className="col-12 d-flex align-items-center p-0">
      <div className="col-md-3 p-0 align-items-center">
        <PageDetails pagination={pagination} />
      </div>
      <div className="d-flex col-md-9 justify-content-end pr-0">
        <div className="flex-wrap d-flex align-items-center justify-content-center">
          <div className="">
            <div className="col-md-12 col-sm-4 col-8">
              <InputSearch
                placeholder="search here..."
                className="w-100"
                onChange={handleChangeWithLib}
                //   value={searchText}
              />
            </div>
          </div>
        </div>

        <div className="col-xs-3 d-flex align-items-center justify-content-center pe-0">
          <Badge
            className="rounded-50"
            variant="primary"
            size="sm"
            icon={ArrowLeft}
            title="Previous Page"
            disabled={!pagination?.prevPageUrl}
            onClick={() => handlePagination("prev")}
          />
          <Badge
            className="rounded-50 ms-2"
            variant="primary"
            size="sm"
            icon={ArrowRight}
            title="Next Page"
            disabled={!pagination?.nextPageUrl}
            onClick={() => handlePagination("next")}
          />
        </div>
      </div>
    </div>
  );

  const scheduleForm = () => {
    return (
      <div className="row m-0">
        <h6>
          {isNewAppointment
            ? "Training Schedule Request"
            : "Scheduled Training"}
        </h6>
        <div className="w-80">
          <hr></hr>
        </div>
        <div className="col-12 mb-3 d-flex align-items-center">
          <Input
            type="radio"
            value={0}
            checked={trainingTypeSelected}
            onChange={() => handleTrainingTypeChange("Training Type")}
            disabled={!isNewAppointment}
            containerClassName="h7-web"
          />
          &nbsp;<label className="m-0 h6">Training Type</label>
          &nbsp;&nbsp;&nbsp;
          <Input
            type="radio"
            value={1}
            checked={!trainingTypeSelected}
            onChange={() => handleTrainingTypeChange("Qualification")}
            disabled={!isNewAppointment}
            containerClassName="h7-web"
          />
          &nbsp;<label className="m-0 h6">Qualification</label>
        </div>
        {trainingTypeSelected && (
          <div className="row p-0 m-0 mb-3 ">
            <div className="col-12 d-flex">
              <label className="col-3 pl-0 h6">Training Type</label>
              <Dropdown
                Shadow
                selectOptions={trainingType}
                value={ConstructObject(
                  trainingType,
                  usePayLoad?.trainingTypeId
                )}
                onChange={(e) => handleChange("trainingTypeId", parseInt(e))}
                containerClassName={"w-100 p-0 h7-web"}
                isDisabled={!isNewAppointment}
              />
            </div>
            {submitted && usePayLoad.trainingTypeId === 0 && (
              <span className="error h6-web">Please select training type</span>
            )}
          </div>
        )}
        {!trainingTypeSelected && (
          <>
            <div className="row p-0 m-0 mb-3 ">
              <div className="col-12 d-flex">
                <label className="col-3 pl-0 h6">Qualification</label>

                <Dropdown
                  Shadow
                  selectOptions={qualificationsDrop}
                  value={ConstructObject(
                    qualificationsDrop,
                    usePayLoad?.qualificationId
                  )}
                  onChange={(e) => handleChange("qualificationId", parseInt(e))}
                  containerClassName={"w-100 p-0 col-9 border-0 h7-web"}
                  isDisabled={!isNewAppointment}
                />
              </div>
              {submitted && usePayLoad.qualificationId === 0 && (
                <span className="error h6-web">
                  Please select Qualification
                </span>
              )}
            </div>
          </>
        )}
        <div className="col-12 mb-3 d-flex">
          <label className="col-3 pl-0 h6">Title</label>
          <Input
            containerClassName="col-9 p-0 h6 h7-web"
            className="w-100"
            Shadow
            value={usePayLoad?.title}
            onChange={(e) => handleChange("title", e)}
            type="text"
            placeholder="Enter Training Description"
            readOnly={!isNewAppointment}
          />
        </div>

        <div className="col-12 d-flex align-items-center calender-date  mb-3">
          <label className="col-3  pl-0 h6">Cut Off Date</label>
          <div className="col-9 p-0">
            {/* <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDateTimePicker
                className="w-100 h6 "
                {...pickerEditorProps("endDate")}
              />
            </MuiPickersUtilsProvider> */}

            <DatePickers
              selected={startDate}
              onChange={(e) => datePickerChange("endDate", e)}
              showTimeSelect
              dateFormat="MMMM d, yyyy h:mm aa"
              timeIntervals={1}
              className="shadow-text col-12 h7-web picker-height"
              minDate={moment().toDate()}
              disabled={!isNewAppointment}
            />
          </div>
        </div>

        {/* <div className='col-12 mb-3'>
                    <label className='col-3 pl-0 h6'>Max Attendees</label>
                    <input className='border col-9 pr-0 rounded p-2' value={usePayLoad?.maxAttendees} onChange={(e) => handleChange('maxAttendees', parseInt(e))} type='number' placeholder='Enter Max Attendees' />
                </div> */}
        {trainingTypeSelected && (
          <>
            <div className="row p-0 m-0 mb-3">
              <div className="col-12 d-flex">
                <label className="col-3 pl-0 h6">Training Device</label>
                <Dropdown
                  Shadow
                  selectOptions={TrainingDevice}
                  value={ConstructObject(
                    TrainingDevice,
                    usePayLoad?.trainingDeviceId
                  )}
                  onChange={(e) =>
                    handleChange("trainingDeviceId", parseInt(e))
                  }
                  containerClassName={"w-100 p-0 h7-web "}
                  isDisabled={!isNewAppointment}
                />
              </div>
              {submitted && usePayLoad.trainingDeviceId === 0 && (
                <span className="error h6-web">
                  Please select Training Device
                </span>
              )}
            </div>
            <div className="row p-0 m-0 mb-3 ">
              <div className="col-12 d-flex">
                <label className="col-3 pl-0 h6">Aircraft Type</label>

                <Dropdown
                  Shadow
                  selectOptions={Aircraft}
                  value={ConstructObject(Aircraft, usePayLoad?.aircraftTypeId)}
                  onChange={(e) => handleChange("aircraftTypeId", parseInt(e))}
                  containerClassName={"w-100 p-0 h7-web "}
                  isDisabled={!isNewAppointment}
                />
              </div>
              {submitted && usePayLoad.aircraftTypeId === 0 && (
                <span className="error h6-web">
                  Please select Aircraft type
                </span>
              )}
            </div>
          </>
        )}
        {pilotSelection?.length > 0 && (
          <>
            <div className="col-12 mb-0 d-flex">
              <label className="col-3 pl-0 h6">Select Pilots</label>
            </div>
            <div className="col-12 mb-3 d-flex">
              <div className="col-md-12 pl-0 pr-0">
                <div className="d-flex align-items-center p-2">
                  {searchText == ""
                    ? rowItems.length > 0 && TableControls
                    : TableControls}
                </div>
                <div className="mt-1">
                  <table className="w-100 custom-table">
                    <tr className="h7 text-grey1">
                      <th>Select</th>
                      <th>Name</th>
                    </tr>
                    <tbody className="h7-web ">
                      {rowItems && rowItems.length > 0 ? (
                        rowItems.map((row, rowIndex) => (
                          <tr className=" m-2">
                            <td>
                              <Checkbox
                                value={row.pilotId}
                                size="xs"
                                checked={row.isAssigned}
                                onChange={setChecked}
                                readOnly={!isNewAppointment}
                              />
                            </td>
                            <td>{row.pilotName}</td>
                          </tr>
                        ))
                      ) : (
                        <tr className="text-center h6-web">No records found</tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="col-12 mb-3 d-flex">
          <label className="col-3 pl-0 h6">Description</label>
          <TextArea
            Shadow
            className="col-9 p-0"
            value={usePayLoad?.description}
            onChange={(e) => handleChange("description", e)}
            type="text"
            placeholder=" Enter Description"
            readOnly={!isNewAppointment}
          />
        </div>
        <div className="col-12 mb-3 d-flex">
          <label className="col-3 pl-0 h6">Location</label>

          <Dropdown
            selectOptions={filterLocationOptions}
            Shadow
            value={ConstructObject(filterLocationOptions, usePayLoad?.location)}
            onChange={(e) => handleChange("location", e)}
            containerClassName={"w-100 p-0 col-9 h7-web "}
            isDisabled={!isNewAppointment}
          />
          {/* <input className='border col-9 h6 rounded p-2' type='text' value={usePayLoad?.location} onChange={(e) => handleChange('location', e)} /> */}
        </div>
        <div className={"d-flex justify-content-end col-12 "}>
          {/* {!isNewAppointment && (
            <Button
              className="rounded h6-web w-80 mr-2"
              size="lg"
              title={"Delete"}
              variant="primary"
              onClick={() => {
                visibleChange();
                commitAppointment("deleted");
              }}
            />
          )}

          <Button
            className="rounded h6-web w-80"
            size="lg"
            title={isNewAppointment ? "Create" : "Save"}
            variant="primary"
            onClick={() => {
              handleClick();
            }}
          /> */}

          {isNewAppointment ? (
            <Button
              className="rounded h6-web w-80"
              size="lg"
              title={"Create"}
              variant="primary"
              onClick={() => {
                handleClick();
              }}
            />
          ) : null}
        </div>
      </div>
    );
  };
  return (
    <>
      {visible && (
        <Modal
          open={visible}
          onClose={() => cancelChanges()}
          showCloseIcon={true}
          children={scheduleForm()}
        ></Modal>
      )}
    </>
  );
};
export default memo(RequestTrainingSchedule);
