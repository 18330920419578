import React from 'react';
import { Modal } from "antd";
import Loaders from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./Loader.scss";

const Loader = (props) => {
    return (
        <Modal visible={true} centered={true} className="loader-modal" maskStyle={{ opacity: "0.3" }}
            footer={null} closable={false} width={"unset"} transitionName="none">
            <Loaders type="Puff" color="#EA353D" height={100} width={100}/>
        </Modal>
    )
}

export default Loader;