import "./Users.scss";
import endpoint from "../../utils/endpoints";
import React, { useState, useEffect } from "react";
import HeaderTitle from "../../components/HeaderTitle/HeaderTitle";
import { withStyles } from "@material-ui/core/styles";
import { TableCell, TableRow } from "@material-ui/core";
import DataTable from "../../components/DataTable/DataTable";
import { Modal } from "antd";
import { confirmAlert, errorToast } from "../../components/Generic/Generic";
import API from "../../utils/Api";
import Loader from "../../components/Loader/Loader";
import { getFromLocal } from "../../utils/generic";
import "antd/dist/antd.css";
import { EditTwoTone, DeleteTwoTone } from "@ant-design/icons";
import { successDialog, errorDialog } from "../../components/Generic/Generic";

const columns = [
  {
    title: "User Name",
    showSort: true,
    id: "Name",
  },
  {
    title: "Login-In Id",
    showSort: true,
    id: "UserName",
  },
  {
    title: "Role Name",
    showSort: true,
    id: "RoleName",
  },
  {
    title: "LIC Number",
    showSort: true,
    id: "LICNumber",
  },
  {
    title: "LOA Number",
    showSort: true,
    id: "LOANumber",
  },
  {
    title: "Status",
    showSort: true,
    id: "Status",
  },
  {
    title: "Actions",
    showSort: false,
  },
];

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#33485D",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    overflowWrap: "anywhere",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "white",
      borderTop: "1px solid #E5E5E5",
    },
  },
}))(TableRow);

const Users = (props) => {
  const [isBusy, setBusy] = useState(true);
  const [records, getRecords] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("CreatedOn");
  var [finalRow, setFinalRow] = useState(records);
  var [searchText, setSearchText] = useState("");

  const [showUserModal, setShowUserModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [modalTitle, setmodalTitle] = useState("Add User");
  const [emailField, setEmailFieldValue] = useState("");
  const [fullNameField, setFullNameFieldValue] = useState("");
  const [isAdd, setAdd] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const [, setView] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [showCompany, setShowCompany] = useState(false);

  const [licNumberField, setlicNumberFieldValue] = useState("");
  const [licNumberValidityField, setlicNumberValidityFieldValue] = useState("");
  const [loaNumberField, setloaNumberFieldValue] = useState("");
  const [loaNumberValidityField, setloaNumberValidityFieldValue] = useState("");
  const [otrNumberField, setotrNumberFieldValue] = useState("");
  const [otrNumberValidityField, setotrNumberValidityFieldValue] = useState("");
  const [positionId, setPositionId] = useState("");
  const [RoleId, setRoleId] = useState("");
  const [RoleName, setRoleName] = useState("");
  const [companyCode, setCompanyCode] = useState("");
  const [originalCompanyCode, setOriginalCompanyCode] = useState("");

  const [userPositions, setUserPositions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    if (records) {
      setFinalRow(records);
    }
  }, [records]);

  useEffect(() => {
    if (searchText) {
      var searchTxt = searchText.toString().trim().toLowerCase();
      var newRows = records?.filter((value) => {
        return (
          value.name?.toString().toLowerCase().includes(searchTxt) ||
          value.userName?.toLowerCase().includes(searchTxt) ||
          value.status?.toLowerCase().includes(searchTxt) ||
          value.roleName?.toString().toLowerCase().includes(searchTxt)
        );
      });
      setFinalRow(newRows);
    } else {
      setFinalRow(records);
    }
  }, [searchText, records]);

  useEffect(() => {
    var user = getFromLocal("user");
    var pageName = {};
    pageName = user.userRolePermissions;
    var pageActions = pageName.filter((r) => r.pageName === "Users");
    var actions = pageActions.map((a) => a.pageActions);
    if (actions.length > 0) {
      var action = actions[0].map((b) => b.actionName);
      action.forEach(actionsList);
      function actionsList(action, index, arr) {
        if (action === "Add") {
          setAdd(true);
        }
        if (action === "Update") {
          setUpdate(true);
        }
        if (action === "View") {
          setView(true);
        }
        if (action === "Delete") {
          setDelete(true);
        }
      }
    }
  }, []);

  const getUsersData = async (id) => {
    var user = getFromLocal("user");
    var userId = parseInt(user?.userId);
    var headers = {
      UserId: userId,
    };
    API.getWithHeader(endpoint.GetUsers, headers, (isSuccess, response) => {
      if (isSuccess) {
        getRecords(response);
        setBusy(false);
      } else {
        errorToast(response.statusText);
        setBusy(false);
      }
    });
  };

  useEffect(() => {
    setBusy(true);
    getRoles();
    getUsersData();
    getPositions();
    getCompanies();
  }, []);

  const handleDelete = async (id) => {
    var user = getFromLocal("user");

    const handleOk = async () => {
      var data = {
        EntityID: id,
        ModifiedBy: parseInt(user["userId"]),
      };
      await deleteUserDetails(data);
    };

    const handleCancel = () => {};

    confirmAlert(
      "Do you want to delete these items?",
      "",
      handleOk,
      handleCancel
    );
  };

  async function deleteUserDetails(data) {
    setBusy(true);
    var user = getFromLocal("user");
    var userId = parseInt(user?.userId);
    var headers = {
      UserId: userId,
    };
    await API.postWithHeader(
      endpoint.DeleteUser,
      headers,
      data,
      (isSuccess, response) => {
        if (isSuccess) {
          getUsersData();
        } else {
          errorToast(response.statusText);
          setBusy(false);
        }
      }
    );
  }

  async function saveFormDetails(formData) {
    var data = null;
    var user = getFromLocal("user");
    var userId = parseInt(user?.userId);
    var headers = {
      UserId: userId,
    };
    await API.postWithHeader(
      endpoint.AddUser,
      headers,
      formData,
      (isSuccess, response) => {
        if (isSuccess) {
          data = "success";
        } else {
          errorToast(response?.data?.title);
        }
      }
    );
    return data;
  }

  async function updateFormDetails(formData) {
    var data = null;
    var user = getFromLocal("user");
    var userId = parseInt(user?.userId);
    var headers = {
      UserId: userId,
    };
    await API.postWithHeader(
      endpoint.UpdateUser,
      headers,
      formData,
      (isSuccess, response) => {
        if (isSuccess) {
          data = "success";
        } else {
          errorToast(response.statusText);
        }
      }
    );
    return data;
  }

  const getUserModal = (title, data) => {
    if (data) {
      setFullNameFieldValue(data?.name);
      setEmailFieldValue(data?.userName);
      setlicNumberFieldValue(data?.licNumber);
      setloaNumberFieldValue(data?.loaNumber);
      setotrNumberFieldValue(data?.otrNumber);
      setRoleId(data?.roleId);
      setRoleName(data?.roleName);
      if (data?.roleId === 6) {
        setShowCompany(true);
      } else {
        setShowCompany(false);
      }
      setCompanyCode(data?.companyCode);
      setOriginalCompanyCode(data?.companyCode);
      setlicNumberValidityFieldValue(data?.licNumberValidity);
      setloaNumberValidityFieldValue(data?.loaNumberValidity);
      setotrNumberValidityFieldValue(data?.otrNumberValidity);
      setPositionId(data?.positionId);
    } else {
      resetModalField();
    }
    setModalData(data);
    setmodalTitle(title);
    setShowUserModal(true);
  };

  const handleModalSubmit = async (e) => {
    setBusy(true);
    e.preventDefault();
    var fields = [];
    fields = e.target;
    var user = getFromLocal("user");
    var response = null;
    if (modalTitle === "Add User") {
      var ProcessFields = {
        UserId: user["userId"],
        EntityKey: fields["RoleId"].value,
        ChildEntityKey: fields["FullName"].value,
        UserEmail: fields["EmailId"].value,
        LOANumber: fields["LOANumber"].value,
        LICNumber: fields["LICNumber"].value,
        LOAValidityStr: fields["LOANumberValidity"].value,
        LICValidityStr: fields["LICNumberValidity"].value,
        PositionId: parseInt(fields["PositionId"].value),
        OTRNumber: fields["OTRNumber"].value,
        OTRValidityStr: fields["OTRNumberValidity"].value,
        CompanyCode: fields["CompanyCode"]?.value,
      };
      response = await saveFormDetails(ProcessFields);
    } else {
      var payload = {
        ID: modalData?.id,
        EntityKey: fields["EmailId"].value,
        ChildEntityKey: fields["FullName"].value,
        EntityID: parseInt(fields["RoleId"].value),
        ModifiedBy: user?.userId,
        LOANumber: fields["LOANumber"].value,
        LICNumber: fields["LICNumber"].value,
        LOAValidityStr: fields["LOANumberValidity"].value,
        LICValidityStr: fields["LICNumberValidity"].value,
        PositionId: parseInt(fields["PositionId"].value),
        OTRNumber: fields["OTRNumber"].value,
        OTRValidityStr: fields["OTRNumberValidity"].value,
        CompanyCode: fields["CompanyCode"]?.value,
      };
      response = await updateFormDetails(payload);
    }

    if (response === "success") {
      successDialog("Success", () => {
        resetModalField();
        getUsersData();
      });
    } else {
      errorDialog("Error Processing Request");
      resetModalField();
      setBusy(false);
    }
    setShowUserModal(false);
  };

  const resetModalField = () => {
    setFullNameFieldValue("");
    setEmailFieldValue("");
    setRoleId("");
    setRoleName("");
    setCompanyCode("");
    setOriginalCompanyCode("");
    setShowCompany(false);
    setPositionId("");
    setlicNumberFieldValue("");
    setlicNumberValidityFieldValue("");
    setloaNumberFieldValue("");
    setloaNumberValidityFieldValue("");
    setotrNumberFieldValue("");
    setotrNumberValidityFieldValue("");
  };

  const handleCancel = () => {
    setShowUserModal(false);
  };

  function changeNameFieldValue(e) {
    setFullNameFieldValue(e.target.value);
  }

  function changeEmailFieldValue(e) {
    setEmailFieldValue(e.target.value);
  }

  function changelicNumberFieldValue(e) {
    setlicNumberFieldValue(e.target.value);
  }

  function changeloaNumberFieldValue(e) {
    setloaNumberFieldValue(e.target.value);
  }

  function changeotrNumberFieldValue(e) {
    setotrNumberFieldValue(e.target.value);
  }

  function changelicNumberValidityFieldValue(e) {
    setlicNumberValidityFieldValue(e.target.value);
  }

  function changeloaNumberValidityFieldValue(e) {
    setloaNumberValidityFieldValue(e.target.value);
  }

  function changeotrNumberValidityFieldValue(e) {
    setotrNumberValidityFieldValue(e.target.value);
  }

  function changePositionDropDownValue(e) {
    setPositionId(e.target.value);
  }

  function changeRoleDropDownValue(e) {
    setRoleId(e.target.value);
    setCompanyCode(originalCompanyCode);
    if (e.target.value === "6") {
      setShowCompany(true);
    } else {
      setShowCompany(false);
    }
  }

  function changeCompanyDropDownValue(e) {
    setCompanyCode(e.target.value);
  }

  async function getPositions() {
    var type = {
      EntityKey: "tbl_lites_training_position_master",
    };
    await API.getByQuery(endpoint.getMaster, type, (isSuccess, response) => {
      if (isSuccess) {
        setUserPositions(response);
      } else {
        errorToast(response.statusText);
      }
    });
  }

  async function getRoles() {
    var user = getFromLocal("user");
    var type = {
      EntityKey: "tbl_lites_training_role_master",
      ChildEntityKey: user.roleName,
    };
    await API.getByQuery(endpoint.getMaster, type, (isSuccess, response) => {
      if (isSuccess) {
        setUserRoles(response);
      } else {
        errorToast(response.statusText);
      }
    });
  }

  async function getCompanies() {
    var url = `${endpoint.ScheduleMasterData}`;

    var request = {
      includeTrainingTypes: false,
      includeCompanyCodes: true,
      includeAircraftTypes: false,
      includeTrainingDevices: false,
    };

    await API.post(url, request, (isSuccess, response) => {
      if (isSuccess) {
        setCompanies(response?.companies);
      } else {
        errorToast("Error Loading Companies");
      }
    });
  }

  const renderTableBody = (row, key) => {
    return (
      <StyledTableRow key={key}>
        <StyledTableCell>{row.name}</StyledTableCell>
        <StyledTableCell>{row.userName}</StyledTableCell>
        <StyledTableCell>{row.roleName}</StyledTableCell>
        <StyledTableCell>{row.licNumber}</StyledTableCell>
        <StyledTableCell>{row.loaNumber}</StyledTableCell>
        <StyledTableCell>{row.status}</StyledTableCell>
        <StyledTableCell style={{ width: "100px" }}>
          {isUpdate ? (
            <label onClick={() => getUserModal("Edit User", row)}>
              <EditTwoTone
                style={{ fontSize: "17px", cursor: "pointer" }}
                title="Edit User"
              />
            </label>
          ) : null}{" "}
          &nbsp;&nbsp;
          {isDelete ? (
            <DeleteTwoTone
              style={{ fontSize: "17px", cursor: "pointer" }}
              onClick={() => handleDelete(row.id)}
              title="Delete"
            />
          ) : null}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const renderUserModal = () => {
    return (
      <Modal
        title={modalTitle}
        visible={true}
        okText={modalTitle === "Add User" ? "Add" : "Update"}
        width={700}
        onCancel={handleCancel}
        footer={null}
      >
        <form onSubmit={handleModalSubmit}>
          {modalTitle === "Add User" && (
            <div className="form-group row">
              <label className="col-sm-3 col-form-label">User Type</label>
              <div className="col-sm-9">
                <select
                  className="form-control form-control-sm"
                  name="RoleId"
                  required
                  value={RoleId}
                  onChange={changeRoleDropDownValue}
                >
                  <option value="">-----Select-----</option>
                  {userRoles.map((e, index) => {
                    return (
                      <option key={index} value={e.Id}>
                        {e.RoleName}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          )}
          {modalTitle !== "Add User" && (
            <div className="form-group row">
              <label className="col-sm-3 col-form-label">User Type</label>
              <div className="col-sm-9">
                <input
                  type="text"
                  disabled
                  className="form-control"
                  value={RoleName}
                  name="Role Name"
                  placeholder="Role Name"
                />
              </div>
              <input type="hidden" name="RoleId" value={RoleId} />
            </div>
          )}
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">Name</label>
            <div className="col-sm-9">
              <input
                type="text"
                required
                className="form-control"
                value={fullNameField}
                onChange={changeNameFieldValue}
                name="FullName"
                placeholder="Full name"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">Email ID</label>
            <div className="col-sm-9">
              <input
                type="text"
                required
                className="form-control"
                value={emailField}
                onChange={changeEmailFieldValue}
                name="EmailId"
                placeholder="Email ID"
              />
            </div>
          </div>
          {showCompany && (
            <div className="form-group row">
              <label className="col-sm-3 col-form-label">Company</label>
              <div className="col-sm-9">
                <select
                  className="form-control form-control-sm"
                  name="CompanyCode"
                  required
                  value={companyCode}
                  onChange={changeCompanyDropDownValue}
                >
                  <option value="">-----Select-----</option>
                  {companies.map((e, index) => {
                    return (
                      <option key={index} value={e.code}>
                        {e.code} - {e.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          )}
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">Positions</label>
            <div className="col-sm-9">
              <select
                className="form-control"
                name="PositionId"
                required
                value={positionId}
                onChange={changePositionDropDownValue}
              >
                <option value="">--Select--</option>
                {userPositions.map((e, index) => {
                  return (
                    <option key={index} value={e.Id}>
                      {e.Name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">LIC Number</label>
            <div className="col-sm-9">
              <input
                type="text"
                required
                className="form-control"
                value={licNumberField}
                onChange={changelicNumberFieldValue}
                name="LICNumber"
                placeholder="LIC Number"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">
              LIC Number Validity
            </label>
            <div className="col-sm-9">
              <input
                type="date"
                required
                className="form-control"
                value={licNumberValidityField}
                onChange={changelicNumberValidityFieldValue}
                name="LICNumberValidity"
                placeholder="LIC Number Validity"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">LOA Number</label>
            <div className="col-sm-9">
              <input
                type="text"
                required
                className="form-control"
                value={loaNumberField}
                onChange={changeloaNumberFieldValue}
                name="LOANumber"
                placeholder="LOA Number"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">
              LOA Number Validity
            </label>
            <div className="col-sm-9">
              <input
                type="date"
                required
                className="form-control"
                value={loaNumberValidityField}
                onChange={changeloaNumberValidityFieldValue}
                name="LOANumberValidity"
                placeholder="LOA Number Validity"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">OTR Number</label>
            <div className="col-sm-9">
              <input
                type="text"
                required
                className="form-control"
                value={otrNumberField}
                onChange={changeotrNumberFieldValue}
                name="OTRNumber"
                placeholder="OTR Number"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">
              OTR Number Validity
            </label>
            <div className="col-sm-9">
              <input
                type="date"
                required
                className="form-control"
                value={otrNumberValidityField}
                onChange={changeotrNumberValidityFieldValue}
                name="OTRNumberValidity"
                placeholder="OTR Number Validity"
              />
            </div>
          </div>
          <button type="submit" className="btn btn-primary">
            {modalTitle === "Add User" ? "Add" : "Update"}
          </button>
          &nbsp;&nbsp;&nbsp;
          <button onClick={handleCancel} className="btn btn-primary">
            Cancel
          </button>
        </form>
      </Modal>
    );
  };

  return (
    <div className="main-content">
      <div className="row">
        {isBusy && <Loader />}
        {showUserModal && renderUserModal()}
        <div className="col-8">
          <HeaderTitle title="Users" />
        </div>
        {isAdd ? (
          <div className="col-4">
            <button
              type="submit"
              onClick={() => getUserModal("Add User", null)}
              className="btn btn-primary float-right"
            >
              Add User
            </button>
          </div>
        ) : null}
      </div>
      <DataTable
        setSearchText={setSearchText}
        columns={columns}
        rows={finalRow}
        order={order}
        orderBy={orderBy}
        setOrder={(e) => setOrder(e)}
        setOrderBy={(e) => setOrderBy(e)}
        renderTableBody={(row, key) => renderTableBody(row, key)}
      />
    </div>
  );
};

export default Users;
