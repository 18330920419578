import "./Profile.scss";
import endpoint from "../../utils/endpoints";
import React, { useState, useEffect } from "react";
import { errorToast } from "../../components/Generic/Generic";
import API from "../../utils/Api";
import Loader from "../../components/Loader/Loader";
import { getFromLocal } from "../../utils/generic";
import "antd/dist/antd.css";
import { successDialog, errorDialog } from "../../components/Generic/Generic";

const Profile = (props) => {
  const [isBusy, setBusy] = useState(true);

  const [emailField, setEmailFieldValue] = useState("");
  const [fullNameField, setFullNameFieldValue] = useState("");
  const [licNumberField, setlicNumberFieldValue] = useState("");
  const [licNumberValidityField, setlicNumberValidityFieldValue] = useState("");
  const [loaNumberField, setloaNumberFieldValue] = useState("");
  const [loaNumberValidityField, setloaNumberValidityFieldValue] = useState("");
  const [positionId, setPositionId] = useState("");

  const [userPositions, setUserPositions] = useState([]);

  const getUsersData = async () => {
    let user = getFromLocal("user");
    var processFields = {};
    processFields.EntityID = parseInt(user?.userId);
    user = getFromLocal("user");
    var userId = parseInt(user?.userId);
    var headers = {
      UserId: userId,
    };
    API.getByQueryWithHeader(
      endpoint.GetUsers,
      headers,
      processFields,
      (isSuccess, response) => {
        if (isSuccess) {
          patchForm(response);
          setBusy(false);
        } else {
          errorToast(response.statusText);
          setBusy(false);
        }
      }
    );
  };

  useEffect(() => {
    setBusy(true);
    getUsersData();
    getPositions();
  }, []);

  async function updateFormDetails(formData) {
    var data = null;
    var user = getFromLocal("user");
    var userId = parseInt(user?.userId);
    var headers = {
      UserId: userId,
    };
    await API.postWithHeader(
      endpoint.UpdateUser,
      headers,
      formData,
      (isSuccess, response) => {
        if (isSuccess) {
          data = "success";
        } else {
          errorToast(response?.data?.title);
        }
      }
    );
    return data;
  }

  const handleModalSubmit = async (e) => {
    setBusy(true);
    e.preventDefault();
    var fields = [];
    fields = e.target;
    var user = getFromLocal("user");
    var response = null;

    var payload = {
      ID: user?.userId,
      EntityKey: fields["EmailId"].value,
      ChildEntityKey: fields["FullName"].value,
      EntityID: parseInt(fields["RoleId"].value),
      ModifiedBy: user?.userId,
      LOANumber: fields["LOANumber"].value,
      LICNumber: fields["LICNumber"].value,
      LOAValidityStr: fields["LOANumberValidity"].value,
      LICValidityStr: fields["LICNumberValidity"].value,
      PositionId: parseInt(fields["PositionId"].value),
    };
    response = await updateFormDetails(payload);

    if (response === "success") {
      setBusy(false);
      successDialog("Success", () => {});
    } else {
      errorDialog("User Not Exists");
      setBusy(false);
    }
  };
  function patchForm(data) {
    setFullNameFieldValue(data[0].name);
    setEmailFieldValue(data[0].userName);
    setlicNumberFieldValue(data[0].licNumber);
    setloaNumberFieldValue(data[0].loaNumber);
    setlicNumberValidityFieldValue(data[0].licNumberValidity);
    setloaNumberValidityFieldValue(data[0].loaNumberValidity);
    setPositionId(data[0].positionId);
  }

  function changeNameFieldValue(e) {
    setFullNameFieldValue(e.target.value);
  }

  function changeEmailFieldValue(e) {
    setEmailFieldValue(e.target.value);
  }

  function changelicNumberFieldValue(e) {
    setlicNumberFieldValue(e.target.value);
  }

  function changeloaNumberFieldValue(e) {
    setloaNumberFieldValue(e.target.value);
  }

  function changelicNumberValidityFieldValue(e) {
    setlicNumberValidityFieldValue(e.target.value);
  }

  function changeloaNumberValidityFieldValue(e) {
    setloaNumberValidityFieldValue(e.target.value);
  }

  function changePositionDropDownValue(e) {
    setPositionId(e.target.value);
  }

  async function getPositions() {
    var type = {
      EntityKey: "tbl_lites_training_position_master",
    };
    await API.getByQuery(endpoint.getMaster, type, (isSuccess, response) => {
      if (isSuccess) {
        setUserPositions(response);
      } else {
        errorToast(response.statusText);
      }
    });
  }

  return (
    <div className="main-content">
      <div className="row">
        {isBusy && <Loader />}
        <form className="container" onSubmit={handleModalSubmit}>
          <div className="row" style={{ marginBottom: "15px" }}>
            <div className="col-md-6">
              <label className="form-label">User Type</label>
              <select className="form-control" disabled name="RoleId">
                <option value="1" defaultValue>
                  Instructor
                </option>
                <option value="2">Head</option>
                <option value="3">Director</option>
                <option value="4">Pilot</option>
              </select>
            </div>
            <div className="col-md-6">
              <label className="form-label">Name</label>
              <input
                type="text"
                required
                className="form-control"
                value={fullNameField}
                onChange={changeNameFieldValue}
                name="FullName"
                placeholder="Full name"
              />
            </div>
          </div>

          <div className="row" style={{ marginBottom: "15px" }}>
            <div className="col-md-6">
              <label className="form-label">Email ID</label>
              <input
                type="text"
                disabled
                required
                className="form-control"
                value={emailField}
                onChange={changeEmailFieldValue}
                name="EmailId"
                placeholder="Email ID"
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">Category</label>
              <select
                className="form-control"
                name="PositionId"
                required
                value={positionId}
                onChange={changePositionDropDownValue}
              >
                <option value="">--Select--</option>
                {userPositions.map((e, index) => {
                  return (
                    <option key={index} value={e.Id}>
                      {e.Name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="row" style={{ marginBottom: "15px" }}>
            <div className="col-md-6">
              <label className="form-label">LIC Number</label>
              <input
                type="text"
                required
                className="form-control"
                value={licNumberField}
                onChange={changelicNumberFieldValue}
                name="LICNumber"
                placeholder="LIC Number"
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">LIC Validity</label>
              <input
                type="date"
                required
                className="form-control"
                value={licNumberValidityField}
                onChange={changelicNumberValidityFieldValue}
                name="LICNumberValidity"
                placeholder="LIC Number Validity"
              />
            </div>
          </div>

          <div className="row" style={{ marginBottom: "15px" }}>
            <div className="col-md-6">
              <label className="form-label">LOA Number</label>
              <input
                type="text"
                required
                className="form-control"
                value={loaNumberField}
                onChange={changeloaNumberFieldValue}
                name="LOANumber"
                placeholder="LOA Number"
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">LOA Validity</label>
              <input
                type="date"
                required
                className="form-control"
                value={loaNumberValidityField}
                onChange={changeloaNumberValidityFieldValue}
                name="LOANumberValidity"
                placeholder="LOA Number Validity"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <button type="submit" className="btn btn-primary">
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;
