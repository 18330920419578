import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import routes from "../../../routes";
import Template from "../../../Template";

const AuthLayout = (props) => {
  const getRoutes = () => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => container(prop)}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const container = (prop) => {
    let Component = prop.component;
    return (
      <>
        {prop?.isNewDesign ? (
          <Template isActive={prop} {...prop}>
            {" "}
            <Component {...prop} {...props} />
          </Template>
        ) : (
          <Component {...prop} {...props} />
        )}
      </>
    );
  };

  return (
    <Switch>
      {getRoutes()}
      <Redirect from="*" to="/auth/login" />
    </Switch>
  );
};

export default AuthLayout;
