import React, { useState, createContext } from "react";

const ModalContext = createContext(null);
ModalContext.displayName = "ModalContext";

const ModalProvider = ({ children }) => {
  const [modalInstances, setModalInstances] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const expiredToken = (text, handleDeleteOk) => {
    let newInstances = [...modalInstances];
    newInstances = [
      ...newInstances,
      {
        id: newInstances?.length,
        showModal: true,
        content: text,
        handleDeleteOk: () => handleDeleteOk(newInstances?.length),
      },
    ];
    setModalInstances(newInstances);
  };

  const resetSession = (text, content, logOut, stayActive) => {
    let newInstances = [...modalInstances];
    newInstances = [
      ...newInstances,
      {
        id: newInstances?.length,
        showModal: true,
        content: content,
        logOut: logOut,
        stayActive: stayActive,
      },
    ];
    setModalInstances(newInstances);
  };

  const deleteUsers = (text, onOk, onCancel) => {
    let newInstances = [...modalInstances];
    newInstances = [
      ...newInstances,
      {
        id: newInstances?.length,
        showModal: true,
        content: text,
        onOk: () => onOk(newInstances?.length),
        onCancel: () => onCancel(newInstances?.length),
      },
    ];
    setModalInstances(newInstances);
  };

  const deleteModal = (rowId, text, handleDeleteOk, handleDeleteCancel) => {
    let newInstances = [...modalInstances];
    newInstances = [
      ...newInstances,
      {
        id: newInstances?.length, // modal id
        rowId: rowId,
        showModal: true,
        content: text,
        handleDeleteOk: () => handleDeleteOk(rowId, newInstances?.length),
        handleDeleteCancel: () =>
          handleDeleteCancel(newInstances?.length),
      },
    ];
    setModalInstances(newInstances);
  };

  return (
    <ModalContext.Provider
      value={{
        modalInstances,
        expiredToken,
        resetSession,
        setModalInstances,
        isLoading,        
        setIsLoading,
        deleteUsers,
        deleteModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export { ModalProvider };
export { ModalContext };
