import axios from "axios";
import { baseurl } from './Constants';
import { getFromLocal, clearLocal } from '../utils/generic';
import { infoDialog, errorToast } from '../components/Generic/Generic';
import endpoint from './endpoints';
const user = getFromLocal("user");

const instance = axios.create({
    baseURL: baseurl || ""
});

var headers = {
    'Content-Type': 'application/json'
}

const handleTokenExpired = () => {
    infoDialog("Your session get expired!, Please signin again", () => {
        var credentials = {
            UserId: parseInt(user["userId"])
        };
        API.post(endpoint.UserLogout, credentials, (isSuccess, response) => {
            if (isSuccess) {
                clearLocal();
                window.location = '/auth/login';
            }
            else {
                errorToast("logout failed")
            }
        })
    })
}
export default class API {
    static get(url, callback) {
        headers["Authorization"] = 'Bearer ' + getFromLocal("token");
        return axios.get(url, { headers: headers }).then((response) => {
            callback(true, response.data);
        }).catch((error) => {
            // console.log(`${url}--err-->`, error.response)
            if (error.response?.status === 401) {
            
                handleTokenExpired()
            } else {
                callback(false, error.response);
            }
        });
    }

    static getWithHeader(url, requestHeader, callback) {
        requestHeader["Content-Type"] = "application/json";
        requestHeader["Authorization"] = 'Bearer ' + getFromLocal("token");
        return axios.get(url, { headers: requestHeader }).then((response) => {
            callback(true, response.data);
        }).catch((error) => {
            // console.log(`${url}--err-->`, error.response)
            if (error.response?.status === 401) {
                console.log('errorerror---->', error)
                handleTokenExpired()
            } else {
                callback(false, error.response);
            }
        });
    }

    static getByQueryWithHeader(url, requestHeader, data, callback) {
        requestHeader["Content-Type"] = "application/json";
        requestHeader["Authorization"] = 'Bearer ' + getFromLocal("token");
        return axios.get(url, { params: data, headers: requestHeader }).then((response) => {
            callback(true, response.data);
        }).catch((error) => {
            //console.log(`${url}--err-->`, error.response)
            if (error.response?.status === 401) {
                // console.log('errorerror---->', error)
                handleTokenExpired()
            } else {
                callback(false, error.response);
            }
        });
    }

    static getByQuery(url, data, callback) {
        headers["Authorization"] = 'Bearer ' + getFromLocal("token");
        return axios.get(url, { params: data, headers: headers }).then((response) => {
            callback(true, response.data);
        }).catch((error) => {
            //console.log(`${url}--err-->`, error.response)
            if (error.response?.status === 401) {
                // console.log('errorerror---->', error)
                handleTokenExpired()
            } else {
                callback(false, error.response);
            }
        });
    }

    static post(url, data, callback) {
        headers["Authorization"] = 'Bearer ' + getFromLocal("token");
        return instance.post(url, data, { headers: headers })
            .then((response) => {
                callback(true, response.data);
            })
            .catch((error) => {
                //console.log(`${url}--err-->`, error.response)
                if (error.response?.status === 401) {
                    // console.log('errorerror---->', error)
                    handleTokenExpired()
                } else {
                    callback(false, error.response);
                }
            });
    }

    static postWithHeader(url, requestHeader, data, callback) {
        requestHeader["Content-Type"] = "application/json";
        requestHeader["Authorization"] = 'Bearer ' + getFromLocal("token");
        return instance.post(url, data, { headers: requestHeader })
            .then((response) => {
                callback(true, response.data);
            })
            .catch((error) => {
                //console.log(`${url}--err-->`, error.response)
                if (error.response?.status === 401) {
                    // console.log('errorerror---->', error)
                    handleTokenExpired()
                } else {
                    callback(false, error.response);
                }
            });
    }
    static deleteWithHeader(url, requestHeader, data, callback) {
        requestHeader["Content-Type"] = "application/json";
        requestHeader["Authorization"] = 'Bearer ' + getFromLocal("token");
        return instance.delete(url, { headers: requestHeader }, data)
            .then((response) => {
                callback(true, response.data);
            })
            .catch((error) => {
                //console.log(`${url}--err-->`, error.response)
                if (error.response?.status === 401) {
                    // console.log('errorerror---->', error)
                    handleTokenExpired()
                } else {
                    callback(false, error.response);
                }
            });
    }
}
