import React from "react";
import { Modal as CustomModal } from "react-responsive-modal";
import { Button } from "../sp-button/Button";

import "react-responsive-modal/styles.css";

export const Modal = (props) => {
  const {
    // for data sent via context provider
    modalInstance,
    setModalInstances,
    // for data passed as children
    open,
    setOpen,
    onClose,
    children,
    title,
    // common props
    blockScroll = true,
    closeOnOverlayClick = false,
    showCloseIcon = false,
    initialFocusRef = false,
    ...rest
  } = props || {};

  const {
    showModal,
    content,
    handleDeleteOk,
    handleDeleteCancel,
    reset,
    stayActive,
    logOut,
  } = modalInstance || {};

  if (content) {
    return (
      <CustomModal
        {...rest}
        open={showModal}
        onClose={showModal}
        title={title}
        blockScroll={blockScroll}
        closeOnOverlayClick={closeOnOverlayClick}
        showCloseIcon={showCloseIcon}
        initialFocusRef={initialFocusRef}
        classNames={{ modal: "rounded " }}
        center
      >
        <div>
          <p className="h5 p-3 text-center">{content}</p>
          <div className="d-flex justify-content-end mt-2">
            {handleDeleteCancel && (
              <Button
                onClick={handleDeleteCancel}
                className="rounded me-2"
                size="md"
                title="Cancel"
                variant="grey2"
              />
            )}
            {handleDeleteOk && (
              <Button
                onClick={handleDeleteOk}
                className="rounded"
                size="md"
                title="Ok"
              />
            )}
          </div>

          {/* used for session reset container only */}
          {reset && (
            <div className="d-flex justify-content-end mt-2">
              <Button
                onClick={logOut}
                className="rounded me-2"
                size="md"
                title="Log me out"
                variant="grey2"
              />

              <Button
                onClick={stayActive}
                className="rounded"
                size="md"
                title="Keep me signed in"
              />
            </div>
          )}
        </div>
      </CustomModal>
    );
  }

  if (children) {
    return (
      <CustomModal
        {...rest}
        open={open}
        onClose={onClose}
        title={title}
        blockScroll={blockScroll}
        closeOnOverlayClick={closeOnOverlayClick}
        showCloseIcon={showCloseIcon}
        center
        initialFocusRef={initialFocusRef}
        focusTrapped={false}
      >
        {children}
      </CustomModal>
    );
  }

  return <></>;
};
