import React, { useState, useEffect, useContext } from "react";

import moment from "moment";
import "./lgtcAccount.scss";
import { getFromLocal } from "../../services/storage/LocalStorage";
import { errorToast, successToast } from "../../componentsNew/Toast/Toast";
import { PageDetails } from "../../componentsNew/page-details/PageDetails";
import { usePagination } from "../../hooks/usePagination";
import ArrowLeft from "../../../assets/svg/NewIcons/arrow_left_white.svg";
import ArrowRight from "../../../assets/svg/NewIcons/arrow_right_white.svg";
import {
  Badge,
  InputSearch,
  Button,
  Modal,
  // Input,
  Dropdown,
  DatePickers,
} from "../../componentsNew/core";
import LGTCAccount from "./LGTCAccount";
import { useModal } from "../../hooks/useModal";
import { ModalContext } from "../../services/context/modal-context/State";
import { ENDPOINTS, getApi, deleteApi, postApi } from "../../services/http";
import ViewIcon from "../../../assets/svg/NewIcons/view.svg";
import EditIcon from "../../../assets/svg/NewIcons/edit.svg";
import DeleteIcon from "../../../assets/svg/NewIcons/delete.svg";
import LGTCViewTraining from "./LGTCViewTraining";
import SortValue from "../../helper/sort";
import ConstructObject from "../../hooks/helper";

import { RemoveModalInstance } from "../../helper";
import { useHistory } from "react-router-dom";

export default function LGTCTrainings(props) {
  const history = useHistory();

  var end = new Date();
  end.setHours(0, 0, 0, 0);
  var start = new Date();
  start.setHours(0, 0, 0, 0);
  start.setDate(start.getDate() - 90);

  const initialState = {
    pilotRank: "",
    aircraftType: "",
    competencies: [],
    trainingTypes: [],
    startDate: moment(start).format("YYYY-MM-DD"),
    endDate: moment(end).format("YYYY-MM-DD"),
    score: "",
    trainingResult: "",
  };

  const [PayLoad, setPayload] = useState(initialState);
  const [, setAdd] = useState(false);
  const [sort, setSort] = useState("");
  const [tableSortKey, setTableSortKey] = useState("");
  const [isUpdate, setUpdate] = useState(false);
  const [isView, setView] = useState(false);
  const [isDelete, setDelete] = useState(false);

  const [formID, setFormID] = useState("");
  const [formValues, setFormDetails] = useState();
  const [formHeaderValues, setFormHeaderValues] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [pilotId, setpilotId] = useState("");
  const [trainingDate, settrainingDate] = useState("");
  const [pilotBarcode, setPilotBarcode] = useState("");
  const [instructorBarcode, setInstructorBarcode] = useState("");

  const user = getFromLocal("user");
  const userId = parseInt(user?.userId);
  const [trainingData, setTrainingData] = useState([]);
  const [searchText, setSearchText] = useState("");

  const [trainingDataBackupData, setTrainingDataBackupData] = useState([]);
  const { pagination, rowItems, handlePagination } =
    usePagination(trainingData);
  const { handleExpiredToken } = useModal();
  const { setIsLoading } = useContext(ModalContext);
  const modal = useContext(ModalContext);
  const [aircraftTypeListDrop, setAircraftTypeListDrop] = useState([]);
  const [competenciesListDrop, setCompetenciesListDrop] = useState([]);
  const [trainingTypesListDrop, setTrainingTypesListDrop] = useState([]);
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);

  const pilotRanks = [
    {
      label: "Select",
      value: "",
    },
    {
      label: "FO",
      value: "FO",
    },
    {
      label: "CPT",
      value: "CPT",
    },
    {
      label: "FA",
      value: "FA",
    },
    {
      label: "PU",
      value: "PU",
    },
    {
      label: "SFA",
      value: "SFA",
    },
  ];

  const trainingResult = [
    {
      label: "Select",
      value: "",
    },
    {
      label: "Satisfactory",
      value: "Satisfactory",
    },
    {
      label: "Unsatisfactory",
      value: "Unsatisfactory",
    },
    {
      label: "Passed",
      value: "Passed",
    },
    {
      label: "Failed",
      value: "Failed",
    },
  ];

  const score = [
    {
      label: "Select",
      value: "",
    },
    {
      label: "1",
      value: "1",
    },
    {
      label: "2",
      value: "2",
    },
    {
      label: "3",
      value: "3",
    },
    {
      label: "4",
      value: "4",
    },
    {
      label: "5",
      value: "5",
    },
  ];

  const updatePayload = (key, value) => {
    if (key === "competencies" || key === "trainingTypes") {
      let val = value?.map((x) => x.value);
      setPayload({ ...PayLoad, [key]: val });
    } else {
      setPayload({ ...PayLoad, [key]: value });
    }
  };

  useEffect(() => {
    handlePagination();
  }, [trainingData]);

  useEffect(() => {
    getTrainingData();
    getairCraftType();
    fetchCategory();
    getForm();
  }, []);

  const getForm = async () => {
    setIsLoading(true);
    var getData = { EntityID: 3, ChildEntityId: 0 };
    const response = await postApi(`${ENDPOINTS.GET_FORM}`, getData);
    if (response) {
      if (response.status == 200) {
        setIsLoading(false);
        let val = response.data.map((el) => ({
          value: el.Questionarie,
          label: el.Questionarie,
        }));

        setCompetenciesListDrop(val);
      } else if (response.status == 401) {
        setIsLoading(false);
        handleExpiredToken();
      } else {
        setIsLoading(false);
        errorToast(response.data.statusText);
      }
    }
  };

  const fetchCategory = async () => {
    setIsLoading(true);
    const response = await getApi(`${ENDPOINTS.GET_TRAINING_MASTER_DATA}`);
    if (response) {
      if (response.status == 200) {
        setIsLoading(false);
        let val = response.data?.trainingCategories
          ?.find((item) => "Corrective" === item?.category)
          ?.trainingTypes?.map((el) => ({
            value: el.type,
            label: el.type,
          }));
        setTrainingTypesListDrop(val);
      } else if (response.status == 401) {
        setIsLoading(false);
        handleExpiredToken();
      } else {
        setIsLoading(false);
        errorToast("Failed to fetch");
      }
    }
  };

  async function getairCraftType() {
    setIsLoading(true);
    var airCraftType = {
      EntityKey: "tbl_lites_training_aircraft_master",
    };
    const response = await getApi(`${ENDPOINTS.GET_MASTER}`, {
      params: airCraftType,
    });
    if (response) {
      if (response.status == 200) {
        setIsLoading(false);
        let val = response.data.map((el) => ({
          value: el.Name,
          label: el.Name,
        }));
        val.unshift({
          value: "",
          label: "Select",
        });

        setAircraftTypeListDrop(val);
      } else if (response.status == 401) {
        setIsLoading(false);
        handleExpiredToken();
      } else {
        setIsLoading(false);
        errorToast(response.statusText);
      }
    }
  }

  const handleReset = async (e) => {
    setStartDate(start);
    setEndDate(end);
    setPayload(initialState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    getTrainingData();
  };

  const getTrainingData = async () => {
    setIsLoading(true);
    var headers = {
      UserId: userId,
    };
    var data = PayLoad;
    const response = await postApi(
      `${ENDPOINTS.GETFILTEREDTRAININGFORMS}`,
      data,
      {
        headers: headers,
      }
    );
    if (response) {
      if (response.status == 200) {
        setTrainingData(response?.data);
        setTrainingDataBackupData(response?.data);

        setIsLoading(false);
      } else if (response.status == 401) {
        setIsLoading(false);
        handleExpiredToken();
      } else {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    var user = getFromLocal("user");
    var pageName = {};
    pageName = user.userRolePermissions;
    var pageActions = pageName.filter((r) => r.pageName === "Trainings");
    var actions = pageActions.map((a) => a.pageActions);
    if (actions.length > 0) {
      var action = actions[0].map((b) => b.actionName);
      action.forEach(actionsList);
      function actionsList(action, index, arr) {
        if (action === "Add") {
          setAdd(true);
        }
        if (action === "Update") {
          setUpdate(true);
        }
        if (action === "View") {
          setView(true);
        }
        if (action === "Delete") {
          setDelete(true);
        }
      }
    }
  }, []);

  const handleChangeWithLib = (value) => {
    let newRows = [];
    setSearchText(value);
    if (value) {
      let searchTxt = value.toString().trim().toLowerCase();
      newRows = trainingDataBackupData?.filter((value) => {
        return (
          (value.traineeId &&
            value.traineeId.toString().toLowerCase().includes(searchTxt)) ||
          (value.traineeName &&
            value.traineeName.toLowerCase().includes(searchTxt)) ||
          (value.traineeCompany &&
            value.traineeCompany.toLowerCase().includes(searchTxt)) ||
          (value.trainingCategory &&
            value.trainingCategory.toLowerCase().includes(searchTxt)) ||
          (value.aircraftType &&
            value.aircraftType.toLowerCase().includes(searchTxt)) ||
          (value.trainer &&
            value.trainer.toString().toLowerCase().includes(searchTxt)) ||
          (value.trainingDate &&
            value.trainingDate.toString().toLowerCase().includes(searchTxt)) ||
          (value.score && value.score.toLowerCase().includes(searchTxt)) ||
          (value.result &&
            value.result.toString().toLowerCase().includes(searchTxt))
        );
      });
    } else {
      newRows = [...trainingDataBackupData];
    }
    setTrainingData(newRows);
  };

  const getFormDetails = async (formData) => {
    setIsLoading(true);
    let headers = {
      UserId: userId,
    };
    const response = await getApi(
      `${ENDPOINTS.GET_TRAINING_FORM_DETAILS}/${formData}`,
      {
        headers: headers,
      }
    );
    if (response) {
      if (response.status == 200) {
        setIsLoading(false);
        let headerDetails = response.data["trainingFormMasterData"];
        setFormHeaderValues(headerDetails[0]);
        setFormID(headerDetails[0]["formId"]);
        setpilotId(headerDetails[0]["idNumber"]);
        settrainingDate(headerDetails[0]["trainingDateStr"]);
        let recordsList = response.data["trainingFormScoreDetails"];
        if (headerDetails.length > 0 && recordsList.length > 0) {
          let headings = [];
          const map = new Map();
          for (const item of recordsList) {
            if (!map.has(item.headerCategory)) {
              map.set(item.headerCategory, true);
              headings.push({
                name: item.headerCategory,
              });
            }
          }

          // let items = [];
          let itemsLeftPanel = [];
          let itemsRightPanel = [];

          if (headings.length > 0) {
            var x = [];
            var y = [];
            for (var i = 0; i < headings.length; i++) {
              //if (i % 2 == 0) {
              x.push(headings[i]);
              //} else {
              //y.push(headings[i]);
              //}
            }

            for (const [, value] of x.entries()) {
              var c = [];
              c = recordsList.filter((s) => s.headerCategory === value.name);
              var html = (
                <div>
                  <table className="formTable" style={{ marginBottom: "0px" }}>
                    <tbody>
                      <tr>
                        <th colSpan="6" className="content-color-modal">
                          {value.name}
                        </th>
                      </tr>
                      {c.map((item, index) => {
                        return (
                          <tr>
                            <td className="content-color-modal">
                              {item.questionarie}
                            </td>
                            <td width="30" align="center">
                              {item.score === 1 ? "X" : ""}
                            </td>
                            <td width="30" align="center">
                              {item.score === 2 ? "X" : ""}
                            </td>
                            <td width="30" align="center">
                              {item.score === 3 ? "X" : ""}
                            </td>
                            <td width="30" align="center">
                              {item.score === 4 ? "X" : ""}
                            </td>
                            <td width="30" align="center">
                              {item.score === 5 ? "X" : ""}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              );

              itemsLeftPanel.push(html);
            }

            for (const [, value] of y.entries()) {
              let c = [];
              c = recordsList.filter((s) => s.headerCategory === value.name);
              let html = (
                <div>
                  <table className="formTable" style={{ marginBottom: "0px" }}>
                    <tbody>
                      <tr>
                        <th colSpan="6" className="content-color-modal">
                          {value.name}
                        </th>
                      </tr>
                      {c.map((item, index) => {
                        return (
                          <tr>
                            <td className="content-color-modal">
                              {item.questionarie}
                            </td>
                            <td width="30" align="center">
                              {item.score === 1 ? "X" : ""}
                            </td>
                            <td width="30" align="center">
                              {item.score === 2 ? "X" : ""}
                            </td>
                            <td width="30" align="center">
                              {item.score === 3 ? "X" : ""}
                            </td>
                            <td width="30" align="center">
                              {item.score === 4 ? "X" : ""}
                            </td>
                            <td width="30" align="center">
                              {item.score === 5 ? "X" : ""}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              );

              itemsRightPanel.push(html);
            }

            let resp = (
              <div className="row">
                <div className="col-md-12">
                  <table style={{ marginBottom: "0px" }} width="100%">
                    <tbody>
                      {itemsLeftPanel.length > 0 ? (
                        <tr>
                          <td
                            style={{
                              borderLeft: "0px",
                              borderTop: "0px",
                              borderBottom: "0px",
                            }}
                          ></td>
                          <td
                            width="30"
                            align="center"
                            style={{ borderTop: "0px", borderBottom: "0px" }}
                          >
                            1
                          </td>
                          <td
                            width="30"
                            align="center"
                            style={{ borderTop: "0px", borderBottom: "0px" }}
                          >
                            2
                          </td>
                          <td
                            width="30"
                            align="center"
                            style={{ borderTop: "0px", borderBottom: "0px" }}
                          >
                            3
                          </td>
                          <td
                            width="30"
                            align="center"
                            style={{ borderTop: "0px", borderBottom: "0px" }}
                          >
                            4
                          </td>
                          <td
                            width="30"
                            align="center"
                            style={{ borderTop: "0px", borderBottom: "0px" }}
                          >
                            5
                          </td>
                        </tr>
                      ) : null}
                      <tr>
                        <td
                          colSpan="6"
                          style={{ padding: "0px", border: "0px" }}
                        >
                          <div>{itemsLeftPanel}</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-md-6 no-padding-left">
                  <table style={{ marginBottom: "0px" }} width="100%">
                    <tbody>
                      {itemsRightPanel.length > 0 ? (
                        <tr>
                          <td
                            style={{
                              borderLeft: "0px",
                              borderTop: "0px",
                              borderBottom: "0px",
                            }}
                          ></td>
                          <td
                            width="30"
                            align="center"
                            style={{ borderTop: "0px", borderBottom: "0px" }}
                          >
                            1
                          </td>
                          <td
                            width="30"
                            align="center"
                            style={{ borderTop: "0px", borderBottom: "0px" }}
                          >
                            2
                          </td>
                          <td
                            width="30"
                            align="center"
                            style={{ borderTop: "0px", borderBottom: "0px" }}
                          >
                            3
                          </td>
                          <td
                            width="30"
                            align="center"
                            style={{ borderTop: "0px", borderBottom: "0px" }}
                          >
                            4
                          </td>
                          <td
                            width="30"
                            align="center"
                            style={{
                              borderRight: "0px",
                              borderTop: "0px",
                              borderBottom: "0px",
                            }}
                          >
                            5
                          </td>
                        </tr>
                      ) : null}
                      <tr>
                        <td
                          colSpan="6"
                          style={{ padding: "0px", border: "0px" }}
                        >
                          <div>{itemsRightPanel}</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style={{ clear: "both" }}></div>
              </div>
            );

            setFormDetails(resp);
          }
        }
        if (response?.data["qrCode"]) {
          let barCode = response?.data["qrCode"]["pilotQRCode"];
          let barCodeR = response?.data["qrCode"]["instructorQRCode"];
          setPilotBarcode(barCode);
          setInstructorBarcode(barCodeR);
        }
        setShowModal(true);
      } else if (response.status == 401) {
        setIsLoading(false);
        handleExpiredToken();
      } else {
        setIsLoading(false);
        errorToast(response.statusText);
      }
    }
  };

  const handleDeleteCancel = (modalId) => {
    let newInstance = RemoveModalInstance(modal, modalId);
    modal.setModalInstances(newInstance);
  };

  const handleDeleteOk = async (rowId, modalId) => {
    setIsLoading(true);
    let data = {
      EntityID: rowId,
      ModifiedBy: userId,
    };
    let headers = {
      UserId: userId,
    };
    let response = await deleteApi(`${ENDPOINTS.SAVE_FORM_TRAINING}`, data, {
      headers: headers,
    });
    if (response) {
      if (response.status == 200) {
        successToast("Training deleted successfully");
        getTrainingData();
      } else if (response.status == 401) {
        setIsLoading(false);
        handleExpiredToken();
      } else {
        setIsLoading(false);
      }
    }
    let newInstance = RemoveModalInstance(modal, modalId);
    modal.setModalInstances(newInstance);
  };

  const handleDelete = async (rowID) => {
    modal.deleteModal(
      rowID,
      "Are you sure you want to delete this training?",
      handleDeleteOk,
      handleDeleteCancel
    );
  };

  const SortBy = (key) => {
    if (tableSortKey == key) {
      if (sort == "") {
        setSort("asc");
        let val = trainingData.sort(SortValue(key));
        settrainingDate(val);
        handlePagination();
      } else if (sort == "asc") {
        setSort("desc");
        let val = trainingData.sort(SortValue(`-${key}`));
        settrainingDate(val);
        handlePagination();
      } else if (sort == "desc") {
        setSort("asc");
        let val = trainingData.sort(SortValue(key));
        settrainingDate(val);
        handlePagination();
      } else {
        setSort("");
      }
    } else {
      setTableSortKey(key);
      setSort("asc");
      let val = trainingData.sort(SortValue(key));
      settrainingDate(val);
      handlePagination();
    }
  };

  const ViewField = (rowID) => {
    return (
      <Badge
        className="rounded-3"
        size="xs"
        variant="white"
        icon={ViewIcon}
        title="View details"
        onClick={() => getFormDetails(rowID)}
      />
    );
  };

  const DeleteField = (rowID) => {
    return (
      <Badge
        className="rounded-3"
        size="xs"
        variant="white"
        icon={DeleteIcon}
        title="Delete"
        onClick={() => handleDelete(rowID)}
      />
    );
  };

  const EditField = (rowID) => {
    return (
      <Badge
        className="rounded-3"
        size="xs"
        variant="white"
        icon={EditIcon}
        title="sign form"
        onClick={() => {
          history.push({
            pathname: "/dashboard/new-design/lgtc/trainings/edit-training",
            state: { formID: rowID },
          });
        }}
      />
    );
  };

  const TableControls = (
    <div className="d-flex justify-content-end">
      <div className="flex-wrap d-flex align-items-center justify-content-center">
        <div className="">
          <div className="col-md-12 col-sm-4 col-8">
            <InputSearch
              placeholder="search here..."
              className="w-100"
              onChange={handleChangeWithLib}
              value={searchText}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-3 d-flex align-items-center justify-content-center pe-0">
        <Badge
          className="rounded-50"
          variant="primary"
          size="xl"
          icon={ArrowLeft}
          title="Previous Page"
          disabled={!pagination?.prevPageUrl}
          onClick={() => handlePagination("prev")}
        />
        <Badge
          className="rounded-50 ms-2"
          variant="primary"
          size="xl"
          icon={ArrowRight}
          title="Next Page"
          disabled={!pagination?.nextPageUrl}
          onClick={() => handlePagination("next")}
        />
      </div>
    </div>
  );
  const ChildrenModal = (
    <>
      <LGTCViewTraining
        formId={formID}
        pilotBarcode={pilotBarcode}
        instructorBarcode={instructorBarcode}
        formHeaderValues={formHeaderValues}
        setShowModal={setShowModal}
        formValues={formValues}
        pilotId={pilotId}
        trainingDate={trainingDate}
      ></LGTCViewTraining>
    </>
  );

  const Table = (
    <>
      <div>
        <div className="">
          <div className="col-6 mb-2">
            <span className="h4 text-grey1 ">Search</span>
          </div>
          <div className="col-12 p-0">
            <table className="form-table-ta">
              <tbody>
                <tr>
                  <td className="h6-web">Rank</td>
                  <td>
                    <Dropdown
                      selectOptions={pilotRanks}
                      containerClassName={"w-100 p-0"}
                      value={ConstructObject(pilotRanks, PayLoad.pilotRank)}
                      onChange={(e) => {
                        updatePayload("pilotRank", e);
                      }}
                    />
                  </td>
                  <td className="h6-web">Aircraft Type</td>
                  <td>
                    <Dropdown
                      selectOptions={aircraftTypeListDrop}
                      containerClassName={"w-100 p-0"}
                      value={ConstructObject(
                        aircraftTypeListDrop,
                        PayLoad.aircraftType
                      )}
                      onChange={(e) => {
                        updatePayload("aircraftType", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="h6-web">Training Result</td>
                  <td>
                    <Dropdown
                      selectOptions={trainingResult}
                      containerClassName={"w-100 p-0"}
                      value={ConstructObject(
                        trainingResult,
                        PayLoad.trainingResult
                      )}
                      onChange={(e) => {
                        updatePayload("trainingResult", e);
                      }}
                    />
                  </td>
                  <td className="h6-web">Score</td>
                  <td>
                    <Dropdown
                      selectOptions={score}
                      containerClassName={"w-100 p-0"}
                      value={ConstructObject(score, PayLoad.score)}
                      onChange={(e) => {
                        updatePayload("score", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="h6-web">Training Types</td>
                  <td colSpan={3}>
                    <div className="d-flex flex-wrap ">
                      <Dropdown
                        selectOptions={trainingTypesListDrop}
                        containerClassName={"w-100 p-0"}
                        value={ConstructObject(
                          trainingTypesListDrop,
                          PayLoad.trainingTypes
                        )}
                        isMulti={true}
                        onChange={(e) => {
                          updatePayload("trainingTypes", e);
                        }}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="h6-web">Competencies</td>
                  <td colSpan={3}>
                    <Dropdown
                      selectOptions={competenciesListDrop}
                      containerClassName={"w-100 p-0"}
                      value={ConstructObject(
                        competenciesListDrop,
                        PayLoad.competencies
                      )}
                      isMulti={true}
                      onChange={(e) => {
                        updatePayload("competencies", e);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="h6-web">Start Date</td>
                  <td>
                    <DatePickers
                      selected={startDate}
                      onChange={(e) => {
                        setStartDate(e);
                        updatePayload(
                          "startDate",
                          moment(e).format("YYYY-MM-DD")
                        );
                      }}
                      dateFormat="MMMM d, yyyy"
                      className="rounded w-100  border border-1 h7-web m-0 date-picker-bg picker-height"
                    />
                  </td>
                  <td className="h6-web">End Date</td>
                  <td>
                    <DatePickers
                      selected={endDate}
                      onChange={(e) => {
                        setEndDate(e);
                        updatePayload(
                          "endDate",
                          moment(e).format("YYYY-MM-DD")
                        );
                      }}
                      dateFormat="MMMM d, yyyy"
                      className="rounded w-100  border border-1 h7-web m-0 date-picker-bg picker-height"
                    />
                  </td>
                </tr>

                <tr>
                  <td colSpan={2}></td>
                  <td>
                    <Button
                      className="rounded h6-web w-100"
                      size="lg"
                      title={"Clear Filters"}
                      variant="grey2"
                      onClick={(e) => handleReset(e)}
                    />
                  </td>
                  <td>
                    <Button
                      className="rounded h6-web w-100"
                      size="lg"
                      title={"Search"}
                      variant="primary"
                      onClick={(e) => handleSubmit(e)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="col-6">
            <span className="h4 text-grey1">Trainings</span>
          </div>
          <div className="col-6 p-0">{TableControls}</div>
        </div>

        <div className=" mt-2 mb-2 table-responsive ">
          <table className="table table-bordered shadow-sm align-middle ">
            <thead className="bg-grey h7-table text-grey1">
              <tr className="text-center text-nowrap">
                <td>Actions</td>
                <td>Status</td>
                <td onClick={() => SortBy("traineeId")}>
                  Trainee ID{" "}
                  <i
                    className={
                      tableSortKey == "traineeId"
                        ? sort == "asc"
                          ? "fa fa-sort-asc"
                          : "fa fa-sort-desc"
                        : "fa fa-fw fa-sort"
                    }
                  ></i>
                </td>
                <td onClick={() => SortBy("traineeName")}>
                  Trainee Name{" "}
                  <i
                    className={
                      tableSortKey == "traineeName"
                        ? sort == "asc"
                          ? "fa fa-sort-asc"
                          : "fa fa-sort-desc"
                        : "fa fa-fw fa-sort"
                    }
                  ></i>
                </td>
                <td onClick={() => SortBy("traineeCompany")}>
                  Trainee Company{" "}
                  <i
                    className={
                      tableSortKey == "traineeCompany"
                        ? sort == "asc"
                          ? "fa fa-sort-asc"
                          : "fa fa-sort-desc"
                        : "fa fa-fw fa-sort"
                    }
                  ></i>
                </td>
                <td onClick={() => SortBy("trainingCategory")}>
                  Training Type{" "}
                  <i
                    className={
                      tableSortKey == "trainingCategory"
                        ? sort == "asc"
                          ? "fa fa-sort-asc"
                          : "fa fa-sort-desc"
                        : "fa fa-fw fa-sort"
                    }
                  ></i>
                </td>
                <td onClick={() => SortBy("aircraftType")}>
                  Aircraft Type{" "}
                  <i
                    className={
                      tableSortKey == "aircraftType"
                        ? sort == "asc"
                          ? "fa fa-sort-asc"
                          : "fa fa-sort-desc"
                        : "fa fa-fw fa-sort"
                    }
                  ></i>
                </td>
                <td onClick={() => SortBy("trainer")}>
                  Trainer{" "}
                  <i
                    className={
                      tableSortKey == "trainer"
                        ? sort == "asc"
                          ? "fa fa-sort-asc"
                          : "fa fa-sort-desc"
                        : "fa fa-fw fa-sort"
                    }
                  ></i>
                </td>
                <td onClick={() => SortBy("trainingDate")}>
                  Training Date{" "}
                  <i
                    className={
                      tableSortKey == "trainingDate"
                        ? sort == "asc"
                          ? "fa fa-sort-asc"
                          : "fa fa-sort-desc"
                        : "fa fa-fw fa-sort"
                    }
                  ></i>
                </td>
                <td onClick={() => SortBy("score")}>
                  Score{" "}
                  <i
                    className={
                      tableSortKey == "score"
                        ? sort == "asc"
                          ? "fa fa-sort-asc"
                          : "fa fa-sort-desc"
                        : "fa fa-fw fa-sort"
                    }
                  ></i>
                </td>
                <td onClick={() => SortBy("result")}>
                  Result{" "}
                  <i
                    className={
                      tableSortKey == "result"
                        ? sort == "asc"
                          ? "fa fa-sort-asc"
                          : "fa fa-sort-desc"
                        : "fa fa-fw fa-sort"
                    }
                  ></i>
                </td>
              </tr>
            </thead>
            <tbody className="h8 text-table-body">
              {rowItems && rowItems.length > 0 ? (
                rowItems.map((row, rowIndex) => (
                  <tr className="text-center" key={"row_" + rowIndex}>
                    <td>
                      <div className="d-flex justify-content-center">
                        {isView ? ViewField(row.formId) : null}
                        {isUpdate ? EditField(row.formId) : null}
                        {isDelete ? DeleteField(row.formId) : null}
                      </div>
                    </td>
                    <td>
                      {row.pilotSigned &&
                      row.instructorSigned &&
                      row.directorSigned
                        ? "Signed"
                        : "Not signed"}
                    </td>
                    <td>{row.traineeId}</td>
                    <td>{row.traineeName}</td>
                    <td>{row.traineeCompany}</td>
                    <td>{row.trainingCategory}</td>
                    <td>{row.aircraftType}</td>
                    <td>{row.trainer}</td>
                    <td>{row.trainingDate}</td>
                    <td>{row.score}</td>
                    <td>{row.result}</td>
                  </tr>
                ))
              ) : (
                <tr className="text-center h6-web">No records found</tr>
              )}
            </tbody>
          </table>

          <PageDetails pagination={pagination} />

          {showModal && (
            <Modal
              open={showModal}
              onClose={() => setShowModal(false)}
              showCloseIcon={true}
              children={ChildrenModal}
              classNames={{
                modal: "training-modal",
                closeIcon: "training-modal-close-button",
              }}
            ></Modal>
          )}
        </div>
      </div>
    </>
  );

  return (
    <LGTCAccount breadCrumbTitle="Trainings" AddButtonShow={true}>
      {Table}
    </LGTCAccount>
  );
}
