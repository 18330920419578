import Home from "./app/pages/Dashboard/Dashboard";
import DashboardIcon from "./assets/svg/DashboardIcon.svg";
import ProfileIcon from "./assets/svg/ProfileIcon.svg";
import TrainingsIcon from "./assets/svg/TrainingsIcon.svg";
import DODashboard from "./assets/svg/NewIcons/dashboardDO.svg";
import DOSchedule from "./assets/svg/NewIcons/ScheduleDO.svg";

import userMenu from "./assets/svg/NewIcons/usermenu.svg";
import pilotMenu from "./assets/svg/NewIcons/pilotMenu.svg";

import Login from "./app/pages/Login/Login";

import LoginV2 from "./app/pages/login-account/LoginV2";

import Dashboard from "./app/pages/Dashboard/Dashboard";
import SignForm from "./app/pages/SignForm/SignForm";
import AddUser from "./app/pages/AddUser/Adduser";
import Users from "./app/pages/Users/Users";
import ChangePassword from "./app/pages/ChangePassword/ChangePassword";
import Profile from "./app/pages/Profile/Profile";
import PilotProfile from "./app/pages/PilotProfile/PilotProfile";
import Airports from "./app/pages/Airports/Airports";
import Aircrafts from "./app/pages/Aircrafts/Aircrafts";

import MyProfile from "./app/pages/MyProfile/MyProfile";
import DirectorDashboard from "./app/pages/Director/Dashboard/DirectorDashboard";
import DirectorDashboardV2 from "./app/pages/Director/Dashboard/DirectorDashboardV2";

import DirectorDashboardNew from "./app/pages/director-account/DirectorDashboard";
import DirectorTrainingScheduleNew from "./app/pages/director-account/DirectorTrainingSchedule";

import LGTCPilot from "./app/pages/lgtc-account/LGTCPilot";
import LGTCTrainings from "./app/pages/lgtc-account/LGTCTraining";

import TRAXParts from "./app/pages/Parts/TRAXParts";
import TRAXRequisitionRequests from "./app/pages/Parts/TRAXRequisitionRequests";
import TraxUsers from "./app/pages/Users/TraxUsers";
import TraxDashboard from "./app/pages/TraxDashboard/TraxDashboard";
import TrackRequisitionData from "./app/pages/TraxRequisition/trackrequisition";
import TraxDashboardShipping from "./app/pages/TraxDashboardShipping/TraxDashboardShipping";
import TraxDashboardReceiving from "./app/pages/TraxDashboardReceiving";

var routes = [
  {
    path: "/new-design/login",
    name: "Login",
    layout: "/auth",
    component: Login,
    visibleInMenu: false,
  },
  {
    path: "/login",
    name: "Login",
    layout: "/auth",
    component: LoginV2,
    visibleInMenu: false,
    isNewDesign: true,
    topHeader: false,
    footer: true,
    noHeaderColour: true,
  },
  {
    path: "/home",
    name: "Dashboard",
    layout: "/dashboard",
    component: Dashboard,
    icon: DashboardIcon,
    visibleInMenu: true,
  },
  {
    path: "/new-design/home",
    name: "Dashboard",
    layout: "/dashboard",
    component: Dashboard,
    icon: DashboardIcon,
    visibleInMenu: true,
    isNewDesign: true,
    topHeader: false,
    bottomHeader: true,
    footer: true,
  },
  {
    path: "/director",
    name: "Director Dashboard",
    layout: "/dashboard",
    component: DirectorDashboard,
    icon: DashboardIcon,
    visibleInMenu: true,
  },
  {
    path: "/new-design/director",
    name: "Director Dashboard",
    layout: "/dashboard",
    component: DirectorDashboardV2,
    icon: DashboardIcon,
    visibleInMenu: false,
    isNewDesign: true,
    topHeader: false,
    bottomHeader: true,
    footer: true,
  },
  {
    path: "/new-design/director/dashboard",
    name: "Director Dashboard",
    layout: "/dashboard",
    component: DirectorDashboardNew,
    icon: DODashboard,
    visibleInMenu: true,
    isNewDesign: true,
    topHeader: false,
    bottomHeader: true,
    footer: true,
  },
  {
    path: "/new-design/director/training-schedule",
    name: "Director Training Schedule",
    layout: "/dashboard",
    component: DirectorTrainingScheduleNew,
    icon: DOSchedule,
    visibleInMenu: true,
    isNewDesign: true,
    topHeader: false,
    bottomHeader: true,
    footer: true,
  },
  {
    path: "",
    name: "Dashboard",
    layout: "/dashboard",
    component: TraxDashboard,
    icon: DODashboard,
    visibleInMenu: true,
    isNewDesign: true,
    topHeader: false,
    bottomHeader: true,
    footer: true,
  },
  {
    path: "/new-design/lgtc/pilot",
    name: "Pilots",
    layout: "/dashboard",
    component: LGTCPilot,
    icon: pilotMenu,
    visibleInMenu: true,
    isNewDesign: true,
    topHeader: false,
    bottomHeader: true,
    footer: true,
  },
  {
    path: "/new-design/lgtc/trainings",
    name: "Trainings",
    layout: "/dashboard",
    component: LGTCTrainings,
    icon: DOSchedule,
    visibleInMenu: true,
    isNewDesign: true,
    topHeader: false,
    bottomHeader: true,
    footer: true,
  },
  {
    path: "/dashboard-shipping",
    name: "Dashboard Shipping",
    layout: "/dashboard",
    component: TraxDashboardShipping,
    icon: DOSchedule,
    visibleInMenu: true,
    isNewDesign: true,
    topHeader: false,
    bottomHeader: true,
    footer: true,
  },
  {
    path: "/dashboard-receiving",
    name: "Dashboard Receiving",
    layout: "/dashboard",
    component: TraxDashboardReceiving,
    icon: DOSchedule,
    visibleInMenu: true,
    isNewDesign: true,
    topHeader: false,
    bottomHeader: true,
    footer: true,
  },
  {
    path: "/parts",
    name: "Parts",
    layout: "/dashboard",
    component: TRAXParts,
    icon: DOSchedule,
    visibleInMenu: true,
    isNewDesign: true,
    topHeader: false,
    bottomHeader: true,
    footer: true,
  },
  {
    path: "/user",
    name: "Users",
    layout: "/dashboard",
    component: TraxUsers,
    icon: userMenu,
    visibleInMenu: true,
    isNewDesign: true,
    topHeader: false,
    bottomHeader: true,
    footer: true,
  },
  {
    path: "/requisition",
    name: "Requisition Request",
    layout: "/dashboard",
    component: TRAXRequisitionRequests,
    icon: DOSchedule,
    visibleInMenu: true,
    isNewDesign: true,
    topHeader: false,
    bottomHeader: true,
    footer: true,
  },
  {
    path: "/track-requisition",
    name: "Track Requisition",
    layout: "/dashboard",
    component: TrackRequisitionData,
    icon: DOSchedule,
    visibleInMenu: true,
    isNewDesign: true,
    topHeader: false,
    bottomHeader: true,
    footer: true,
  },
  {
    subMenu: true,
    name: "PERSONAL",
    menuItems: [
      {
        path: "/profile",
        name: "Profile",
        layout: "/dashboard",
        component: Profile,
        icon: ProfileIcon,
        visibleInMenu: false,
      },
      {
        path: "/pilot-profile",
        name: "Pilot Profile",
        layout: "/dashboard",
        component: PilotProfile,
        icon: ProfileIcon,
        visibleInMenu: true,
      },
      {
        path: "/myprofile",
        name: "My Profile",
        icon: ProfileIcon,
        layout: "/dashboard",
        component: MyProfile,
        visibleInMenu: true,
      },
      {
        path: "/adduser",
        name: "Add User",
        icon: ProfileIcon,
        layout: "/dashboard",
        component: AddUser,
        visibleInMenu: false,
      },
      {
        path: "/change-password",
        name: "Change Password",
        icon: ProfileIcon,
        layout: "/dashboard",
        component: ChangePassword,
        visibleInMenu: false,
      },
      {
        path: "/users",
        name: "Users",
        icon: ProfileIcon,
        layout: "/dashboard",
        component: Users,
        visibleInMenu: true,
      },
      {
        path: "/sign-form",
        name: "Training Type",
        icon: TrainingsIcon,
        layout: "/dashboard",
        component: SignForm,
        visibleInMenu: false,
      },

      {
        path: "/current",
        name: "Current",
        layout: "/dashboard",
        component: Home,
        visibleInMenu: false,
      },
      {
        path: "/pending",
        name: "Pending",
        layout: "/dashboard",
        component: Home,
        visibleInMenu: false,
      },
    ],
  },
  {
    subMenu: true,
    name: "Admin",
    menuItems: [
      {
        path: "/airports",
        name: "Airports",
        icon: TrainingsIcon,
        layout: "/dashboard",
        component: Airports,
        visibleInMenu: true,
      },
      {
        path: "/aircrafts",
        name: "Aircrafts",
        icon: TrainingsIcon,
        layout: "/dashboard",
        component: Aircrafts,
        visibleInMenu: true,
      },
    ],
  },
  {
    subMenu: false,
    name: "LGTC AND ACC",
    menuItems: [],
  },
];

export default routes;
